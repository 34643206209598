import { ButtonTypeMap } from "@mui/material/Button"
import { useTranslation } from "react-i18next"
import { DefaultComponentProps } from "@mui/material/OverridableComponent"
import { appThemeWarningColor, appThemeWarningDarkColor } from "../../app/Shared.Theme"
import { AbortIcon } from "../Shared.Icon"
import { GradientButton } from "./GradientButton"

interface CancelButtonProps {
  id?: string
  label?: string
  noIcon?: boolean
}

export const CancelButton = (props: CancelButtonProps & DefaultComponentProps<ButtonTypeMap>) => {
  const { id, label, disabled, startIcon, endIcon, onClick, type, noIcon } = props
  const { t } = useTranslation()

  return (
    <GradientButton
      id={id}
      type={type}
      onClick={onClick}
      endIcon={endIcon}
      disabled={disabled}
      startIcon={noIcon ? "" : startIcon || <AbortIcon />}
      startColor={appThemeWarningColor}
      endColor={appThemeWarningDarkColor}
      label={label || t("shared:button.abort")}
    />
  )
}
