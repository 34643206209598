import { Box, Typography } from "@mui/material"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { GridBaseColDef } from "@mui/x-data-grid/internals"
import { GridValueGetterParams } from "@mui/x-data-grid"
import { useHistory } from "react-router-dom"
import { useAppSelector } from "../../../hooks"
import { SingleLineTextField } from "../../../../uikit/input/SingleLineTextField"
import { filterArrayBySearchInput } from "../../../Shared.Utils"
import { DataGridTable } from "../../../../uikit/table/DataGridTable"
import { selectZevDonePeriodicBillings, selectVewaDonePeriodicBillings } from "../store/billingsSlice"
import {
  VewaPeriodicBillingItem,
  ZevPeriodicBillingItem,
} from "../../../../domain/portal/manager/billings/Billings.Model"
import { selectSelectedContractData } from "../store/contractSelectorSlice"
import { ProductType } from "../../../../data/generated-sources/openapi"

interface PeriodicBillingsDoneStatusProps {
  areaId: string
}

export const PeriodicBillingsDoneStatus = ({ areaId }: PeriodicBillingsDoneStatusProps) => {
  const history = useHistory()
  const { t } = useTranslation("settlements")
  const { contractId, contractProductType } = useAppSelector(selectSelectedContractData)
  const zevDoneBills = useAppSelector(selectZevDonePeriodicBillings)
  const vewaDoneBills = useAppSelector(selectVewaDonePeriodicBillings)

  const [filterQuery, setFilterQuery] = useState<string>("")
  const [billingsList, setBillingsList] = useState<ZevPeriodicBillingItem[] | VewaPeriodicBillingItem[]>()
  const isVewaProduct = contractProductType === ProductType.VEWA

  useEffect(() => {
    if (isVewaProduct) {
      return setBillingsList(vewaDoneBills)
    }
    setBillingsList(zevDoneBills)
  }, [zevDoneBills, vewaDoneBills])

  const filterZevData = (searchInput: string) => {
    if (zevDoneBills) {
      const filtered = filterArrayBySearchInput<ZevPeriodicBillingItem>(zevDoneBills, searchInput)
      setBillingsList(filtered)
    }
  }

  const filterVewaData = (searchInput: string) => {
    if (vewaDoneBills) {
      const filtered = filterArrayBySearchInput<VewaPeriodicBillingItem>(vewaDoneBills, searchInput)
      setBillingsList(filtered)
    }
  }

  const handleFilterInput = (searchInput: string) => {
    isVewaProduct ? filterVewaData(searchInput) : filterZevData(searchInput)
  }

  const doneBillingsColumns: Array<GridBaseColDef> = [
    {
      field: "datePeriod",
      headerName: t("section-list-header.bill.period"),
      flex: 200,
      sortComparator: (v1, v2) => (v1?.startDate > v2?.startDate ? 1 : -1),
      valueGetter: (params: GridValueGetterParams): string => params.row,
      renderCell: ({ value }) => <Typography variant="subtitle2">{value.datePeriod}</Typography>,
    },
    {
      field: "totalSum",
      headerName: t("section-list-header.bill.total"),
      flex: 150,
    },
    {
      field: "activeState",
      headerName: t("section-list-header.bill.status"),
      renderCell: ({ value }) => <>{t(`list-billing-status.${value}`)}</>,
      flex: 200,
    },
    {
      field: "contractType",
      headerName: t("section-list-header.bill.contractType"),
      valueGetter: (params: GridValueGetterParams): string => params.row?.productName || "-",
      flex: 200,
    },
  ]

  return (
    <Box p={0} pt={6}>
      <Typography variant="h5" fontWeight={400} mb={4}>
        {t("section.title.doneBills")}
      </Typography>
      <SingleLineTextField
        id="filter"
        name="filter"
        type="text"
        sx={{ width: "24rem", mb: 4 }}
        label={t("shared:form.search.label")}
        value={filterQuery}
        onChange={(event) => {
          setFilterQuery(event.target.value)
          handleFilterInput(event.target.value)
        }}
      />
      <DataGridTable<ZevPeriodicBillingItem | VewaPeriodicBillingItem>
        rows={billingsList || []}
        columns={doneBillingsColumns}
        redirectParamName={"id"}
        redirectDescription={t("")}
        getRowClassName={({ row }) => (row?.makeBill ? "RowClickable" : "RowNotClickable")}
        onRowClick={({ row }) => {
          if (isVewaProduct) {
            return history.push(`/${areaId}/contracts/${contractId}/billings/vewa/${row.id}/bills`)
          }
          if (!row?.makeBill) return
          history.push(`/${areaId}/contracts/${contractId}/billings/all/${row.id}/finalize`)
        }}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 25,
            },
          },
        }}
      />
    </Box>
  )
}
