import { useQuery } from "@tanstack/react-query"
import { getZevAnaliseByUtilityUnitId } from "../../../../domain/portal/admin/utility-units/UtilityUnits.Repository"
import { Medium, MeterReadingsCalcResolution } from "../../../../data/generated-sources/openapi"
import { isAdminPortal, useQueryDefaultOptions } from "../../../Shared.Utils"
import { useEffect, useState } from "react"
import { ChartTabsEnum } from "../analiyse/Analise.Enum"
import { getBarChartColors, vewaTabList } from "../analiyse/charts/chart.config"
import { useTranslation } from "react-i18next"
import { ErrorAlert } from "../../../../uikit/Shared.Alert"
import { getMeterReadingForUtilityUnit } from "../../../../domain/portal/manager/utilityUnit/UtilityUnit.Repository"
import VewaChartWrapper from "../analiyse/charts/VewaChartWrapper"
import DateUtils from "../../../../services/utils/DateUtils"

interface VewaBillingAnalyseInterface {
  utilityUnitId: string
  startDate: string
  endDate: string
  billFrom: string
  billTo: string
}

export const VewaBillingAnalyse = (props: VewaBillingAnalyseInterface) => {
  const { utilityUnitId, startDate, endDate, billFrom, billTo } = props
  const { t } = useTranslation()
  const [selectedTab, setSelectedTab] = useState<string>(ChartTabsEnum.HEAT)
  const [chartData, setChartData] = useState<unknown[]>()
  const [heatWaterTotals, setHeatWaterTotals] = useState<unknown>()

  const { data, isLoading, isError, remove, refetch } = useQuery(
    ["getUtilityUnitAnaliseForBilling"],
    () =>
      isAdminPortal()
        ? getZevAnaliseByUtilityUnitId(utilityUnitId, startDate, endDate, true, MeterReadingsCalcResolution.MONTH)
        : getMeterReadingForUtilityUnit(utilityUnitId, startDate, endDate, true, MeterReadingsCalcResolution.MONTH),
    {
      enabled: !!utilityUnitId,
      ...useQueryDefaultOptions,
    },
  )

  useEffect(() => {
    getFormattedHeatData()
  }, [data, selectedTab, utilityUnitId])

  useEffect(() => () => remove(), [])

  const getFormattedHeatData = () => {
    //todo: mockDataResponse replace it with data?.vewa?
    const total1 = data?.vewa?.data[selectedTab === Medium.HEAT ? Medium.HEAT : Medium.WARM_WATER]
    const total2 = data?.vewa?.data[selectedTab === Medium.HEAT ? Medium.COLD : Medium.COLD_WATER]
    // eslint-disable-next-line
    const combinedConsumption: any = {}

    if (total1) {
      // eslint-disable-next-line no-unsafe-optional-chaining
      for (const item of total1?.data) {
        const dateTime = item.dateTime
        const consumption = parseFloat(item.consumption)

        combinedConsumption[dateTime] = { produced: consumption }
      }
    }

    if (total2) {
      // eslint-disable-next-line no-unsafe-optional-chaining
      for (const item of total2?.data) {
        const dateTime = item.dateTime
        const consumption = parseFloat(item.consumption)

        if (combinedConsumption[dateTime]) {
          combinedConsumption[dateTime].energyHighTariff = consumption
        } else {
          combinedConsumption[dateTime] = { energyHighTariff: consumption }
        }
      }
    }
    const missingDates = DateUtils.addMonthsBetweenDates(billFrom, startDate)
    const combinedArray = Object.keys(combinedConsumption).map((dateTime) => ({
      dateTime,
      ...combinedConsumption[dateTime],
    }))
    if (combinedArray.length) {
      missingDates.reverse().forEach((date) => {
        if (!combinedConsumption[date]) {
          combinedArray.unshift({ dateTime: date, produced: 0, energyHighTariff: 0 })
        }
      })
    }
    const missingFutureMonths = DateUtils.addMonthsBetweenDates(
      DateUtils.addMonths(combinedArray[combinedArray.length - 1]?.dateTime, 1),
      DateUtils.addMonths(billTo, 1),
    )
    missingFutureMonths.forEach((date) => {
      combinedArray.push({ dateTime: date, produced: 0, energyHighTariff: 0 })
    })
    setChartData(combinedArray)
    setHeatWaterTotals({
      total1: total1?.totalConsumption ?? 0,
      total2: total2?.totalConsumption ?? 0,
    })
  }

  if (isError) return <ErrorAlert retry={refetch} message={t("error-codes:GENERIC_FATAL_ERROR")} />

  return (
    <div className="ckw-vewa-billing-chart-wrapper">
      <VewaChartWrapper
        meterReadingDateRange={MeterReadingsCalcResolution.YEAR}
        analiseData={{ totals: heatWaterTotals, data: chartData }}
        headerTabs={vewaTabList}
        handleDateChange={() => console.log("handleDateChange")}
        handleTimeChange={() => console.log("handleTimeChange")}
        handleSelectTab={setSelectedTab}
        barColors={getBarChartColors(selectedTab, t)}
        isLoading={isLoading}
        selectedTab={selectedTab}
        isBillingView={true}
      />
    </div>
  )
}
