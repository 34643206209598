import { apiHeaders } from "../../../../Domain.Calls"

import { ZevAdminIndividualParticipantBilling } from "./BillingsParticipant.Model"
import {
  IndividualParticipantBillingPricesUpdateRequest,
  IndividualParticipantBillingState,
  ServiceComponentFeature,
  ZevPricePackage,
} from "../../../../../data/generated-sources/openapi"
import { DOMAIN_DEPENDENCIES } from "../../../../../app/App.Config"
import { getProductName } from "./BillingsAllParticipant.Repository"

export const getAdminIndividualParticipantBillingDetailsById = async (
  billingId: string,
  contractId: string,
): Promise<ZevAdminIndividualParticipantBilling> => {
  const { data: billing } =
    // eslint-disable-next-line max-len
    await DOMAIN_DEPENDENCIES.adminIndividualParticipantBillingApi.getAdminIndividualParticipantBillingDetailsById(
      billingId,
      apiHeaders(DOMAIN_DEPENDENCIES),
    )
  const { data: serviceFeatures } = await DOMAIN_DEPENDENCIES.adminContractApi.adminGetContractServiceComponentFeatures(
    contractId,
    apiHeaders(DOMAIN_DEPENDENCIES),
  )
  const hasCollectionFeature = serviceFeatures?.features.includes(ServiceComponentFeature.COLLECTION)

  const productName = await getProductName(contractId)

  const canCreateBilling = true

  const billPricePackage: ZevPricePackage[] = [
    {
      id: 0,
      name: "-",
      order: 0,
      prices: billing.prices,
    },
  ]

  const isBillingSuspended = billing.billingStatus === IndividualParticipantBillingState.SUSPENDED

  return {
    ...billing,
    productName,
    hasCollectionFeature,
    canCreateBilling,
    ...(billing?.bill && { bills: [billing?.bill] }),
    pricePackages: billPricePackage,
    isBillingSuspended,
  }
}

export const approveIndividualParticipantBilling = (billingId: string) =>
  DOMAIN_DEPENDENCIES.adminIndividualParticipantBillingApi.putAdminIndividualParticipantBillingApproveStateById(
    billingId,
    apiHeaders(DOMAIN_DEPENDENCIES),
  )

export const updateIndividualParticipantBillingPricesById = async (
  billingId: string,
  individualParticipantBillingPricesUpdateRequest: IndividualParticipantBillingPricesUpdateRequest,
) =>
  DOMAIN_DEPENDENCIES.adminIndividualParticipantBillingApi.updateAdminIndividualParticipantBillingPricesById(
    billingId,
    individualParticipantBillingPricesUpdateRequest,
    apiHeaders(DOMAIN_DEPENDENCIES),
  )

export const suspendZevIndividualParticipantBilling = async (billingId: string) =>
  DOMAIN_DEPENDENCIES.adminIndividualParticipantBillingApi.putAdminIndividualParticipantBillingSuspendStateById(
    billingId,
    apiHeaders(DOMAIN_DEPENDENCIES),
  )

export const unSuspendZevIndividualParticipantBilling = async (billingId: string) =>
  DOMAIN_DEPENDENCIES.adminIndividualParticipantBillingApi.putAdminIndividualParticipantBillingUnSuspendStateById(
    billingId,
    apiHeaders(DOMAIN_DEPENDENCIES),
  )

export const reopenIndividualParticipantBilling = async (billingId: string) => {
  return DOMAIN_DEPENDENCIES.adminIndividualParticipantBillingApi.putAdminIndividualParticipantBillingReopenStateById(
    billingId,
    apiHeaders(DOMAIN_DEPENDENCIES),
  )
}

export const sendSapIndividualParticipantBilling = async (billingId: string) =>
  DOMAIN_DEPENDENCIES.adminIndividualParticipantBillingApi.sapSendAdminIndividualParticipantBillingById(
    billingId,
    apiHeaders(DOMAIN_DEPENDENCIES),
  )
