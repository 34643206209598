import { ContractsApiEnum } from "../Components.Enums"
import { contractsColumnComparator } from "../contracts/Contracts.Comparator"
import { ContractsColumns } from "../contracts/Contracts.Enum"
import ContractsManagementTable from "../contracts/ContractsManagement.Table"
import { getAllContractsWithAreaName } from "../../../../../domain/portal/admin/contracts/Contracts.Repository"
import { getUniqueId } from "../../../../../services/utils/SharedUtils"
import { ContractAdminResponse } from "../../../../../data/generated-sources/openapi"

export const CONTRACT_MANAGEMENT_LIST_COMPONENT_CONFIG = {
  contractManagement: {
    name: "contractManagement",
    header: {
      title: "contracts:table.title",
      showButton: false,
      showFilter: true,
    },
    columnEnums: ContractsColumns,
    apiDefaultParams: {
      page: 1,
      limit: 100000,
      orderBy: "",
    },
    defaultSorting: {
      column: ContractsColumns.PRODUCT,
      direction: "desc",
    },
    columnComparator: contractsColumnComparator,
    getDataFromRepository: getAllContractsWithAreaName,
    tableHeaders: [
      {
        column: ContractsColumns.STATUS,
        label: "shared:status.label",
        width: "10%",
        orderable: true,
      },
      {
        column: ContractsColumns.CONTRACT_NAME,
        label: "contracts:table.header.contractName",
        width: "25%",
        orderable: true,
      },
      {
        column: ContractsColumns.AREA,
        label: "contracts:table.header.area",
        width: "20%",
        orderable: true,
      },
      {
        column: ContractsColumns.PRODUCT,
        label: "contracts:table.header.product",
        width: "25%",
        orderable: true,
      },
      {
        column: ContractsColumns.START_DATE,
        label: "contracts:table.header.startDate",
        width: "10%",
        orderable: true,
      },
      {
        column: ContractsColumns.END_DATE,
        label: "contracts:table.header.endDate",
        width: "10%",
        orderable: false,
      },
    ],
    renderTableRows: (item: ContractAdminResponse) => (
      <ContractsManagementTable itemConfig={item} key={getUniqueId()} />
    ),
  },
}

export const CONTRACT_MANAGEMENT_API_CONFIG = {
  contractManagement: ContractsApiEnum,
}
