import { Form, Formik } from "formik"
import { useTranslation } from "react-i18next"
import { FormActions } from "../../../../../uikit/form/FormView"
import { BillingsRecurringCreateState } from "./BillingsRecurringCreate.Reducer"
import { BillingsRecurringForm } from "./form/BillingsRecurringCreateForm"
import { Redirect } from "react-router"
import { ErrorAlert } from "../../../../../uikit/Shared.Alert"
import { validateBillingsRecurring } from "./form/BillingsRecurringCreateForm.Validation"
import { PrimaryButtonLoading } from "../../../../../uikit/button/PrimaryButtonLoading"
import { RouteComponentProps } from "react-router-dom"
import { AdminPortalRouteParams } from "../../AdminPortal.Routes"
import { mapDispatchToProps } from "./BillingsRecurringCreate.Connect"
import { PlusIcon } from "../../../../../uikit/Shared.Icon"
import { emptyBillingsRecurringCreate } from "../../../../../domain/portal/admin/billings/recurring/BillingsRecurring.Model"
import { ProgressIndicator } from "../../../../../uikit/progress/ProgressIndicator"
import { PaperBox } from "../../../../../uikit/page/PaperBox"
import { DividerBox } from "../../../../../uikit/box/DividerBox"
import { useQuery } from "@tanstack/react-query"
import { getAllContracts } from "../../../../../domain/portal/admin/contracts/Contracts.Repository"
import { useQueryDefaultOptions } from "../../../../Shared.Utils"
import { ContractAdminResponse } from "../../../../../data/generated-sources/openapi"

interface BillingsRecurringCreateComponentProps
  extends BillingsRecurringCreateState,
    RouteComponentProps<AdminPortalRouteParams>,
    ReturnType<typeof mapDispatchToProps> {}

export const BillingsRecurringCreateComponent = (props: BillingsRecurringCreateComponentProps) => {
  const { t } = useTranslation("billings-recurring")
  const { viewState, createBillingsRecurring } = props
  const {
    data: contractsData,
    isLoading: isLoadingContracts,
    isError: isErrorContracts,
  } = useQuery(["getAllAdminContracts"], () => getAllContracts(), {
    ...useQueryDefaultOptions,
  })
  const contracts: ContractAdminResponse[] | undefined = contractsData?.elements

  if (isLoadingContracts) return <ProgressIndicator />
  if (viewState.domainResult) return <Redirect to={`/billings/recurring/details/${viewState.domainResult}`} />
  return (
    <>
      {(viewState.domainError || isErrorContracts) && (
        <ErrorAlert
          message={isErrorContracts ? t("error-codes:GENERIC_FATAL_ERROR") : viewState?.domainError?.message}
        />
      )}
      {contracts?.length && (
        <PaperBox>
          <Formik
            validateOnBlur
            initialValues={emptyBillingsRecurringCreate}
            onSubmit={(values) => {
              createBillingsRecurring(values)
            }}
            validate={(values) => validateBillingsRecurring(values, t)}
          >
            {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue, isValid }) => (
              <Form onSubmit={handleSubmit}>
                <BillingsRecurringForm
                  contracts={contracts}
                  values={values}
                  errors={errors}
                  touched={touched}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  setFieldValue={setFieldValue}
                />
                <DividerBox />
                <FormActions>
                  <PrimaryButtonLoading
                    startIcon={<PlusIcon />}
                    isLoading={viewState.isLoading}
                    label={t("form.action.create.cta")}
                    type="submit"
                    disabled={viewState.isLoading || !isValid}
                  />
                </FormActions>
              </Form>
            )}
          </Formik>
        </PaperBox>
      )}
    </>
  )
}
