import React from "react"
import { GridBaseColDef } from "@mui/x-data-grid/internals"
import { GridRenderCellParams, GridValidRowModel, GridValueGetterParams } from "@mui/x-data-grid"
import { TFunction } from "i18next"
import ParticipantTableCellView from "./components/ParticipantTableCellView"
import { ParticipationResponse } from "../../../../data/generated-sources/openapi"

export const UtilityUnitTableColumnsConfig: (t: TFunction) => Array<GridBaseColDef> = (t: TFunction) => [
  {
    field: "unit",
    headerName: t("utilityUnit:table.label.unit"),
    flex: 150,
    valueGetter: (params: GridValueGetterParams): string => params.row?.name || "-",
  },
  {
    field: "participants",
    headerName: t("utilityUnit:label.currentParticipant"),
    renderCell: (params: GridRenderCellParams<GridValidRowModel>): React.ReactElement | string => {
      const currentParticipant = params.row?.participationObjects?.find(
        (participant: ParticipationResponse) => participant.isCurrent,
      )
      if (currentParticipant) {
        return (
          <ParticipantTableCellView
            currentParticipation={currentParticipant}
            upcomingEvent={params.row?.upcomingEvent}
            key={params.row.id}
          />
        )
      }
      return "-"
    },
    flex: 250,
  },
]
