import { useEffect, useState } from "react"
import { VewaBillingDoneBills } from "../../shared/vewaBillings/VewaBillingDoneBills"
import DateUtils from "../../../../services/utils/DateUtils"
import { setPageSubtitle } from "../../../appcontainer/AppContainer.Epic"
import {
  customerGetVewaParticipantBillingBills,
  customerVewaParticipantBillsDownloadCsv,
} from "../../../../domain/portal/manager/bills/Bills.Repository"
import { useMutation, useQuery } from "@tanstack/react-query"
import { useTranslation } from "react-i18next"
import { useAppDispatch } from "../../../hooks"
import { RouteComponentProps } from "react-router-dom"
import { ManagerPortalRoutesProps } from "../ManagerPortal.Routes"
import { ErrorAlert } from "../../../../uikit/Shared.Alert"
import { Box, CircularProgress, Stack } from "@mui/material"
import { ManagerOutlinedButton, ManagerPrimaryButton } from "../../../../uikit/button/ManagerButtons"
import { AxiosErrorDataType } from "../../../Shared.Utils"

export const VewaParticipantsDoneBills = ({ match: { params } }: RouteComponentProps<ManagerPortalRoutesProps>) => {
  const { billingId } = params
  const { t } = useTranslation("settlements")
  const dispatch = useAppDispatch()
  const [error, setError] = useState<AxiosErrorDataType>()

  const {
    data: bill,
    isFetching,
    remove: removeBill,
  } = useQuery(["getVewaBill"], () => customerGetVewaParticipantBillingBills(billingId), {
    enabled: !!billingId,
    onError: setError,
  })

  const {
    mutate: downloadCSV,
    isLoading: isDownloadingCSV,
    isError: isDownloadCsvError,
  } = useMutation(["customerDownloadVewaCsv"], () => customerVewaParticipantBillsDownloadCsv(billingId))

  useEffect(() => {
    return () => {
      removeBill()
    }
  }, [removeBill])

  // Hotjar
  useEffect(() => {
    // Load Hotjar script
    const script = document.createElement("script")
    script.innerHTML =
      "(function(h,o,t,j,a,r){ h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)}; h._hjSettings={hjid:4936004,hjsv:6}; a=o.getElementsByTagName('head')[0]; r=o.createElement('script');r.async=1; r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv; a.appendChild(r); })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');"
    script.async = true

    document.head.appendChild(script)

    return () => {
      document.head.removeChild(script)
    }
  }, [])

  const billDateRange = `${DateUtils.getDeFormatDate(bill?.startDate || "", "DD. MMM")} - ${DateUtils.getDeFormatDate(
    bill?.endDate || "",
    "DD. MMM YY",
  )}`

  bill && dispatch(setPageSubtitle(billDateRange))

  return (
    <Box p={6}>
      <ErrorAlert
        scrollOnDisplay
        visible={!!error || isDownloadCsvError}
        message={t(`error-codes:${error?.response?.data?.code || error?.code || "OTHER"}`)}
      />
      {isFetching || !bill ? (
        <CircularProgress />
      ) : (
        <>
          <VewaBillingDoneBills {...bill} />
          <Stack py={6} flexDirection={"row"} alignItems={"center"} justifyContent={"flex-end"}>
            <ManagerOutlinedButton
              onClick={() => downloadCSV()}
              isLoading={isDownloadingCSV}
              label={t("bills.downloadCSV")}
              sx={{ mr: 2 }}
            />
            <ManagerPrimaryButton label={t("bills.downloadAllBills")} />
          </Stack>
        </>
      )}
    </Box>
  )
}
