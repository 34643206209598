export enum MeterApiEnum {
  domainDependenceApiClass = "adminMeterApi",
  getMethod = "adminGetMeters",
}

export enum UtilityUnitMetersApiEnum {
  domainDependenceApiClass = "adminUtilityUnitApi",
  getMethod = "adminGetMetersForUtilityUnit",
}

export enum UtilityUnitApiEnum {
  domainDependenceApiClass = "adminUtilityUnitApi",
  getMethod = "adminGetUtilityUnits",
}

export enum AreaApiEnum {
  domainDependenceApiClass = "adminAreaApi",
  getMethod = "adminGetAreas",
}

export enum BuildingUtilityUnitsApiEnum {
  domainDependenceApiClass = "adminBuildingApi",
  getMethod = "adminGetUtilityUnitForBuilding",
}

export enum BuildingContractsApiEnum {
  domainDependenceApiClass = "adminBuildingApi",
  getMethod = "adminGetContractForBuilding",
}

export enum BuildingMetersApiEnum {
  domainDependenceApiClass = "adminBuildingApi",
  getMethod = "adminGetAllMetersForBuilding",
}

export enum ContractsApiEnum {
  domainDependenceApiClass = "adminContractApi",
  getMethod = "adminGetContracts",
}

export enum AreaContractsApiEnum {
  domainDependenceApiClass = "adminAreaApi",
  getMethod = "adminGetAreaContracts",
}

export enum AreaBuildingsApiEnum {
  domainDependenceApiClass = "adminAreaApi",
  getMethod = "adminGetAreaBuildings",
}

export enum ContractsBuildingApiEnum {
  domainDependenceApiClass = "adminContractApi",
  getMethod = "adminGetPageBuildingsByContractId",
}

export enum ContractParticipantsApiEnum {
  domainDependenceApiClass = "adminContractApi",
  getMethod = "adminGetParticipantsByContractId",
}

export enum ServiceInitialBillingApiEnum {
  domainDependenceApiClass = "adminContractApi",
  getMethod = "adminGetInitialServiceBillingsByContractId",
}

export enum ServiceRecurringBillingApiEnum {
  domainDependenceApiClass = "adminContractApi",
  getMethod = "adminGetRecurringServiceBillingsByContractId",
}

export enum ProfileContractsApiEnum {
  domainDependenceApiClass = "adminProfileApi",
  getMethod = "adminGetPagedContractsByProfileId",
}

export enum ParticipantPeriodicBillingApiEnum {
  domainDependenceApiClass = "adminContractApi",
  getMethod = "adminGetPageAllParticipantsBillingsByContractId",
}

export enum VewaParticipantPeriodicBillingApiEnum {
  domainDependenceApiClass = "adminContractApi",
  getMethod = "adminGetPagedVewaAllParticipantsBillingsByContractId",
}

export enum ParticipantIndividualBillingApiEnum {
  domainDependenceApiClass = "adminContractApi",
  getMethod = "adminGetPageIndividualParticipantsBillingsByContractId",
}
