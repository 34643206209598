/* eslint-disable indent */
import { useCallback, useState } from "react"
import { Box, Checkbox, IconButton, Stack, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"
import { GridBaseColDef } from "@mui/x-data-grid/internals"
import { GridRenderCellParams, GridRow, GridRowProps, GridValueGetterParams } from "@mui/x-data-grid"

import { ParticipationTypography, zevParticipationColumns } from "./ZevBillingParticipations"
import { DataGridTable } from "../../../../uikit/table/DataGridTable"
import { FormatStringAsNumber } from "../../../../domain/Domain.Formatters"
import { BillDetails, PaymentStatus, SummaryPosition, SummaryType } from "../../../../data/generated-sources/openapi"
import { FileDownloadCard, KeyboardArrowDownIcon, KeyboardArrowUpIcon } from "../../../../uikit/Shared.Icon"
import { ZevUnitConsumptionChart } from "./ZevUnitConsumptionChart"
import {
  ZevAllParticipantBilling,
  ZevIndividualParticipantBilling,
} from "../../../../domain/portal/manager/billings/Billings.Model"
import {
  ProductName,
  ZevAdminAllParticipantBilling,
  ZevAdminIndividualParticipantBilling,
} from "../../../../domain/portal/admin/billings/participant/BillingsParticipant.Model"
import { isAdminPortal } from "../../../Shared.Utils"

interface ZevBillingAggregateConsumptionProps {
  isLoading: boolean
  billing?:
    | ZevAllParticipantBilling
    | ZevIndividualParticipantBilling
    | ZevAdminAllParticipantBilling
    | ZevAdminIndividualParticipantBilling
  isFinallizeBilling?: boolean
  onDownloadClick?: (billId: string) => void
  onToggleBillPay?: (billId: string, paidOrUnpaid: "paid" | "unpaid") => void
}

export const ZevBillingAggregateConsumption = ({
  isLoading,
  billing,
  isFinallizeBilling,
  onDownloadClick,
  onToggleBillPay,
}: ZevBillingAggregateConsumptionProps) => {
  const { t } = useTranslation("settlements")
  const [expandedRowId, setExpandedRowId] = useState<string>("")

  const handleExpandRowClick = (rowId: string) => {
    const selectedRowId = expandedRowId === rowId ? "" : rowId
    setExpandedRowId(selectedRowId)
  }

  const ArrowCell = useCallback(
    ({ value }: GridRenderCellParams) => {
      const sameRowId = expandedRowId === value
      const expandId = sameRowId ? "" : value
      return (
        <IconButton aria-label="expand row" size="small" onClick={() => handleExpandRowClick(expandId)}>
          {sameRowId ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </IconButton>
      )
    },
    [expandedRowId],
  )
  const NumberCell = useCallback(({ value }: GridRenderCellParams) => {
    return <ParticipationTypography>{FormatStringAsNumber(value)}</ParticipationTypography>
  }, [])

  const ChfCell = useCallback(({ value }: GridRenderCellParams) => {
    return <ParticipationTypography>{`CHF ${FormatStringAsNumber(value)}`}</ParticipationTypography>
  }, [])

  const DownloadBtn = useCallback(
    ({ value }: GridRenderCellParams) => {
      return (
        <IconButton
          disabled={isLoading}
          sx={{ cursor: "pointer" }}
          onClick={() => onDownloadClick && onDownloadClick(value)}
        >
          <FileDownloadCard />
        </IconButton>
      )
    },
    [isLoading],
  )

  const ToggleBillPay = useCallback(
    ({ value }: GridRenderCellParams) => {
      const { paymentStatus, id } = value
      const payee = paymentStatus === PaymentStatus.PAID ? "unpaid" : "paid"
      return (
        <>
          <Checkbox
            size="small"
            disabled={isLoading}
            checked={paymentStatus === PaymentStatus.PAID}
            sx={{ cursor: "pointer", "&.Mui-checked": { color: "#000" } }}
            onClick={() => onToggleBillPay && onToggleBillPay(id, payee)}
          />
          <Typography fontSize={14}>{t("shared:billing.status.PAID")}</Typography>
        </>
      )
    },
    [isLoading],
  )

  const ExpandableTableRow = useCallback(
    (props: GridRowProps) => {
      const { rowId, row } = props
      const isSelectedRow = rowId === expandedRowId
      const expandedStyle = isAdminPortal()
        ? { "&:nth-of-type(odd)": { backgroundColor: "#fafafb" } }
        : { backgroundColor: "#fafafb" }

      const productName = billing?.productName?.toLowerCase() ?? ""
      const isPremiumContract = [
        ProductName.ZEV_PREMIUM.toLowerCase(),
        ProductName.ZEV_PREMIUM_PLUS.toLowerCase(),
      ].includes(productName)

      return (
        <>
          <GridRow {...props} selected={isSelectedRow} key={rowId} />
          {isSelectedRow && (
            <Box p={2} pb={4} sx={{ ...expandedStyle }}>
              <>
                {isPremiumContract && isAdminPortal() && (
                  <Stack flexDirection={"row"} alignItems={"center"} justifyContent={"flex-end"} py={2} px={4}>
                    <Typography fontWeight={"bold"} mr={1}>{`${t(
                      "billings-participant:sap-billing-id",
                    )} :`}</Typography>
                    <Typography component={"span"} mr={3}>
                      {row?.orderReferenceNumber ?? "-"}
                    </Typography>
                    <Typography fontWeight={"bold"} mr={1}>{`${t(
                      "billings-participant:sap-invoice-id",
                    )} :`}</Typography>
                    <Typography component={"span"}>{row?.invoiceReferenceNumber ?? "-"}</Typography>
                  </Stack>
                )}
              </>
              <ZevUnitConsumptionChart {...(row as BillDetails)} />
            </Box>
          )}
        </>
      )
    },
    [expandedRowId],
  )

  const extendedColumns: Array<GridBaseColDef> = [
    ...zevParticipationColumns(t),
    {
      field: "energyConsumption",
      headerName: "kWh",
      renderCell: NumberCell,
      valueGetter: (params: GridValueGetterParams) => {
        return params.row?.billingCalculations?.summaryPositions.map((summary: SummaryPosition) => {
          if (summary.summaryType === SummaryType.TOTAL_ENERGY_CONSUMPTION) {
            return summary.consumption
          }
        })
      },
      flex: 100,
    },
    {
      field: "price",
      headerName: t("billings-participant:detail.all.consumption.list.label.price"),
      valueGetter: (params: GridValueGetterParams) => {
        return params.row?.billingCalculations?.summaryPositions
          ?.filter((summary: SummaryPosition) => summary.summaryType === SummaryType.FINAL_AMOUNT_DUE)
          .map((sum: SummaryPosition) => sum.amountDue)[0]
      },
      renderCell: ChfCell,
      flex: 120,
    },
    ...(isFinallizeBilling && isAdminPortal()
      ? [
          {
            field: "sapStatus",
            headerName: t("billings-participant:detail.all.consumption.list.label.sapStatus"),
            valueGetter: (params: GridValueGetterParams) => params.row.accountingStatus,
            renderCell: ({ value }: GridRenderCellParams) => (
              <Typography>{t(`shared:accounting.status.${value}`)}</Typography>
            ),
            flex: 120,
          },
        ]
      : []),
    ...(isFinallizeBilling && billing?.productName?.toLowerCase()?.includes(ProductName.ZEV_COMFORT.toLowerCase())
      ? [
          ...(!billing?.hasCollectionFeature
            ? [
                {
                  field: "status",
                  headerName: t("billings-participant:detail.all.consumption.list.label.status"),
                  valueGetter: (params: GridValueGetterParams) => params.row,
                  renderCell: ToggleBillPay,
                  flex: 120,
                },
              ]
            : []),
          {
            field: "download",
            headerName: "",
            valueGetter: (params: GridValueGetterParams) => params.row?.id,
            renderCell: DownloadBtn,
            flex: 50,
          },
        ]
      : []),
    {
      field: "expand",
      headerName: "",
      valueGetter: (params: GridValueGetterParams) => params.row?.id,
      renderCell: ArrowCell,
      flex: 40,
    },
  ]

  return (
    <Box>
      {isFinallizeBilling ? (
        <Typography mb={4} fontSize={24} fontWeight={400}>
          {t("billingEditDone.title.usage")}
        </Typography>
      ) : (
        <Typography mb={4} fontSize={24} fontWeight={400}>
          {t("aggregateConsumption.title")}
        </Typography>
      )}

      <DataGridTable<BillDetails>
        rows={billing?.bills || []}
        loading={isLoading}
        autoHeight={false}
        columns={extendedColumns}
        getRowHeight={() => 64}
        slots={{ row: ExpandableTableRow }}
        onRowClick={({ row }) => handleExpandRowClick(row.id)}
        onPaginationModelChange={() => setExpandedRowId("")}
        sx={{
          ".MuiDataGrid-cell--withRenderer": {
            outline: "none !important",
          },
          ".MuiDataGrid-virtualScrollerContent": {
            ...(expandedRowId && { height: { md: "1050px !important", lg: "850px !important" } }),
          },
        }}
      />
    </Box>
  )
}
