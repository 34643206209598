import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { ManagerPrimaryButton } from "../../../../../uikit/button/ManagerButtons"
import { useMutation } from "@tanstack/react-query"
import { deleteOneParticipantById } from "../../../../../domain/portal/manager/participants/Participants.Repository"
import { AxiosResponse } from "axios"
import { RouteComponentProps, withRouter } from "react-router-dom"
import { AdminPortalRouteParams } from "../../../admin/AdminPortal.Routes"
import { ErrorAlert } from "../../../../../uikit/Shared.Alert"
import { AxiosErrorDataType } from "../../../../Shared.Utils"

interface ParticipantsDeleteModalPropsInterface extends RouteComponentProps<AdminPortalRouteParams> {
  participantId: string
}

const ParticipantsDeleteModal = withRouter((props: ParticipantsDeleteModalPropsInterface) => {
  const { participantId, match, history } = props
  const [open, setOpen] = useState(false)
  const { t } = useTranslation("customer-participants")
  const {
    mutate: deleteParticipant,
    isLoading: deleteParticipantIsLoading,
    isError: deleteParticipantIsError,
    error: deleteParticipantError,
    reset: deleteParticipantReset,
  } = useMutation<AxiosResponse, AxiosErrorDataType>(["api"], () => deleteOneParticipantById(participantId), {
    onSuccess: () => history.push(`/${match?.params.areaId}/participants`),
  })

  useEffect(() => {
    !open && deleteParticipantReset()
  }, [open, deleteParticipantReset])

  return (
    <Box my={2} display={"flex"} justifyContent={"flex-end"}>
      <Button variant="outlined" onClick={() => setOpen(!open)}>
        {t("button.deleteParticipant")}
      </Button>
      <Dialog open={open} onClose={() => setOpen(!open)} maxWidth="sm">
        <DialogTitle>{t("modal.delete.question")}</DialogTitle>
        <ErrorAlert
          visible={deleteParticipantIsError}
          message={t(`error-codes:${deleteParticipantError?.response?.data?.code ?? "OTHER"}`)}
        />
        <DialogContent>
          <DialogContentText>{t("modal.delete.desc")}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={() => setOpen(!open)}>
            {t("shared:form.action.cancel")}
          </Button>
          <ManagerPrimaryButton
            label={t("button.deleteParticipant")}
            isLoading={deleteParticipantIsLoading}
            disabled={deleteParticipantIsLoading}
            onClick={() => deleteParticipant()}
          />
        </DialogActions>
      </Dialog>
    </Box>
  )
})

export default ParticipantsDeleteModal
