import { I18nNamespace } from "../../../App.i18n"

export const MetersI18n: I18nNamespace = {
  name: "meters",
  de: {
    "page.title": "MESSTECHNIK",
    "table.title": "ZÄHLPUNKTE",
    "label.meter": "Meter",
    "label.name": "Name",
    "label.medium": "Medium",
    "label.meterId": "ZÄHLPUNKT ID",
    "label.meterIdSpec": "Zählpunkt-ID",
    "label.type": "Typ",
    "label.electricityPrice": "Strompreis",
    "label.usageType": "Nutzungsart",
    "label.unitId": "Nutzeinheit",
    "label.VIRTUAL": "Virtuell",
    "label.PHYSICAL": "Physisch",
    "label.ELECTRICITY": "Elektrizität",
    "label.HEAT": "Wärme",
    "label.COLD": "Kälte",
    "label.WARM_WATER": "Warmwasser",
    "label.COLD_WATER": "Kaltwasser",
    "label.RESIDENCE": "Wohneinheit",
    "label.BUSINESS": "Gewerbeeinheit",
    "label.UNDEFINED": "Unspezifisch",
    "label.GENERAL_SUPPLY": "Allgemeine Versorgung",
    "label.HEATING_PUMP": "Wärmepumpe",
    "label.billableFrom": "Abrechnungsfähig von",
    "label.billableTo": "Abrechnungsfähig bis",
    "label.startDate": "Startdatum",
    "label.endDate": "Enddatum",
    "label.saveChanges": "Zähler speichern",
    "label.meterDesign": "Zählerausführung",
    "label.price": "Strompreis",
    "label.adjustPrices": "Strompreis anpassen",
    "label.activate.title": "Zählpunkte aktivieren",
    "label.activate.metersUnits": "Zählpunkte an Nutzeinheiten",
    "label.activate.moreMeters": "Weitere Zählpunkte",
    "label.activate.location": "Ort",
    "label.activate.successMsg": "Zählpunkte erfolgreich aktiviert!",
    "label.activate.confirmMsg": "Sind Sie sicher, dass Sie ausgewählte Zählpunkte aktivieren möchten?",
    "label.deleteMeter": "Zählpunkt löschen",
    "label.deleteMeter.confirmMsg": "Sind Sie sicher, dass Sie diesen Zähler löschen möchten?",
    "errorMsg.other": "Das Preispaket kann nur für physische Stromzähler festgelegt werden.",
    "errorMsg.zev_invalid_billing_date":
      "Das Startdatum des Zählpunktss muss zu Beginn der ZEV oder nach dem aktuellen Startdatum des Zählpunktes liegen.",
    "successMsg.text": "Der Zähler wurde erfolgreich angelegt. Gehen Sie zum Zähler",
    "breadcrumb.meters.update": "ZÄHLPUNKT BEARBEITEN",
  },
  en: {
    "page.title": "Messtechnik",
    "table.title": "Meter",
    "label.meter": "Meter",
    "label.name": "Name",
    "label.medium": "Medium",
    "label.meterId": "Meter ID",
    "label.meterIdSpec": "Meter ID",
    "label.type": "Type",
    "label.electricityPrice": "Electricity Price",
    "label.usageType": "Usage type",
    "label.nitId": "Unit ID",
    "label.VIRTUAL": "virutal",
    "label.PHYSICAL": "physical",
    "label.ELECTRICITY": "electricity",
    "label.HEAT": "heat",
    "label.COLD": "cold",
    "label.WARM_WATER": "warm water",
    "label.COLD_WATER": "cold water",
    "label.billableFrom": "Billable from",
    "label.billableTo": "Billable to",
    "label.startDate": "Start date",
    "label.endDate": "End date",
    "label.meterDesign": "meter design",
    "label.price": "price",
    "label.adjustPrices": "Adjust electricity price",
    "label.saveChanges": "Save Meter",
    "label.RESIDENCE": "Residence",
    "label.BUSINESS": "Business",
    "label.UNDEFINED": "Unspecified",
    "label.GENERAL_SUPPLY": "General supply",
    "label.HEATING_PUMP": "Heating pump",
    "label.activate.title": "ENABLE METERS",
    "label.activate.metersUnits": "Metering points at utility units",
    "label.activate.moreMeters": "More Meters ",
    "label.activate.location": "Location",
    "label.activate.confirmMsg": "Are you sure you want to enable selected measurement points?",
    "label.activate.successMsg": "Meters activated successfully!",
    "label.deleteMeter": "Delete Meter",
    "label.deleteMeter.confirmMsg": "Are you sure you want to delete this meter?",
    "errorMsg.other": "Das Preispaket kann nur für physische Stromzähler festgelegt werden.",
    "errorMsg.zev_invalid_billing_date":
      "Das Startdatum des Zählpunktss muss zu Beginn der ZEV oder nach dem aktuellen Startdatum des Zählpunktes liegen.",
    "successMsg.text": "Der Zähler wurde erfolgreich angelegt. Gehen Sie zum Zähler",
    "breadcrumb.meters.update": "UPDATE METER",
  },
}
