import { Box } from "@mui/material"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { PrimaryTab, PrimaryTabContainer } from "../../../../../uikit/tabs/PrimaryTab"
import { FormSectionTitle } from "../../../../../uikit/form/FormView"
import { PaperBox } from "../../../../../uikit/page/PaperBox"
import SharedListComponent from "../../sharedListComponet/SharedList.Component"
import { COMPONENTS_CONFIG } from "../Components.Config"
import { getExactParamFromUrl } from "../../../../Shared.Utils"
import { AdminPortalRouteEnums } from "../../AdminPortal.Enums"
import { ContractAdminResponse, ProductType } from "../../../../../data/generated-sources/openapi"
import { BillNewBadge } from "./ParticipanPeriodicBillingTable.Component"
import { useAppSelector } from "../../../../hooks"
import { selectAdminParticipantBillings, selectAdminVewaParticipantBillings } from "../../store/billingsSlice"
import TabPanel from "../../../manager/utilityUnit/UtilityUnitDetail"

const ContractManagementParticipantBillingComponent = ({ product }: ContractAdminResponse) => {
  const { t } = useTranslation("zevs")
  const zevParticipantBills = useAppSelector(selectAdminParticipantBillings)
  const vewaParticipantBills = useAppSelector(selectAdminVewaParticipantBillings)
  const [selectedTab, setSelectedTab] = useState(0)
  const isZevProduct = product?.productType === ProductType.ZEV
  const newZevParticipantBills = zevParticipantBills.filter((b) => b.isNew)
  const newVewaParticipantBills = vewaParticipantBills.filter((b) => b.isNew)

  const hasNewBills = isZevProduct ? newZevParticipantBills.length : newVewaParticipantBills.length

  return (
    <PaperBox>
      <FormSectionTitle label={t("detail.participant.billing.title")} />
      <Box sx={{ width: "100%" }}>
        <Box
          sx={{
            ".MuiPaper-root": {
              boxShadow: "none !important",
            },
          }}
        >
          <PrimaryTabContainer value={selectedTab} onChange={(_, tab) => setSelectedTab(tab)}>
            <PrimaryTab
              label={t("detail.participant.billing.tab.all")}
              iconPosition={"end"}
              icon={hasNewBills ? <BillNewBadge /> : <></>}
            />
            {isZevProduct ? <PrimaryTab label={t("detail.participant.billing.tab.individual")} /> : null}
          </PrimaryTabContainer>
          <TabPanel value={selectedTab} index={0}>
            <SharedListComponent
              configNew={
                isZevProduct
                  ? COMPONENTS_CONFIG.zevParticipantPeriodicBilling
                  : COMPONENTS_CONFIG.vewaParticipantPeriodicBilling
              }
              getParamFromUrl={() => getExactParamFromUrl("/management-contracts/:id", AdminPortalRouteEnums.id)}
            />
          </TabPanel>
          <TabPanel value={selectedTab} index={1}>
            <SharedListComponent
              configNew={COMPONENTS_CONFIG.participantIndividualBilling}
              getParamFromUrl={() => getExactParamFromUrl("/management-contracts/:id", AdminPortalRouteEnums.id)}
            />
          </TabPanel>
        </Box>
      </Box>
    </PaperBox>
  )
}

export default ContractManagementParticipantBillingComponent
