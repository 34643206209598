import { useTranslation } from "react-i18next"
import { Paper, Grid, styled, Typography, Stack } from "@mui/material"
import { CommonFormikProps } from "../../../../Shared.Utils"

import { ContractFormTitle, ContractFormRow } from "../common/CommonFormFragments"
import { SingleLineTextField } from "../../../../../uikit/input/SingleLineTextField"
import { SingleLineDatePicker } from "../../../../../uikit/input/SingleLineDatePicker"
import { SelectPicker } from "../../../../../uikit/input/SelectPicker"
import { ProfileIcon, PaymentInformationIcon } from "../../../../../uikit/Shared.Icon"
import { BillingSettingsResponse, ProductType } from "../../../../../data/generated-sources/openapi"
import { apiFormattedDateToTimestamp } from "../../../../../domain/Domain.Formatters"
import DateUtils from "../../../../../services/utils/DateUtils"
import EventIcon from "@mui/icons-material/Event"
import { getNextBillingData } from "../../../../../services/utils/BillingUtilis"

interface BillingSettingsFormProps extends CommonFormikProps<BillingSettingsResponse> {
  productType: ProductType
  vewaStartDate?: string
}
const SectionTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  color: "#000000",
  fontSize: theme.typography.pxToRem(16),
}))

const ContractBillingDataFields = ({
  values,
  errors,
  touched,
  handleBlur,
  setFieldValue,
  productType,
  vewaStartDate,
}: BillingSettingsFormProps) => {
  const { t } = useTranslation("contracts-management")
  const touchedFileds = Object.keys(touched)

  const { nextBillingAvailableDates, vewaMinDate, vewaMaxDate, nextBillingFrequencyOptions } = getNextBillingData(
    productType,
    values.billingPeriod.startDate,
    values.billingFrequency,
    vewaStartDate,
  )

  return (
    <Paper sx={{ p: 1, mb: 2, pb: 2 }}>
      <ContractFormTitle>{t("billingForm.title")}</ContractFormTitle>
      <Grid container rowGap={5}>
        <Grid container item>
          {productType === ProductType.ZEV ? (
            <ContractFormRow>
              <SelectPicker
                type="text"
                name="nextBillingDate"
                label={t("billingForm.nextBillingDate")}
                value={values?.nextBillingDate ?? ""}
                items={nextBillingAvailableDates.map((billingDate) => ({
                  label: DateUtils.getDeFormatDateWithMonthString(billingDate),
                  value: DateUtils.getDeFormatDate(billingDate),
                }))}
                onBlur={handleBlur}
                onChange={({ target }) => setFieldValue("nextBillingDate", target.value)}
                helperText={
                  touchedFileds.includes("nextBillingDate") && errors.nextBillingDate
                    ? errors.nextBillingDate
                    : undefined
                }
              />
            </ContractFormRow>
          ) : (
            <ContractFormRow>
              <SingleLineDatePicker
                required
                name="nextBillingDate"
                label={t("billingForm.nextBillingDate")}
                value={apiFormattedDateToTimestamp(values.nextBillingDate)}
                onBlur={handleBlur}
                onChange={(value) => setFieldValue("nextBillingDate", DateUtils.getDeFormatDate(value))}
                helperText={
                  touchedFileds.includes("nextBillingDate") && errors.nextBillingDate
                    ? errors.nextBillingDate
                    : undefined
                }
                {...(vewaMinDate && { minDate: vewaMinDate })}
                {...(vewaMaxDate && { maxDate: vewaMaxDate })}
              />
            </ContractFormRow>
          )}
          <ContractFormRow>
            <SingleLineTextField
              disabled
              type="text"
              name="startDate"
              label={t("billingForm.period")}
              value={`${DateUtils.getDeFormatDate(
                values.billingPeriod.startDate,
                "DD. MMM YYYY",
              )} - ${DateUtils.getDeFormatDate(values?.billingPeriod.endDate, "DD. MMM YYYY")}`}
              InputProps={{
                endAdornment: <EventIcon />,
              }}
            />
          </ContractFormRow>
          <ContractFormRow>
            <SingleLineTextField
              disabled
              type="text"
              name="billingFrequency"
              label={t("billingForm.billingFrequency")}
              value={t(`billingFrequency.${values.billingFrequency}`)}
            />
          </ContractFormRow>
          <ContractFormRow>
            <SelectPicker
              type="text"
              name="nextBillingFrequency"
              label={t("billingForm.nextBillingFrequency")}
              emptyValue="None"
              disabled={productType === ProductType.VEWA}
              value={values.nextBillingFrequency ?? ""}
              items={nextBillingFrequencyOptions.map((frequency) => ({
                label: t(`billingFrequency.${frequency}`),
                value: frequency,
              }))}
              onBlur={handleBlur}
              onChange={({ target }) => setFieldValue("nextBillingFrequency", target.value || undefined)}
              helperText={
                touchedFileds.includes("nextBillingFrequency") && errors.nextBillingFrequency
                  ? errors.nextBillingFrequency
                  : undefined
              }
            />
          </ContractFormRow>
        </Grid>
        <Grid container item>
          <Grid item xs={12} p={1}>
            <Stack direction="row" spacing={1.25}>
              <ProfileIcon />
              <SectionTitle>{t("billingForm.contactPerson")}</SectionTitle>
            </Stack>
          </Grid>
          <ContractFormRow>
            <SingleLineTextField
              required
              name="name"
              type="text"
              label={t("billingForm.name")}
              value={values.mainContactPerson?.name}
              onBlur={handleBlur}
              onChange={({ target }) => setFieldValue("mainContactPerson.name", target.value)}
              helperText={
                touchedFileds.includes("name") && errors.mainContactPerson?.name
                  ? errors.mainContactPerson?.name
                  : undefined
              }
            />
          </ContractFormRow>
          <ContractFormRow>
            <SingleLineTextField
              name="telephone"
              type="text"
              label={t("billingForm.phone")}
              value={values.mainContactPerson?.contact?.telephone}
              onBlur={handleBlur}
              onChange={({ target }) => setFieldValue("mainContactPerson.contact.telephone", target.value)}
              helperText={
                touchedFileds.includes("telephone") && errors.mainContactPerson?.contact?.telephone
                  ? errors.mainContactPerson?.contact?.telephone
                  : undefined
              }
            />
          </ContractFormRow>
          <ContractFormRow>
            <SingleLineTextField
              name="mobile"
              type="text"
              label={t("billingForm.mobile")}
              value={values.mainContactPerson?.contact?.mobile}
              onBlur={handleBlur}
              onChange={({ target }) => setFieldValue("mainContactPerson.contact.mobile", target.value)}
              helperText={
                touchedFileds.includes("mobile") && errors.mainContactPerson?.contact?.mobile
                  ? errors.mainContactPerson?.contact?.mobile
                  : undefined
              }
            />
          </ContractFormRow>
          <ContractFormRow>
            <SingleLineTextField
              name="email"
              type="text"
              label={t("billingForm.email")}
              value={values.mainContactPerson?.contact?.email}
              onBlur={handleBlur}
              onChange={({ target }) => setFieldValue("mainContactPerson.contact.email", target.value)}
              helperText={
                touchedFileds.includes("email") && errors.mainContactPerson?.contact?.email
                  ? errors.mainContactPerson?.contact?.email
                  : undefined
              }
            />
          </ContractFormRow>
          <ContractFormRow />
        </Grid>
        <Grid container item>
          <Grid item xs={12} p={1}>
            <Stack direction="row" spacing={1.25}>
              <PaymentInformationIcon />
              <SectionTitle>{t("billingForm.paymentInfo")}</SectionTitle>
            </Stack>
          </Grid>
          <ContractFormRow>
            <SingleLineTextField
              required
              name="payee"
              type="text"
              label={t("billingForm.payee")}
              value={values.paymentInformation?.payee}
              helperText={
                touched.paymentInformation?.payee && errors.paymentInformation?.payee
                  ? errors.paymentInformation?.payee
                  : undefined
              }
              onBlur={handleBlur}
              onChange={({ target }) => setFieldValue("paymentInformation.payee", target.value)}
            />
          </ContractFormRow>
          <ContractFormRow>
            <SingleLineTextField
              required
              name="iban"
              type="text"
              label={t("billingForm.iban")}
              value={values.paymentInformation?.iban}
              helperText={
                touchedFileds.includes("iban") && errors.paymentInformation?.iban
                  ? errors.paymentInformation?.iban
                  : undefined
              }
              onBlur={handleBlur}
              onChange={({ target }) => setFieldValue("paymentInformation.iban", target.value)}
            />
          </ContractFormRow>
          <ContractFormRow>
            <SingleLineTextField
              name="vatNumber"
              type="text"
              label={t("billingForm.vatNumber")}
              value={values.paymentInformation?.vatNumber}
              helperText={
                touchedFileds.includes("vatNumber") && errors.paymentInformation?.vatNumber
                  ? errors.paymentInformation?.vatNumber
                  : undefined
              }
              onBlur={handleBlur}
              onChange={({ target }) => setFieldValue("paymentInformation.vatNumber", target.value)}
            />
          </ContractFormRow>
          <ContractFormRow />
        </Grid>
      </Grid>
    </Paper>
  )
}

export default ContractBillingDataFields
