import React, { useEffect, useState } from "react"
import { ProductHistoryItemModel, ProductHistoryPropsModel } from "./ProductHistoryModel"
import { Grid, Paper } from "@mui/material"
import { FormSectionTitle } from "../../../../uikit/form/FormView"
import { DataGridTable } from "../../../../uikit/table/DataGridTable"
import { useQuery } from "@tanstack/react-query"
import { isAdminPortal, useQueryDefaultOptions } from "../../../Shared.Utils"
import { FullScreenLoader } from "../../../../uikit/indicator/ProgressIndicator"
import { ErrorAlert } from "../../../../uikit/Shared.Alert"
import { useTranslation } from "react-i18next"
import { GridBaseColDef } from "@mui/x-data-grid/internals"
import { GridValueGetterParams } from "@mui/x-data-grid"
import { getUniqueId } from "../../../../services/utils/SharedUtils"
import DateUtils from "../../../../services/utils/DateUtils"
import { customerGetProductHistoryByContractId } from "../../../../domain/portal/manager/contracts/Contract.Repository"
import { adminGetProductHistoryByContractId } from "../../../../domain/portal/admin/contracts/Contracts.Repository"

const ProductHistory = (props: ProductHistoryPropsModel) => {
  const { title, contractId } = props
  const { t } = useTranslation()
  const [productHistoryData, setProductHistoryData] = useState<ProductHistoryItemModel[]>([])

  const { data, isLoading, isRefetching, isSuccess, isError, remove, refetch } = useQuery(
    ["getProductHistory"],
    () =>
      isAdminPortal()
        ? adminGetProductHistoryByContractId(contractId)
        : customerGetProductHistoryByContractId(contractId),
    {
      enabled: !!contractId,
      ...useQueryDefaultOptions,
    },
  )

  useEffect(() => {
    if (data?.data) {
      const formatData: ProductHistoryItemModel[] = data.data.productHistory.map((item) => {
        return {
          ...item,
          id: getUniqueId(),
        }
      })
      setProductHistoryData(formatData)
    }
  }, [data])

  useEffect(() => {
    contractId && refetch()
  }, [contractId, refetch])

  useEffect(() => () => remove(), [remove])

  const productHistoryColumns: Array<GridBaseColDef> = [
    {
      field: "contractName",
      headerName: t("products:field.label.products"),
      valueGetter: (params: GridValueGetterParams) => {
        return params.row.productName ?? "-"
      },
      flex: 300,
    },
    {
      field: "contractStartDate",
      valueGetter: (params: GridValueGetterParams) =>
        params.row.contractStartDate ? DateUtils.getDeFormatWithDots(params.row.contractStartDate) : "-",
      headerName: t("shared:form.startDate"),
      flex: 200,
    },
    {
      field: "contractEndDate",
      valueGetter: (params: GridValueGetterParams) =>
        params.row?.contractEndDate ? DateUtils.getDeFormatWithDots(params.row.contractEndDate) : "-",
      headerName: t("shared:form.endDate"),
      flex: 200,
    },
  ]

  return (
    <>
      {isLoading || isRefetching ? <FullScreenLoader /> : null}
      <Paper
        style={{
          boxShadow: isAdminPortal() ? "indigo" : "none",
        }}
      >
        <Grid container>
          {title ? (
            <Grid item md={12} p={3}>
              <FormSectionTitle label={title} />
            </Grid>
          ) : null}
          <Grid item md={12} p={3}>
            <ErrorAlert visible={isError && !isLoading} message={t("error-codes:OTHER")} />
            {isSuccess ? (
              <DataGridTable<ProductHistoryItemModel>
                loading={isLoading}
                rows={productHistoryData ?? []}
                columns={productHistoryColumns}
              />
            ) : null}
          </Grid>
        </Grid>
      </Paper>
    </>
  )
}

export default ProductHistory
