import { Box, Typography, styled } from "@mui/material"
import { useTranslation } from "react-i18next"
import { GridBaseColDef } from "@mui/x-data-grid/internals"
import { TFunction } from "i18next"
import { GridRenderCellParams, GridValueGetterParams } from "@mui/x-data-grid"

import { DataGridRedirectRowTable } from "../../../../uikit/table/DataGridTable"
import { getTranslateValue } from "../../../Shared.Utils"
import DateUtils from "../../../../services/utils/DateUtils"
import { getParticipantFullName } from "../../../../services/utils/ParticipantUtils"
import { BillDetails } from "../../../../data/generated-sources/openapi"
import { appThemeSecondaryColor } from "../../../Shared.Theme"

export const ParticipationTypography = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  textWrap: "wrap",
  color: theme.palette.text.primary,
  fontSize: theme.typography.pxToRem(14),
}))

const ZevParticiaptionDateToCell = ({ value }: GridRenderCellParams) => (
  <ParticipationTypography>
    {value?.from ? DateUtils.getDeFormatWithDots(value.from) : ""}
    {" - "}
    {value?.to ? DateUtils.getDeFormatWithDots(value.to) : ""}
  </ParticipationTypography>
)

const ZevParticiapntNameCell = ({ value }: GridRenderCellParams) =>
  value ? (
    <ParticipationTypography>{getParticipantFullName(value)}</ParticipationTypography>
  ) : (
    <Typography fontSize={14} color={appThemeSecondaryColor}>
      {getTranslateValue("settlements:list.noParticipation.name")}
    </Typography>
  )

export const zevParticipationColumns: (t: TFunction) => Array<GridBaseColDef> = (t: TFunction) => [
  {
    field: "buildingName",
    headerName: t("settlements:section-list-header.bill.building"),
    valueGetter: (params: GridValueGetterParams) => params.row?.buildingName || "-",
    flex: 200,
  },
  {
    field: "utilityUnitName",
    valueGetter: (params: GridValueGetterParams) => params.row?.utilityUnit?.name || "-",
    headerName: t("utilityUnit:table.label.unit"),
    flex: 200,
  },
  {
    field: "fromTo",
    valueGetter: (params: GridValueGetterParams) => params.row,
    sortComparator: (v1, v2) => (v1?.from > v2?.from ? 1 : -1),
    headerName: t("settlements:section-list-header.bill.fromTo"),
    renderCell: ZevParticiaptionDateToCell,
    flex: 200,
  },
  {
    field: "participation",
    valueGetter: (params: GridValueGetterParams) => params.row?.billPersonalData,
    renderCell: ZevParticiapntNameCell,
    headerName: t("settlements:section-list-header.bill.subscriber"),
    flex: 200,
  },
]

export const ZevBillingParticipations = ({
  bills,
  isLoading,
  onGoToUnitClick,
}: {
  isLoading: boolean
  bills: BillDetails[]
  onGoToUnitClick: (unitId: string) => void
}) => {
  const { t } = useTranslation("settlements")

  return (
    <Box>
      <Typography mb={4} fontSize={23} fontWeight={400}>
        {t("billingEdit.subtitle.change.participants")}
      </Typography>
      <DataGridRedirectRowTable<BillDetails>
        loading={isLoading}
        rows={bills}
        columns={zevParticipationColumns(t)}
        onRowClick={({ row }) => onGoToUnitClick(row?.utilityUnitId)}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
      />
    </Box>
  )
}
