import { useTranslation } from "react-i18next"
import { Badge, Box, Typography } from "@mui/material"
import { IndividualParticipantBillingItem } from "../../../../domain/portal/manager/billings/Billings.Model"
import { IndividualParticipantBillingState } from "../../../../data/generated-sources/openapi"
import { useAppSelector } from "../../../hooks"
import {
  selectAllIndividualBillings,
  selectDoneIndividualBillings,
  selectNotDoneIndividualBillings,
} from "../store/billingsSlice"
import { IndividualDoneBillings } from "./IndividualDoneBillings"
import { DataGridRedirectRowTable } from "../../../../uikit/table/DataGridTable"
import { GridValueGetterParams } from "@mui/x-data-grid"
import { GridBaseColDef } from "@mui/x-data-grid/internals"
import { useHistory } from "react-router-dom"
import { WaitingDataTypography } from "./PeriodicBillingList"
import { selectSelectedContractData } from "../store/contractSelectorSlice"

interface IndividualBillingsListProps {
  areaId: string
  separateTables: boolean
}

export const IndividualBillingsList = ({ areaId, separateTables }: IndividualBillingsListProps) => {
  const { t } = useTranslation("settlements")
  const history = useHistory()
  const { contractId } = useAppSelector(selectSelectedContractData)
  const individualBills = useAppSelector(selectAllIndividualBillings)
  const individualDoneBills = useAppSelector(selectDoneIndividualBillings)
  const individualNotDoneBills = useAppSelector(selectNotDoneIndividualBillings)

  const billingsWithNewStatus = individualBills.filter((bill) => bill.isNew)

  const navigateToIndividualParticipantBillingEdit = (billingId: string) => {
    history.push(`/${areaId}/contracts/${contractId}/billings/individual/${billingId}/edit`)
  }

  const navigateToIndividualParticipantBillingFinalize = (billingId: string) => {
    history.push(`/${areaId}/contracts/${contractId}/billings/individual/${billingId}/finalize`)
  }

  const collectBills = (isDone: boolean): IndividualParticipantBillingItem[] => {
    if (!separateTables) return individualBills
    return isDone ? individualDoneBills : individualNotDoneBills
  }

  const individualsBillingsColumns: Array<GridBaseColDef> = [
    {
      field: "datePeriod",
      headerName: t("section-list-header.bill.period"),
      sortComparator: (v1, v2) => (v1?.startDate > v2?.startDate ? 1 : -1),
      valueGetter: (params: GridValueGetterParams): string => params.row,
      renderCell: ({ value }) => <Typography variant="subtitle2">{value.datePeriod}</Typography>,
      flex: 200,
    },
    {
      field: "buildingName",
      headerName: t("section-list-header.bill.building"),
      flex: 150,
    },
    {
      field: "utilityUnitName",
      headerName: t("utilityUnit:table.label.unit"),
      flex: 150,
    },
    {
      field: "participantName",
      headerName: t("section-list-header.bill.subscriber"),
      valueGetter: (params: GridValueGetterParams) => params.row?.participantName,
      renderCell: ({ value }) =>
        value ? (
          <Typography variant="subtitle2">{value}</Typography>
        ) : (
          <Typography variant="subtitle2" color={"#6C9C30"}>
            {t("list.noParticipation.name")}
          </Typography>
        ),
      flex: 150,
    },
    {
      field: "activeState",
      headerName: t("section-list-header.bill.status"),
      valueGetter: (params: GridValueGetterParams): string => params.row,
      sortComparator: (v1, v2) => {
        return t(`list-billing-status.${v1?.activeState}`) > t(`list-billing-status.${v2?.activeState}`) ? 1 : -1
      },
      renderCell: ({ value }) => {
        let waitMsg = ""
        const isWaitingForDataStatus = value?.activeState === IndividualParticipantBillingState.WAITING_FOR_DATA

        if (isWaitingForDataStatus) {
          waitMsg = t("list-billing-status.WAITING_FOR_DATA_ETA_PAST")
          if (value?.estDataArrivalDate) {
            waitMsg = t("list-billing-status.WAITING_FOR_DATA_ETA", {
              estDate: value?.estDataArrivalDate,
            })
          }
        }

        return (
          <Box>
            <Typography variant="subtitle2">{t(`list-billing-status.${value?.activeState}`)}</Typography>
            {isWaitingForDataStatus && <WaitingDataTypography>{waitMsg}</WaitingDataTypography>}
          </Box>
        )
      },
      flex: 150,
    },
  ]

  return (
    <Box p={4}>
      <Typography variant="h5" fontWeight={400} mt={2} mb={5}>
        {t("dashboard:section-overview.most.recent.bills")}
        {!!billingsWithNewStatus?.length && (
          <Badge sx={{ mx: 2 }} badgeContent={billingsWithNewStatus?.length} color="warning" />
        )}
      </Typography>
      <DataGridRedirectRowTable<IndividualParticipantBillingItem>
        rows={collectBills(false)}
        columns={individualsBillingsColumns}
        hideFooterPagination={!separateTables}
        redirectDescription={t("list-billing-makeBill")}
        getRowClassName={({ row }) => (row?.makeBill ? "" : "RowNotClickable")}
        onRowClick={({ row }) => {
          const { id, activeState, makeBill } = row
          if (!makeBill) return
          const { DATA_AVAILABLE, IN_PROGRESS, IN_PROGRESS_REOPENED } = IndividualParticipantBillingState

          if ([DATA_AVAILABLE, IN_PROGRESS, IN_PROGRESS_REOPENED].includes(activeState)) {
            navigateToIndividualParticipantBillingEdit(id)
          } else {
            navigateToIndividualParticipantBillingFinalize(id)
          }
        }}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 25,
            },
          },
        }}
      />
      {separateTables && <IndividualDoneBillings contractId={contractId} areaId={areaId} bills={collectBills(true)} />}
    </Box>
  )
}
