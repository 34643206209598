import { DOMAIN_DEPENDENCIES } from "../../../../app/App.Config"
import { apiHeaders } from "../../../Domain.Calls"
import {
  ParticipantResponse,
  UtilityUnitResponse,
  PagedMeterResponse,
  UtilityUnitUpdateAdminRequest,
  ParticipationResponse,
  ZevPricePackage,
  MeterReadingsCalcResolution,
  MoveinWithNewParticipantAdminRequest,
  MeterReadingsUtilityUnitResponse,
} from "../../../../data/generated-sources/openapi"
import { DomainDependencies } from "../../../Domain.Dependencies"
import { UtilityUnitMoveInFormData, UtilityUnitParticipationParticipant } from "../../../participant/Participant.Model"
import { timestampToApiFormattedDate } from "../../../Domain.Formatters"
import { getAreaParticipants } from "../areas/Areas.Repository"
import { getParticipantFullName } from "../../../../services/utils/ParticipantUtils"
import { getContractPricePackages } from "../contracts/Contracts.Repository"

export const getUtilityUnitById = async (id: string): Promise<UtilityUnitResponse> => {
  const { data } = await DOMAIN_DEPENDENCIES.adminUtilityUnitApi.adminGetUtilityUnitById(
    id,
    apiHeaders(DOMAIN_DEPENDENCIES),
  )
  return data
}

export const getPricePackagesByUtilityUnitId = async (
  utilityUnitId: string,
): Promise<{ pricePackages: ZevPricePackage[]; contractId: string } | null> => {
  const utilityUnit = await getUtilityUnitById(utilityUnitId)
  if (!utilityUnit?.contractId) {
    return null
  }
  const pricePackages = await getContractPricePackages(utilityUnit?.contractId)
  return { pricePackages, contractId: utilityUnit?.contractId }
}

export const updateUtilityUnitById = (id: string, data: UtilityUnitUpdateAdminRequest) => {
  return DOMAIN_DEPENDENCIES.adminUtilityUnitApi.adminPutUtilityUnitById(id, data, apiHeaders(DOMAIN_DEPENDENCIES))
}

export const deleteUtilityUnit = async (utilityUnitId: string) => {
  return DOMAIN_DEPENDENCIES.adminUtilityUnitApi.adminDeleteUtilityUnitById(
    utilityUnitId,
    apiHeaders(DOMAIN_DEPENDENCIES),
  )
}

export const deactivateUtilityUnit = (utilityUnitId: string, from: string) => {
  return DOMAIN_DEPENDENCIES.adminUtilityUnitApi.adminDeactivateUtilityUnitById(
    utilityUnitId,
    from,
    apiHeaders(DOMAIN_DEPENDENCIES),
  )
}

export const getUtilityUnitParticipationParticipant = async (
  utilityUnitId: string,
  participationId: string,
  deps?: DomainDependencies,
) => {
  const dependencies = deps ?? DOMAIN_DEPENDENCIES
  const authHeaders = apiHeaders(dependencies)

  const { data: participation } = await dependencies.adminUtilityUnitApi.getAdminParticipationById(
    utilityUnitId,
    participationId,
    authHeaders,
  )

  if (!participation.participantId) return undefined

  const { data } = await dependencies.adminParticipantsApi.adminGetParticipantById(
    participation.participantId || "",
    authHeaders,
  )
  return data
}

export const getPagedMetersForUtilityUnit = async (
  utilityUnitId: string,
  page?: number,
  limit?: number,
  orderBy?: string,
  deps?: DomainDependencies,
): Promise<PagedMeterResponse> => {
  const dependencies = deps ?? DOMAIN_DEPENDENCIES
  const authHeaders = apiHeaders(dependencies)

  const { data } = await dependencies.adminUtilityUnitApi.adminGetMetersForUtilityUnit(
    utilityUnitId,
    page,
    limit,
    orderBy,
    authHeaders,
  )

  return data
}

export const mapUnitParticipationParticipant = (
  participations: ParticipationResponse[],
  participants: ParticipantResponse[],
) => {
  if (!participations.length) return []
  const sortedParticipations = [...participations]?.sort((a, b) =>
    a?.moveInDate ?? "" < (b?.moveInDate ?? "") ? 1 : -1,
  )

  const filterOnlyParticipant = sortedParticipations.filter((participant) => participant?.participantId)
  const filterOnlyVacancy = sortedParticipations.filter((participant) => !participant?.participantId)

  const participantParticipation: UtilityUnitParticipationParticipant[] = filterOnlyParticipant?.map(
    (participation, index) => {
      let canCancelMoveOut = false
      const participant = participants?.filter((part) => part.id === participation.participantId)[0]

      if (index === 0) {
        canCancelMoveOut = true
      }

      return {
        ...participation,
        ...(participant && {
          participant: {
            ...participant,
            ...(participant?.personalData && { fullName: getParticipantFullName(participant?.personalData) }),
          },
        }),
        prevParticipantMoveOut: filterOnlyParticipant[index + 1]?.moveOutDate,
        nextParticipantMoveIn: filterOnlyParticipant[index - 1]?.moveInDate,
        canCancelMoveOut,
      }
    },
  )
  const vacancyParticipation: UtilityUnitParticipationParticipant[] = filterOnlyVacancy?.map((participation) => {
    return {
      ...participation,
      prevParticipantMoveOut: "",
      nextParticipantMoveIn: "",
    }
  })

  const updatedParticipation = [...participantParticipation, ...vacancyParticipation]?.sort((a, b) =>
    (a?.moveInDate ?? "") < (b?.moveInDate ?? "") ? 1 : -1,
  )

  return updatedParticipation
}

export const getAllParticipationsForUtilityUnit = async (
  utilityUnitId: string,
  page?: number,
  limit?: number,
  orderBy?: string,
): Promise<UtilityUnitParticipationParticipant[]> => {
  const authHeaders = apiHeaders(DOMAIN_DEPENDENCIES)

  const { data: participations } = await DOMAIN_DEPENDENCIES.adminUtilityUnitApi.adminGetParticipationByUtilityUnitId(
    utilityUnitId,
    page,
    limit,
    orderBy,
    authHeaders,
  )

  const participantsIds: Array<string> = []

  participations.elements?.forEach((participation: ParticipationResponse) => {
    if (participation?.participantId) participantsIds.push(participation?.participantId)
  })

  const { data: participants } = await DOMAIN_DEPENDENCIES.adminParticipantsApi.adminGetParticipantBulkByIds(
    { ids: participantsIds },
    apiHeaders(DOMAIN_DEPENDENCIES),
  )

  return mapUnitParticipationParticipant(participations?.elements, participants?.elements)
}

export const moveInUtilityUnitParticipantExisting = (
  utilityUnitId: string,
  participationId: string,
  utilityUnitMoveIn: UtilityUnitMoveInFormData,
) => {
  const { moveInDate, participantId } = utilityUnitMoveIn
  return DOMAIN_DEPENDENCIES.adminUtilityUnitApi.adminMoveinParticipationExisting(
    utilityUnitId,
    participationId,
    {
      participantId,
      moveinDate: timestampToApiFormattedDate(moveInDate, DOMAIN_DEPENDENCIES),
    },
    apiHeaders(DOMAIN_DEPENDENCIES),
  )
}

export const adminMoveinWithNewParticipant = (
  utilityUnitId: string,
  participationId: string,
  payload: MoveinWithNewParticipantAdminRequest,
) => {
  return DOMAIN_DEPENDENCIES.adminUtilityUnitApi.adminMoveinWithNewParticipant(
    utilityUnitId,
    participationId,
    payload,
    apiHeaders(DOMAIN_DEPENDENCIES),
  )
}

export const moveoutUtilityUnitParticipationVacancy = async (
  utilityUnitId: string,
  participationId: string,
  moveOutDate: string,
) => {
  return DOMAIN_DEPENDENCIES.adminUtilityUnitApi.adminMoveoutParticipationVacancy(
    utilityUnitId,
    participationId,
    {
      moveoutDate: moveOutDate,
    },
    apiHeaders(DOMAIN_DEPENDENCIES),
  )
}

export const utilityParticipationChangeMoveInDate = async (
  utilityUnitId: string,
  participationId: string,
  moveInDate: number,
) => {
  return DOMAIN_DEPENDENCIES.adminUtilityUnitApi.adminParticipationChangeMoveinDate(
    utilityUnitId,
    participationId,
    {
      moveinDate: timestampToApiFormattedDate(moveInDate, DOMAIN_DEPENDENCIES),
    },
    apiHeaders(DOMAIN_DEPENDENCIES),
  )
}

export const utilityParticipationChangeMoveOutDate = (
  utilityUnitId: string,
  participationId: string,
  moveOutDate: number,
) => {
  return DOMAIN_DEPENDENCIES.adminUtilityUnitApi.adminParticipationChangeMoveoutDate(
    utilityUnitId,
    participationId,
    {
      moveoutDate: timestampToApiFormattedDate(moveOutDate, DOMAIN_DEPENDENCIES),
    },
    apiHeaders(DOMAIN_DEPENDENCIES),
  )
}

export const deleteUtilityUnitParticipation = async (utilityUnitId: string, participationId: string) =>
  DOMAIN_DEPENDENCIES.adminUtilityUnitApi.adminParticipationStopMovein(
    utilityUnitId,
    participationId,
    apiHeaders(DOMAIN_DEPENDENCIES),
  )

export const getAreaParticipantsByUtilityUnitId = async (utilityUnitId: string) => {
  const headers = apiHeaders(DOMAIN_DEPENDENCIES)
  const unit = await getUtilityUnitById(utilityUnitId)
  const { data: building } = await DOMAIN_DEPENDENCIES.adminBuildingApi.adminGetBuildingById(unit.buildingId, headers)
  return getAreaParticipants(building.areaId)
}

export const deleteUtilityUnitParticipationMoveOut = async (utilityUnitId: string, participationId: string) =>
  DOMAIN_DEPENDENCIES.adminUtilityUnitApi.adminParticipationStopMoveout(
    utilityUnitId,
    participationId,
    apiHeaders(DOMAIN_DEPENDENCIES),
  )

export const getZevAnaliseByUtilityUnitId = async (
  utilityUnitId: string,
  startDate: string,
  endDate: string,
  isFinalOnly: boolean,
  resolution: MeterReadingsCalcResolution,
): Promise<MeterReadingsUtilityUnitResponse> => {
  const { data } = await DOMAIN_DEPENDENCIES.adminUtilityUnitApi.adminGetMeterReadingsByUtilityUnitId(
    utilityUnitId,
    startDate,
    endDate,
    resolution,
    isFinalOnly,
    apiHeaders(DOMAIN_DEPENDENCIES),
  )

  return data
}
