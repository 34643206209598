import { useTranslation } from "react-i18next"
import { Route, RouteComponentProps, Switch } from "react-router-dom"
import { ProfileIcon } from "../../../../uikit/Shared.Icon"
import { BreadcrumbsNextIcon, BreadcrumbsNavButton } from "./fragments/BreadcrumbsFragments"
import { getParamsFromUrl } from "../../../Shared.Utils"
import { AdminPortalRouteParams } from "../AdminPortal.Routes"

export const MyProfileBreadcrumb = ({ history }: RouteComponentProps<AdminPortalRouteParams>) => {
  const { t } = useTranslation("profiles")
  const buildingUrlParams = getParamsFromUrl("/my-profile/:profileId") || ""

  return (
    <Switch>
      <Route path="/my-profile/:profileId/update">
        <ProfileIcon />
        <BreadcrumbsNextIcon />
        <BreadcrumbsNavButton
          label={t("breadCrumbs.myProfile")}
          onClick={() => history.push(`/my-profile/${buildingUrlParams?.profileId}`)}
        />
        <BreadcrumbsNavButton disabled label={t("breadCrumbs.myProfile.edit")} />
      </Route>
      <Route path="/my-profile/">
        <ProfileIcon />
        <BreadcrumbsNextIcon />
        <BreadcrumbsNavButton disabled label={t("breadCrumbs.myProfile")} />
      </Route>
    </Switch>
  )
}
