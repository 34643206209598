import React from "react"
import { useHistory } from "react-router-dom"
import { useMutation } from "@tanstack/react-query"
import { Paper, Stack } from "@mui/material"
import { useTranslation } from "react-i18next"
import { COMPONENTS_CONFIG } from "../Components.Config"

import SharedListComponent from "../../sharedListComponet/SharedList.Component"
import { DownloadIcon } from "../../../../../uikit/Shared.Icon"
import { PrimaryButtonLoading } from "../../../../../uikit/button/PrimaryButtonLoading"
import { downloadCsvAllParticipantByContractId } from "../../../../../domain/portal/admin/contracts/Contracts.Repository"
import { ContractAdminResponse } from "../../../../../data/generated-sources/openapi"
import { FormSectionTitle } from "../../../../../uikit/form/FormView"

export const ContractParticipants = ({ areaId, id }: ContractAdminResponse) => {
  const { t } = useTranslation()
  const history = useHistory()

  const { mutate: downloadCSV, isLoading: isDownloadingCSV } = useMutation(["downloadCsv"], () =>
    downloadCsvAllParticipantByContractId(id),
  )

  return (
    <>
      {isDownloadingCSV ? (
        <Paper sx={{ p: 1, mb: 3 }}>
          <Stack p={2} direction={"row"} alignItems={"center"}>
            <FormSectionTitle label={t("contracts-management:participants.title")} />
            <PrimaryButtonLoading
              disabled
              isLoading
              onClick={undefined}
              startIcon={<DownloadIcon />}
              label={t("shared:button.downloadCsv")}
            />
          </Stack>
        </Paper>
      ) : (
        <SharedListComponent
          queryParam={id}
          onHeaderDownloadBtnClick={downloadCSV}
          configNew={COMPONENTS_CONFIG.contractManagementParticipants}
          onHeaderBtnClick={() => history.push(`/management-contracts/${id}/area/${areaId}/participants/create`)}
        />
      )}
    </>
  )
}
