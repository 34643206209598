import React, { useEffect } from "react"
import { Grid, List, ListItem, ListItemText } from "@mui/material"
import { useTranslation } from "react-i18next"
import { useHistory, useParams } from "react-router-dom"
import { SpaceBetweenMiddleBox } from "../../../../../uikit/box/AlignmentBox"
import { DividerBox } from "../../../../../uikit/box/DividerBox"
import { SmallPaddedBox } from "../../../../../uikit/box/PaddedBox"
import { PrimaryEditButton } from "../../../../../uikit/button/PrimaryEditButton"
import { FormSectionTitle } from "../../../../../uikit/form/FormView"
import { PaperBox } from "../../../../../uikit/page/PaperBox"
import { ProfileManagerNameListItem } from "../../../../../domain/portal/admin/profiles/Profiles.Model"
import { useQuery } from "@tanstack/react-query"
import { getManagersByContractId } from "../../../../../domain/portal/admin/contracts/Contracts.Repository"
import { ErrorAlert } from "../../../../../uikit/Shared.Alert"
import { useQueryDefaultOptions } from "../../../../Shared.Utils"

const ManagerListComponent = () => {
  const { t } = useTranslation("contracts-management")
  const { id } = useParams<{ id: string }>()
  const history = useHistory()
  const { isError, data, remove } = useQuery(["adminGetManagersByContractId"], () => getManagersByContractId(id), {
    enabled: !!id,
    ...useQueryDefaultOptions,
  })

  useEffect(() => remove, [remove])

  const renderManagerList = () => {
    if (!data?.length) return <ListItem>{t("shared:label.empty.list")}</ListItem>

    return data?.map((item: ProfileManagerNameListItem, index: number) => {
      return (
        <ListItem
          key={index}
          sx={{
            borderBottom: "1px solid #E5E5E5",
            padding: "15px",
            cursor: "pointer",
            "&:hover": {
              backgroundColor: "#F1F1F1",
            },
          }}
          onClick={() => history.push(`/management-contracts/${id}/profiles/${item.id}`)}
        >
          <ListItemText primary={item.fullNameAddress} />
        </ListItem>
      )
    })
  }

  return (
    <>
      <DividerBox />
      <PaperBox>
        <SpaceBetweenMiddleBox>
          <SmallPaddedBox>
            <FormSectionTitle label={t("header.title")} />
          </SmallPaddedBox>
          <SmallPaddedBox>
            <PrimaryEditButton onClick={() => history.push(`/management-contracts/${id}/managers/update`)} />
          </SmallPaddedBox>
        </SpaceBetweenMiddleBox>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <SmallPaddedBox>
              {isError ? (
                <ErrorAlert message={t("error-codes:OTHER")} visible={isError} />
              ) : (
                <List dense={true}>{renderManagerList()}</List>
              )}
            </SmallPaddedBox>
          </Grid>
        </Grid>
      </PaperBox>
    </>
  )
}

export default ManagerListComponent
