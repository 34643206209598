import { PaperBox } from "../../../../uikit/page/PaperBox"
import ChartWrapper from "./charts/ChartWrapper"
import { AnaliseWrapComponentPropsInterface } from "./Analise.Model"
import SolarChartWrapper from "./charts/SolarChartWrapper"
import VewaChartWrapper from "./charts/VewaChartWrapper"

const AnaliseContractWrapComponent = (props: AnaliseWrapComponentPropsInterface) => {
  const {
    meterReadingDateRange,
    barColors,
    solarBarColors,
    analiseData,
    solarAnaliseData,
    selectedTab,
    headerTabs,
    isLoading,
    handleSelectTab,
    handleDateChange,
    handleTimeChange,
    isVewa,
  } = props

  return (
    <>
      <PaperBox sx={{ width: "100%", pt: 3 }}>
        {isVewa ? (
          <VewaChartWrapper
            headerTabs={headerTabs}
            meterReadingDateRange={meterReadingDateRange}
            analiseData={analiseData}
            handleDateChange={handleDateChange}
            handleTimeChange={handleTimeChange}
            barColors={barColors}
            handleSelectTab={handleSelectTab}
            isLoading={isLoading}
            selectedTab={selectedTab}
          />
        ) : (
          <ChartWrapper
            headerTabs={headerTabs}
            meterReadingDateRange={meterReadingDateRange}
            analiseData={analiseData}
            handleDateChange={handleDateChange}
            handleTimeChange={handleTimeChange}
            barColors={barColors}
            handleSelectTab={handleSelectTab}
            isLoading={isLoading}
            selectedTab={selectedTab}
          />
        )}
      </PaperBox>
      {solarAnaliseData && selectedTab ? (
        <PaperBox sx={{ width: "100%", mt: 3 }}>
          <SolarChartWrapper
            headerTabs={headerTabs}
            meterReadingDateRange={meterReadingDateRange}
            analiseData={solarAnaliseData}
            handleDateChange={handleDateChange}
            handleTimeChange={handleTimeChange}
            barColors={solarBarColors ?? []}
            handleSelectTab={handleSelectTab}
            isLoading={isLoading}
            selectedTab={selectedTab}
          />
        </PaperBox>
      ) : null}
    </>
  )
}

export default AnaliseContractWrapComponent
