import { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { Route, RouteComponentProps, Switch } from "react-router-dom"
import { IconButton } from "@mui/material"
import { useQuery } from "@tanstack/react-query"

import { AdminPortalRouteParams } from "../AdminPortal.Routes"
import { getParticipantById } from "../../../../domain/participant/Participant.Repository"
import { BreadcrumbsNavButton, BreadcrumbsNextIcon } from "./fragments/BreadcrumbsFragments"
import { ProfileIcon } from "../../../../uikit/Shared.Icon"

export const ParticipantDetailsBreadcrumb = ({
  history,
  match: { params },
}: RouteComponentProps<AdminPortalRouteParams>) => {
  const { participantId } = params
  const { t } = useTranslation("profiles")

  const { data: participantData, remove } = useQuery(["getParticipant"], () => getParticipantById(participantId), {
    enabled: !!participantId,
  })

  useEffect(() => {
    return () => remove()
  }, [remove, participantId])

  return (
    <Switch>
      <Route path="/participants/:participantId/update">
        <IconButton onClick={() => history.push("/participants")}>
          <ProfileIcon />
        </IconButton>
        <BreadcrumbsNextIcon />
        <BreadcrumbsNavButton
          label={`${participantData?.personalData?.firstName} ${participantData?.personalData?.lastName}`}
          onClick={() => history.push(`/participants/${participantId}`)}
        />
        <BreadcrumbsNavButton disabled label={t("update.breadCrumb.title")} />
      </Route>
      <Route path="/participants/:participantId">
        <IconButton onClick={() => history.push("/participants")}>
          <ProfileIcon />
        </IconButton>
        <BreadcrumbsNextIcon />
        <BreadcrumbsNavButton
          label={`${participantData?.personalData?.firstName} ${participantData?.personalData?.lastName}`}
          disabled
        />
      </Route>
      <Route path="/participants">
        <IconButton onClick={() => history.push("/participants")}>
          <ProfileIcon />
        </IconButton>
        <BreadcrumbsNextIcon />
        <BreadcrumbsNavButton disabled label={t("list.title")} />
      </Route>
    </Switch>
  )
}
