import { useCallback, useMemo, useState } from "react"
import { GridRenderCellParams, GridValueGetterParams } from "@mui/x-data-grid"
import { useTranslation } from "react-i18next"
import { Box, Typography } from "@mui/material"
import { GridBaseColDef } from "@mui/x-data-grid/internals"
import DateUtils from "../../../../services/utils/DateUtils"
import { ParticipantResponse, ParticipationResponse } from "../../../../data/generated-sources/openapi"
import { PrimaryTab, PrimaryTabContainer } from "../../../../uikit/tabs/PrimaryTab"
import TabPanel from "../../manager/utilityUnit/UtilityUnitDetail"
import { DataGridRedirectRowTable } from "../../../../uikit/table/DataGridTable"

interface ParticipantUtilityUnitsHistoryProps {
  participant?: ParticipantResponse
  isLoading: boolean
  goToUnit: (unitId: string) => void
}

export const ParticipantUtilityUnitsHistory = ({
  participant,
  isLoading,
  goToUnit,
}: ParticipantUtilityUnitsHistoryProps) => {
  const { t } = useTranslation("utilityUnit")
  const [selectedTab, setSelectedTab] = useState(0)

  const currentParticipation = useMemo(
    () => participant?.participations?.filter((part) => part.isCurrent),
    [participant?.participations],
  )
  const pastParticipation = useMemo(
    () => participant?.participations?.filter((part) => !part.isCurrent),
    [participant?.participations],
  )

  const DateCell = useCallback(
    ({ value }: GridRenderCellParams) => (
      <Typography fontSize={14}>{value ? DateUtils.getDeFormatWithDots(value) : "-"}</Typography>
    ),
    [],
  )

  const unitHistoryColumns: Array<GridBaseColDef> = [
    {
      field: "buildingName",
      headerName: t("buildings:list.bildings"),
      valueGetter: (params: GridValueGetterParams) => params.row?.utilityUnit?.building?.name || "-",
      flex: 300,
    },
    {
      field: "utilityUnitName",
      valueGetter: (params: GridValueGetterParams) => params.row?.utilityUnit?.name || "-",
      headerName: t("table.label.unit"),
      flex: 200,
    },
    {
      field: "moveInDate",
      valueGetter: (params: GridValueGetterParams) => params.row?.moveInDate,
      headerName: t("managerDetailsHeader.moveInDate"),
      renderCell: DateCell,
      flex: 200,
    },
    {
      field: "moveOutDate",
      valueGetter: (params: GridValueGetterParams) => params.row?.moveOutDate,
      headerName: t("managerDetailsHeader.moveOutDate"),
      renderCell: DateCell,
      flex: 200,
    },
  ]

  return (
    <Box mt={4} flex={1}>
      <PrimaryTabContainer
        value={selectedTab}
        variant="scrollable"
        onChange={(_, tab) => setSelectedTab(tab)}
        sx={{ paddingLeft: 0 }}
      >
        <PrimaryTab label={t("utilityUnits.current")} />
        <PrimaryTab label={t("utilityUnits.past")} />
      </PrimaryTabContainer>
      <Box mt={4}>
        <TabPanel value={selectedTab} index={0}>
          <DataGridRedirectRowTable<ParticipationResponse>
            loading={isLoading}
            rows={currentParticipation || []}
            columns={unitHistoryColumns}
            onRowClick={({ row }) => goToUnit(row?.utilityUnitId)}
            initialState={{
              sorting: {
                sortModel: [{ field: "moveInDate", sort: "asc" }],
              },
            }}
          />
        </TabPanel>
        <TabPanel value={selectedTab} index={1}>
          {pastParticipation && (
            <DataGridRedirectRowTable<ParticipationResponse>
              loading={isLoading}
              columns={unitHistoryColumns}
              rows={pastParticipation || []}
              onRowClick={({ row }) => goToUnit(row?.utilityUnitId)}
              initialState={{
                sorting: {
                  sortModel: [{ field: "moveInDate", sort: "asc" }],
                },
              }}
            />
          )}
        </TabPanel>
      </Box>
    </Box>
  )
}
