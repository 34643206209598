import { PaperBox } from "../../../../uikit/page/PaperBox"
import ChartWrapper from "./charts/ChartWrapper"
import { AnaliseWrapComponentPropsInterface } from "./Analise.Model"

const AnaliseWrapComponent = (props: AnaliseWrapComponentPropsInterface) => {
  const {
    meterReadingDateRange,
    barColors,
    analiseData,
    headerTabs,
    isLoading,
    selectedTab,
    handleSelectTab,
    handleDateChange,
    handleTimeChange,
  } = props

  return (
    <>
      <PaperBox sx={{ width: "100%", pt: 3 }}>
        <ChartWrapper
          headerTabs={headerTabs}
          meterReadingDateRange={meterReadingDateRange}
          analiseData={analiseData}
          handleDateChange={handleDateChange}
          handleTimeChange={handleTimeChange}
          barColors={barColors}
          handleSelectTab={handleSelectTab}
          isLoading={isLoading}
          selectedTab={selectedTab}
        />
      </PaperBox>
    </>
  )
}

export default AnaliseWrapComponent
