import { useState } from "react"
import { DialogContent, DialogActions, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"
import { Form, Formik } from "formik"
import { useMutation } from "@tanstack/react-query"
import DateUtils from "../../../../../services/utils/DateUtils"
import {
  MoveinWithNewParticipantAdminRequest,
  ParticipantAdminCreateRequest,
} from "../../../../../data/generated-sources/openapi"

import { adminMoveinWithNewParticipant } from "../../../../../domain/portal/admin/utility-units/UtilityUnits.Repository"
import { AxiosErrorDataType } from "../../../../Shared.Utils"
import { initialParticipantValues } from "../../../../../domain/participant/Participant.Model"
import { ParticipantRegisterSchema } from "../../../manager/participants/ParticipantsValidation.Schema"
import { ErrorAlert } from "../../../../../uikit/Shared.Alert"
import { ParticipantCreateUpdateFormFields } from "../../../manager/participants/components/ParticipantCreateUpdateFormFields"
import { PrimaryButtonLoading } from "../../../../../uikit/button/PrimaryButtonLoading"
import { CancelButton } from "../../../../../uikit/button/CancelButton"

interface ParticipantCreateAndMoveInAdminFormProps {
  moveInDate: number
  participationId: string
  utilityUnitId: string
  onClose: () => void
  onUpdateSuccess: () => void
}

export const ParticipantCreateAndMoveInAdminForm = ({
  moveInDate,
  participationId,
  utilityUnitId,
  onClose,
  onUpdateSuccess,
}: ParticipantCreateAndMoveInAdminFormProps) => {
  const { t } = useTranslation("customer-participants")
  const [registerError, setRegisterError] = useState<AxiosErrorDataType>()

  const { mutate: moveInNewUser, isLoading: isMovingIn } = useMutation(
    ["moveInNewUser", utilityUnitId],
    (createData: MoveinWithNewParticipantAdminRequest) =>
      adminMoveinWithNewParticipant(utilityUnitId, participationId, createData),
    {
      onSuccess: onUpdateSuccess,
      onError: setRegisterError,
    },
  )

  const handleOnSubmit = (participant: ParticipantAdminCreateRequest) => {
    moveInNewUser({
      moveinDate: DateUtils.getDeFormatDate(moveInDate),
      participant,
    })
  }

  return (
    <Formik<ParticipantAdminCreateRequest>
      onSubmit={handleOnSubmit}
      initialValues={initialParticipantValues}
      validationSchema={ParticipantRegisterSchema}
    >
      {({ isValid, dirty, ...rest }) => (
        <Form>
          <DialogContent sx={{ px: 0 }}>
            <ErrorAlert
              visible={!!registerError}
              message={t(`error-codes:${registerError?.response?.data?.code || registerError?.code || "OTHER"}`)}
            />
            <Typography fontSize={24} fontWeight={400} mt={3} mb={5}>
              {t("onboarding:participantsInfo.title")}
            </Typography>
            <ParticipantCreateUpdateFormFields {...rest} adminCreate />
          </DialogContent>
          <DialogActions sx={{ "&.MuiDialogActions-root": { pl: 0 } }}>
            <CancelButton onClick={onClose} noIcon />
            <PrimaryButtonLoading
              onClick={() => handleOnSubmit(rest.values)}
              isLoading={isMovingIn}
              label={t("shared:form.action.save")}
              disabled={!isValid || !dirty || isMovingIn}
            />
          </DialogActions>
        </Form>
      )}
    </Formik>
  )
}
