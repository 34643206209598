import { useCallback, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { GridBaseColDef } from "@mui/x-data-grid/internals"
import { Box, IconButton, Typography, Stack, styled } from "@mui/material"
import { GridRenderCellParams, GridRow, GridRowProps, GridValueGetterParams } from "@mui/x-data-grid"

import { ManagerOutlinedButton } from "../../../../uikit/button/ManagerButtons"
import { vewaParticipationColumns } from "./VewaBillingParticipatioins"
import { DataGridTable } from "../../../../uikit/table/DataGridTable"
import {
  KeyboardArrowDownIcon,
  KeyboardArrowUpIcon,
  FileDownload,
  ActivateIconFilled,
} from "../../../../uikit/Shared.Icon"
import {
  PagedVewaBillsResponse,
  VewaIndividualParticipationConsumptionResponse,
} from "../../../../data/generated-sources/openapi"
import DateUtils from "../../../../services/utils/DateUtils"
import { FormatStringAsNumber } from "../../../../domain/Domain.Formatters"
import { VewaBillingAnalyse } from "./VewaBillingAnalyse"
import { useMutation } from "@tanstack/react-query"
import { customerReopenVewaParticipantDoneBilling } from "../../../../domain/portal/manager/billings/Billings.Repository"
import { ErrorAlert } from "../../../../uikit/Shared.Alert"
import { AxiosError } from "axios"
import { useHistory, useParams } from "react-router-dom"
import { ManagerPortalRoutesProps } from "../../manager/ManagerPortal.Routes"
import { isAdminPortal } from "../../../Shared.Utils"
import { adminReopenVewaParticipantDoneBilling } from "../../../../domain/portal/admin/billings/Billings.Repository"

const GreenInfoBox = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  padding: theme.spacing(2.5),
  marginBottom: theme.spacing(5),
  borderRadius: theme.spacing(1),
  color: theme.palette.secondary.main,
  backgroundColor: theme.palette.background.editPrimary,
}))

export const VewaBillingDoneBills = ({
  startDate,
  endDate,
  totalEnergyConsumption,
  totalWaterConsumption,
  status,
  elements,
  billingDate,
}: PagedVewaBillsResponse) => {
  const { t } = useTranslation("settlements")
  const [expandedRowId, setExpandedRowId] = useState<string>("")
  const [tableHeight, setTableHeight] = useState<number>(0)
  const [isPaginationChanged, setIsPaginationChanged] = useState<boolean>(false)
  const { areaId, billingId, contractId } = useParams<ManagerPortalRoutesProps>()
  const history = useHistory()

  const {
    mutate: reopenBill,
    isLoading,
    error,
  } = useMutation(isAdminPortal() ? adminReopenVewaParticipantDoneBilling : customerReopenVewaParticipantDoneBilling, {
    onSuccess: () => {
      const redirectUrl = isAdminPortal()
        ? `/management-contracts/${contractId}/billings/vewa/${billingId}/validate`
        : `/${areaId}/contracts/${contractId}/billings/vewa/${billingId}/validate`
      history.push(redirectUrl)
    },
  })

  useEffect(() => {
    if (!tableHeight || isPaginationChanged) {
      handleTableHeight()
    }
  }, [expandedRowId, isPaginationChanged])

  const handleTableHeight = () => {
    const tableRows = document.querySelectorAll(".data-grid-table .MuiDataGrid-row")
    const initTableHeight = tableRows[0]?.clientHeight
    const finalHeight = tableRows.length * initTableHeight
    setTableHeight(finalHeight ?? 0)
    setIsPaginationChanged(false)
  }

  const handlePdfDownload = (id: string) => {
    // eslint-disable-next-line no-console
    console.log("handlePdfDownload - id", id)
  }

  const ArrowCell = useCallback(
    ({ value }: GridRenderCellParams) => {
      const sameRowId = expandedRowId === value
      const expandId = sameRowId ? "" : value
      return (
        <IconButton aria-label="doenload pdf" size="small" onClick={() => setExpandedRowId(expandId)}>
          {sameRowId ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </IconButton>
      )
    },
    [expandedRowId],
  )

  const DownloadCell = ({ value }: GridRenderCellParams) => (
    <IconButton aria-label="expand row" size="small" onClick={() => handlePdfDownload(value)} disabled>
      <FileDownload />
    </IconButton>
  )

  const ExpandableTableRow = useCallback(
    (props: GridRowProps) => {
      const { rowId, row } = props
      const utilityUnitId = row?.utilityUnit?.id
      const participationEndDate = row?.to
      const participationStartDate = row?.from
      return (
        <>
          <GridRow {...props} key={rowId} />
          {rowId === expandedRowId && (
            <Box p={2}>
              <VewaBillingAnalyse
                utilityUnitId={utilityUnitId}
                billFrom={startDate}
                billTo={endDate}
                endDate={participationEndDate}
                startDate={participationStartDate}
              />
            </Box>
          )}
        </>
      )
    },
    [expandedRowId],
  )

  const NumberCell = useCallback(
    ({ value }: GridRenderCellParams) => (
      <Typography fontSize={14} fontWeight={500} color="inherit">
        {FormatStringAsNumber(value)}
      </Typography>
    ),
    [],
  )

  const BillingInfoData = ({ title, subTitle }: { title: string; subTitle: string }) => (
    <Box mr={5} mb={2}>
      <Typography mb={1} fontSize={14} fontWeight={700}>
        {title}
      </Typography>
      <Typography fontSize={14} fontWeight={400}>
        {subTitle}
      </Typography>
    </Box>
  )

  const handleReopenBill = () => {
    billingId && reopenBill(billingId)
  }
  const BillingInfoRow = useCallback(
    () => (
      <Stack flexDirection={"row"} alignItems={"center"} justifyContent={"space-between"} flexWrap={"wrap"} mb={4}>
        <Stack flexDirection={"row"} alignItems={"center"} justifyContent={"flex-start"} flexWrap={"wrap"}>
          <BillingInfoData
            title={t("billingEdit.timerange")}
            subTitle={`${DateUtils.getDeFormatDate(startDate, "DD. MMM YY")} - ${DateUtils.getDeFormatDate(
              endDate,
              "DD. MMM YY",
            )}`}
          />
          <BillingInfoData
            title={t("bills.invoiceDate")}
            subTitle={DateUtils.getDeFormatDate(billingDate, "DD. MMM YY")}
          />
          <BillingInfoData title={t("total.kwh")} subTitle={FormatStringAsNumber(totalEnergyConsumption)} />
          <BillingInfoData title={t("total.m3")} subTitle={FormatStringAsNumber(totalWaterConsumption)} />
          <BillingInfoData
            title={t("section-list-header.bill.status")}
            subTitle={t(`shared:billing.status.${status}`)}
          />
        </Stack>
        <ManagerOutlinedButton
          onClick={() => handleReopenBill()}
          isLoading={isLoading}
          label={t("shared:form.action.edit")}
          sx={{ mb: 2 }}
        />
      </Stack>
    ),
    [startDate, endDate, totalEnergyConsumption, totalWaterConsumption, billingDate, status, t],
  )

  const billsColumns: Array<GridBaseColDef> = [
    ...vewaParticipationColumns(t),
    {
      field: "energyConsumption",
      headerName: "kWh",
      renderCell: NumberCell,
      flex: 70,
    },
    {
      field: "waterConsumption",
      headerName: "m³",
      renderCell: NumberCell,
      flex: 70,
    },
    {
      field: "download",
      headerName: "",
      renderCell: DownloadCell,
      valueGetter: (params: GridValueGetterParams) => params.row?.participationId,
      flex: 40,
    },
    {
      field: "expand",
      headerName: "",
      valueGetter: (params: GridValueGetterParams) => params.row?.participationId,
      renderCell: ArrowCell,
      flex: 40,
    },
  ]

  return (
    <Box>
      <ErrorAlert visible={!!error} message={t(`error-codes:${(error as AxiosError)?.code || "OTHER"}`)} />
      <GreenInfoBox>
        <ActivateIconFilled color="inherit" sx={{ mr: 2 }} />
        <Typography fontSize={18} fontWeight={400} color={"inherit"}>
          {t("bills.createdInfo")}
        </Typography>
      </GreenInfoBox>

      <BillingInfoRow />

      <Typography mb={4} fontSize={24} fontWeight={400}>
        {t("aggregateConsumption.title")}
      </Typography>

      <DataGridTable<VewaIndividualParticipationConsumptionResponse>
        className={"data-grid-table"}
        rows={elements || []}
        columns={billsColumns}
        loading={false}
        autoHeight={false}
        getRowId={({ participationId }) => participationId}
        slots={{
          row: ExpandableTableRow,
        }}
        onPaginationModelChange={() => {
          setExpandedRowId("")
          setIsPaginationChanged(true)
        }}
        sx={{
          ".MuiDataGrid-cell--withRenderer": {
            outline: "none !important",
          },
          ".MuiDataGrid-virtualScrollerContent": {
            height: expandedRowId ? tableHeight + 530 + "px !important" : "auto",
          },
        }}
        getRowHeight={() => 64}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
      />
    </Box>
  )
}
