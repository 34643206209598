import { useMutation, useQuery } from "@tanstack/react-query"
import { useEffect, useState } from "react"
import { Box } from "@mui/material"
import { RouteComponentProps } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { useAppDispatch } from "../../../hooks"

import DateUtils from "../../../../services/utils/DateUtils"
import { ManagerPortalRoutesProps } from "../ManagerPortal.Routes"
import { setPageSubtitle } from "../../../appcontainer/AppContainer.Epic"
import {
  customerAllParticipantBillingDetailsById,
  customerReopenAllParticipantBilling,
} from "../../../../domain/portal/manager/billings/Billings.Repository"
import { ErrorAlert } from "../../../../uikit/Shared.Alert"
import { AxiosErrorDataType } from "../../../Shared.Utils"
import { AllParticipantsBillingState } from "../../../../data/generated-sources/openapi"
import { ZevBillingFinalize } from "../../shared/zevBillings/ZevBillingFinalize"
import {
  customerDownloadAllParticipantBillingCsv,
  customerDownloadAllParticipantBillingPdfs,
  customerDownloadBillPdf,
  customerUpdateBillPaidOrUnpaid,
} from "../../../../domain/portal/manager/bills/Bills.Repository"

export const ZevAllDoneBilling = ({ match: { params }, history }: RouteComponentProps<ManagerPortalRoutesProps>) => {
  const { billingId, contractId, areaId } = params
  const dispatch = useAppDispatch()
  const { t } = useTranslation("billings-participant")
  const [error, setError] = useState<AxiosErrorDataType>()

  const redirectToEditBill = () => history.push(`/${areaId}/contracts/${contractId}/billings/all/${billingId}/edit`)

  const {
    data: billing,
    isFetching: isFetchingBilling,
    remove: removeBill,
    refetch: refetchBill,
  } = useQuery(["getVewaBill"], () => customerAllParticipantBillingDetailsById(billingId, contractId), {
    enabled: !!billingId,
    onSuccess: (data) => {
      if (![AllParticipantsBillingState.DONE, AllParticipantsBillingState.PAID].includes(data.billingStatus)) {
        history.goBack()
      }
    },
    onError: setError,
  })

  const { mutate: reopenBilling, isLoading: isReopeningBilling } = useMutation(
    ["reopenBilling"],
    () => customerReopenAllParticipantBilling(billingId),
    {
      onSuccess: redirectToEditBill,
      onError: setError,
    },
  )

  const { mutate: downloadAllBill, isLoading: isDownloadingAllPdf } = useMutation(
    ["downloadAllBill"],
    () => customerDownloadAllParticipantBillingPdfs(billing?.id ?? ""),
    {
      onError: setError,
    },
  )

  const { mutate: downloadAllCsv, isLoading: isDownloadingAllCsv } = useMutation(
    ["downloadAllCsv"],
    () => customerDownloadAllParticipantBillingCsv(billing?.id ?? ""),
    {
      onError: setError,
    },
  )

  const { mutate: downloadBill, isLoading: isDownloadingBill } = useMutation(
    ["downloadBill"],
    (billId: string) => customerDownloadBillPdf(billId),
    {
      onError: setError,
    },
  )
  const { mutate: togglePayStatus, isLoading: isTogglePayStatus } = useMutation(
    ["togglePayStatus"],
    ({ billId, paidOrUnpaid }: { billId: string; paidOrUnpaid: "paid" | "unpaid" }) =>
      customerUpdateBillPaidOrUnpaid(billId, paidOrUnpaid),
    {
      onSuccess: () => refetchBill(),
      onError: setError,
    },
  )

  const billDateRange = `${DateUtils.getDeFormatDate(
    billing?.billingStartDate ?? "",
    "DD. MMM",
  )} - ${DateUtils.getDeFormatDate(billing?.billingEndDate ?? "", "DD. MMM YY")}`

  useEffect(() => {
    return () => {
      removeBill()
    }
  }, [removeBill])

  useEffect(() => {
    billing && dispatch(setPageSubtitle(`${t("shared:label.period")} ${billDateRange}`))
  }, [billing])

  return (
    <Box p={6}>
      <ErrorAlert
        scrollOnDisplay
        visible={!!error}
        message={t(`error-codes:${error?.response?.data?.code ?? error?.code ?? "OTHER"}`)}
      />
      <ZevBillingFinalize
        billing={billing}
        onReopenBilling={reopenBilling}
        isReopeningBilling={isReopeningBilling}
        onDownloadAllBills={downloadAllBill}
        isDownloadingAllPdf={isDownloadingAllPdf}
        onDownloadAllCsv={downloadAllCsv}
        isDownloadingAllCsv={isDownloadingAllCsv}
        isLoading={isFetchingBilling || isTogglePayStatus || isDownloadingBill}
        onDownloadBill={(billId: string) => downloadBill(billId)}
        onToggleBillPay={(billId, paidOrUnpaid) => togglePayStatus({ billId, paidOrUnpaid })}
      />
    </Box>
  )
}
