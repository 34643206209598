import { ChartTabsEnum } from "../Analise.Enum"
import { BarChartColorsConfigInterface, LegendConfigInterface, TabItemModel } from "../Analise.Model"
import DateUtils from "../../../../../services/utils/DateUtils"
import { TFunction } from "i18next"
import {
  Medium,
  MeterReadingsContractResponse,
  MeterReadingsUtilityUnitResponse,
} from "../../../../../data/generated-sources/openapi"
import {
  appThemePrimaryColor,
  chartLightBeigeColor,
  chartLightBlueColor,
  chartLightGreenColor,
  chartLightPurpleColor,
  chartMainBlueColor,
  chartMainBlueLightColor,
  chartMainGreenColor,
  mediumBlueColor,
  mediumBlueLightColor,
  mediumGrayColor,
  mediumGreenColor,
  mediumPinkColor,
  mediumPurpleColor,
  mediumPurpleLightColor,
  mediumPurpleMidColor,
  mediumRedColor,
  solarPrimaryColor,
} from "../../../../Shared.Theme"
import { MeterReadingSolarPowerIcon } from "../icons/MeterReadingSolarPowerIcon"
import { MeterReadingHighTarrifIcon } from "../icons/MeterReadingHighTarrifIcon"
import { MeterReadingLowTarrifIcon } from "../icons/MeterReadingLowTarrifIcon"
import { MeterReadingUsedIcon } from "../icons/MeterReadingUsedIcon"
import { MeterReadingSoldIcon } from "../icons/MeterReadingSoldIcon"
import { ChartConfig } from "../../../../../uikit/chart/ChartConfig"

export const INITIAL_DATE = DateUtils.getDeFormatDate(DateUtils.substructDateDays(DateUtils.getNow(), 1))

export const tabList: TabItemModel[] = [
  {
    translateLabel: "chart.tab.electricity",
    value: ChartTabsEnum.ELECTRICITY,
  },
  {
    translateLabel: "chart.tab.heatAndCooling",
    value: ChartTabsEnum.HEAT,
  },
  {
    translateLabel: "chart.tab.warmAndColdWater",
    value: ChartTabsEnum.WATER,
  },
]

export const vewaTabList = [
  {
    translateLabel: "chart.tab.heat",
    value: ChartTabsEnum.HEAT,
  },
  {
    translateLabel: "chart.tab.water",
    value: ChartTabsEnum.WATER,
  },
]

export const COLORS = [chartMainGreenColor, chartMainBlueColor, chartMainBlueLightColor]

export const getLegendConfig = (t: TFunction, chartView: string): LegendConfigInterface => {
  switch (chartView) {
    case ChartTabsEnum.ELECTRICITY:
      return {
        totalProduction: {
          id: "totalProduced",
          color: mediumGreenColor,
          title: t("shared:consumption.solar"),
          unit: "kWh",
          icon: MeterReadingSolarPowerIcon(),
        },
        totalHighTariff: {
          id: "totalHighTariff",
          color: mediumBlueLightColor,
          title: t("shared:consumption.boughtHigh"),
          unit: "kWh",
          icon: MeterReadingHighTarrifIcon(),
        },
        totalLowTariff: {
          id: "totalLowTariff",
          color: chartLightBlueColor,
          title: t("shared:consumption.boughtLow"),
          unit: "kWh",
          icon: MeterReadingLowTarrifIcon(),
        },
      }
    case ChartTabsEnum.HEAT:
      return {
        totalProduction: {
          id: "totalProduced",
          color: mediumRedColor,
          title: t("analise:legend.heat"),
          unit: "kWh",
        },
        totalHighTariff: {
          id: "totalHighTariff",
          color: mediumBlueLightColor,
          title: t("analise:legend.cooling"),
          unit: "kWh",
        },
      }
    default:
      return {
        totalProduction: {
          id: "totalProduced",
          color: mediumPurpleColor,
          title: t("analise:legend.heatWater"),
          unit: "kWh",
        },
        totalHighTariff: {
          id: "totalHighTariff",
          color: mediumPurpleMidColor,
          title: t("analise:legend.coldWater"),
          unit: "kWh",
        },
      }
  }
}

export const getSolarLegendConfig = (t: TFunction, chartView: string): LegendConfigInterface => {
  switch (chartView) {
    case ChartTabsEnum.ELECTRICITY:
      return {
        totalProduction: {
          id: "totalProduced",
          color: appThemePrimaryColor,
          title: t("shared:solar.ownConsumption"),
          unit: "kWh",
          icon: MeterReadingSoldIcon(),
        },
        totalHighTariff: {
          id: "totalHighTariff",
          color: solarPrimaryColor,
          title: t("shared:solar.sendToNetwork"),
          unit: "kWh",
          icon: MeterReadingUsedIcon(),
        },
      }
    default:
      return {
        totalProduction: {
          id: "totalProduced",
          color: mediumRedColor,
          title: t("analise:legend.heatWater"),
          unit: "kWh",
        },
        totalHighTariff: {
          id: "totalHighTariff",
          color: mediumBlueColor,
          title: t("analise:legend.coldWater"),
          unit: "kWh",
        },
      }
  }
}

export const getTabList = (
  mediumResponse: MeterReadingsUtilityUnitResponse | MeterReadingsContractResponse | undefined,
) => {
  if (!mediumResponse) {
    return []
  }

  const tabs: TabItemModel[] = []

  const electricityMedium = !!mediumResponse?.zev?.data
  const heatMedium = !!mediumResponse?.vewa?.data[Medium.HEAT || Medium.COLD]
  const waterMedium = !!mediumResponse?.vewa?.data[Medium.WARM_WATER || Medium.COLD_WATER]

  if (electricityMedium) {
    tabs.push(tabList[0])
  }
  if (heatMedium) {
    tabs.push(tabList[1])
  }
  if (waterMedium) {
    tabs.push(tabList[2])
  }
  return tabs
}

export const getBarChartColors = (selectedTab: string, t: TFunction): BarChartColorsConfigInterface[] => {
  switch (selectedTab) {
    case ChartTabsEnum.ELECTRICITY:
      return [
        {
          id: "produced",
          color: "#c9d935",
          title: t("shared:consumption.solar"),
          unit: "kWh",
        },
        {
          id: "energyHighTariff",
          color: "#00acf5",
          title: t("shared:consumption.boughtHigh"),
          unit: "kWh",
        },
        {
          id: "energyLowTariff",
          color: "#83d0f5",
          title: t("shared:consumption.boughtLow"),
          unit: "kWh",
        },
      ]
    case ChartTabsEnum.HEAT:
      return [
        {
          id: "produced",
          color: mediumRedColor,
          bgColor: mediumPinkColor,
          title: t("shared:consumption.solar"),
          unit: "kWh",
        },
        {
          id: "energyHighTariff",
          color: mediumBlueLightColor,
          bgColor: mediumGrayColor,
          title: t("shared:consumption.boughtHigh"),
          unit: "kWh",
        },
      ]
    case ChartTabsEnum.WATER:
      return [
        {
          id: "produced",
          color: mediumPurpleColor,
          bgColor: mediumPurpleLightColor,
          title: t("shared:consumption.solar"),
          unit: "m3",
        },
        {
          id: "energyHighTariff",
          color: mediumPurpleMidColor,
          bgColor: mediumPurpleLightColor,
          title: t("shared:consumption.boughtHigh"),
          unit: "m3",
        },
      ]
    default:
      return []
  }
}
export const getSolarBarChartColors = (selectedTab: string, t: TFunction): BarChartColorsConfigInterface[] => {
  switch (selectedTab) {
    case ChartTabsEnum.ELECTRICITY:
      return [
        {
          id: "energyHighTariff",
          color: appThemePrimaryColor,
          title: t("shared:consumption.boughtHigh"),
          unit: "kWh",
        },
        {
          id: "produced",
          color: solarPrimaryColor,
          title: t("shared:consumption.solar"),
          unit: "kWh",
        },
      ]
    default:
      return []
  }
}

export const getZevDailyChartConfig = (isVewa: boolean, t: TFunction): ChartConfig[] => {
  if (isVewa) {
    return [
      {
        id: "produced",
        color: appThemePrimaryColor,
        title: t("daily.cpSum"),
        unit: "ll",
        type: "line",
      },
      {
        id: "energyHighTariff",
        color: chartLightPurpleColor,
        title: t("daily.cpSum"),
        unit: "kWh",
        type: "line",
      },
    ]
  }
  return [
    {
      id: "production",
      color: appThemePrimaryColor,
      title: t("daily.production"),
      unit: "kWh",
      type: "line",
    },
    {
      id: "cpSum",
      color: chartLightPurpleColor,
      title: t("daily.cpSum"),
      unit: "kWh",
      type: "line",
    },
    {
      id: "areaUsage",
      color: chartLightGreenColor,
      title: t("daily.areaUsage"),
      unit: "kWh",
      type: "area",
    },
    {
      id: "areaSold",
      color: chartLightBeigeColor,
      title: t("daily.areaSold"),
      unit: "kWh",
      type: "area",
    },
    {
      id: "areaBought",
      color: chartMainBlueLightColor,
      title: t("daily.areaBought"),
      unit: "kWh",
      type: "area",
    },
  ]
}
