import { useState } from "react"
import { Form, Formik } from "formik"
import { useTranslation } from "react-i18next"
import { Grid, Stack, IconButton } from "@mui/material"
import { useMutation } from "@tanstack/react-query"
import { BillingFrequency, BillingSettingsResponse } from "../../../../../../data/generated-sources/openapi"

import { CancelRoundIcon, EditIcon } from "../../../../../../uikit/Shared.Icon"
import { ManagerPrimaryButton } from "../../../../../../uikit/button/ManagerButtons"
import { SingleLineTextField } from "../../../../../../uikit/input/SingleLineTextField"
import { customerUpdateContractPaymentInformationById } from "../../../../../../domain/portal/manager/contracts/Contract.Repository"

import {
  BillingSettingsFormWrapper,
  BillingSettingsFormTitle,
  BillingSettingsRow,
  BillingSettingsValueLabel,
  BillingSettingsLabel,
} from "./BillingSettingsTab"
import { paymentBillingSchema } from "./BillingPreferencesSchema.Validation"
import { AxiosErrorDataType } from "../../../../../Shared.Utils"

interface BillingPaymentFormProps {
  contractId: string
  billingResponse: BillingSettingsResponse
  onSuccessUpdate: () => void
  onErrorUpdate: (error: AxiosErrorDataType) => void
}

const BillingPaymentForm = ({
  contractId,
  billingResponse,
  onSuccessUpdate,
  onErrorUpdate,
}: BillingPaymentFormProps) => {
  const { t } = useTranslation("settings")
  const [editMode, setEditMode] = useState(false)

  const { mutate: updateSettings, isLoading } = useMutation(
    ["updateSettings"],
    ({ paymentInformation }: BillingSettingsResponse) =>
      customerUpdateContractPaymentInformationById(contractId, { paymentInformation }),
    {
      onError: onErrorUpdate,
      onSuccess: () => {
        setEditMode(false)
        onSuccessUpdate()
      },
    },
  )

  const handleSubmit = (billingData: BillingSettingsResponse) => updateSettings(billingData)

  return (
    <Formik<BillingSettingsResponse & { newNextBillingFrequency?: BillingFrequency }>
      initialValues={billingResponse}
      onSubmit={handleSubmit}
      enableReinitialize
      validateOnBlur
      validationSchema={paymentBillingSchema}
    >
      {({ values, errors, touched, isValid, dirty, handleBlur, setFieldValue, resetForm }) => {
        const touchedFileds = Object.keys(touched)
        return (
          <Form>
            <BillingSettingsFormWrapper container item rowGap={3} editMode={editMode}>
              <Grid item xs={12}>
                <Stack flexDirection={"row"} justifyContent={"flex-start"} alignItems={"center"} mb={2}>
                  <BillingSettingsFormTitle>{t("billingPreferences.paymentInfo")}</BillingSettingsFormTitle>
                  <IconButton
                    onClick={() => {
                      resetForm()
                      setEditMode((prevState) => !prevState)
                    }}
                    color="primary"
                  >
                    {editMode ? <CancelRoundIcon fontSize="small" /> : <EditIcon fontSize="small" />}
                  </IconButton>
                </Stack>
              </Grid>
              {editMode ? (
                <>
                  <BillingSettingsRow>
                    <SingleLineTextField
                      required
                      name="payee"
                      type="text"
                      label={t("billingPreferences.beneficiary")}
                      value={values.paymentInformation?.payee}
                      helperText={
                        touchedFileds.includes("payee") && errors.paymentInformation?.payee
                          ? errors.paymentInformation.payee
                          : undefined
                      }
                      onBlur={handleBlur}
                      onChange={({ target }) => setFieldValue("paymentInformation.payee", target.value)}
                    />
                  </BillingSettingsRow>
                  <BillingSettingsRow>
                    <SingleLineTextField
                      required
                      name="iban"
                      type="text"
                      label={t("contracts-management:billingForm.iban")}
                      value={values.paymentInformation?.iban}
                      helperText={
                        touchedFileds.includes("iban") && errors.paymentInformation?.iban
                          ? errors.paymentInformation?.iban
                          : undefined
                      }
                      onBlur={handleBlur}
                      onChange={({ target }) => setFieldValue("paymentInformation.iban", target.value)}
                    />
                  </BillingSettingsRow>
                  <BillingSettingsRow>
                    <SingleLineTextField
                      name="paymentDeadlineInDays"
                      type="number"
                      label={t("billingPreferences.period")}
                      value={values.paymentInformation?.paymentDeadlineInDays}
                      helperText={
                        touchedFileds.includes("vatNumber") && errors.paymentInformation?.paymentDeadlineInDays
                          ? errors.paymentInformation?.paymentDeadlineInDays
                          : undefined
                      }
                      onBlur={handleBlur}
                      onChange={({ target }) => setFieldValue("paymentInformation.paymentDeadlineInDays", target.value)}
                    />
                  </BillingSettingsRow>
                  <BillingSettingsRow>
                    <SingleLineTextField
                      name="vatNumber"
                      type="text"
                      label={t("billingPreferences.vatTitle")}
                      value={values.paymentInformation?.vatNumber}
                      helperText={
                        touchedFileds.includes("vatNumber") && errors.paymentInformation?.vatNumber
                          ? errors.paymentInformation?.vatNumber
                          : undefined
                      }
                      onBlur={handleBlur}
                      onChange={({ target }) => setFieldValue("paymentInformation.vatNumber", target.value)}
                    />
                  </BillingSettingsRow>

                  <Grid item xs={12}>
                    <ManagerPrimaryButton
                      type="submit"
                      isLoading={isLoading}
                      label={t("shared:form.action.save")}
                      disabled={isLoading || !isValid || !dirty}
                    />
                  </Grid>
                </>
              ) : (
                <>
                  <BillingSettingsRow>
                    <BillingSettingsLabel>{t("billingPreferences.beneficiary")}</BillingSettingsLabel>
                    <BillingSettingsValueLabel>{values.paymentInformation.payee ?? "-"}</BillingSettingsValueLabel>
                  </BillingSettingsRow>
                  <BillingSettingsRow>
                    <BillingSettingsLabel>{t("billingPreferences.iban")}</BillingSettingsLabel>
                    <BillingSettingsValueLabel>{values.paymentInformation.iban ?? "-"}</BillingSettingsValueLabel>
                  </BillingSettingsRow>
                  <BillingSettingsRow>
                    <BillingSettingsLabel>{t("billingPreferences.period")}</BillingSettingsLabel>
                    <BillingSettingsValueLabel>
                      {values.paymentInformation.paymentDeadlineInDays ?? "-"}
                    </BillingSettingsValueLabel>
                  </BillingSettingsRow>
                  <BillingSettingsRow>
                    <BillingSettingsLabel>{t("billingPreferences.vatTitle")}</BillingSettingsLabel>
                    <BillingSettingsValueLabel>{values.paymentInformation.vatNumber ?? "-"}</BillingSettingsValueLabel>
                  </BillingSettingsRow>
                </>
              )}
            </BillingSettingsFormWrapper>
          </Form>
        )
      }}
    </Formik>
  )
}

export default BillingPaymentForm
