import { useTranslation } from "react-i18next"
import { Route, RouteComponentProps, Switch } from "react-router-dom"
import { useQuery } from "@tanstack/react-query"
import { IconButton } from "@mui/material"
import { useCallback, useEffect } from "react"

import { getParamsFromUrl } from "../../../Shared.Utils"
import { AreaIcon, BuildingIcon } from "../../../../uikit/Shared.Icon"
import { adminGetBreadcrumbInfo } from "../../../../domain/portal/admin/breadcrumps/Breadcrumps.Repository"
import { BreadcrumbsNextIcon, BreadcrumbsNavButton } from "./fragments/BreadcrumbsFragments"
import { AdminPortalRouteParams } from "../AdminPortal.Routes"

export const BuildingsBreadcrumb = ({ history }: RouteComponentProps<AdminPortalRouteParams>) => {
  const { t } = useTranslation("buildings")
  const buildingUrlParams = getParamsFromUrl("/buildings/:id") || ""

  const { data, remove } = useQuery(
    ["getBreadcrumbInfo"],
    () => adminGetBreadcrumbInfo({ buildingId: buildingUrlParams?.id }),
    {
      enabled: !!buildingUrlParams?.id,
    },
  )

  useEffect(() => {
    return () => remove()
  }, [remove, buildingUrlParams?.id])

  const AreaBuildingBreadcrumbs = useCallback(
    (props) => {
      const redirectToAreas = () => history.push("/area")
      return (
        <>
          <IconButton onClick={redirectToAreas}>
            <AreaIcon />
          </IconButton>
          <BreadcrumbsNextIcon />
          <BreadcrumbsNavButton
            label={data?.building?.area?.name || ""}
            onClick={() => history.push(`/area/${data?.building?.area?.id}`)}
          />
          <BreadcrumbsNavButton
            disabled={props?.disabledBuilding}
            label={data?.building?.name || ""}
            onClick={() => history.push(`/buildings/${data?.building?.id}`)}
          />
          {props?.labelText && (
            <>
              <BreadcrumbsNavButton label={props?.labelText} disabled />
            </>
          )}
        </>
      )
    },
    [history, data?.building],
  )

  return (
    <Switch>
      <Route path="/buildings/:id/utility-unit/create" exact>
        <AreaBuildingBreadcrumbs labelText={t("breadcrumb.utilityUnit.create")} />
      </Route>

      <Route path="/buildings/:id/meters/create" exact>
        <AreaBuildingBreadcrumbs labelText={t("breadcrumb.meter.create")} />
      </Route>

      <Route path="/buildings/:id/update">
        <AreaBuildingBreadcrumbs labelText={t("update.title")} />
      </Route>

      <Route path="/buildings/:id/">
        <AreaBuildingBreadcrumbs disabledBuilding />
      </Route>

      <Route path="/buildings" exact>
        <IconButton onClick={() => history.push("/buildings")}>
          <BuildingIcon />
        </IconButton>
        <BreadcrumbsNextIcon />
        <BreadcrumbsNavButton disabled label={t("list.title")} />
      </Route>
    </Switch>
  )
}
