import { useEffect, useState } from "react"
import { useQuery, useMutation } from "@tanstack/react-query"
import { useTranslation } from "react-i18next"
import { Form, Formik } from "formik"
import {
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Button,
  RadioGroup,
  FormControlLabel,
  Radio,
  Box,
  Divider,
  IconButton,
} from "@mui/material"
import { useAppDispatch, useAppSelector } from "../../../../hooks"
import {
  getAreaParticipantsByUtilityUnitId,
  moveInUtilityUnitParticipantExisting,
} from "../../../../../domain/portal/admin/utility-units/UtilityUnits.Repository"
import {
  UtilityUnitMoveInFormData,
  UtilityUnitMoveInType,
  UtilityUnitParticipationParticipant,
} from "../../../../../domain/participant/Participant.Model"
import { selectShowMoveInParticipantDialog, setMoveInParticipantDialog } from "../../store/uiSlice"

import { AreaParticipantsList } from "./AreaParticipantsList"
import { ErrorAlert } from "../../../../../uikit/Shared.Alert"
import { CancelButton } from "../../../../../uikit/button/CancelButton"
import { SingleLineDatePicker } from "../../../../../uikit/input/SingleLineDatePicker"
import { PrimaryButtonLoading } from "../../../../../uikit/button/PrimaryButtonLoading"
import { participantMoveInSchema } from "../../../manager/utilityUnit/components/participations/MoveInForm"
import { ParticipantCreateAndMoveInAdminForm } from "./ParticipantRegisterAndMoveInForm"
import { validationError } from "../../../../Shared.Validation"
import { AbortIcon } from "../../../../../uikit/Shared.Icon"
import { AxiosErrorDataType } from "../../../../Shared.Utils"
import DateUtils from "../../../../../services/utils/DateUtils"

interface MoveInDialogProps {
  onUpdateSuccess: () => void
  participation: UtilityUnitParticipationParticipant
}

export const UtilityUnitMoveInDialog = ({ participation, onUpdateSuccess }: MoveInDialogProps) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation("utilityUnitParticipations")
  const afterMoveOutStep = useAppSelector(selectShowMoveInParticipantDialog)
  const [showDialog, setShowDialog] = useState(false)
  const [moveInError, setMoveInError] = useState<AxiosErrorDataType>()

  const currentTime = new Date().getTime()
  const moveInMinDate =
    participation?.isCurrent && participation?.moveOutDate
      ? DateUtils.getTimeStamp(DateUtils.addDays(participation?.moveOutDate || "", 1))
      : DateUtils.getTimeStamp(participation?.moveInDate ?? "")

  const initialMoveInDate = moveInMinDate > currentTime ? moveInMinDate : currentTime

  const closeDialog = () => {
    setShowDialog(false)
    afterMoveOutStep && dispatch(setMoveInParticipantDialog(false))
  }

  const handleUpdateSuccess = () => {
    onUpdateSuccess()
    closeDialog()
  }

  const {
    isLoading,
    isFetching,
    data: participants,
    remove: removeParticipants,
  } = useQuery(["getAreaParticipants"], () => getAreaParticipantsByUtilityUnitId(participation?.utilityUnitId), {
    enabled: !!participation?.utilityUnitId,
    onError: setMoveInError,
  })

  const { mutate: moveIn, isLoading: isMovingIn } = useMutation(
    ["moveIn"],
    (moveInData: UtilityUnitMoveInFormData) =>
      moveInUtilityUnitParticipantExisting(participation?.utilityUnitId, participation?.id, moveInData),
    {
      onSuccess: handleUpdateSuccess,
      onError: setMoveInError,
    },
  )

  useEffect(() => {
    setShowDialog(afterMoveOutStep)
    return () => removeParticipants()
  }, [removeParticipants, afterMoveOutStep])

  useEffect(() => {
    return () => setMoveInError(undefined)
  }, [])

  const handleMoveInSubmit = (moveInData: UtilityUnitMoveInFormData) => {
    setMoveInError(undefined)
    if (afterMoveOutStep && moveInData.moveInType === UtilityUnitMoveInType.VACANCY) {
      return closeDialog()
    }
    moveIn(moveInData)
  }

  return (
    <>
      <Button variant="text" onClick={() => setShowDialog(true)} sx={{ textTransform: "none", p: 0 }}>
        {t("list.button.reportMoveIn")}
      </Button>
      <Dialog open={showDialog} onClose={closeDialog} fullWidth maxWidth="md">
        {afterMoveOutStep ? (
          <DialogTitle sx={{ pt: 6 }}>{t("dialog.moveOut.title")}</DialogTitle>
        ) : (
          <DialogTitle sx={{ pt: 6 }}>{t("moveIn.form.title")}</DialogTitle>
        )}
        <IconButton
          aria-label="close"
          onClick={closeDialog}
          sx={{
            position: "absolute",
            top: 35,
            right: 35,
            color: "#000",
          }}
        >
          <AbortIcon />
        </IconButton>

        <ErrorAlert
          scrollOnDisplay
          visible={!!moveInError}
          message={t(`error-codes:${moveInError?.response?.data?.code || moveInError?.code || "OTHER"}`)}
        />

        <Formik<UtilityUnitMoveInFormData & { moveInType: UtilityUnitMoveInType }>
          initialValues={{
            participantId: "",
            moveInDate: initialMoveInDate,
            moveInType: UtilityUnitMoveInType.VACANCY,
          }}
          validationSchema={participantMoveInSchema}
          onSubmit={handleMoveInSubmit}
        >
          {({ values, touched, handleBlur, handleChange, handleSubmit, setFieldValue, errors, isValid, dirty }) => (
            <DialogContent>
              <Form onSubmit={handleSubmit}>
                <RadioGroup row color="secondary" name="moveInType" defaultValue={UtilityUnitMoveInType.VACANCY}>
                  <FormControlLabel
                    label={`${t("move.form.toggle.create")}`}
                    value={UtilityUnitMoveInType.CREATE}
                    control={<Radio onChange={handleChange} />}
                  />
                  <FormControlLabel
                    label={`${t("move.form.toggle.select")}`}
                    value={UtilityUnitMoveInType.EXISTING}
                    control={<Radio onChange={handleChange} />}
                  />
                  {afterMoveOutStep && (
                    <FormControlLabel
                      label={`${t("move.form.toggle.vacancy")}`}
                      value={UtilityUnitMoveInType.VACANCY}
                      control={<Radio onChange={handleChange} />}
                    />
                  )}
                </RadioGroup>

                {values.moveInType !== UtilityUnitMoveInType.VACANCY && (
                  <>
                    <Box py={4}>
                      <SingleLineDatePicker
                        name="moveInDate"
                        label={t("moveIn.form.moveInDate")}
                        value={values.moveInDate}
                        minDate={new Date(participation?.moveInDate || "")}
                        helperText={validationError(errors.moveInDate, touched.moveInDate)}
                        onChange={(value) => value !== -1 && setFieldValue("moveInDate", value)}
                        onBlur={handleBlur}
                        textInputStyle={{ width: "35%" }}
                      />
                    </Box>
                    <Divider sx={{ py: 2 }} />
                  </>
                )}

                {values.moveInType === UtilityUnitMoveInType.EXISTING && (
                  <AreaParticipantsList
                    isLoading={(isLoading && isFetching) || !participants}
                    participants={participants || []}
                    onSelectParticipant={(id) => setFieldValue("participantId", id)}
                  />
                )}

                {values.moveInType !== UtilityUnitMoveInType.CREATE && (
                  <DialogActions sx={{ "&.MuiDialogActions-root": { pl: 0 } }}>
                    <CancelButton onClick={closeDialog} noIcon />
                    <PrimaryButtonLoading
                      type="submit"
                      isLoading={isMovingIn}
                      label={t("shared:form.action.save")}
                      disabled={!isValid || (!dirty && !afterMoveOutStep) || isMovingIn}
                    />
                  </DialogActions>
                )}
              </Form>

              {values.moveInType === UtilityUnitMoveInType.CREATE && (
                <ParticipantCreateAndMoveInAdminForm
                  moveInDate={values.moveInDate}
                  participationId={participation.id}
                  utilityUnitId={participation?.utilityUnitId}
                  onClose={closeDialog}
                  onUpdateSuccess={onUpdateSuccess}
                />
              )}
            </DialogContent>
          )}
        </Formik>
      </Dialog>
    </>
  )
}
