import { useMutation, useQuery } from "@tanstack/react-query"
import { useCallback, useEffect, useState } from "react"
import { Paper, Stack, Typography } from "@mui/material"
import { RouteComponentProps } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { AdminPortalRouteParams } from "../../AdminPortal.Routes"

import DateUtils from "../../../../../services/utils/DateUtils"
import { AxiosErrorDataType } from "../../../../Shared.Utils"
import {
  approveAllParticipantBilling,
  getAllParticipantBillingDetailsById,
  suspendZevAllParticipantBilling,
  unSuspendZevAllParticipantBilling,
  updateAllParticipantBillingPricesById,
} from "../../../../../domain/portal/admin/billings/participant/BillingsAllParticipant.Repository"
import { AllParticipantsBillingState, ZevPricePackage } from "../../../../../data/generated-sources/openapi"
import { ErrorAlert, SuccessAlert } from "../../../../../uikit/Shared.Alert"
import { ZevBillingOverview } from "../../../shared/zevBillings/ZevBillingOverview"
import { BillingStatusTypeView } from "./view/BillingStatusTypeView"
import { PrimaryButtonLoading } from "../../../../../uikit/button/PrimaryButtonLoading"
import { CancelIcon, TickIcon } from "../../../../../uikit/Shared.Icon"
import { BillingStatusType } from "../../../../../domain/portal/admin/billings/participant/BillingsParticipant.Model"

export const ZevAllParticipantBilling = ({
  match: { params },
  history,
}: RouteComponentProps<AdminPortalRouteParams>) => {
  const { billingId, id: contractId } = params
  const { t } = useTranslation("billings-participant")
  const [error, setError] = useState<AxiosErrorDataType>()
  const [successUpdateMsg, setSuccessUpdateMsg] = useState<string>("")

  const redirectToUtilityUnit = (utilityUnitId: string) => history.push(`/utility-units/${utilityUnitId}`)
  const redirectToFinalizeBill = () =>
    history.push(`/management-contracts/${contractId}/billings/all/${billingId}/finalize`)

  const hideAlertMsgs = () => {
    setError(undefined)
    setSuccessUpdateMsg("")
  }

  const {
    data: billing,
    isFetching: isFetchingBilling,
    remove: removeBill,
    refetch: refetchBill,
  } = useQuery(["getParticipantBilling"], () => getAllParticipantBillingDetailsById(billingId, contractId), {
    enabled: !!billingId,
    onSuccess: (data) => {
      if ([AllParticipantsBillingState.DONE, AllParticipantsBillingState.PAID].includes(data.billingStatus)) {
        redirectToFinalizeBill()
      }
    },
    onError: setError,
  })

  const { mutate: approveAllBilling, isLoading: isApprovingBilling } = useMutation(
    ["approveAllBilling"],
    () => {
      hideAlertMsgs()
      return approveAllParticipantBilling(billingId)
    },
    {
      onSuccess: redirectToFinalizeBill,
      onError: setError,
    },
  )

  const { mutate: updatePricePackage, isLoading: isUpdatingPrices } = useMutation(
    ["updatePricePackage"],
    (zevPrices: ZevPricePackage[]) => {
      hideAlertMsgs()
      return updateAllParticipantBillingPricesById(billingId, { pricePackages: zevPrices })
    },
    {
      onSuccess: () => refetchBill(),
      onError: setError,
    },
  )

  const { mutate: suspendBilling, isLoading: isSuspending } = useMutation(
    ["suspendBilling"],
    () => {
      hideAlertMsgs()
      return suspendZevAllParticipantBilling(billingId)
    },
    {
      onSuccess: () => {
        refetchBill()
        setSuccessUpdateMsg("detail.all.alert.lock.success")
      },
      onError: setError,
    },
  )

  const { mutate: unSuspendBilling, isLoading: isUnSuspending } = useMutation(
    ["unSuspendBilling"],
    () => {
      hideAlertMsgs()
      return unSuspendZevAllParticipantBilling(billingId)
    },
    {
      onSuccess: () => {
        refetchBill()
        setSuccessUpdateMsg("detail.all.alert.unlock.success")
      },
      onError: setError,
    },
  )

  const handleUpdatePrice = (updatedPrice: ZevPricePackage) => {
    const updatedPackages = billing?.pricePackages?.map((pricePak) => {
      if (pricePak.id === updatedPrice.id) {
        return updatedPrice
      }
      return pricePak
    })

    updatedPackages && updatePricePackage(updatedPackages)
  }

  useEffect(() => {
    return () => {
      removeBill()
    }
  }, [removeBill])

  const billDateRange = `${DateUtils.getDeFormatDate(
    billing?.billingStartDate ?? "",
    "DD. MMM",
  )} - ${DateUtils.getDeFormatDate(billing?.billingEndDate ?? "", "DD. MMM YY")}`

  const BillSuspendButton = useCallback(() => {
    if (!billing?.billingStatus) {
      return <></>
    }

    const billInProgress = [
      AllParticipantsBillingState.IN_PROGRESS,
      AllParticipantsBillingState.IN_PROGRESS_REOPENED,
    ].includes(billing?.billingStatus)

    if (!billInProgress && !billing.isBillingSuspended) {
      return <></>
    }

    return (
      <PrimaryButtonLoading
        size="small"
        isLoading={isSuspending || isUnSuspending}
        disabled={isSuspending || isUnSuspending}
        startIcon={billInProgress ? <CancelIcon /> : <TickIcon />}
        onClick={() => (billInProgress ? suspendBilling() : unSuspendBilling())}
        label={billInProgress ? t("detail.all.cta.lock") : t("detail.all.cta.unlock")}
      />
    )
  }, [billing?.billingStatus, isSuspending, isUnSuspending])

  return (
    <Paper sx={{ p: 3, minHeight: "80vh" }}>
      {successUpdateMsg && <SuccessAlert message={t(successUpdateMsg)} />}
      <ErrorAlert
        scrollOnDisplay
        visible={!!error}
        message={t(`error-codes:${error?.response?.data?.code ?? error?.code ?? "OTHER"}`)}
      />
      {billing?.billingStatus && !isFetchingBilling && (
        <Stack flexDirection={"row"} alignItems={"center"} justifyContent={"space-between"} mb={4}>
          <BillingStatusTypeView billingStatusType={BillingStatusType[billing?.billingStatus]} />
          <BillSuspendButton />
        </Stack>
      )}
      {billing && !isFetchingBilling && (
        <Typography fontSize={18} fontWeight={400} mb={3}>{`${t("shared:label.period")} ${billDateRange}`}</Typography>
      )}
      <ZevBillingOverview
        billing={billing}
        isUpdatingPrices={isUpdatingPrices}
        isLoadingBilling={isFetchingBilling}
        isApprovingBilling={isApprovingBilling}
        onPriceUpdate={handleUpdatePrice}
        onClickApprove={approveAllBilling}
        onGoToUnitClick={redirectToUtilityUnit}
      />
    </Paper>
  )
}
