import { useTranslation } from "react-i18next"
import { Route, RouteComponentProps, Switch } from "react-router-dom"
import { BillingIcon } from "../../../../uikit/Shared.Icon"
import { AdminPortalRouteParams } from "../AdminPortal.Routes"
import { useEffect, useState } from "react"
import { getParamsFromUrl } from "../../../Shared.Utils"
import { AxiosResponse } from "axios"
import { getOnlyInitialBillingById } from "../../../../domain/portal/admin/billings/initial/BillingsInitial.Repository"
import { IconButton } from "@mui/material"
import { BreadcrumbsNextIcon, BreadcrumbsNavButton } from "./fragments/BreadcrumbsFragments"

export const BillingsInitialBreadcrumb = (props: RouteComponentProps<AdminPortalRouteParams>) => {
  const { t } = useTranslation("billings-initial")
  const { history } = props
  const [invoiceReferenceNumber, setInvoiceReferenceNumber] = useState("")

  useEffect(() => {
    const profileUrlParams = getParamsFromUrl("/billings/initial/details/:billingId")
    if (profileUrlParams && profileUrlParams?.billingId) {
      getOnlyInitialBillingById(profileUrlParams?.billingId).then((response: AxiosResponse) => {
        setInvoiceReferenceNumber(response.data?.invoiceReferenceNumber)
      })
    }
  }, [history.location.pathname])

  return (
    <Switch>
      <Route path="/billings/initial/details/:billingId">
        <IconButton onClick={() => history.push("/billings/initial")}>
          <BillingIcon color="primary" />
        </IconButton>
        <BreadcrumbsNextIcon />
        <BreadcrumbsNavButton disabled label={invoiceReferenceNumber || t("breadcrumb.billing.details")} />
      </Route>

      <Route path="/billings/initial">
        <IconButton onClick={() => history.push("/billings/initial")}>
          <BillingIcon color="primary" />
        </IconButton>
        <BreadcrumbsNextIcon />
        <BreadcrumbsNavButton disabled label={t("breadcrumb.title.initial")} />
      </Route>
    </Switch>
  )
}
