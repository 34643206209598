import { Form, Formik } from "formik"
import { useTranslation } from "react-i18next"
import { Redirect } from "react-router"
import { Typography, Box, InputAdornment, IconButton } from "@mui/material"

import { TextButton } from "../../uikit/button/TextButton"
import { SingleLineTextField } from "../../uikit/input/SingleLineTextField"
import { ImpressionHeader } from "../../uikit/typography/Header"
import { SmallPaddedBox, SmallPaddedHorizontalBox } from "../../uikit/box/PaddedBox"
import { FormActions } from "../../uikit/form/FormView"
import { PrimaryButtonLoading } from "../../uikit/button/PrimaryButtonLoading"
import { ErrorAlert } from "../../uikit/Shared.Alert"
import { validationError } from "../Shared.Validation"

import { AuthAdminContainerView } from "./views/AuthAdminContainerView"
import { AuthType, AuthLoginValues } from "../../domain/auth/Auth.Model"
import { AuthState } from "./AuthLogin.Reducer"
import { validateAuth } from "./AuthLogin.Validation"
import { mapDispatchToProps } from "./AuthLoginAdmin.Connect"
import { useState } from "react"
import { PasswordOff, PasswordOn } from "../../uikit/Shared.Icon"

export interface AuthLoginAdminComponentProps extends AuthState, ReturnType<typeof mapDispatchToProps> {}

export const AuthLoginAdminComponent = (props: AuthLoginAdminComponentProps) => {
  const { t } = useTranslation("auth")
  const { viewState, login, navigateToForgottenPassword } = props
  const [showPassword, setShowPassword] = useState(false)

  if (viewState.domainResult) return <Redirect to={"/management-contracts"} />

  return (
    <AuthAdminContainerView>
      <ImpressionHeader id="login-title">{t("login.title")}</ImpressionHeader>
      <Box ml={2} mr={2} mb={2}>
        <Typography id="login-label" variant="body1">
          {t("login.label")}
        </Typography>
      </Box>
      <Formik<AuthLoginValues>
        validateOnBlur
        initialValues={{
          authType: AuthType.ADMINISTRATOR,
          emailAddress: "",
          password: "",
        }}
        onSubmit={(values) => login(values.emailAddress, values.password, values.authType)}
        validate={(values) => validateAuth(values, t)}
      >
        {({ values, touched, errors, handleChange, handleBlur, handleSubmit, isValid }) => (
          <Form onSubmit={handleSubmit}>
            {viewState.domainError && <ErrorAlert message={viewState.domainError.message} />}
            <SmallPaddedBox>
              <SingleLineTextField
                id="emailAddress"
                autoFocus={true}
                name="emailAddress"
                type="emailAddress"
                label={t("forgotten-password.field.email")}
                helperText={validationError(errors.emailAddress, touched.emailAddress)}
                value={values.emailAddress}
                onChange={handleChange}
                onBlur={handleBlur}
                sx={{
                  ".MuiInput-input": {
                    "&:-internal-autofill-selected": {
                      "-webkit-box-shadow": "0 0 0 30px white inset",
                    },
                  },
                }}
              />
            </SmallPaddedBox>
            <SmallPaddedHorizontalBox>
              <SingleLineTextField
                id="password"
                name="password"
                type={showPassword ? "text" : "password"}
                label={t("login.field.password")}
                helperText={validationError(errors.password, touched.password)}
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword((show) => !show)}
                      >
                        {showPassword ? <PasswordOn /> : <PasswordOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                sx={{
                  ".MuiInput-input": {
                    "&:-internal-autofill-selected": {
                      "-webkit-box-shadow": "0 0 0 30px white inset",
                    },
                  },
                }}
              />
            </SmallPaddedHorizontalBox>
            <FormActions>
              <SmallPaddedBox>
                <PrimaryButtonLoading
                  id="login-cta"
                  disabled={!isValid || viewState.isLoading}
                  isLoading={viewState.isLoading}
                  label={t("login.action.cta")}
                  type="submit"
                />
              </SmallPaddedBox>
            </FormActions>
            <SmallPaddedBox>
              <TextButton
                id="forgotten-password-cta"
                label={t("login.action.forgottenPassword")}
                onClick={navigateToForgottenPassword}
              />
            </SmallPaddedBox>
          </Form>
        )}
      </Formik>
    </AuthAdminContainerView>
  )
}
