import { Box, CircularProgress, IconButton, Paper, Stack, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"
import { useMutation, useQuery } from "@tanstack/react-query"
import {
  getOneParticipantById,
  updateOneParticipantById,
} from "../../../../../domain/portal/manager/participants/Participants.Repository"
import { useEffect, useState } from "react"
import { ErrorAlert } from "../../../../../uikit/Shared.Alert"
import { AxiosResponse } from "axios"
import { AlignItemsCenterBox } from "../../../../../uikit/box/AlignmentBox"
import { bgColorGreen } from "../../../../Shared.Theme"
import { Formik } from "formik"
import { ParticipantPersonalEditForm } from "./ParticipantPersonalEditForm"
import { ManagerPrimaryButton } from "../../../../../uikit/button/ManagerButtons"
import { VALIDATION_DEPS } from "../../../../Shared.Validation"
import { validateParticipants } from "../ParticipantsFormValidation"
import { ParticipantResponse } from "../../../../../data/generated-sources/openapi"
import ParticipantDetailDomicileAddress from "./ParticipantDetailDomicileAddress"
import ParticipantDetailPersonalData from "./ParticipantDetailPersonalData"
import ParticipantDomicileAddressEditForm from "./ParticipantDomicileAddressEditForm"
import ParticipantsDeleteModal from "./ParticipantsDeleteModal"
import { useAppDispatch } from "../../../../hooks"
import { setPageSubtitle } from "../../../../appcontainer/AppContainer.Epic"
import { CancelRoundIcon, EditIcon } from "../../../../../uikit/Shared.Icon"
import { RouteComponentProps, withRouter } from "react-router-dom"
import { ManagerPortalRoutesProps } from "../../ManagerPortal.Routes"
import { ParticipantUtilityUnitsHistory } from "../../../shared/participants/ParticipantUtilityUnitsHistory"
import { AxiosErrorDataType } from "../../../../Shared.Utils"

interface ParticipantDetailsProps extends RouteComponentProps<ManagerPortalRoutesProps> {
  selectedParticipantId?: string
}

const ParticipantsDetail = withRouter(
  ({ history, match: { params }, selectedParticipantId }: ParticipantDetailsProps) => {
    const { participantId: routeParticipantId, areaId } = params
    const participantId = routeParticipantId || selectedParticipantId || ""
    const [isEditPersonalDetails, setIsEditPersonalDetails] = useState(false)
    const [isEditDomicileAddress, setIsEditDomicileAddress] = useState(false)
    const [participantError, setParticipantError] = useState<AxiosErrorDataType | null>(null)
    const { t } = useTranslation("customer-participants")
    const dispatch = useAppDispatch()

    const {
      data: participantData,
      isLoading: participantIsLoading,
      remove,
    } = useQuery<AxiosResponse, AxiosErrorDataType>(["api"], () => getOneParticipantById(participantId), {
      enabled: !!participantId,
      onError: setParticipantError,
    })

    const { mutate: updateParticipant, isLoading: updateParticipantIsLoading } = useMutation(
      ["updateParticipant"],
      (participant: ParticipantResponse) => updateOneParticipantById(participantId, participant),
      {
        onSuccess: () => {
          setIsEditPersonalDetails(false)
          setIsEditDomicileAddress(false)
          remove()
        },
        onError: setParticipantError,
      },
    )

    const participantName = `${participantData?.data?.personalData?.firstName}
   ${participantData?.data?.personalData?.lastName}`

    useEffect(() => {
      dispatch(setPageSubtitle(participantName))
    }, [participantName, dispatch])

    useEffect(() => {
      return () => remove()
    }, [remove])

    return (
      <Paper sx={{ p: 5 }}>
        <Stack>
          {participantIsLoading && <CircularProgress />}
          <ParticipantsDeleteModal participantId={participantId} />

          <ErrorAlert
            visible={!!participantError}
            message={t(`error-codes:${participantError?.response?.data?.code || "OTHER"}`)}
          />

          <Formik
            initialValues={participantData?.data}
            enableReinitialize={true}
            validate={(values) => validateParticipants(values, VALIDATION_DEPS, t)}
            onSubmit={(values) => {
              updateParticipant({
                ...participantData?.data,
                ...values,
              })
            }}
          >
            {({ values, errors, touched, handleChange, handleBlur, handleSubmit, resetForm, setFieldValue }) => (
              <form onSubmit={handleSubmit}>
                <Stack
                  spacing={3}
                  sx={{
                    p: 3,
                    backgroundColor: isEditPersonalDetails ? bgColorGreen : "transparent",
                    borderRadius: 2,
                  }}
                >
                  <AlignItemsCenterBox>
                    <Typography fontWeight={600}>{t("detail.personalData")}</Typography>
                    <IconButton
                      onClick={() => {
                        setIsEditPersonalDetails((prevState) => !prevState)
                        resetForm()
                      }}
                      color="primary"
                    >
                      {isEditPersonalDetails ? <CancelRoundIcon fontSize="small" /> : <EditIcon fontSize="small" />}
                    </IconButton>
                  </AlignItemsCenterBox>

                  {isEditPersonalDetails ? (
                    <>
                      <ParticipantPersonalEditForm
                        values={values}
                        errors={errors}
                        touched={touched}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        setFieldValue={setFieldValue}
                      />
                      <ManagerPrimaryButton
                        sx={{ width: "fit-content", mt: 3 }}
                        label={t("registerForm.save")}
                        onClick={() => handleSubmit()}
                        isLoading={updateParticipantIsLoading}
                        disabled={updateParticipantIsLoading}
                      />
                    </>
                  ) : (
                    <ParticipantDetailPersonalData
                      personalData={participantData?.data?.personalData}
                      contactDetails={participantData?.data?.contactDetails}
                      gpType={participantData?.data?.gpType}
                    />
                  )}
                </Stack>

                <Stack
                  mt={3}
                  sx={{
                    p: 3,
                    backgroundColor: isEditDomicileAddress ? bgColorGreen : "transparent",
                    borderRadius: 2,
                  }}
                >
                  <AlignItemsCenterBox>
                    <Typography fontWeight={600}>{t("detail.domicileAddress")}</Typography>
                    <IconButton
                      onClick={() => {
                        setIsEditDomicileAddress((prevState) => !prevState)
                        resetForm()
                      }}
                      color={"primary"}
                    >
                      {isEditDomicileAddress ? <CancelRoundIcon fontSize="small" /> : <EditIcon fontSize="small" />}
                    </IconButton>
                  </AlignItemsCenterBox>

                  {isEditDomicileAddress ? (
                    <>
                      <ParticipantDomicileAddressEditForm
                        values={values}
                        errors={errors}
                        touched={touched}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        setFieldValue={setFieldValue}
                      />

                      <ManagerPrimaryButton
                        sx={{ width: "fit-content", mt: 3 }}
                        label={t("registerForm.save")}
                        onClick={() => handleSubmit()}
                        isLoading={updateParticipantIsLoading}
                        disabled={updateParticipantIsLoading}
                      />
                    </>
                  ) : (
                    <ParticipantDetailDomicileAddress {...participantData?.data?.domicileAddress} />
                  )}
                </Stack>
              </form>
            )}
          </Formik>
        </Stack>
        <Box px={2}>
          <ParticipantUtilityUnitsHistory
            participant={participantData?.data}
            isLoading={participantIsLoading}
            goToUnit={(unitId) => history.push(`/${areaId}/utility-units/${unitId}`)}
          />
        </Box>
      </Paper>
    )
  },
)

export default ParticipantsDetail
