import { useTranslation } from "react-i18next"
import { Badge, Box, Button, Stack, styled, Typography } from "@mui/material"
import { useAppSelector } from "../../../hooks"
import { useHistory } from "react-router-dom"
import { GridBaseColDef } from "@mui/x-data-grid/internals"
import { GridValueGetterParams } from "@mui/x-data-grid"
import {
  selectZevAllPeriodicBillings,
  selectZevNotDonePeriodicBillings,
  selectVewaDonePeriodicBillings,
  selectVewaNotDonePeriodicBillings,
} from "../store/billingsSlice"
import {
  AllParticipantsBillingState,
  ProductType,
  VewaAllParticipantsBillingState,
} from "../../../../data/generated-sources/openapi"

import { DataGridRedirectRowTable } from "../../../../uikit/table/DataGridTable"
import { PeriodicBillingsDoneStatus } from "./PeriodicBillingsDoneStatus"
import ArrowForwardIcon from "@mui/icons-material/ArrowForward"
import {
  VewaPeriodicBillingItem,
  ZevPeriodicBillingItem,
} from "../../../../domain/portal/manager/billings/Billings.Model"
import { selectSelectedContractData } from "../store/contractSelectorSlice"

interface PeriodicBillingProps {
  areaId: string
  separateTables?: boolean
  isLoading?: boolean
}

export const WaitingDataTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.main,
  fontSize: theme.typography.pxToRem(13),
  flexWrap: "wrap",
}))

export const PeriodicBillingList = ({ areaId, separateTables, isLoading }: PeriodicBillingProps) => {
  const { t } = useTranslation("settlements")
  const history = useHistory()

  const { contractId, contractProductType } = useAppSelector(selectSelectedContractData)
  const zevAllBills = useAppSelector(selectZevAllPeriodicBillings)
  const zevNotDoneBills = useAppSelector(selectZevNotDonePeriodicBillings)
  const vewaDoneBills = useAppSelector(selectVewaDonePeriodicBillings)
  const vewaNotDoneBills = useAppSelector(selectVewaNotDonePeriodicBillings)

  const billingsWithNewStatus = zevAllBills.filter((bill) => bill.isNew)

  const collectBills = (): ZevPeriodicBillingItem[] | VewaPeriodicBillingItem[] => {
    if (contractProductType === ProductType.ZEV) {
      if (!separateTables) {
        return zevAllBills
      }
      return zevNotDoneBills
    } else {
      if (!separateTables) {
        return [...vewaDoneBills, ...vewaNotDoneBills]
      }
      return vewaNotDoneBills
    }
  }

  const navigateToBillingEdit = (billingId: string) => {
    history.push(`/${areaId}/contracts/${contractId}/billings/all/${billingId}/edit`)
  }

  const navigateToBillingFinalize = (billingId: string) => {
    history.push(`/${areaId}/contracts/${contractId}/billings/all/${billingId}/finalize`)
  }

  const handleVewaBillingNavigation = (billingId: string, activeState: VewaAllParticipantsBillingState) => {
    if (activeState === VewaAllParticipantsBillingState.IN_PROGRESS) {
      history.push(`/${areaId}/contracts/${contractId}/billings/vewa/${billingId}/validate`)
    }
    if (activeState === VewaAllParticipantsBillingState.DONE) {
      history.push(`/${areaId}/contracts/${contractId}/billings/vewa/${billingId}/bills`)
    }
  }

  const handleZevRowClick = ({ id, activeState }: ZevPeriodicBillingItem) => {
    const { DATA_AVAILABLE, IN_PROGRESS, IN_PROGRESS_REOPENED } = AllParticipantsBillingState
    if ([DATA_AVAILABLE, IN_PROGRESS, IN_PROGRESS_REOPENED].includes(activeState)) {
      navigateToBillingEdit(id)
    } else {
      navigateToBillingFinalize(id)
    }
  }

  const billingsColumns: Array<GridBaseColDef> = [
    {
      field: "datePeriod",
      headerName: t("section-list-header.bill.period"),
      sortComparator: (v1, v2) => (v1?.startDate > v2?.startDate ? 1 : -1),
      valueGetter: (params: GridValueGetterParams): string => params.row,
      renderCell: ({ value }) => <Typography fontSize={14}>{value.datePeriod}</Typography>,
      flex: 200,
    },
    {
      field: "totalSum",
      headerName: t("section-list-header.bill.total"),
      flex: 150,
    },
    {
      field: "activeState",
      headerName: t("section-list-header.bill.status"),
      valueGetter: (params: GridValueGetterParams): string => params.row,
      sortComparator: (v1, v2) =>
        t(`list-billing-status.${v1?.activeState}`) > t(`list-billing-status.${v2?.activeState}`) ? 1 : -1,
      renderCell: ({ value }) => {
        let waitMsg = ""
        const isWaitingForDataStatus = value.activeState === AllParticipantsBillingState.WAITING_FOR_DATA

        if (isWaitingForDataStatus) {
          waitMsg = t("list-billing-status.WAITING_FOR_DATA_ETA_PAST")
          if (value?.estDataArrivalDate) {
            waitMsg = t("list-billing-status.WAITING_FOR_DATA_ETA", {
              estDate: value?.estDataArrivalDate,
            })
          }
        }

        return (
          <Box>
            <Typography fontSize={14}>{t(`list-billing-status.${value?.activeState}`)}</Typography>
            {isWaitingForDataStatus && <WaitingDataTypography>{waitMsg}</WaitingDataTypography>}
          </Box>
        )
      },
      flex: 200,
    },
    {
      field: "contractType",
      headerName: t("section-list-header.bill.contractType"),
      valueGetter: (params: GridValueGetterParams): string => params.row?.productName || "-",
      flex: 200,
    },
  ]

  return (
    <Stack p={4} flex={1}>
      <Stack flexDirection={"row"} alignItems={"center"} justifyContent={"space-between"}>
        <Typography variant="h5" fontWeight={400} mt={2} mb={5}>
          {t("dashboard:section-overview.most.recent.bills")}
          {!!billingsWithNewStatus?.length && (
            <Badge sx={{ mx: 2 }} badgeContent={billingsWithNewStatus?.length} color="warning" />
          )}
        </Typography>
        {!separateTables && (
          <Button
            variant="text"
            onClick={() => history.push(`/${areaId}/billings`)}
            startIcon={<ArrowForwardIcon fontSize="small" color="inherit" />}
          >
            {t("section-view.all.bills")}
          </Button>
        )}
      </Stack>
      <DataGridRedirectRowTable<ZevPeriodicBillingItem | VewaPeriodicBillingItem>
        rows={collectBills()}
        columns={billingsColumns}
        redirectDescription={t("settlements:list-billing-makeBill")}
        hideFooterPagination={!separateTables}
        loading={isLoading}
        getRowClassName={({ row }) => (row?.makeBill ? "" : "RowNotClickable")}
        onRowClick={({ row }) => {
          if (!row?.makeBill) return
          if (contractProductType === ProductType.ZEV) {
            handleZevRowClick(row)
          }
          if (contractProductType === ProductType.VEWA) {
            const { id, activeState } = row
            handleVewaBillingNavigation(id, activeState)
          }
        }}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 25,
            },
          },
        }}
      />
      {separateTables && <PeriodicBillingsDoneStatus areaId={areaId} />}
    </Stack>
  )
}
