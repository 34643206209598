import { useEffect, useMemo, useState } from "react"
import { RouteComponentProps, Switch, Route, withRouter, Redirect } from "react-router-dom"
import { AppContainerState } from "./AppContainer.Reducer"
import AuthLoginAdminConnect from "../auth/AuthLoginAdmin.Connect"
import AuthLoginManagerConnect from "../auth/AuthLoginManager.Connect"
import { firstViewState, ViewState } from "../Shared.Reducer"
import { AppHeaderView } from "./views/header/AppHeaderView"
import { AdminNavigationViewProps } from "./views/navigation/AdminNavigationView"
import { AppContainerView } from "./views/AppContainerView"
import { AppPageView } from "./views/AppPageView"
import { AppConfirmDialog } from "./views/AppConfirmDialog"
import { AppToast } from "./views/AppToast"
import AuthNewAccountPasswordConnect from "../auth/AuthNewAccountPassword.Connect"
import { mapDispatchToProps } from "./AppContainer.Connect"
import { ProgressIndicator } from "../../uikit/progress/ProgressIndicator"
import { AuthSession, AuthType } from "../../domain/auth/Auth.Model"
import { AppNavigationView } from "./views/navigation/AppNavigationView"
import { CustomerNavigationViewProps } from "./views/navigation/CustomerNavigationView"
import { AdminHeaderViewProps } from "./views/header/AdminHeaderView"
import { CustomerHeaderViewProps } from "./views/header/CustomerHeaderView"
import { ManagerPortalRoutes } from "../portal/manager/ManagerPortal.Routes"
import { AdminPortalRoutes, AdminPortalRouteParams } from "../portal/admin/AdminPortal.Routes"
import { FaqComponent } from "../portal/manager/faq/Faq.Component"
import { COOKIE_ITEMS } from "../config/outsorceConstants"
import HelmetHandler from "../auth/helmetHandler"
import "../../assets/css/base.css"
import AuthResetPasswordConnect from "../auth/AuthResetPassword/AuthResetPassword.Connect"
import { AuthResetPasswordSuccessfulMassageComponent } from "../auth/AuthResetPassword/AuthResetPassword.Component"
import AuthForgottenPasswordConnect from "../auth/AuthForgottenPassword.Connect"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { ThemeProvider } from "@mui/material"
import { appTheme, managerTheme } from "../Shared.Theme"
import { ONBOARDING_REQUIRED_COOKIE_NAME, readCookie } from "../Shared.Cookie"
import { Onboarding } from "../portal/manager/onboarding/Onboarding"

export interface AppContainerComponentProps
  extends AppContainerState,
    RouteComponentProps<AdminPortalRouteParams>,
    ReturnType<typeof mapDispatchToProps> {}

export interface AuthTypeProps {
  authType?: AuthType
}
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      staleTime: 5 * 60 * 1000,
      refetchOnWindowFocus: false,
    },
  },
})
export default withRouter((props: AppContainerComponentProps) => {
  const {
    appConfirmDialogState,
    appToastState,
    dialogAbortClick,
    dialogConfirmClick,
    toastClose,
    sessionExpired,
    location,
    logout,
    viewState,
    checkSession,
  } = props

  const authType = useMemo(() => viewState?.domainResult?.authType, [viewState])
  const [isReady, setIsReady] = useState(false)

  useEffect(() => {
    getConfigFile()
  }, [])

  useEffect(() => {
    HelmetHandler.configureHamlet()
    if (sessionExpired) {
      logout(location.pathname)
    }
  }, [sessionExpired, logout, location.pathname])

  useEffect(() => {
    HelmetHandler.configureHamlet()
    if (firstViewState(viewState)) {
      checkSession()
    }
  }, [viewState, checkSession])

  const getConfigFile = () => {
    fetch("/config.json")
      .then((r) => r.json())
      .then((data) => {
        localStorage.setItem("API_BASE_URL", data.API_BASE_URL)
      })
      .finally(() => setIsReady(true))
  }

  if (!isReady) return null

  return (
    <>
      <AuthResetPasswordSuccessfulMassageComponent />
      <QueryClientProvider client={queryClient}>
        <Switch>
          <Route exact path="/signin" component={AuthLoginManagerConnect} />
          <Route exact path="/signin/admin" component={AuthLoginAdminConnect} />
          <Route path="/reset-password" component={AuthForgottenPasswordConnect} />
          <Route path="/change-password" component={AuthResetPasswordConnect} />
          <Route path="/session/help/new-account-password" component={AuthNewAccountPasswordConnect} />
          <Route path="/faq" component={FaqComponent} />
          <AuthGuard {...props}>
            <AuthenticatedRoutes {...props} authType={authType} />
          </AuthGuard>
        </Switch>
        {appConfirmDialogState && (
          <AppConfirmDialog
            appConfirmDialog={appConfirmDialogState}
            dialogAbortClick={dialogAbortClick}
            dialogConfirmClick={() => appConfirmDialogState && dialogConfirmClick(appConfirmDialogState.nextAction)}
          />
        )}
        {appToastState && <AppToast appToast={appToastState} toastClose={toastClose} />}
      </QueryClientProvider>
    </>
  )
})

const AuthenticatedRoutes = (
  props: AuthTypeProps &
    AdminNavigationViewProps &
    CustomerNavigationViewProps &
    AdminHeaderViewProps &
    CustomerHeaderViewProps &
    AppContainerState &
    RouteComponentProps<AdminPortalRouteParams> &
    ReturnType<typeof mapDispatchToProps>,
) => {
  const { authType, viewState, location } = props
  const routeAreaId = useMemo(() => location.pathname.split("/")[1] ?? "", [location])
  const lastSelectedArea = useMemo(() => viewState?.domainResult?.lastSelectedArea, [viewState])
  const areas = useMemo(() => viewState?.domainResult?.areas ?? [], [viewState])
  const currentAreaId = useMemo(
    () => (routeAreaId === "" || routeAreaId === "areas" ? lastSelectedArea?.id : routeAreaId) ?? "",
    [routeAreaId, lastSelectedArea],
  )

  const onboardingRequired = readCookie(`${ONBOARDING_REQUIRED_COOKIE_NAME}-${currentAreaId}`)

  return (
    <ThemeProvider theme={authType === AuthType.ADMINISTRATOR ? appTheme : managerTheme}>
      <AppContainerView>
        {onboardingRequired ? (
          <Switch>
            <Route path="/:areaId/*">
              <Onboarding areaId={currentAreaId} {...props} />
            </Route>
          </Switch>
        ) : (
          <>
            <AppHeaderView {...props} />
            <AppNavigationView areas={areas} currentAreaId={currentAreaId} {...props} />
            <AppPageView {...props} sx={{ mt: 5 }}>
              {authType === AuthType.MANAGER && <ManagerPortalRoutes selectedAreaId={currentAreaId} />}
              {authType === AuthType.ADMINISTRATOR && <AdminPortalRoutes />}
            </AppPageView>
          </>
        )}
      </AppContainerView>
    </ThemeProvider>
  )
}

export const AuthGuard = (props: { viewState: ViewState<AuthSession>; children: React.ReactNode }) => {
  const { viewState, children } = props

  if (viewState.isLoading) return <ProgressIndicator />
  if (viewState.domainError) return signInRouteHandler()
  return <>{children}</>
}

const signInRouteHandler = () => {
  const wasAdmin = localStorage.getItem(COOKIE_ITEMS.userCkwIsAdmin)
  const redirectUrl = `/signin${wasAdmin ? "/admin" : ""}`
  localStorage.removeItem(COOKIE_ITEMS.userCkwIsAdmin)
  return <Redirect to={redirectUrl} />
}
