import { useEffect } from "react"
import { Redirect } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { Form, Formik } from "formik"
import { Dialog, DialogContent, DialogActions, Typography, Divider } from "@mui/material"

import { ErrorAlert } from "../../../../../uikit/Shared.Alert"
import { ProductPriceComponentUpsert } from "../../../../../domain/portal/admin/products/Products.Model"
import { PriceComponentForm } from "../form/PriceComponentForm"
import { validatePriceComponentForm } from "../form/PriceComponentForm.Validation"
import { ViewState } from "../../../../Shared.Reducer"
import { ProgressIndicator } from "../../../../../uikit/progress/ProgressIndicator"
import { coerce } from "../../../../Shared.View"
import { VALIDATION_DEPS } from "../../../../Shared.Validation"
import { FormUpdateActionsView } from "../../../../../uikit/form/FormUpdateActions"

interface ProductUpdatePriceDialogViewProps {
  open: boolean
  onClose: () => void
  productId: string
  priceId: string
  updatePriceComponent: (
    productId: string,
    priceId: string,
    ProductPriceComponentUpsert: ProductPriceComponentUpsert,
  ) => void
  getProductPriceComponentViewState: ViewState<ProductPriceComponentUpsert>
  updateProductPriceComponentViewState: ViewState<string>
}

export const ProductUpdatePriceDialogViewComponent = (props: ProductUpdatePriceDialogViewProps) => {
  const { t } = useTranslation("products")
  const {
    open,
    onClose,
    productId,
    priceId,
    getProductPriceComponentViewState,
    updatePriceComponent,
    updateProductPriceComponentViewState,
  } = props

  useEffect(() => {
    if (updateProductPriceComponentViewState.domainResult) onClose()
  }, [updateProductPriceComponentViewState, onClose])

  if (updateProductPriceComponentViewState.domainResult) return <Redirect to={`/products/${productId}`} />
  if (getProductPriceComponentViewState.isLoading) return <ProgressIndicator />

  return (
    <>
      <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
        {coerce(getProductPriceComponentViewState.domainResult, (priceComponentUpsert) => (
          <Formik<ProductPriceComponentUpsert>
            initialValues={priceComponentUpsert}
            onSubmit={(values) => updatePriceComponent(productId, priceId, values)}
            validate={(values) => validatePriceComponentForm(values, VALIDATION_DEPS, t)}
          >
            {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isValid, setFieldValue, dirty }) => (
              <Form onSubmit={handleSubmit}>
                {updateProductPriceComponentViewState.domainError && (
                  <ErrorAlert message={updateProductPriceComponentViewState.domainError.message} />
                )}
                <Typography variant="h6" p={3}>
                  {t("price.form.title.updatePriceComponent")}
                </Typography>
                <Divider />
                <DialogContent>
                  <PriceComponentForm
                    values={values}
                    errors={errors}
                    setFieldValue={setFieldValue}
                    touched={touched}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                  />
                </DialogContent>
                <Divider />
                <DialogActions sx={{ padding: 2.5 }}>
                  <FormUpdateActionsView
                    buttonCtaLabel={t("update.price.form.action.cta")}
                    isValid={isValid}
                    dirty={dirty}
                    isLoading={updateProductPriceComponentViewState.isLoading}
                    navigateBack={() => onClose()}
                  />
                </DialogActions>
              </Form>
            )}
          </Formik>
        ))}
      </Dialog>
    </>
  )
}
