import { useTranslation } from "react-i18next"
import { IconButton } from "@mui/material"
import { useQuery } from "@tanstack/react-query"
import { useCallback, useEffect, useState } from "react"
import { Route, RouteComponentProps, Switch } from "react-router-dom"

import { AdminPortalRouteParams } from "../AdminPortal.Routes"
import { getParamsFromUrl } from "../../../Shared.Utils"
import { getOnlyProfileById } from "../../../../domain/portal/admin/profiles/Profiles.Repository"
import { ProfileIcon } from "../../../../uikit/Shared.Icon"
import { BreadcrumbsNextIcon, BreadcrumbsNavButton } from "./fragments/BreadcrumbsFragments"

export const ProfilesBreadcrumb = (props: RouteComponentProps<AdminPortalRouteParams>) => {
  const { history } = props
  const { t } = useTranslation("profiles")
  const [profileName, setProfileName] = useState("")
  const profileUrlParams = getParamsFromUrl("/profiles/:profileId") || ""

  const { remove: removeProfile } = useQuery(["getProfile"], () => getOnlyProfileById(profileUrlParams?.profileId), {
    enabled: !!profileUrlParams?.profileId,
    onSuccess: ({ data }) => {
      setProfileName(`${data?.personal?.firstName} ${data?.personal?.lastName}`)
    },
  })

  useEffect(() => {
    return () => {
      setProfileName("")
      removeProfile()
    }
  }, [removeProfile, profileUrlParams?.profileId])

  const ProfileStartIcon = useCallback(() => {
    const redirectToProfiles = () => history.push("/profiles")
    return (
      <>
        <IconButton onClick={redirectToProfiles}>
          <ProfileIcon />
        </IconButton>
        <BreadcrumbsNextIcon />
      </>
    )
  }, [history])

  return (
    <Switch>
      <Route path="/profiles/:profileId/contracts/update">
        <ProfileStartIcon />
        <BreadcrumbsNavButton
          label={profileName}
          onClick={() => history.push(`/profiles/${profileUrlParams?.profileId}`)}
        />
        <BreadcrumbsNavButton disabled label={t("breadCrumbs.contract.update")} />
      </Route>

      <Route path="/profiles/:profileId/update">
        <ProfileStartIcon />
        <BreadcrumbsNavButton
          label={profileName}
          onClick={() => history.push(`/profiles/${profileUrlParams?.profileId}`)}
        />
        <BreadcrumbsNavButton disabled label={t("update.breadCrumb.title")} />
      </Route>

      <Route path="/profiles/create">
        <ProfileStartIcon />
        <BreadcrumbsNavButton disabled label={t("create.title")} />
      </Route>

      <Route path="/profiles/:profileId">
        <ProfileStartIcon />
        <BreadcrumbsNavButton disabled label={profileName} />
      </Route>

      <Route path="/profiles">
        <ProfileStartIcon />
        <BreadcrumbsNavButton disabled label={t("list.title")} />
      </Route>
    </Switch>
  )
}
