import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
  styled,
} from "@mui/material"
import { useState } from "react"
import { useTranslation } from "react-i18next"

import { ManagerOutlinedButton, ManagerPrimaryButton } from "../../../../uikit/button/ManagerButtons"
import { FormatStringAsNumber, formatMoneyLabel } from "../../../../domain/Domain.Formatters"
import { appThemeGrey10, appThemeGrey200 } from "../../../Shared.Theme"
import { AbortIcon } from "../../../../uikit/Shared.Icon"
import {
  ZevAllParticipantBilling,
  ZevIndividualParticipantBilling,
} from "../../../../domain/portal/manager/billings/Billings.Model"
import {
  ProductName,
  ZevAdminAllParticipantBilling,
  ZevAdminIndividualParticipantBilling,
} from "../../../../domain/portal/admin/billings/participant/BillingsParticipant.Model"
import { isManagerPortal } from "../../../Shared.Utils"

const TotalConsumptionStack = styled(Stack)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(14),
  backgroundColor: appThemeGrey10,
  padding: theme.spacing(3, 6),
  borderRadius: theme.spacing(1),
}))

const TotalText = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(13),
  fontWeight: 700,
  color: appThemeGrey200,
  paddingBottom: theme.spacing(1.5),
}))

const TotalAmount = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(23),
  color: theme.palette.text.primary,
  margin: 0,
  padding: 0,
}))

interface ZevBillingTotalSectionProps {
  isLoading: boolean
  onClickApprove: () => void
  billing:
    | ZevAllParticipantBilling
    | ZevIndividualParticipantBilling
    | ZevAdminAllParticipantBilling
    | ZevAdminIndividualParticipantBilling
}

export const ZevBillingTotalSection = ({ billing, isLoading, onClickApprove }: ZevBillingTotalSectionProps) => {
  const { totalCosts, totalConsumption, canCreateBilling, isBillingSuspended, hasCollectionFeature, productName } =
    billing
  const { t } = useTranslation("settlements")
  const [showSapDialog, setShowSapDialog] = useState(false)
  const isDisabled = isLoading || isBillingSuspended || !canCreateBilling

  const sendToSAP = isManagerPortal() && productName?.includes(ProductName.ZEV_PREMIUM_PLUS) && hasCollectionFeature

  const handleConfirmApprove = () => {
    setShowSapDialog(false)
    onClickApprove()
  }

  return (
    <TotalConsumptionStack flexDirection={"row"} alignItems={"center"} justifyContent={"space-between"}>
      <Stack flexDirection={"row"} alignItems={"center"}>
        <Box mr={10}>
          <TotalText>{t("billings-participant:detail.all.total.total.kwh")}</TotalText>
          <TotalAmount mt={1}>{FormatStringAsNumber(totalConsumption)}</TotalAmount>
        </Box>
        <Box>
          <TotalText>{t("billings-participant:detail.all.total.total")}</TotalText>
          <TotalAmount mt={1}>{formatMoneyLabel(totalCosts)}</TotalAmount>
        </Box>
      </Stack>
      {sendToSAP ? (
        <ManagerPrimaryButton
          disabled={isDisabled}
          isLoading={isLoading}
          onClick={() => setShowSapDialog(true)}
          label={t("billingEdit.confirm.and.send.to.ckw")}
        />
      ) : (
        <ManagerPrimaryButton
          disabled={isDisabled}
          isLoading={isLoading}
          onClick={handleConfirmApprove}
          label={t("billingEdit.create.billing")}
        />
      )}

      <Dialog open={showSapDialog}>
        <IconButton
          style={{ position: "absolute", top: "10px", right: "10px" }}
          onClick={() => setShowSapDialog(false)}
        >
          <AbortIcon />
        </IconButton>
        <DialogTitle style={{ textAlign: "center" }}>{t("billingEdit.confirmation.dialog.title")}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" style={{ textAlign: "center" }}>
            {t("billingEdit.confirmation.dialog.body")}
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            "&.MuiDialogActions-root": {
              alignItems: "center",
              justifyContent: "center",
            },
          }}
        >
          <ManagerOutlinedButton
            isLoading={false}
            onClick={() => setShowSapDialog(false)}
            label={t("billingEdit.confirmation.dialog.action.cancel")}
            sx={{ mr: 3.5 }}
          />
          <ManagerPrimaryButton
            disabled={false}
            isLoading={false}
            onClick={handleConfirmApprove}
            label={t("billingEdit.confirmation.dialog.action.confirm")}
          />
        </DialogActions>
      </Dialog>
    </TotalConsumptionStack>
  )
}
