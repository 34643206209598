import { DOMAIN_DEPENDENCIES } from "../../../../app/App.Config"
import { VewaCostShares } from "../../../../data/generated-sources/openapi"
import { apiHeaders } from "../../../Domain.Calls"

export const adminGetVewaAllParticipantBillingDetailsById = async (billId: string) => {
  const { data } = await DOMAIN_DEPENDENCIES.adminAllParticipantBillingApi.adminGetVewaAllParticipantBillingById(
    billId,
    apiHeaders(DOMAIN_DEPENDENCIES),
  )
  return data
}

export const adminGetVewaAllParticipantBillingParticipants = async (
  billId: string,
  page?: number,
  limit?: number,
  orderBy?: string,
) => {
  const { data } =
    await DOMAIN_DEPENDENCIES.adminAllParticipantBillingApi.adminGetVewaIndividualParticipationConsumption(
      billId,
      page,
      limit,
      orderBy,
      apiHeaders(DOMAIN_DEPENDENCIES),
    )
  return data
}

export const adminCreateVewaParticipantBillingBills = (billingId: string) =>
  DOMAIN_DEPENDENCIES.adminBillsApi.adminCreateVewaParticipantBillingBills(billingId, apiHeaders(DOMAIN_DEPENDENCIES))

export const adminUpdateBillingCostShares = async (
  vewaAllParticipantBillingId: string,
  vewaCostShares: VewaCostShares,
) =>
  DOMAIN_DEPENDENCIES.adminAllParticipantBillingApi.adminUpdateBillingCostShares(
    vewaAllParticipantBillingId,
    vewaCostShares,
    apiHeaders(DOMAIN_DEPENDENCIES),
  )

export const adminReopenVewaParticipantDoneBilling = async (billingId: string) =>
  DOMAIN_DEPENDENCIES.adminAllParticipantBillingApi.adminReopenVewaParticipantBilling(
    billingId,
    apiHeaders(DOMAIN_DEPENDENCIES),
  )
