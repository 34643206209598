import { Formik } from "formik"
import { useTranslation } from "react-i18next"
import { RouteComponentProps } from "react-router"
import { SingleLineTextField } from "../../../uikit/input/SingleLineTextField"
import { PageHeader } from "../../../uikit/typography/Header"
import { SmallPaddedBox } from "../../../uikit/box/PaddedBox"
import { Typography, Box } from "@mui/material"
import { FormActions } from "../../../uikit/form/FormView"
import { PrimaryButtonLoading } from "../../../uikit/button/PrimaryButtonLoading"
import { ErrorAlert } from "../../../uikit/Shared.Alert"
import { validationError, VALIDATION_DEPS } from "../../Shared.Validation"
import { AdminPortalRouteParams } from "../../portal/admin/AdminPortal.Routes"
import { useEffect, useState } from "react"
import { mapDispatchToProps } from "../AuthNewAccountPassword.Connect"
import { useHistory } from "react-router-dom"
import { AuthManagerContainerView } from "../views/AuthManagerContainerView"
import { validateResetPassword } from "./AuthResetPassword.Validation"
import { AuthResetPasswordState } from "./AuthResetPassword.Reducer"
import { useDispatch, useSelector } from "react-redux"
import { AuthActionType } from "../Auth.Epic"
import "./authResetPassword.css"
import { AppState } from "../../App.Reducer"

export interface AuthResetPasswordComponentProps
  extends AuthResetPasswordState,
    RouteComponentProps<AdminPortalRouteParams>,
    ReturnType<typeof mapDispatchToProps> {}

export interface AuthResetPasswordValues {
  currentPassword: string
  password: string
  confirmPassword: string
}

export interface AuthResetPasswordApiBody {
  currentPassword: string
  newPassword: string
}

export const AuthResetPasswordComponent = (props: AuthResetPasswordComponentProps) => {
  const { t } = useTranslation("auth")
  const { viewState } = props
  const dispatch = useDispatch()
  const history = useHistory()
  useEffect(() => {
    if (viewState.domainResult) {
      setTimeout(() => {
        history.goBack()
      }, 2000)
    }
  }, [viewState, history])

  const onResetPassword = (values: AuthResetPasswordValues) => {
    dispatch({
      type: AuthActionType.AUTH_RESET_PASSWORD,
      passwordObj: {
        currentPassword: values.currentPassword,
        newPassword: values.password,
      },
    })
  }

  return (
    <div className="ckw-reset-password-wrapper">
      <AuthManagerContainerView>
        <PageHeader id="reset-password-title" textAlign="center">
          {t("resetPassword.newPassword")}
        </PageHeader>
        <>
          <Box ml={2} mr={2} mb={2}>
            <Typography variant="body1" textAlign="center" id="reset-password-subtitle">
              {t("resetPassword.newPasswordSubLabel")}
            </Typography>
          </Box>
          <Formik<AuthResetPasswordValues>
            validateOnBlur
            initialValues={{
              currentPassword: "",
              password: "",
              confirmPassword: "",
            }}
            onSubmit={(values) => onResetPassword(values)}
            validate={(values) => validateResetPassword(values, VALIDATION_DEPS, t)}
          >
            {({ values, errors, handleChange, handleBlur, handleSubmit, isValid, touched }) => (
              <form onSubmit={handleSubmit}>
                {viewState.domainError && <ErrorAlert message={viewState.domainError.message} />}
                <SmallPaddedBox>
                  <SingleLineTextField
                    id="currentPassword"
                    autoFocus={true}
                    name="currentPassword"
                    type="password"
                    label={t("resetPassword.currentPassword")}
                    helperText={validationError(errors.currentPassword, touched.currentPassword)}
                    value={values.currentPassword}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </SmallPaddedBox>
                <SmallPaddedBox>
                  <SingleLineTextField
                    id="password"
                    name="password"
                    type="password"
                    label={t("resetPassword.newPassword")}
                    helperText={validationError(errors.password, touched.password)}
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </SmallPaddedBox>
                <SmallPaddedBox>
                  <SingleLineTextField
                    id="confirmPassword"
                    name="confirmPassword"
                    type="password"
                    label={t("resetPassword.newPasswordRepeat")}
                    helperText={validationError(errors.confirmPassword, touched.confirmPassword)}
                    value={values.confirmPassword}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </SmallPaddedBox>
                <FormActions>
                  <SmallPaddedBox>
                    <PrimaryButtonLoading
                      id="reset-password-cta"
                      disabled={!isValid || viewState.isLoading}
                      isLoading={viewState.isLoading}
                      label={t("resetPassword.submitBtn")}
                      type="submit"
                    />
                  </SmallPaddedBox>
                </FormActions>
              </form>
            )}
          </Formik>
        </>
      </AuthManagerContainerView>
    </div>
  )
}

export const AuthResetPasswordSuccessfulMassageComponent = () => {
  const { t } = useTranslation("auth")
  const authResetPasswordDomainResult = useSelector(
    (state: AppState) => state.adminPortal.authResetPassword.viewState.domainResult,
  )
  const [showMsg, setShowMsg] = useState(false)
  useEffect(() => {
    if (authResetPasswordDomainResult) {
      setShowMsg(true)
      setTimeout(() => {
        setShowMsg(false)
      }, 5000)
    }
  }, [authResetPasswordDomainResult])
  return (
    <>
      {showMsg && (
        <div className="ckw-reset-password-success-massage-wrapper">
          <div className="ckw-successful-massage">
            <p>{t("resetPassword.successful")}</p>
          </div>
          <div className="ckw-overlay"></div>
        </div>
      )}
    </>
  )
}
