import { useEffect, useState } from "react"
import { CircularProgress, Stack, Typography } from "@mui/material"
import { GridRowSelectionModel } from "@mui/x-data-grid"
import { useParams } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { useQuery } from "@tanstack/react-query"
import { ParticipantResponse } from "../../../../../../data/generated-sources/openapi"

import { getPagedParticipantsByAreaId } from "../../../../../../domain/portal/manager/participants/Participants.Repository"
import { DataGridTable } from "../../../../../../uikit/table/DataGridTable"
import { SingleLineTextField } from "../../../../../../uikit/input/SingleLineTextField"
import { ParticipantsListColumns } from "../../../participants/ParticipantsListTableColumnsConfig"
import { AxiosErrorDataType, filterArrayBySearchInput, useQueryDefaultOptions } from "../../../../../Shared.Utils"

export const MoveInParticipantList = ({
  onFetchError,
  onSelectParticipant,
}: {
  onFetchError: (error: AxiosErrorDataType) => void
  onSelectParticipant: (participantId: string) => void
}) => {
  const { t } = useTranslation()
  const { areaId } = useParams<{ areaId: string }>()
  const [filterQuery, setFilterQuery] = useState<string>("")
  const [participantList, setParticipantList] = useState<ParticipantResponse[]>([])
  const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([])

  const {
    isLoading,
    isFetching,
    data: areaParticipants,
    remove: removeParticipants,
  } = useQuery(["getMeter"], () => getPagedParticipantsByAreaId(areaId), {
    enabled: !!areaId,
    ...useQueryDefaultOptions,
    onError: onFetchError,
    onSuccess: (data) => setParticipantList(data),
  })

  const filterData = (searchInput: string) => {
    if (areaParticipants) {
      const filtered = filterArrayBySearchInput<ParticipantResponse>(areaParticipants, searchInput)
      setParticipantList(filtered)
    }
  }

  useEffect(() => {
    return () => removeParticipants()
  }, [removeParticipants])

  useEffect(() => {
    onSelectParticipant("")
  }, [])

  return (
    <>
      <Stack spacing={2} pb={5} pt={2}>
        <Typography fontSize={24} fontWeight={400}>
          {t("onboarding:participantsInfo.selectParticipant")}
        </Typography>
        <SingleLineTextField
          autoFocus={false}
          id="filter"
          name="filter"
          type="text"
          sx={{ width: "24rem" }}
          label={t("shared:form.search.label")}
          value={filterQuery}
          onChange={(event) => {
            setFilterQuery(event.target.value)
            filterData(event.target.value)
          }}
        />
      </Stack>
      {isLoading && isFetching ? (
        <CircularProgress />
      ) : (
        <DataGridTable<ParticipantResponse>
          rows={participantList}
          columns={ParticipantsListColumns(t)}
          loading={isLoading && isFetching}
          checkboxSelection
          onCellClick={({ id }) => {
            if (id === rowSelectionModel[0]) {
              setRowSelectionModel([])
              onSelectParticipant("")
            } else {
              setRowSelectionModel([id])
              onSelectParticipant(id?.toString())
            }
          }}
          rowSelectionModel={rowSelectionModel}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
        />
      )}
    </>
  )
}
