import { useMutation, useQuery } from "@tanstack/react-query"
import { useEffect, useState } from "react"
import { Paper } from "@mui/material"
import { RouteComponentProps } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { AdminPortalRouteParams } from "../../AdminPortal.Routes"

import { AxiosErrorDataType } from "../../../../Shared.Utils"
import {
  getAllParticipantBillingDetailsById,
  reopenAllParticipantBilling,
  sendSapAllParticipantBillings,
} from "../../../../../domain/portal/admin/billings/participant/BillingsAllParticipant.Repository"
import { AllParticipantsBillingState } from "../../../../../data/generated-sources/openapi"
import { ErrorAlert } from "../../../../../uikit/Shared.Alert"
import { ZevBillingFinalize } from "../../../shared/zevBillings/ZevBillingFinalize"
import {
  downloadAllParticipantBillingCsv,
  downloadAllParticipantBillingPdfs,
  downloadBillPdf,
  updateAdminBillPaidOrUnpaid,
} from "../../../../../domain/portal/admin/billings/participant/Bill.Repository"

export const ZevAllDoneParticipantBilling = ({
  match: { params },
  history,
}: RouteComponentProps<AdminPortalRouteParams>) => {
  const { billingId, id: contractId } = params
  const { t } = useTranslation("billings-participant")
  const [error, setError] = useState<AxiosErrorDataType>()

  const redirectToEditBill = () => history.push(`/management-contracts/${contractId}/billings/all/${billingId}/edit`)

  const {
    data: billing,
    isFetching: isFetchingBilling,
    remove: removeBill,
    refetch: refetchBill,
  } = useQuery(["getParticipantBilling"], () => getAllParticipantBillingDetailsById(billingId, contractId), {
    enabled: !!billingId,
    onSuccess: (data) => {
      if (![AllParticipantsBillingState.DONE, AllParticipantsBillingState.PAID].includes(data.billingStatus)) {
        redirectToEditBill()
      }
    },
    onError: setError,
  })

  const { mutate: reopenBilling, isLoading: isReopeningBilling } = useMutation(
    ["reopenBilling"],
    () => {
      setError(undefined)
      return reopenAllParticipantBilling(billingId)
    },
    {
      onSuccess: redirectToEditBill,
      onError: setError,
    },
  )

  const { mutate: downloadAllBill, isLoading: isDownloadingAllPdf } = useMutation(
    ["downloadAllBill"],
    () => {
      setError(undefined)
      return downloadAllParticipantBillingPdfs(billing?.id ?? "")
    },
    {
      onError: setError,
    },
  )

  const { mutate: downloadAllCsv, isLoading: isDownloadingAllCsv } = useMutation(
    ["downloadAllCsv"],
    () => {
      setError(undefined)
      return downloadAllParticipantBillingCsv(billing?.id ?? "")
    },
    {
      onError: setError,
    },
  )

  const { mutate: downloadPdfBill, isLoading: isDownloadingBill } = useMutation(
    ["downloadPdfBill"],
    (billId: string) => {
      setError(undefined)
      return downloadBillPdf(billId)
    },
    {
      onError: setError,
    },
  )

  const { mutate: togglePayStatus, isLoading: isTogglePayStatus } = useMutation(
    ["togglePayStatus"],
    ({ billId, paidOrUnpaid }: { billId: string; paidOrUnpaid: "paid" | "unpaid" }) => {
      setError(undefined)
      return updateAdminBillPaidOrUnpaid(billId, paidOrUnpaid)
    },
    {
      onSuccess: () => refetchBill(),
      onError: setError,
    },
  )

  const { mutate: sendAllBillingtoSAP, isLoading: isSendingSap } = useMutation(
    ["sendAllBillingtoSAP"],
    () => {
      setError(undefined)
      return sendSapAllParticipantBillings(billingId)
    },
    {
      onSuccess: () => refetchBill(),
      onError: setError,
    },
  )

  useEffect(() => {
    return () => {
      removeBill()
    }
  }, [removeBill])

  return (
    <Paper sx={{ p: 3, minHeight: "80vh" }}>
      <ErrorAlert
        scrollOnDisplay
        visible={!!error}
        message={t(`error-codes:${error?.response?.data?.code ?? error?.code ?? "OTHER"}`)}
      />
      <ZevBillingFinalize
        billing={billing}
        isLoading={isFetchingBilling || isTogglePayStatus || isDownloadingBill}
        onSendSAP={sendAllBillingtoSAP}
        isSendingToSAP={isSendingSap}
        onReopenBilling={reopenBilling}
        isReopeningBilling={isReopeningBilling}
        onDownloadAllBills={downloadAllBill}
        isDownloadingAllPdf={isDownloadingAllPdf}
        onDownloadAllCsv={downloadAllCsv}
        isDownloadingAllCsv={isDownloadingAllCsv}
        onDownloadBill={(billId: string) => downloadPdfBill(billId)}
        onToggleBillPay={(billId, paidOrUnpaid) => togglePayStatus({ billId, paidOrUnpaid })}
      />
    </Paper>
  )
}
