import { useParams } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { Stack, Typography, TypographyProps, styled, Box, Paper, CircularProgress } from "@mui/material"

import { formatMoneyDecimalLabel } from "../../../../../domain/Domain.Formatters"
import { FormSectionTitle } from "../../../../../uikit/form/FormView"
import RedirectButton from "../../../../../uikit/button/RedirectButton"
import { useQuery } from "@tanstack/react-query"
import { useCallback, useEffect } from "react"
import {
  getContractPricePackages,
  getProductAndContractByContractId,
} from "../../../../../domain/portal/admin/contracts/Contracts.Repository"
import { ProductType, ZevPricePackage } from "../../../../../data/generated-sources/openapi"
import { useQueryDefaultOptions } from "../../../../Shared.Utils"

const PackagesWrapper = styled("div")({
  display: "flex",
  flexWrap: "wrap",
  alignItems: "center",
  justifyContent: "flex-start",
})

const Caption = styled((props: TypographyProps) => <Typography variant="caption" {...props} />)(({ theme }) => ({
  color: theme.palette.secondary.dark,
}))

const PriceValue = styled(Typography)(({ theme }) => ({
  fontSize: "0.9em",
  color: theme.palette.text.primary,
  marginBottom: "1rem",
}))

export const ContractElectricityPricesSection = () => {
  const { id } = useParams<{ id: string }>()
  const { t } = useTranslation("contracts-management")

  const {
    isLoading: isLoadingProduct,
    isFetching: isFetchingProduct,
    data: contractProductData,
    remove: removeProductAndContract,
  } = useQuery(["getProductAndContract"], () => getProductAndContractByContractId(id), {
    enabled: !!id,
    ...useQueryDefaultOptions,
  })
  const isZevProductType = contractProductData?.product?.productType === ProductType.ZEV

  const { data: pricePackages, remove: removePrices } = useQuery(
    ["getPricePackages"],
    () => getContractPricePackages(id),
    {
      enabled: !!id,
      ...useQueryDefaultOptions,
    },
  )

  useEffect(() => {
    return () => {
      removePrices()
      removeProductAndContract()
    }
  }, [removePrices, removeProductAndContract])

  const ElectricityPrice = useCallback(
    ({ name, prices, id }: ZevPricePackage) => (
      <Box key={id} p={1} mr={6}>
        <Caption>{t("priceForm.name")}</Caption>
        <PriceValue>{name}</PriceValue>
        <Caption>{t("priceForm.solarPrice")}</Caption>
        <PriceValue>{formatMoneyDecimalLabel(prices?.solarPrice)}</PriceValue>
        <Caption>{t("priceForm.highTariffPrice")}</Caption>
        <PriceValue>{formatMoneyDecimalLabel(prices?.highTariffPrice)}</PriceValue>
        <Caption>{t("priceForm.lowTariffPrice")}</Caption>
        <PriceValue>{formatMoneyDecimalLabel(prices?.lowTariffPrice)}</PriceValue>
        <Caption>{prices?.isFixedRate ? t("priceForm.monthlyMeasurement") : t("priceForm.kwhMeasurement")}</Caption>
        <PriceValue>{formatMoneyDecimalLabel(prices?.additionalServicesPrice)}</PriceValue>
        <Caption>{t("priceForm.spikePrice")}</Caption>
        <PriceValue>{prices?.spikePrice ? formatMoneyDecimalLabel(prices?.spikePrice) : "-"}</PriceValue>
      </Box>
    ),
    [t],
  )

  if (isLoadingProduct && isFetchingProduct) return <CircularProgress color="primary" />
  if (!isZevProductType) return <></>

  return (
    <Paper sx={{ p: 2 }}>
      <Stack flexDirection={"row"} alignItems="center" justifyContent="space-between" mb={4}>
        <FormSectionTitle label={t("priceForm.title")} />
        <RedirectButton redirectUrl={`/management-contracts/${id}/prices/update`} />
      </Stack>
      <PackagesWrapper>{(pricePackages || []).map(ElectricityPrice)}</PackagesWrapper>
    </Paper>
  )
}
