import { useEffect, useState } from "react"
import { Dialog, DialogActions, DialogContent, DialogTitle, Button } from "@mui/material"
import { useMutation } from "@tanstack/react-query"
import { useTranslation } from "react-i18next"

import { ManagerPrimaryButton } from "../../../../../../uikit/button/ManagerButtons"
import { customerDeleteUtilityUnitParticipation } from "../../../../../../domain/portal/manager/utilityUnit/UtilityUnit.Repository"
import { ErrorAlert } from "../../../../../../uikit/Shared.Alert"
import { ParticipationDialogPros } from "./UtilityUnitParticipations"
import { AxiosErrorDataType } from "../../../../../Shared.Utils"

export const DeleteParticipationParticipant = ({
  showDialog,
  participation,
  onUpdateSuccess,
  onClose,
}: ParticipationDialogPros) => {
  const { t } = useTranslation("utilityUnitParticipations")
  const [moveError, setMoveError] = useState<AxiosErrorDataType>()

  useEffect(() => {
    return () => {
      setMoveError(undefined)
    }
  }, [setMoveError])

  const handleClose = () => {
    setMoveError(undefined)
    onClose()
  }

  const { mutate: deleteParticipation, isLoading: isDeleting } = useMutation(
    ["deleteParticipation"],
    () => customerDeleteUtilityUnitParticipation(participation.utilityUnitId, participation.id),
    {
      onSuccess: onUpdateSuccess,
      onError: setMoveError,
    },
  )

  return (
    <Dialog open={showDialog} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle>{t("tooltip.delete")}</DialogTitle>
      <ErrorAlert
        scrollOnDisplay
        visible={!!moveError}
        message={t(`error-codes:${moveError?.response?.data?.code || moveError?.code || "OTHER"}`)}
      />
      <DialogContent>{t("delete.dialog.body")}</DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleClose} disabled={isDeleting}>
          {t("shared:form.action.cancel")}
        </Button>
        <ManagerPrimaryButton
          isLoading={isDeleting}
          disabled={isDeleting}
          label={t("tooltip.delete")}
          onClick={() => deleteParticipation()}
        />
      </DialogActions>
    </Dialog>
  )
}
