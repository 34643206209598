import { Stack, Box } from "@mui/material"
import { isValidElement } from "react"
import {
  appThemeGrey200,
  appThemeGrey400,
  appThemeGrey800,
  appThemeGreyNewDesign,
  appThemeSecondaryColor,
} from "../../app/Shared.Theme"
import { Body1, Body2, Caption, DataItemLabel } from "../typography/Typography"
import { getTranslateValue } from "../../app/Shared.Utils"

interface DataItemBoxPaddingInterface {
  pt?: string | number
  pb?: string | number
  pr?: string | number
  pl?: string | number
}
interface DataItemBoxProps {
  id?: string
  title: string
  value?: string | React.ReactNode
  padding?: DataItemBoxPaddingInterface
}

export const DataItemBox = (props: DataItemBoxProps) => {
  const { id, title, value } = props
  return (
    <Stack>
      <Box pl={1} pt={1} pr={1}>
        <Caption
          id={id ? `data-item-box-title-${id}` : undefined}
          sx={{
            color: appThemeSecondaryColor,
          }}
        >
          {title}
        </Caption>
      </Box>
      <Box id={id ? `data-item-box-value-${id}` : undefined} pl={1} pb={1} pr={1}>
        {isValidElement(value) && value}
        {!isValidElement(value) && (value ? <Body1>{value}</Body1> : "-")}
      </Box>
    </Stack>
  )
}

export const FormDataItemBox = (props: DataItemBoxProps) => {
  const { id, title, value } = props
  return (
    <Stack>
      <Box pl={1} pt={1} pr={1}>
        <Caption
          id={id ? `data-item-box-title-${id}` : undefined}
          sx={{
            color: "#9e9e9e",
            fontSize: "0.65rem",
          }}
        >
          {title}
        </Caption>
      </Box>
      <Box id={id ? `data-item-box-value-${id}` : undefined} pl={1} pb={1} pr={1}>
        {isValidElement(value) && value}
        {!isValidElement(value) && (value ? <Body1>{value}</Body1> : "-")}
      </Box>
    </Stack>
  )
}

export const PhoneFormDataItemBox = (props: DataItemBoxProps) => {
  const { id, title, value } = props
  return (
    <Stack>
      <Box pl={1} pt={1} pr={1}>
        <Caption
          id={id ? `data-item-box-title-${id}` : undefined}
          sx={{
            color: "#9e9e9e",
            fontSize: "0.65rem",
          }}
        >
          {title}
        </Caption>
      </Box>
      <Box id={id ? `data-item-box-value-${id}` : undefined} pl={1} pb={1} pr={1}>
        {isValidElement(value) && value}
        {!isValidElement(value) && (value ? <Body1>{value}</Body1> : "-")}
      </Box>
    </Stack>
  )
}

export const DataItemBoxNewDesign = (props: DataItemBoxProps) => {
  const { id, title, value, padding } = props
  return (
    <Stack>
      <Box pl={padding?.pl ?? 1} pt={padding?.pt ?? 1} pr={padding?.pr ?? 1} pb={padding?.pb ?? 0}>
        <Caption
          id={id ? `data-item-box-title-${id}` : undefined}
          sx={{
            color: appThemeGreyNewDesign,
          }}
        >
          {title}
        </Caption>
      </Box>
      <Box
        id={id ? `data-item-box-value-${id}` : undefined}
        pl={padding?.pl ?? 1}
        pt={padding?.pt ?? 1}
        pr={padding?.pr ?? 1}
        pb={padding?.pb ?? 0}
      >
        {isValidElement(value) && value}
        {!isValidElement(value) && (value ? <DataItemLabel>{value}</DataItemLabel> : "-")}
      </Box>
    </Stack>
  )
}

export const DataItemBoxNoValue = (props: Partial<DataItemBoxProps>) => {
  const { id, title } = props
  return (
    <Box id={id ? `data-item-box-value-${id}` : undefined} pl={1} pb={1} pr={1} pt={1}>
      {title && <Caption sx={{ color: appThemeGrey400 }}>{getTranslateValue(title)}</Caption>}
      <Body2>-</Body2>
    </Box>
  )
}

export const ManagerDataItemBox = (props: DataItemBoxProps) => {
  const { id, title, value, padding } = props
  return (
    <Stack>
      <Box pl={padding?.pl ?? 0} pt={padding?.pt ?? 0} pr={padding?.pr ?? 0} pb={padding?.pb ?? 0}>
        <Caption
          id={id ? `data-item-box-title-${id}` : undefined}
          sx={{
            fontFamily: "Montserrat",
            fontStyle: "normal",
            fontWeight: "500",
            fontSize: "16px",
            lineHeight: "16px",
            color: appThemeGrey200,
          }}
        >
          {title}
        </Caption>
      </Box>
      <Box id={id ? `data-item-box-value-${id}` : undefined}>
        {isValidElement(value) && value}
        {!isValidElement(value) &&
          (value ? (
            <DataItemLabel
              style={{
                fontFamily: "Montserrat",
                fontStyle: "normal",
                fontSize: "16px",
                lineHeight: "16px",
                color: appThemeGrey800,
                fontWeight: "500",
                marginTop: "0.5rem",
              }}
            >
              {value}
            </DataItemLabel>
          ) : (
            "-"
          ))}
      </Box>
    </Stack>
  )
}
