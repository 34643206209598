import { useTranslation } from "react-i18next"
import { useState } from "react"
import { useMutation } from "@tanstack/react-query"
import { Form, Formik } from "formik"
import { Grid, IconButton, Stack } from "@mui/material"
import moment from "moment"
import DateUtils from "../../../../../../services/utils/DateUtils"

import { BillingFrequency, BillingSettingsResponse } from "../../../../../../data/generated-sources/openapi"
import {
  customerUpdateContractVewaBillingSettingsById,
  customerUpdateContractZevBillingSettingsById,
} from "../../../../../../domain/portal/manager/contracts/Contract.Repository"

import { ManagerBlackAlert } from "../../../../../../uikit/Shared.Alert"
import { CancelRoundIcon, EditIcon } from "../../../../../../uikit/Shared.Icon"
import { SelectPicker } from "../../../../../../uikit/input/SelectPicker"
import { SingleLineTextField } from "../../../../../../uikit/input/SingleLineTextField"
import { ManagerPrimaryButton } from "../../../../../../uikit/button/ManagerButtons"
import { apiFormattedDateToTimestamp } from "../../../../../../domain/Domain.Formatters"
import { SingleLineDatePicker } from "../../../../../../uikit/input/SingleLineDatePicker"
import {
  BillingSettingsFormTitle,
  BillingSettingsFormWrapper,
  BillingSettingsLabel,
  BillingSettingsRow,
  BillingSettingsValueLabel,
} from "./BillingSettingsTab"
import { vewaBillingSchema, zevBillingSchema } from "./BillingPreferencesSchema.Validation"
import { AxiosErrorDataType } from "../../../../../Shared.Utils"

interface BillingSettingsFormProps {
  contractId: string
  isZevProductType: boolean
  vewaStartDate?: string
  billingResponse: BillingSettingsResponse
  onSuccessUpdate: () => void
  onErrorUpdate: (error: AxiosErrorDataType) => void
}

const BillingSettingsForm = ({
  contractId,
  billingResponse,
  isZevProductType,
  vewaStartDate,
  onSuccessUpdate,
  onErrorUpdate,
}: BillingSettingsFormProps) => {
  const { t } = useTranslation("settings")
  const [editMode, setEditMode] = useState(false)
  const [nextFreqDate, setNextFreqDate] = useState("")

  const nextBillingFrequencyOptions = (): BillingFrequency[] => {
    const billingArray = [BillingFrequency.YEARLY, BillingFrequency.BIANNUAL]
    const index = billingArray.indexOf(billingResponse.billingFrequency)
    if (index > -1) {
      billingArray.splice(index, 1)
    }
    return billingArray
  }
  const startDate: Date = vewaStartDate ? new Date(vewaStartDate) : new Date()
  const vewaMinDate: Date = startDate
  const vewaMaxDate: Date = moment(startDate).add(2, "y").toDate()

  const handleUpdateSuccess = () => {
    setEditMode(false)
    onSuccessUpdate()
  }

  const { mutate: updateZevSettings, isLoading: isZevLoading } = useMutation(
    ["updateZevSettings"],
    (nextBillingFrequency?: BillingFrequency) =>
      customerUpdateContractZevBillingSettingsById(
        contractId,
        {
          newNextBillingFrequency: nextBillingFrequency,
        },
        billingResponse.billingFrequency,
      ),
    {
      onError: onErrorUpdate,
      onSuccess: (data) => {
        setNextFreqDate(data?.currentFrequencyLastActiveDate)
        handleUpdateSuccess()
      },
    },
  )

  const { mutate: updateVewaSettings, isLoading: isVewaLoading } = useMutation(
    ["updateVewaSettings"],
    (nextBillingDate: string) =>
      customerUpdateContractVewaBillingSettingsById(contractId, { newNextBillingDate: nextBillingDate }),
    {
      onError: onErrorUpdate,
      onSuccess: handleUpdateSuccess,
    },
  )

  const handleSubmit = ({ nextBillingFrequency, nextBillingDate }: BillingSettingsResponse) => {
    isZevProductType ? updateZevSettings(nextBillingFrequency || undefined) : updateVewaSettings(nextBillingDate)
  }

  return (
    <Formik<BillingSettingsResponse>
      initialValues={billingResponse}
      onSubmit={handleSubmit}
      enableReinitialize
      validateOnBlur
      validationSchema={isZevProductType ? zevBillingSchema : vewaBillingSchema}
    >
      {({ values, errors, touched, isValid, dirty, handleBlur, setFieldValue, resetForm }) => {
        const touchedFileds = Object.keys(touched)
        return (
          <Form>
            {nextFreqDate && (
              <ManagerBlackAlert
                label={t("billingPreferences.frequencyChangeDate", {
                  date: nextFreqDate,
                })}
              />
            )}
            <BillingSettingsFormWrapper container item rowGap={3} editMode={editMode}>
              <Grid item xs={12}>
                <Stack flexDirection={"row"} justifyContent={"flex-start"} alignItems={"center"} mb={2}>
                  <BillingSettingsFormTitle>{t("billingPreferences.preferences")}</BillingSettingsFormTitle>
                  <IconButton
                    onClick={() => {
                      resetForm()
                      setEditMode((prevState) => !prevState)
                    }}
                    color="primary"
                  >
                    {editMode ? <CancelRoundIcon fontSize="small" /> : <EditIcon fontSize="small" />}
                  </IconButton>
                </Stack>
              </Grid>
              {editMode ? (
                <>
                  <BillingSettingsRow>
                    <SingleLineDatePicker
                      disabled={isZevProductType}
                      name="nextBillingDate"
                      label={t("contracts-management:billingForm.nextBillingDate")}
                      value={apiFormattedDateToTimestamp(values.nextBillingDate)}
                      onBlur={handleBlur}
                      onChange={(value) => setFieldValue("nextBillingDate", DateUtils.getDeFormatDate(value))}
                      helperText={
                        touchedFileds.includes("nextBillingDate") && errors.nextBillingDate
                          ? errors.nextBillingDate
                          : undefined
                      }
                      {...(vewaMinDate && { minDate: vewaMinDate })}
                      {...(vewaMaxDate && { maxDate: vewaMaxDate })}
                    />
                  </BillingSettingsRow>
                  <BillingSettingsRow>
                    <SingleLineTextField
                      disabled
                      type="text"
                      name="startDate"
                      label={t("billingPreferences.billingPeriod")}
                      value={`${DateUtils.getDeFormatDateWithMonthString(
                        values.billingPeriod.startDate,
                      )} - ${DateUtils.getDeFormatDateWithMonthString(values?.nextBillingDate)}`}
                    />
                  </BillingSettingsRow>
                  {isZevProductType && (
                    <>
                      <BillingSettingsRow>
                        <SingleLineTextField
                          disabled
                          type="text"
                          name="billingFrequency"
                          label={t("contracts-management:billingForm.billingFrequency")}
                          value={t(`contracts-management:billingFrequency.${values.billingFrequency}`)}
                        />
                      </BillingSettingsRow>
                      <BillingSettingsRow>
                        <SelectPicker
                          type="text"
                          name="nextBillingFrequency"
                          label={t("contracts-management:billingForm.nextBillingFrequency")}
                          emptyValue={t("contracts-management:billingForm.emptyBillingFrequency")}
                          value={values.nextBillingFrequency ?? ""}
                          items={nextBillingFrequencyOptions().map((frequency) => ({
                            label: t(`contracts-management:billingFrequency.${frequency}`),
                            value: frequency,
                          }))}
                          onBlur={handleBlur}
                          onChange={({ target }) => setFieldValue("nextBillingFrequency", target.value)}
                          helperText={
                            touchedFileds.includes("nextBillingFrequency") && errors.nextBillingFrequency
                              ? errors.nextBillingFrequency
                              : undefined
                          }
                        />
                      </BillingSettingsRow>
                    </>
                  )}
                  <Grid item xs={12}>
                    <ManagerPrimaryButton
                      type="submit"
                      isLoading={isVewaLoading || isZevLoading}
                      label={t("shared:form.action.save")}
                      disabled={isVewaLoading || isZevLoading || !isValid || !dirty}
                    />
                  </Grid>
                </>
              ) : (
                <>
                  <BillingSettingsRow>
                    <BillingSettingsLabel>{t("billingPreferences.nextBillingDate")}</BillingSettingsLabel>
                    <BillingSettingsValueLabel>
                      {DateUtils.getDeFormatDateWithMonthString(values.nextBillingDate)}
                    </BillingSettingsValueLabel>
                  </BillingSettingsRow>
                  <BillingSettingsRow>
                    <BillingSettingsLabel>{t("billingPreferences.billingPeriod")}</BillingSettingsLabel>
                    <BillingSettingsValueLabel>{`${DateUtils.getDeFormatDateWithMonthString(
                      values.billingPeriod.startDate,
                    )} - ${DateUtils.getDeFormatDateWithMonthString(
                      values?.billingPeriod?.endDate,
                    )}`}</BillingSettingsValueLabel>
                  </BillingSettingsRow>
                  {isZevProductType && (
                    <>
                      <BillingSettingsRow>
                        <BillingSettingsLabel>
                          {t("contracts-management:billingForm.billingFrequency")}
                        </BillingSettingsLabel>
                        <BillingSettingsValueLabel>
                          {t(`contracts-management:billingFrequency.${values.billingFrequency}`)}
                        </BillingSettingsValueLabel>
                      </BillingSettingsRow>
                      <BillingSettingsRow>
                        <BillingSettingsLabel>
                          {t("contracts-management:billingForm.nextBillingFrequency")}
                        </BillingSettingsLabel>
                        <BillingSettingsValueLabel>
                          {values.nextBillingFrequency
                            ? t(`contracts-management:billingFrequency.${values.nextBillingFrequency}`)
                            : "-"}
                        </BillingSettingsValueLabel>
                      </BillingSettingsRow>
                    </>
                  )}
                </>
              )}
            </BillingSettingsFormWrapper>
          </Form>
        )
      }}
    </Formik>
  )
}

export default BillingSettingsForm
