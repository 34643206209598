import { styled, Typography, Box, Stack } from "@mui/material"
import { NavigationButton } from "../../../../uikit/button/NavigationButton"
import { useTranslation } from "react-i18next"
import { RouteComponentProps } from "react-router"
import { useMemo } from "react"
import { useHistory } from "react-router-dom"
import { adminNavigationConfig, AdminNavItem, NavItemType } from "../../../config/navigationConfig"

export interface AdminNavigationViewProps {
  navigateAdminProduct: () => void
  navigateAdminProfile: () => void
  navigateAdminConnectionObjects: () => void
  navigateAdminContracts: () => void
  navigateAdminBillingInitial: () => void
  navigateAdminBillingRecurring: () => void
  navigateAdminTodoList: () => void
}

const SidebarHeader = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  padding: theme.spacing(1.75, 2),
  color: theme.palette.primary.light,
  fontSize: theme.typography.pxToRem(14),
}))

export const AdminNavigationView = (props: RouteComponentProps) => {
  const { t } = useTranslation()
  const { location } = props
  const selectedNavigation = useMemo(() => determineSelectedNavigation(location.pathname), [location])
  const history = useHistory()

  const navListLayout = () => {
    return adminNavigationConfig.map((item: AdminNavItem, index: number) => {
      switch (item.navType) {
        case NavItemType.BUTTON:
          return (
            <NavigationButton
              key={index}
              label={t(String(item.label))}
              icon={item.icon && item.icon({ fontSize: "medium" })}
              selected={selectedNavigation === item.selectedLabel}
              onClick={() => history.push(String(item.redirectUrl))}
            />
          )
        case NavItemType.HEADER:
          return <SidebarHeader key={index}>{t(String(item.label))}</SidebarHeader>
        case NavItemType.SPACER:
          return <AdminNavigationSpacer key={index} />
      }
    })
  }
  return (
    <Box
      sx={{
        backgroundColor: "#fff",
        borderRight: "1px solid rgba(0, 0, 0, 0.12)",
        position: "fixed",
        top: "80px",
        height: "calc(100vh - 80px)",
        overflowY: "auto",
      }}
    >
      <Stack width={240}>{navListLayout()}</Stack>
    </Box>
  )
}

const AdminNavigationSpacer = () => <Box mb={2.5} />

export enum SelectedNavigation {
  PRODUCTS = "PRODUCT",
  PROFILES = "PROFILES",
  BUILDINGS = "BUILDINGS",
  BILLING_INITIAL = "BILLING_INITIAL",
  BILLING_RECURRING = "BILLING_RECURRING",
  TASK_LIST = "TASK_LIST",
  METERS = "METERS",
  UTILITY = "UTILITY",
  AREA = "AREA",
  CONTRACT_MANAGEMENT = "CONTRACT_MANAGEMENT",
  UNKNOWN = "UNKNOWN",
}

const determineSelectedNavigation = (pathname: string): SelectedNavigation => {
  if (pathname.includes("buildings")) {
    return SelectedNavigation.BUILDINGS
  } else if (pathname.startsWith("/products")) {
    return SelectedNavigation.PRODUCTS
  } else if (pathname.startsWith("/profiles")) {
    return SelectedNavigation.PROFILES
  } else if (pathname.startsWith("/billings/initial")) {
    return SelectedNavigation.BILLING_INITIAL
  } else if (pathname.startsWith("/billings/recurring")) {
    return SelectedNavigation.BILLING_RECURRING
  } else if (pathname.startsWith("/tasklist")) {
    return SelectedNavigation.TASK_LIST
  } else if (pathname.startsWith("/meters")) {
    return SelectedNavigation.METERS
  } else if (pathname.startsWith("/utility-units")) {
    return SelectedNavigation.UTILITY
  } else if (pathname.startsWith("/area")) {
    return SelectedNavigation.AREA
  } else if (pathname.startsWith("/management-contracts")) {
    return SelectedNavigation.CONTRACT_MANAGEMENT
  } else {
    return SelectedNavigation.UNKNOWN
  }
}
