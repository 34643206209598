import { Box as MuiBox, styled, Typography, Stack, Box } from "@mui/material"
import { useTranslation } from "react-i18next"
import { DownloadIcon, PlusIcon } from "../../../../uikit/Shared.Icon"
import { FabButton } from "../../../../uikit/button/FabButton"
import { SingleLineTextField } from "../../../../uikit/input/SingleLineTextField"
import { useHistory } from "react-router-dom"
import { ColumnEnumInterface, SharedListHeaderConfigInterface } from "../sharedComponentsConfig/Components.Interfaces"
import { PrimaryButton } from "../../../../uikit/button/PrimaryButton"
import { generateRedirectUrlWithQueries } from "../../../Shared.Utils"

interface SharedListHeaderProps {
  header: SharedListHeaderConfigInterface
  additionalRouteQueryParams?: ColumnEnumInterface
  filterQuery?: string
  setFilterQuery?: (query: string) => void
  onClick?: () => void
  onDownloadClick?: () => void
  children?: React.ReactNode
}

const HeaderBox = styled(MuiBox)(({ theme }) => ({
  display: "flex",
  flexWrap: "wrap",
  alignItems: "center",
  justifyContent: "space-between",
  padding: theme.spacing(1, 2, 3, 2),
}))

export const SharedListHeader = ({
  header,
  filterQuery,
  setFilterQuery,
  onClick,
  onDownloadClick,
  additionalRouteQueryParams,
}: SharedListHeaderProps) => {
  const { t } = useTranslation()
  const history = useHistory()

  const handleBtnClick = () => {
    if (header?.redirectUrl) {
      history.push(getRedirectUrl())
    } else if (onClick) {
      onClick()
    }
  }

  const getRedirectUrl = (): string => {
    if (!header?.redirectUrl) return ""
    if (additionalRouteQueryParams) {
      return generateRedirectUrlWithQueries(header?.redirectUrl(), additionalRouteQueryParams)
    } else {
      return header?.redirectUrl()
    }
  }

  return (
    <>
      <HeaderBox>
        <Typography variant="h4" fontSize={24} fontWeight={300} textTransform={"uppercase"} fontFamily="Montserrat">
          {t(header?.title)}
        </Typography>
        {header.showDownloadBtn && (
          <PrimaryButton
            onClick={onDownloadClick}
            startIcon={<DownloadIcon />}
            label={t("shared:button.downloadCsv")}
          />
        )}
        <Stack flexDirection="row" alignItems="center" justifyContent="flex-end" minWidth={320}>
          {header?.showFilter && (
            <SingleLineTextField
              name="filter"
              type="text"
              label={t("shared:form.filter.label")}
              autoFocus={true}
              value={filterQuery}
              onChange={(event) => setFilterQuery && setFilterQuery(event.target.value)}
              sx={{ mr: 2 }}
            />
          )}
          {header?.showButton && (
            <Box>
              <FabButton
                disabled={header?.buttonDisabled}
                style={{ opacity: header?.buttonDisabled ? "0.4" : "1" }}
                size="medium"
                icon={header?.buttonIcon ?? <PlusIcon fontSize="medium" />}
                onClick={handleBtnClick}
              />
            </Box>
          )}
        </Stack>
      </HeaderBox>
      {header?.children}
    </>
  )
}
