import { TFunction } from "i18next"
import { GridBaseColDef } from "@mui/x-data-grid/internals"
import { GridRenderCellParams, GridValueGetterParams } from "@mui/x-data-grid"
import DownloadBill from "./DownloadBill"
import BillNumber from "./BillNumber"
import { dateSortComparator } from "../../../../../../uikit/table/DataGridTable"

export const UtilityUnitBillingsTableConfig: (t: TFunction, showDownloadBtn: boolean) => Array<GridBaseColDef> = (
  t: TFunction,
  showDownloadBtn: boolean,
) => [
  {
    field: "name",
    headerName: t("manager-utility-unit:table.header.billNumber"),
    flex: 100,
    renderCell: (params: GridRenderCellParams) => (
      <BillNumber name={params.row?.billDetails?.billNumber} isNew={params.row.isNew} />
    ),
  },
  {
    field: "period",
    headerName: t("shared:label.period"),
    sortComparator: (v1, v2) => dateSortComparator(v1?.startDate, v2?.startDate, v1?.startDate, v2?.startDate),
    valueGetter: (params: GridValueGetterParams) => params.row,
    renderCell: ({ value }) => value?.period ?? "-",
    flex: 250,
  },
  {
    field: "participant",
    headerName: t("manager-utility-unit:table.header.participant"),
    valueGetter: (params: GridValueGetterParams) => params.row?.participantName || "-",
    flex: 200,
  },
  {
    field: "sum",
    headerName: t("manager-utility-unit:table.header.sum"),
    valueGetter: (params: GridValueGetterParams) => params.row?.totalSum ?? "-",
    flex: 100,
  },
  {
    field: "status",
    headerName: t("manager-utility-unit:table.header.status"),
    renderCell: (params: GridRenderCellParams) => (
      <div style={{ textTransform: "capitalize" }}>{t(`shared:status.${params.row?.billingStatus}`).toLowerCase()}</div>
    ),
    flex: 100,
  },
  {
    field: "act",
    headerName: "",
    renderCell: (params: GridRenderCellParams) => {
      if (!showDownloadBtn || params.row.billingStatus !== "DONE") return null
      return <DownloadBill id={params.row?.billDetails?.id} />
    },
    flex: 50,
  },
]
