import { Stack, ButtonBase } from "@mui/material"
import { useState } from "react"
import { appThemePrimaryLightColor } from "../../app/Shared.Theme"

interface NavigationButtonProps {
  icon?: React.ReactNode
  label: string
  selected?: boolean
  onClick?: () => void
  menuHovered?: boolean
}

export const NavigationButton = (props: NavigationButtonProps) => {
  const { icon, label, selected, onClick, menuHovered } = props
  const [isHovered, setIsHovered] = useState(false)
  const activeState = (menuHovered && isHovered) || (selected && !menuHovered)
  const navButtonStyle = {
    ":hover": { color: appThemePrimaryLightColor },
    padding: 1.75,
    paddingLeft: "1.365em",
    fontFamily: "Montserrat",
    fontSize: "0.875em",
    color: isHovered ? appThemePrimaryLightColor : "text.primary",
    justifyContent: "start",
    width: "100%",
    ...(activeState && { background: "#EFEFEF" }),
  }
  return (
    <ButtonBase
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      disableRipple
      onClick={onClick}
      sx={navButtonStyle}
    >
      <Stack direction={"row"} alignItems={"center"} spacing={1}>
        {icon}
        <span>{label}</span>
      </Stack>
    </ButtonBase>
  )
}
