import { TFunction } from "i18next"
import { GridValueGetterParams } from "@mui/x-data-grid"
import RunningCost from "./RunningCost"
import { GridBaseColDef } from "@mui/x-data-grid/internals"

export const FeesTableConfig: (t: TFunction) => Array<GridBaseColDef> = (t: TFunction) => [
  {
    field: "name",
    headerName: t("fees:table.label.runningCosts"),
    flex: 250,
    renderCell: ({ row }) => {
      return <RunningCost billingType={row.billingType} name={row.name} />
    },
  },
  {
    field: "number",
    headerName: t("fees:table.label.number"),
    valueGetter: (params: GridValueGetterParams) => params.row?.units || "-",
    align: "right",
    headerAlign: "right",
    flex: 100,
  },
  {
    field: "price",
    headerName: t("fees:table.label.price"),
    valueGetter: (params: GridValueGetterParams) =>
      params.row?.priceWithoutVat ? `CHF ${params.row?.priceWithoutVat}` : "-",
    align: "right",
    headerAlign: "right",
    flex: 100,
  },
  {
    field: "sum",
    headerName: t("fees:table.label.sum"),
    valueGetter: (params: GridValueGetterParams) => (params.row?.sum ? `CHF ${params.row?.sum}` : "-"),
    align: "right",
    headerAlign: "right",
    flex: 100,
  },
]
