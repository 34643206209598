import { DOMAIN_DEPENDENCIES } from "../../../../app/App.Config"
import { apiHeaders } from "../../../Domain.Calls"
import { AxiosResponse } from "axios"
import {
  BuildingCreateMeterAdminRequest,
  MeterResponse,
  MeterUpdateAdminRequest,
} from "../../../../data/generated-sources/openapi"

export const getMeters = (page?: number, limit?: number, orderBy?: string) => {
  return DOMAIN_DEPENDENCIES.adminMeterApi.adminGetMeters(page, limit, orderBy, apiHeaders(DOMAIN_DEPENDENCIES))
}

export const getMeterById = (id: string): Promise<MeterResponse> => {
  return DOMAIN_DEPENDENCIES.adminMeterApi
    .adminGetMeterById(id, apiHeaders(DOMAIN_DEPENDENCIES))
    .then((res) => {
      return res.data
    })
    .catch((error) => {
      return error
    })
}

export const updateMeterById = (meterId: string, payload: MeterUpdateAdminRequest) => {
  return DOMAIN_DEPENDENCIES.adminMeterApi.adminPutMeterById(meterId, payload, apiHeaders(DOMAIN_DEPENDENCIES))
}

export const adminDeleteMeterById = (meterId: string) =>
  DOMAIN_DEPENDENCIES.adminMeterApi.adminDeleteMeterById(meterId, apiHeaders(DOMAIN_DEPENDENCIES))

export const createMeterForUtilityUnit = (utilityUnitId: string, payload: MeterUpdateAdminRequest) => {
  return DOMAIN_DEPENDENCIES.adminMeterApi
    .adminCreateMeterForUtilityUnit(utilityUnitId, payload, apiHeaders(DOMAIN_DEPENDENCIES))
    .then((res: AxiosResponse) => res.data)
}

export const createMeterForBuilding = (buildingId: string, payload: BuildingCreateMeterAdminRequest) =>
  DOMAIN_DEPENDENCIES.adminMeterApi.adminCreateMeterForBuilding(buildingId, payload, apiHeaders(DOMAIN_DEPENDENCIES))
