import React, { useEffect, useState, useMemo } from "react"
import {
  Autocomplete,
  Paper,
  TextField as MuiTextField,
  styled,
  IconButton,
  List,
  ListItem,
  ListItemText as MuiListItemText,
  Stack,
  CircularProgress,
  FilterOptionsState,
} from "@mui/material"
import { useTranslation } from "react-i18next"
import {
  getAllManagersList,
  getManagersByContractId,
  updateManagersByContractId,
} from "../../../../../domain/portal/admin/contracts/Contracts.Repository"
import { differenceBy } from "lodash-es"
import { useMutation, useQuery } from "@tanstack/react-query"
import { RouteComponentProps } from "react-router"
import { AdminPortalRouteParams } from "../../AdminPortal.Routes"
import { ContractFormTitle } from "../common/CommonFormFragments"
import { RemoveIcon, SaveIcon } from "../../../../../uikit/Shared.Icon"
import { ErrorAlert } from "../../../../../uikit/Shared.Alert"
import { CancelButton } from "../../../../../uikit/button/CancelButton"
import { PrimaryButtonLoading } from "../../../../../uikit/button/PrimaryButtonLoading"
import { ProfileManagerNameListItem } from "../../../../../domain/portal/admin/profiles/Profiles.Model"
import { AxiosErrorDataType } from "../../../../Shared.Utils"

const TextField = styled(MuiTextField)(({ theme }) => ({
  "& label": {
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.secondary.dark,
  },
}))

const ListItemText = styled(MuiListItemText)(({ theme }) => ({
  "& .MuiTypography-root": {
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(18),
  },
}))

export const ContractManagerListForm = ({
  history,
  match: { params },
}: RouteComponentProps<AdminPortalRouteParams>) => {
  const { t } = useTranslation("contracts-management")
  const [managersError, setManagersError] = useState<AxiosErrorDataType>()
  const [selectedManagers, setSelectedManagers] = useState<ProfileManagerNameListItem[]>([])

  const {
    data: allManagers,
    isLoading: isLoadingAllManagers,
    remove: removeManagers,
  } = useQuery(["getManagerList"], () => getAllManagersList(), {
    onError: setManagersError,
  })

  const { isLoading: isLoadingManagers, remove: removeContractManagers } = useQuery(
    ["getContractManagers"],
    () => getManagersByContractId(params.id),
    {
      onError: setManagersError,
      onSuccess: (data) => setSelectedManagers(data),
    },
  )

  const filteredManagers = useMemo(
    () => differenceBy(allManagers, selectedManagers, "id"),
    [allManagers, selectedManagers],
  )

  const { mutate: updateManagersList, isLoading: isUpdatingManagers } = useMutation(
    ["updateManagersList"],
    (managerIds: string[]) => updateManagersByContractId(params.id, managerIds),
    {
      onError: setManagersError,
      onSuccess: () => history.goBack(),
    },
  )

  const saveManagersList = () => updateManagersList(selectedManagers.map((manager) => manager.id))

  const removeManager = (managerId: string) => {
    setSelectedManagers((prevState) => prevState.filter((manager) => manager.id !== managerId))
  }

  useEffect(() => {
    return () => {
      removeManagers()
      removeContractManagers()
    }
  }, [removeContractManagers, removeManagers])

  const ManagerListItem = ({ id, fullNameAddress }: ProfileManagerNameListItem) => (
    <ListItem
      key={id}
      divider
      secondaryAction={
        <IconButton aria-label="delete-icon" onClick={() => removeManager(id)}>
          <RemoveIcon color="primary" />
        </IconButton>
      }
    >
      <ListItemText>{fullNameAddress}</ListItemText>
    </ListItem>
  )

  return (
    <>
      <Paper sx={{ p: 1, mb: 2, minHeight: "70vh" }}>
        <ErrorAlert
          visible={!!managersError}
          title={managersError?.message}
          message={managersError?.code && t(`error-codes:${managersError.code}`)}
        />
        <ContractFormTitle>{t("managersForm.title")}</ContractFormTitle>
        {isLoadingAllManagers || !allManagers ? (
          <CircularProgress color="primary" />
        ) : (
          <>
            <Autocomplete
              sx={{ px: 1 }}
              clearOnBlur
              multiple
              filterSelectedOptions
              limitTags={0}
              clearIcon={() => <></>}
              renderTags={() => <></>}
              options={filteredManagers}
              filterOptions={(
                options: ProfileManagerNameListItem[],
                filterOptionsState: FilterOptionsState<ProfileManagerNameListItem>,
              ) => {
                return options.filter((item: ProfileManagerNameListItem) =>
                  item.fullNameAddress
                    .replace(",", "")
                    .toLowerCase()
                    .includes(filterOptionsState.inputValue.toLowerCase()),
                )
              }}
              getOptionLabel={(manager: ProfileManagerNameListItem) => manager?.fullNameAddress}
              onChange={(_, values, reason, details) => {
                if (reason === "selectOption" && values && details && details?.option) {
                  setSelectedManagers((prevState) => [...prevState, details.option])
                }
              }}
              renderInput={(params) => <TextField {...params} variant="standard" label={t("managersForm.search")} />}
            />
            <List sx={{ p: 1 }}>{selectedManagers?.map(ManagerListItem)}</List>
          </>
        )}
      </Paper>
      <Stack direction={"row"} alignItems="center" justifyContent="space-between">
        <CancelButton onClick={() => history.goBack()} />
        <PrimaryButtonLoading
          disabled={isLoadingManagers || isLoadingAllManagers || isUpdatingManagers}
          isLoading={isLoadingManagers || isUpdatingManagers}
          startIcon={<SaveIcon />}
          onClick={saveManagersList}
          label={t("managersForm.saveManager")}
        />
      </Stack>
    </>
  )
}
