import { Paper, Stack } from "@mui/material"
import { useTranslation } from "react-i18next"
import { CommonFormikProps } from "../../../../Shared.Utils"

import { SelectPicker } from "../../../../../uikit/input/SelectPicker"
import { SingleLineTextField } from "../../../../../uikit/input/SingleLineTextField"
import { utilityUnitTypeValues } from "../../sharedComponentsConfig/utilityUnits/UtilityUnits.Enum"
import { UtilityUnitResponse } from "../../../../../data/generated-sources/openapi"

export const UtilityUnitModifyFormFields = (props: CommonFormikProps<Partial<UtilityUnitResponse>>) => {
  const { values, errors, touched, setFieldValue, handleBlur } = props
  const { t } = useTranslation("utilityUnit")

  return (
    <Paper sx={{ my: 1.25 }}>
      <Stack p={2} spacing={4}>
        <SingleLineTextField
          required
          autoFocus
          name="name"
          type="text"
          value={values.name}
          label={t("form.label.name")}
          onBlur={handleBlur}
          helperText={errors.name && touched.name ? errors.name : undefined}
          onChange={(event) => setFieldValue("name", event.target.value)}
        />
        <SelectPicker
          required
          name="usageType"
          type="text"
          label={t("label.type")}
          value={values.usageType ?? ""}
          helperText={errors.usageType && touched.usageType ? errors.usageType : undefined}
          items={utilityUnitTypeValues.slice(0, utilityUnitTypeValues.length - 1).map((type) => ({
            label: t(`utilityType.${type}`),
            value: type,
          }))}
          onBlur={handleBlur}
          onChange={(event) => setFieldValue("usageType", event.target.value)}
        />
        <SingleLineTextField
          name="unitSpace"
          type="number"
          label={t("label.unitSpace")}
          helperText={errors.unitSpace && touched.unitSpace ? errors.unitSpace : undefined}
          value={values.unitSpace}
          onBlur={handleBlur}
          onChange={(event) => setFieldValue("unitSpace", event.target.value)}
        />
      </Stack>
    </Paper>
  )
}
