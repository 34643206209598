import { Box } from "@mui/material"
import { useTranslation } from "react-i18next"
import DateUtils from "../../../../../services/utils/DateUtils"
import { appThemePrimaryLightColor } from "../../../../Shared.Theme"
import { formatPersonalFullName } from "../../../../../domain/portal/admin/profiles/Profiles.Formatters"
import { DOMAIN_DEPENDENCIES } from "../../../../App.Config"
import { EventType, ParticipationEvent, ParticipationResponse } from "../../../../../data/generated-sources/openapi"

interface ParticipantTableCellViewProps {
  currentParticipation: ParticipationResponse
  upcomingEvent: ParticipationEvent
}
const ParticipantTableCellView = ({ currentParticipation, upcomingEvent }: ParticipantTableCellViewProps) => {
  const { t } = useTranslation()

  const renderParticipant = () =>
    !currentParticipation || !currentParticipation?.participant?.personalData ? (
      "-"
    ) : (
      <p>{formatPersonalFullName(currentParticipation?.participant?.personalData, DOMAIN_DEPENDENCIES)}</p>
    )

  const renderNextParticipant = () => {
    if (!upcomingEvent) return null
    return (
      <span
        style={{
          color: appThemePrimaryLightColor,
        }}
      >
        {t(
          `manager-utility-unit:label.${
            upcomingEvent.event === EventType.IN ? "newParticipantFrom" : "moveOutParticipantFrom"
          }`,
        )}{" "}
        {DateUtils.getDeFormatDateWithMonthString(upcomingEvent.date ?? "")}
      </span>
    )
  }

  return (
    <>
      <Box style={{ minWidth: "50%" }}>{renderParticipant()}</Box> {renderNextParticipant()}
    </>
  )
}

export default ParticipantTableCellView
