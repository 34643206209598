import { useEffect, useState } from "react"
import { Badge } from "@mui/material"
import { useTranslation } from "react-i18next"
import { useAppDispatch, useAppSelector } from "../../../hooks"
import { useQuery } from "@tanstack/react-query"

import { ProductType } from "../../../../data/generated-sources/openapi"
import TabPanel from "../utilityUnit/UtilityUnitDetail"
import { ErrorAlert } from "../../../../uikit/Shared.Alert"
import { IndividualBillingsList } from "./IndividualBillingsList"
import { selectSelectedContractData } from "../store/contractSelectorSlice"
import { PeriodicBillingList } from "./PeriodicBillingList"
import {
  customerGetPageIndividualParticipantsBillingsByContractId,
  customerGetPagedVewaAllParticipantsBillingsByContractId,
  customerGetZevAllParticipantsBillingsByContractId,
} from "../../../../domain/portal/manager/billings/Billings.Repository"
import { PrimaryTabContainer, PrimaryTab, IconPositionEnum } from "../../../../uikit/tabs/PrimaryTab"
import { AxiosErrorDataType } from "../../../Shared.Utils"

export const BillingsListTabs = ({ areaId, separateTables }: { areaId: string; separateTables: boolean }) => {
  const { t } = useTranslation("settlements")
  const dispatch = useAppDispatch()
  const { contractId, contractProductType } = useAppSelector(selectSelectedContractData)
  const [error, setError] = useState<AxiosErrorDataType>()
  const [selectedTab, setSelectedTab] = useState(0)

  const {
    data: zevBillings,
    isFetching: isFetchingBills,
    remove: removeBills,
    refetch: refetchBillings,
  } = useQuery(
    ["getAllZevParticipantsBillings"],
    () => customerGetZevAllParticipantsBillingsByContractId(dispatch, contractId),
    {
      enabled: !!contractId && contractProductType === ProductType.ZEV,
      onError: setError,
    },
  )

  const {
    data: vewaBillings,
    remove: removeVewaBills,
    refetch: refetchVewaBillings,
    isFetching: isFetchingVewaBills,
  } = useQuery(
    ["getAllVewaParticipantsBillings"],
    () => customerGetPagedVewaAllParticipantsBillingsByContractId(dispatch, contractId),
    {
      enabled: !!contractId && contractProductType === ProductType.VEWA,
      onError: setError,
    },
  )

  const {
    data: individualBillings,
    remove: removeIndividualBills,
    refetch: refetchIndBillings,
  } = useQuery(
    ["getAllIndividualBillings"],
    () => customerGetPageIndividualParticipantsBillingsByContractId(dispatch, contractId),
    {
      enabled: !!contractId && contractProductType === ProductType.ZEV,
      onError: setError,
    },
  )

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const periodicBillingsWithNewStatus = (zevBillings || vewaBillings || [])?.filter((bill) => bill.isNew)
  const individualBillsWithNewStatus = individualBillings?.filter((bill) => bill.isNew)

  useEffect(() => {
    setError(undefined)
    if (contractId && contractProductType) {
      if (contractProductType === ProductType.VEWA) {
        setSelectedTab(0)
        refetchVewaBillings()
      }
      if (contractProductType === ProductType.ZEV) {
        refetchBillings()
        refetchIndBillings()
      }
    }
  }, [contractProductType, contractId, refetchBillings, refetchIndBillings, refetchVewaBillings])

  useEffect(() => {
    return () => {
      removeBills()
      removeVewaBills()
      removeIndividualBills()
    }
  }, [removeBills, removeVewaBills, removeIndividualBills])

  const showIndividualTabBadge = !!(
    selectedTab !== 1 &&
    individualBillsWithNewStatus?.length &&
    contractProductType === ProductType.ZEV
  )
  const showBillingTabBadge = !!(selectedTab !== 0 && periodicBillingsWithNewStatus?.length)

  return (
    <>
      <ErrorAlert
        scrollOnDisplay
        visible={!!error}
        message={t(`error-codes:${error?.response?.data?.code || error?.code || "OTHER"}`)}
      />
      <PrimaryTabContainer value={selectedTab} onChange={(_, tab) => setSelectedTab(tab)}>
        <PrimaryTab
          label={t("section-tab.periodic.settlements")}
          iconPosition={IconPositionEnum.END}
          icon={showBillingTabBadge ? <Badge badgeContent={""} color="warning" sx={{ mx: 2 }} /> : <></>}
        />
        {contractProductType === ProductType.ZEV ? (
          <PrimaryTab
            label={t("section-tab.tenants.change")}
            iconPosition={IconPositionEnum.END}
            icon={showIndividualTabBadge ? <Badge badgeContent={""} color="warning" sx={{ mx: 2 }} /> : <></>}
          />
        ) : null}
      </PrimaryTabContainer>
      <TabPanel value={selectedTab} index={0}>
        <PeriodicBillingList
          separateTables={separateTables}
          areaId={areaId}
          isLoading={isFetchingBills || isFetchingVewaBills}
        />
      </TabPanel>
      <TabPanel value={selectedTab} index={1}>
        <IndividualBillingsList areaId={areaId} separateTables={separateTables} />
      </TabPanel>
    </>
  )
}
