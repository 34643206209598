import { useCallback, useEffect, useState } from "react"
import { Paper, Stack, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"
import { AdminPortalRouteParams } from "../../AdminPortal.Routes"
import { RouteComponentProps } from "react-router-dom"
import { useMutation, useQuery } from "@tanstack/react-query"
import { GridActionsColDef, GridRenderCellParams, GridRowSelectionModel, GridValueGetterParams } from "@mui/x-data-grid"
import { GridBaseColDef } from "@mui/x-data-grid/internals"
import {
  activateMetersByContractId,
  getContractManagementById,
  getPagedDraftNonUtilityUnitMetersByContractId,
  getPagedDraftUtilityUnitMetersByContractId,
} from "../../../../../domain/portal/admin/contracts/Contracts.Repository"
import DateUtils from "../../../../../services/utils/DateUtils"
import { MeterActivationRequest, MeterResponse } from "../../../../../data/generated-sources/openapi"
import { AxiosErrorDataType, useQueryDefaultOptions } from "../../../../Shared.Utils"

import { DataGridTable } from "../../../../../uikit/table/DataGridTable"
import { ErrorAlert, OptionalSuccessAlert } from "../../../../../uikit/Shared.Alert"
import { SingleLineDatePicker } from "../../../../../uikit/input/SingleLineDatePicker"
import { CancelButton } from "../../../../../uikit/button/CancelButton"
import { ConfirmDialog } from "../../../../../uikit/confirmDialog/ConfirmDialog"
import { EditIcon, AbortIcon } from "../../../../../uikit/Shared.Icon"

export const ContractMeterActivate = ({ match: { params }, history }: RouteComponentProps<AdminPortalRouteParams>) => {
  const [rowSelectionUnitsModel, setRowSelectionUnitsModel] = useState<GridRowSelectionModel>([])
  const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([])
  const [combineMeters, setCombineMeters] = useState<MeterResponse[]>([])
  const [meterError, setMeterError] = useState<AxiosErrorDataType>()
  const [showSuccessMsg, swtShowSuccessMsg] = useState(false)
  const nmbOfSelectedRows = rowSelectionModel?.length + rowSelectionUnitsModel?.length
  const { t } = useTranslation("meters")
  const { id: contractId } = params

  const { data: contract, remove: removeContract } = useQuery(
    ["getContract"],
    () => getContractManagementById(contractId),
    {
      enabled: !!contractId,
      ...useQueryDefaultOptions,
      onError: setMeterError,
    },
  )

  const {
    data: utilityMeters,
    refetch: refetchUtilityMeters,
    isLoading: utilityMetersAreLoading,
    isFetching: utilityMetersAreFetching,
    remove: utilityMetersRemove,
  } = useQuery(["getUtilityMeters"], () => getPagedDraftUtilityUnitMetersByContractId(contractId), {
    enabled: !!contractId,
    ...useQueryDefaultOptions,
    onError: setMeterError,
    onSuccess: (data) => {
      if (data.length) {
        setCombineMeters((prevState) => [...prevState, ...data])
      }
    },
  })

  const {
    data: meters,
    refetch: refetchMeters,
    isLoading: metersAreLoading,
    isFetching: metersAreFetching,
    remove: metersRemove,
  } = useQuery(["getMeters"], () => getPagedDraftNonUtilityUnitMetersByContractId(contractId), {
    enabled: !!contractId,
    ...useQueryDefaultOptions,
    onError: setMeterError,
    onSuccess: (data) => {
      if (data.length) {
        setCombineMeters((prevState) => [...prevState, ...data])
      }
    },
  })

  const onActivationSuccess = () => {
    refetchMeters()
    refetchUtilityMeters()
    swtShowSuccessMsg(true)
  }

  const { mutate: activateMeters, isLoading: isActivating } = useMutation(
    ["activateMeters", contractId],
    (payload: MeterActivationRequest[]) => activateMetersByContractId(contractId, payload),
    {
      onError: setMeterError,
      onSuccess: onActivationSuccess,
    },
  )

  useEffect(() => {
    return () => {
      metersRemove()
      removeContract()
      utilityMetersRemove()
    }
  }, [utilityMetersRemove, metersRemove, removeContract])

  const handleMetersActivation = () => {
    setMeterError(undefined)
    swtShowSuccessMsg(false)
    const selectedMeters = combineMeters.filter(
      (meter) => rowSelectionModel.includes(meter.id) || rowSelectionUnitsModel.includes(meter.id),
    )
    const metersForActivation = selectedMeters.map((meter) => {
      return {
        meterId: meter.id,
        billableFrom: meter.billableFrom ?? contract?.startDate ?? "",
      }
    })
    activateMeters(metersForActivation)
  }

  const DescriptionCell = useCallback(
    ({ value }: GridRenderCellParams) => <Typography fontWeight={400}>{value}</Typography>,
    [],
  )

  const DateCell = useCallback(
    ({ value }: GridRenderCellParams) => (
      <Typography fontWeight={400} fontSize={14}>
        {value ? DateUtils.getDeFormatWithDots(value) : "-"}
      </Typography>
    ),
    [],
  )

  const DatePickerCell = useCallback(
    ({ value, id }: GridRenderCellParams) => (
      <SingleLineDatePicker
        required
        label={""}
        name="moveInDate"
        openPickerIcon={EditIcon}
        value={value ?? contract?.startDate}
        minDate={contract?.startDate ? new Date(contract?.startDate) : undefined}
        onChange={(value) => {
          const modifiedMeters = [...combineMeters]
          const updatedMeter = modifiedMeters.findIndex((met) => met.id === id)
          if (updatedMeter !== -1) {
            modifiedMeters[updatedMeter].billableFrom = DateUtils.getDeFormatDate(value)
            setCombineMeters(modifiedMeters)
          }
        }}
        textInputStyle={{
          width: "70%",
          ".MuiInput-root:before": { borderBottom: "none" },
          ".MuiInput-root:hover:not(.Mui-disabled, .Mui-error):before": { borderBottom: "none" },
        }}
      />
    ),
    [combineMeters, contract?.startDate],
  )

  const utilityMetersColumns: Array<GridBaseColDef | GridActionsColDef> = [
    {
      flex: 250,
      field: "meteringCode",
      headerName: t("label.meterIdSpec"),
      renderCell: DescriptionCell,
    },
    {
      flex: 200,
      field: "meterAttachmentName",
      headerName: t("label.unitId"),
      renderCell: DescriptionCell,
    },
    {
      flex: 140,
      field: "billableFrom",
      headerName: t("label.billableFrom"),
      valueGetter: (params: GridValueGetterParams) => params.row?.billableFrom,
      renderCell: DatePickerCell,
    },
    {
      flex: 120,
      field: "billableTo",
      headerName: t("label.billableTo"),
      valueGetter: (params: GridValueGetterParams) => params.row?.billableTo,
      renderCell: DateCell,
    },
  ]

  const metersColumns: Array<GridBaseColDef | GridActionsColDef> = [
    {
      flex: 250,
      field: "meteringCode",
      headerName: t("label.meterIdSpec"),
      renderCell: DescriptionCell,
    },
    {
      flex: 200,
      field: "meterAttachmentName",
      headerName: t("label.activate.location"),
      renderCell: DescriptionCell,
    },
    {
      flex: 140,
      field: "billableFrom",
      headerName: t("label.billableFrom"),
      valueGetter: (params: GridValueGetterParams) => params.row?.billableFrom,
      renderCell: DatePickerCell,
    },
    {
      flex: 120,
      field: "billableTo",
      headerName: t("label.billableTo"),
      valueGetter: (params: GridValueGetterParams) => params.row?.billableTo,
      renderCell: DateCell,
    },
  ]

  return (
    <>
      <Paper sx={{ p: 2, mb: 4 }}>
        <Typography fontSize={24} fontWeight={300} mb={4} pt={2} textTransform={"uppercase"}>
          {t("label.activate.title")}
        </Typography>
        <OptionalSuccessAlert scrollOnDisplay show={showSuccessMsg} message={t("label.activate.successMsg")} />
        <ErrorAlert
          scrollOnDisplay
          visible={!!meterError}
          message={t(`error-codes:${meterError?.response?.data?.code || meterError?.code || "OTHER"}`)}
        />

        <Typography fontSize={16} fontWeight={700} my={2}>
          {t("label.activate.metersUnits")}
        </Typography>
        <DataGridTable<MeterResponse>
          rows={utilityMeters ?? []}
          columns={utilityMetersColumns}
          loading={utilityMetersAreLoading && utilityMetersAreFetching}
          checkboxSelection
          rowSelectionModel={rowSelectionUnitsModel}
          onRowSelectionModelChange={(newRowSelectionModel) => setRowSelectionUnitsModel(newRowSelectionModel)}
        />

        <Typography fontSize={16} fontWeight={700} mb={2}>
          {t("label.activate.moreMeters")}
        </Typography>
        <DataGridTable<MeterResponse>
          checkboxSelection
          columns={metersColumns}
          rows={meters ?? []}
          loading={metersAreLoading && metersAreFetching}
          rowSelectionModel={rowSelectionModel}
          onRowSelectionModelChange={(newRowSelectionModel) => {
            setRowSelectionModel(newRowSelectionModel)
          }}
        />
      </Paper>

      <Stack flexDirection={"row"} alignItems={"center"} justifyContent={"space-between"}>
        <CancelButton
          startIcon={<AbortIcon />}
          label={t("shared:form.action.cancel")}
          onClick={() => history.goBack()}
        />
        <ConfirmDialog
          onConfirm={handleMetersActivation}
          dialogTitle={t("label.activate.confirmMsg")}
          actionButtonText={t("label.activate.title")}
          actionButtonLoading={isActivating}
          actionButtonStartIcon={<>({nmbOfSelectedRows})</>}
          actionButtonDisabled={
            isActivating ||
            !nmbOfSelectedRows ||
            (utilityMetersAreLoading && utilityMetersAreFetching) ||
            (metersAreLoading && metersAreFetching)
          }
        />
      </Stack>
    </>
  )
}
