import { Box, Stack } from "@mui/material"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { PrimaryTab, PrimaryTabContainer } from "../../../../../uikit/tabs/PrimaryTab"
import { FormSectionTitle } from "../../../../../uikit/form/FormView"
import { PaperBox } from "../../../../../uikit/page/PaperBox"
import SharedListComponent from "../../sharedListComponet/SharedList.Component"
import { COMPONENTS_CONFIG } from "../Components.Config"
import { ServiceBillingTab } from "./ServiceBilling.Enum"
import {
  AxiosErrorDataType,
  getExactParamFromUrl,
  getParamFromResponseUrl,
  isInactiveStatus,
} from "../../../../Shared.Utils"
import { AdminPortalRouteEnums } from "../../AdminPortal.Enums"
import { BillIcon } from "../../../../../uikit/Shared.Icon"
import { PrimaryButtonLoading } from "../../../../../uikit/button/PrimaryButtonLoading"
import { useMutation, useQuery } from "@tanstack/react-query"
import {
  initServiceBillingByContractId,
  initServiceBillingIsAllowed,
} from "../../../../../domain/portal/admin/contracts/Contracts.Repository"
import { RouteComponentProps, withRouter } from "react-router-dom"
import { AdminPortalRouteParams } from "../../AdminPortal.Routes"
import { ErrorAlert } from "../../../../../uikit/Shared.Alert"
import { AxiosResponse } from "axios"
import { ContractAdminResponse, ContractState } from "../../../../../data/generated-sources/openapi"
import { getAreaById } from "../../../../../domain/portal/admin/areas/Areas.Repository"

const ContractManagementServiceBillingComponent = withRouter(
  (props: RouteComponentProps<AdminPortalRouteParams> & ContractAdminResponse) => {
    const { t } = useTranslation("zevs")
    const {
      match: {
        params: { id },
      },
      history,
      areaId,
      activeState,
    } = props
    const [selectedServiceBillingTab, setSelectedServiceBillingTab] = useState(ServiceBillingTab.INITIAL)

    const {
      data: initServiceBillingIsAllowedData,
      isError: initServiceBillingIsAllowedIsError,
      isLoading: initServiceBillingIsAllowedIsLoading,
      remove: initServiceBillingIsAllowedRemove,
    } = useQuery(["initServiceBillingIsAllowed"], () => initServiceBillingIsAllowed(id), {
      enabled: !!id,
    })

    const {
      data: areaData,
      isError: areaIsError,
      isLoading: areaIsLoading,
      remove: areaRemove,
    } = useQuery(["getAreaForInitBilling"], () => getAreaById(areaId), {
      enabled: !!areaId,
    })

    const {
      mutate: initServiceBillingMutation,
      isLoading: isInitServiceBillingLoading,
      error: initServiceBillingError,
      isError: initServiceBillingIsError,
      reset: initServiceBillingMutationReset,
    } = useMutation<AxiosResponse, AxiosErrorDataType>(
      ["initServiceBilling"],
      () => initServiceBillingByContractId(id),
      {
        onSuccess: (data) => {
          const billingId = getParamFromResponseUrl(data.data, "service-billings/")
          history.push(`/billings/initial/details/${billingId}`)
        },
      },
    )

    useEffect(() => {
      return () => {
        initServiceBillingIsAllowedRemove()
        areaRemove()
        initServiceBillingMutationReset()
      }
    }, [initServiceBillingIsAllowedRemove, areaRemove, initServiceBillingMutationReset])

    const initServiceBillingButtonDisabled = () => {
      return (
        areaIsLoading ||
        areaIsError ||
        isInitServiceBillingLoading ||
        initServiceBillingIsAllowedIsError ||
        initServiceBillingIsAllowedIsLoading ||
        !initServiceBillingIsAllowedData ||
        isInactiveStatus(areaData?.activeState) ||
        isContractDraft()
      )
    }

    const isContractDraft = () => activeState === ContractState.DRAFT

    return (
      <PaperBox>
        <ErrorAlert
          visible={initServiceBillingIsError}
          title={t("error-codes:AREA_INACTIVE_TITLE")}
          message={initServiceBillingError?.response?.data.message}
        />
        <Stack p={2} direction={"row"} alignItems={"center"}>
          <FormSectionTitle label={t("form.subtitle.serviceInvoices")} />
          <PrimaryButtonLoading
            isLoading={isInitServiceBillingLoading}
            onClick={() => initServiceBillingMutation()}
            disabled={initServiceBillingButtonDisabled()}
            startIcon={<BillIcon />}
            sx={{ minWidth: "fit-content" }}
            label={t("billings-initial:details.action.createInitialBilling")}
          />
        </Stack>
        <Box sx={{ width: "100%" }}>
          <Box
            sx={{
              "& > .MuiPaper-root": {
                boxShadow: "none",
              },
            }}
          >
            <PrimaryTabContainer
              value={selectedServiceBillingTab}
              onChange={(_, newValue) => setSelectedServiceBillingTab(newValue)}
            >
              <PrimaryTab label={t("detail.participant.billing.tab.initial")} />
              <PrimaryTab label={t("detail.participant.billing.tab.recurring")} />
            </PrimaryTabContainer>
            {selectedServiceBillingTab === ServiceBillingTab.INITIAL && (
              <SharedListComponent
                configNew={COMPONENTS_CONFIG.serviceInitialBilling}
                getParamFromUrl={() => getExactParamFromUrl("/management-contracts/:id", AdminPortalRouteEnums.id)}
              />
            )}
            {selectedServiceBillingTab === ServiceBillingTab.RECURRING && (
              <SharedListComponent
                configNew={COMPONENTS_CONFIG.serviceRecurringBilling}
                getParamFromUrl={() => getExactParamFromUrl("/management-contracts/:id", AdminPortalRouteEnums.id)}
              />
            )}
          </Box>
        </Box>
      </PaperBox>
    )
  },
)

export default ContractManagementServiceBillingComponent
