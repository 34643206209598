import { Grid, Typography, Box, IconButton } from "@mui/material"
import { DataItemBox } from "../box/DataItemBox"
import { TinyPaddedBox } from "../box/PaddedBox"
import { HasChildren } from "../Shared.Prop"
import { bgColorGreen } from "../../app/Shared.Theme"
import { CancelRoundIcon, EditIcon } from "../Shared.Icon"

export enum FormMode {
  CREATE,
  UPDATE,
}

export const FormView = (props: HasChildren) => {
  const { children } = props
  return (
    <Grid container direction="column">
      {children}
    </Grid>
  )
}

export const FormSectionBody = (props: { label: React.ReactNode; icon?: React.ReactNode }) => {
  const { label, icon } = props
  return (
    <Grid container alignItems="center">
      {icon}
      <Typography
        variant="body1"
        style={{ color: "#1a1a1a", fontSize: "1rem" }}
        sx={{
          textTransform: "lowercase",
          padding: "5px",
        }}
      >
        {label}
      </Typography>
    </Grid>
  )
}

export const FormSectionTitle = (props: { label: React.ReactNode; icon?: React.ReactNode; transform?: string }) => {
  const { label, icon, transform } = props
  return (
    <Grid container alignItems="center">
      {icon && <TinyPaddedBox mt={2}>{icon}</TinyPaddedBox>}
      <TinyPaddedBox mt={2}>
        <Typography
          variant="h5"
          sx={{
            fontWeight: 300,
            textTransform: (transform as "inherit") ?? "uppercase",
          }}
        >
          {label}
        </Typography>
      </TinyPaddedBox>
    </Grid>
  )
}

export const FormSectionTitleIconTrailing = (props: {
  label: React.ReactNode
  editMode: boolean
  editModeClick: () => void
}) => {
  const { label, editMode, editModeClick } = props
  return (
    <Box
      display={"flex"}
      alignItems="center"
      pl={1}
      sx={{
        borderTopLeftRadius: "8px",
        borderTopRightRadius: "8px",
        background: editMode ? bgColorGreen : "transparent",
      }}
    >
      <Typography fontSize={18} p={1} mt={2}>
        {label}
      </Typography>
      <IconButton onClick={editModeClick} sx={{ p: 1, mt: 2 }} color="primary">
        {editMode ? <CancelRoundIcon fontSize="small" /> : <EditIcon fontSize="small" />}
      </IconButton>
    </Box>
  )
}

export const AdminFormSubtitle = (props: { label: React.ReactNode; icon?: React.ReactNode }) => {
  const { label, icon } = props
  return (
    <Grid container alignItems="center">
      {icon && (
        <Box pl={1} mt={2}>
          {icon}
        </Box>
      )}
      <Box pl={1} mt={2}>
        <Typography
          sx={{
            fontWeight: 700,
            fontSize: "1rem",
          }}
        >
          {label}
        </Typography>
      </Box>
    </Grid>
  )
}

export const FormRowCell = (props: HasChildren) => {
  const { children, sx } = props
  return (
    <TinyPaddedBox
      sx={{
        flex: 1,
        ...sx,
      }}
    >
      {children}
    </TinyPaddedBox>
  )
}

export const EditFormRowCell = (props: HasChildren) => {
  const { children } = props
  return (
    <TinyPaddedBox
      sx={{
        flex: 0.25,
      }}
    >
      {children}
    </TinyPaddedBox>
  )
}

export const FormReadOnlyCell = (props: { title: string; value: string }) => {
  const { title, value } = props
  return (
    <Box
      sx={{
        flex: 1,
      }}
    >
      <DataItemBox title={title} value={value} />
    </Box>
  )
}

export const FormRowColumn = (props: HasChildren) => {
  const { children } = props
  return <Grid container>{children}</Grid>
}

export const FormActions = (props: HasChildren) => {
  const { children } = props
  return (
    <Grid container justifyContent="end">
      <TinyPaddedBox>{children}</TinyPaddedBox>
    </Grid>
  )
}

export const FormActionsStart = (props: HasChildren) => {
  const { children } = props
  return (
    <Grid container justifyContent="start">
      <TinyPaddedBox>{children}</TinyPaddedBox>
    </Grid>
  )
}
