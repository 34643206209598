import { useTranslation } from "react-i18next"
import { UtilityUnitBillingsTableConfig } from "./UtilityUnitBillingsTableConfig"
import { DataGridTable } from "../../../../../../uikit/table/DataGridTable"
import { Box } from "@mui/material"
import { CustomBillingDetails, UtilityUnitBillingsProps } from "./UtilityUnitInterfaces"
import { ErrorAlert } from "../../../../../../uikit/Shared.Alert"
import { useHistory } from "react-router-dom"

const UtilityUnitBillings = ({
  showDownloadBtn,
  bills,
  error,
  isError,
  isLoading,
  contractId,
  areaId,
}: UtilityUnitBillingsProps) => {
  const { t } = useTranslation()
  const history = useHistory()

  return (
    <Box p={6} flex={1}>
      <ErrorAlert
        visible={isError}
        message={t(`error-codes:${error?.response?.data?.code || error?.code || "OTHER"}`)}
      />
      <DataGridTable<CustomBillingDetails>
        rows={bills || []}
        columns={UtilityUnitBillingsTableConfig(t, showDownloadBtn)}
        loading={isLoading}
        getRowClassName={() => "RowClickable"}
        onCellClick={({ field, row }) => {
          if (contractId && field !== "act") {
            history.push(
              `/${areaId}/contracts/${contractId}/billings/${row.billingPageType}/${row.id}/${row.billingPageStatus}`,
            )
          }
        }}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 5,
            },
          },
        }}
      />
    </Box>
  )
}

export default UtilityUnitBillings
