import { Grid, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"
import {
  ConsumptionScope,
  Medium,
  MeterResponse,
  UsageType,
} from "../../../../../../../../data/generated-sources/openapi"
import { SelectPicker } from "../../../../../../../../uikit/input/SelectPicker"
import { GroupMetersProps, VewaGroupMetersProps } from "../../../Medium.Interfaces"
import { labelLayout } from "../../../../../../../../uikit/label/InputLabel"
import { getMetersOptions, selectedMeterItemView } from "../../../MediumUtils"

export const VewaWaterGroupMeter = ({ setMeters, meters }: VewaGroupMetersProps) => {
  const { t } = useTranslation("medium")

  return (
    <Grid container sx={{ mb: 3 }}>
      <Grid container item>
        <Grid container xs={12}>
          <Grid item xs={5} mb={4}>
            <Typography fontSize={16} fontWeight={500} mb={2}>
              {t("label.water.meterTotalConsumption")}
            </Typography>
            <SelectPicker
              name="medium"
              type="text"
              label={t("label.measuredHotWaterConsumption")}
              value={null}
              items={getMetersOptions(UsageType.UNDEFINED, meters)}
              onChange={(event) => {
                setMeters(
                  meters.map((meter: MeterResponse) => {
                    if (meter.id === event.target.value) {
                      return {
                        ...meter,
                        isLinkedToVewaConfig: true,
                        linkedConsumptionScope: ConsumptionScope.WATER,
                      }
                    }
                    return meter
                  }),
                )
              }}
            />
            {selectedMeterItemView(UsageType.UNDEFINED, ConsumptionScope.WATER, meters, setMeters)}
          </Grid>
          <Grid container xs={12}>
            <Grid xs={12}>
              <Typography fontSize={16} fontWeight={500} mb={2}>
                {t("label.WATER_HEATING")}
              </Typography>
              <Typography fontSize={16} fontWeight={400} mb={2}>
                {t("label.water.groupCounterText")}
              </Typography>
            </Grid>
            <Grid xs={5} item>
              {labelLayout(t("label.residentalUnits"))}
              <SelectPicker
                name="medium"
                type="text"
                label={t("label.findAddMeters")}
                value={null}
                items={getMetersOptions(
                  UsageType.RESIDENCE,
                  meters.filter((item: GroupMetersProps) => item.medium === Medium.WARM_WATER),
                )}
                onChange={(event) => {
                  setMeters(
                    meters.map((meter: MeterResponse) => {
                      if (meter.id === event.target.value) {
                        return { ...meter, isLinkedToVewaConfig: true, linkedConsumptionScope: ConsumptionScope.WATER }
                      }
                      return meter
                    }),
                  )
                }}
              />
              {selectedMeterItemView(UsageType.RESIDENCE, ConsumptionScope.WATER, meters, setMeters, Medium.WARM_WATER)}
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid xs={5} item>
              {labelLayout(t("label.commercialUnits"))}
              <SelectPicker
                name="medium"
                type="text"
                label={t("label.findAddMeters")}
                value={null}
                items={getMetersOptions(
                  UsageType.BUSINESS,
                  meters.filter((item: GroupMetersProps) => item.medium === Medium.WARM_WATER),
                )}
                onChange={(event) => {
                  setMeters(
                    meters.map((meter: MeterResponse) => {
                      if (meter.id === event.target.value) {
                        return { ...meter, isLinkedToVewaConfig: true, linkedConsumptionScope: ConsumptionScope.WATER }
                      }
                      return meter
                    }),
                  )
                }}
              />
              {selectedMeterItemView(UsageType.BUSINESS, ConsumptionScope.WATER, meters, setMeters, Medium.WARM_WATER)}
            </Grid>
          </Grid>
        </Grid>
        <Grid container xs={12} mt={4}>
          <Grid xs={12}>
            <Typography fontSize={16} fontWeight={500} mb={2}>
              {t("label.COOLING_WATER")}
            </Typography>
            <Typography fontSize={16} fontWeight={400} mb={2}>
              {t("label.coolingWater.groupCounterWaterText")}
            </Typography>
          </Grid>
          <Grid xs={5} item>
            {labelLayout(t("label.residentalUnits"))}
            <SelectPicker
              name="medium"
              type="text"
              label={t("label.findAddMeters")}
              value={null}
              items={getMetersOptions(
                UsageType.RESIDENCE,
                meters.filter((item: GroupMetersProps) => item.medium === Medium.COLD_WATER),
              )}
              onChange={(event) => {
                setMeters(
                  meters.map((meter: MeterResponse) => {
                    if (meter.id === event.target.value) {
                      return {
                        ...meter,
                        isLinkedToVewaConfig: true,
                        linkedConsumptionScope: ConsumptionScope.WATER,
                      }
                    }
                    return meter
                  }),
                )
              }}
            />
            {selectedMeterItemView(UsageType.RESIDENCE, ConsumptionScope.WATER, meters, setMeters, Medium.COLD_WATER)}
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid xs={5} item>
            {labelLayout(t("label.commercialUnits"))}
            <SelectPicker
              name="medium"
              type="text"
              label={t("label.findAddMeters")}
              value={null}
              items={getMetersOptions(
                UsageType.BUSINESS,
                meters.filter((item: GroupMetersProps) => item.medium === Medium.COLD_WATER),
              )}
              onChange={(event) => {
                setMeters(
                  meters.map((meter: MeterResponse) => {
                    if (meter.id === event.target.value) {
                      return {
                        ...meter,
                        isLinkedToVewaConfig: true,
                        linkedConsumptionScope: ConsumptionScope.WATER,
                      }
                    }
                    return meter
                  }),
                )
              }}
            />
            {selectedMeterItemView(UsageType.BUSINESS, ConsumptionScope.WATER, meters, setMeters, Medium.COLD_WATER)}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
