import { apiHeaders } from "../../../Domain.Calls"
import { DOMAIN_DEPENDENCIES } from "../../../../app/App.Config"

export const getAdminTasks = async (page?: number, limit?: number, orderBy?: string, searchQuery?: string) => {
  const authHeaders = apiHeaders(DOMAIN_DEPENDENCIES)
  const { data } = await DOMAIN_DEPENDENCIES.adminTasksApi.getAdminTasks(page, limit, orderBy, searchQuery, authHeaders)

  return data
}

export const adminGetParticipantById = async (participantId: string) => {
  const { data } = await DOMAIN_DEPENDENCIES.adminParticipantsApi.adminGetParticipantById(
    participantId,
    apiHeaders(DOMAIN_DEPENDENCIES),
  )
  return data
}

export const completeAdminTaskById = (taskId: string) => {
  return DOMAIN_DEPENDENCIES.adminTasksApi.completeAdminTaskById(taskId, apiHeaders(DOMAIN_DEPENDENCIES))
}
