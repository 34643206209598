import { I18nNamespace } from "../../../App.i18n"

export const AnaliseI18n: I18nNamespace = {
  name: "analise",
  de: {
    "section.title": "ÜBERBLICK VERBRAUCH",
    "chart.tab.electricity": "Strom",
    "chart.tab.heatAndCooling": "Wärme und Kälte",
    "chart.tab.heat": "Wärme",
    "chart.tab.warmAndColdWater": "Warmwasser und Kaltwasser",
    "chart.tab.water": "Wasser",
    "electricity.title": "Woher kommt mein verbrauchter Strom?",
    "heat.title": "Überblick zum Wärme- und Kälteverbrauch",
    "water.title": "Überblick zum Warm- und Kaltwasserverbrauch",
    "legend.cooling": "Kälte",
    "legend.heat": "Wärme",
    "legend.heatWater": "Warmwasser",
    "legend.coldWater": "Kaltwasser",
    "axis.month": "Monat",
    "axis.day": "Tag",
    "axis.date": "Std",
    "section.solarTitle": "Wohin geht mein produzierter Strom?",
    "daily.title": "TAGESANSICHT",
    "daily.vewa.title": "TAGESANSICHT VERBRAUCH",
    "daily.production": "Produktion",
    "daily.cpSum": "Verbrauch",
    "daily.areaUsage": "Eigenverbrauch",
    "daily.areaSold": "Einspeisung",
    "daily.areaBought": "Netzbezug",
  },
  en: {
    "section.title": "OVERVIEW CONSUMPTION",
    "chart.tab.electricity": "Electricity",
    "chart.tab.heatAndCooling": "Wärme und Kälte",
    "chart.tab.heat": "Wärme",
    "chart.tab.warmAndColdWater": "Warmwasser und Kaltwasser",
    "chart.tab.water": "Wasser",
    "electricity.title": "Where does my consumed electricity come from?",
    "heat.title": "Overview of heating and cooling consumption",
    "water.title": "Overview of hot and cold water consumption",
    "legend.cooling": "Cooling",
    "legend.heat": "Heating",
    "legend.heatWater": "Warm water",
    "legend.coldWater": "Cold water",
    "axis.month": "Month",
    "axis.day": "Day",
    "axis.date": "Date",
    "section.solarTitle": "Where does the electricity I produce go?",
    "daily.title": "TAGESANSICHT",
    "daily.vewa.title": "TAGESANSICHT VERBRAUCH",
    "daily.production": "Produktion",
    "daily.cpSum": "Verbrauch",
    "daily.areaUsage": "Eigenverbrauch",
    "daily.areaSold": "Einspeisung",
    "daily.areaBought": "Netzbezug",
  },
}
