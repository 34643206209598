import { useHistory } from "react-router-dom"
import { TableCell } from "@mui/material/"
import { TableRowClickable } from "../../../../../uikit/table/Table.RowView"
import { StatusType } from "../../../../../domain/Domain.Model"
import { StatusView } from "../../../../../uikit/label/StatusView"
import { StatusTypeObjectKey } from "../../../../Shared.Utils"
import DateUtils from "../../../../../services/utils/DateUtils"
import { DataItemBoxNoValue } from "../../../../../uikit/box/DataItemBox"
import { ContractAdminResponse } from "../../../../../data/generated-sources/openapi"

interface ContractsTableInterface {
  itemConfig: ContractAdminResponse
}

const ContractsManagementTable = ({ itemConfig }: ContractsTableInterface) => {
  const history = useHistory()

  return (
    <TableRowClickable<ContractAdminResponse>
      key={itemConfig.id}
      rowData={itemConfig}
      rowClick={({ id }) => {
        if (id) {
          history.push(`/management-contracts/${id}`)
        }
      }}
    >
      <TableCell align="left">
        <StatusView statusType={StatusType[itemConfig.activeState as StatusTypeObjectKey]} />
      </TableCell>
      <TableCell align="left">{itemConfig.name ?? <DataItemBoxNoValue />}</TableCell>
      <TableCell align="left">{itemConfig?.areaName}</TableCell>
      <TableCell align="left">{itemConfig?.product?.name ?? "-"}</TableCell>
      <TableCell align="left">{DateUtils.getDeFormatDateWithMonthString(itemConfig.startDate)}</TableCell>
      <TableCell align="left">
        {itemConfig.endDate ? DateUtils.getDeFormatDateWithMonthString(itemConfig.endDate) : <DataItemBoxNoValue />}
      </TableCell>
    </TableRowClickable>
  )
}

export default ContractsManagementTable
