import { Button, CircularProgress, ButtonTypeMap } from "@mui/material"
import { DefaultComponentProps } from "@mui/material/OverridableComponent"
import { PrimaryButtonProps } from "./PrimaryButton"
import { GradientButton } from "./GradientButton"
import { managerPrimaryLightColor } from "../../app/Shared.Theme"

export const ManagerPrimaryButton = (props: PrimaryButtonProps & DefaultComponentProps<ButtonTypeMap>) => {
  const { isLoading, endIcon, disabled, ...rest } = props
  return (
    <GradientButton
      startColor={"#9AB20F"}
      endColor={"#4E7818"}
      disabled={disabled || isLoading}
      sx={{
        fontWeight: 500,
        fontSize: "16px",
        padding: "12px 18px",
      }}
      endIcon={endIcon || (isLoading && <CircularProgress style={{ color: "white" }} size={20} />)}
      useNewDesign
      {...rest}
    />
  )
}

export const ManagerOutlinedButton = (props: PrimaryButtonProps & DefaultComponentProps<ButtonTypeMap>) => {
  const { endIcon, isLoading, label, ...rest } = props
  const endIconCustom = isLoading ? <CircularProgress style={{ color: managerPrimaryLightColor }} size={20} /> : endIcon

  return (
    <Button variant="outlined" endIcon={endIconCustom} disabled={isLoading} {...rest}>
      {label}
    </Button>
  )
}
