import { Link } from "@mui/material"
import { format } from "date-fns"

export const formatLocale = (date: Date, formatStr: string, local: Locale) => {
  return format(date, formatStr, {
    locale: local,
  })
}

export const formatEmail = (value?: string, color?: string) => {
  if (!value) return value
  return (
    <Link color={color ?? "#a7b805"} variant="body1" href={`mailto:${value}`}>
      {value}
    </Link>
  )
}

export const formatPhoneWithSpaces = (value?: string) => {
  if (!value) return value
  return value.replace(/^((?:\+41)|(?:0041)|(?:41))?(0?\d\d)(\d\d\d)(\d\d)(\d\d)$/, "$1 $2 $3 $4 $5").trimStart() ?? ""
}

export const formatPhone = (value?: string, color?: string) => {
  if (!value) return value
  return (
    <Link color={color ?? "#a7b805"} variant="body1" href={`tel:${value}`}>
      {formatPhoneWithSpaces(value)}
    </Link>
  )
}
