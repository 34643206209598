import { Typography } from "@mui/material"
import { useTranslation } from "react-i18next"
import { ManagerPrimaryButton } from "../../../../uikit/button/ManagerButtons"

import dashboardChartBackground from "../../../../../res/dashboardChartBackground.jpg"
import { ONBOARDING_REQUIRED_COOKIE_NAME, eraseCookie } from "../../../Shared.Cookie"
import { ContractCustomerResponse } from "../../../../data/generated-sources/openapi/api"
import { useHistory } from "react-router-dom"

export const OnboardingFinalize = ({
  contractName,
  areaId,
  onboardingContracts,
  selectedContract,
  backToOnboardOverview,
}: {
  selectedContract: string
  contractName: string
  areaId: string
  onboardingContracts: ContractCustomerResponse[]
  backToOnboardOverview: () => void
}) => {
  const { t } = useTranslation("onboarding")
  const history = useHistory()

  const remainingContracts = onboardingContracts.filter((contract) => contract.id !== selectedContract)

  const goToAppDashboard = () => {
    eraseCookie(`${ONBOARDING_REQUIRED_COOKIE_NAME}-${areaId}`)
    history.push(`/${areaId}/dashboard`)
  }

  return (
    <>
      <img src={dashboardChartBackground} />
      <Typography pb={4} pt={8} fontSize={23} fontWeight={400}>
        {t("finalize.title", { contractName })}
      </Typography>
      <Typography pb={8} fontSize={16} fontWeight={400}>
        {t("finalize.subTitle", { contractName })}
        {t(`finalize.subTitle.${remainingContracts?.length ? "setup" : "overview"}`)}
      </Typography>
      {remainingContracts?.length ? (
        <ManagerPrimaryButton label={t("finalize.setupButton")} onClick={backToOnboardOverview} />
      ) : (
        <ManagerPrimaryButton label={t("finalize.overviewButton")} onClick={goToAppDashboard} />
      )}
    </>
  )
}
