import * as yup from "yup"
import { getTranslateValue } from "../../../Shared.Utils"
import { VALIDATION_DEPS } from "../../../Shared.Validation"
import { ParticipantGpType, ParticipantPersonalDataSalutationEnum } from "../../../../data/generated-sources/openapi"
import { ParticipantAddressCountry } from "../../../../domain/participant/Participant.Model"

const participantObject = {
  gpType: yup
    .mixed()
    .oneOf(Object.values(ParticipantGpType))
    .required(() =>
      getTranslateValue("shared:validation.mandatory", {
        field: getTranslateValue("customer-participants:registerForm.gpType"),
      }),
    ),
  personalData: yup.object({
    salutation: yup
      .mixed()
      .oneOf(Object.values(ParticipantPersonalDataSalutationEnum))
      .required(() =>
        getTranslateValue("shared:validation.mandatory", {
          field: getTranslateValue("customer-participants:registerForm.salutation"),
        }),
      ),
    firstName: yup
      .string()
      .max(255)
      .trim()
      .required(() =>
        getTranslateValue("shared:validation.mandatory", {
          field: getTranslateValue("customer-participants:registerForm.firstName"),
        }),
      ),
    lastName: yup
      .string()
      .max(255)
      .trim()
      .required(() =>
        getTranslateValue("shared:validation.mandatory", {
          field: getTranslateValue("customer-participants:registerForm.lastName"),
        }),
      ),
  }),
  contactDetails: yup
    .object({
      mobile: yup.string().test(
        "mobile",
        () =>
          getTranslateValue("shared:validation.valid.mobileNumber", {
            field: getTranslateValue("customer-participants:registerForm.mobile"),
          }),
        (val) => (val ? VALIDATION_DEPS.validSwissMobile(val) : true),
      ),
      phone: yup
        .string()
        .optional()
        .test(
          "phone",
          () =>
            getTranslateValue("shared:validation.valid.phoneNumber", {
              field: getTranslateValue("customer-participants:registerForm.phone"),
            }),
          (val) => (val ? VALIDATION_DEPS.validSwissPhoneNumber(val) : true),
        ),
      email: yup
        .string()
        .optional()
        .test(
          "email",
          () =>
            getTranslateValue("shared:validation.valid.email", {
              field: getTranslateValue("customer-participants:registerForm.email"),
            }),
          (val) => (val ? VALIDATION_DEPS.validEmail(val) : true),
        ),
    })
    .optional(),
  domicileAddress: yup.object({
    city: yup
      .string()
      .max(255)
      .trim()
      .required(() =>
        getTranslateValue("shared:validation.mandatory", {
          field: getTranslateValue("customer-participants:registerForm.city"),
        }),
      ),
    postCode: yup
      .string()
      .max(250)
      .trim()
      .required(() =>
        getTranslateValue("shared:validation.mandatory", {
          field: getTranslateValue("customer-participants:registerForm.postCode"),
        }),
      ),
    houseNumber: yup
      .string()
      .max(150)
      .trim()
      .required(() =>
        getTranslateValue("shared:validation.mandatory", {
          field: getTranslateValue("customer-participants:registerForm.houseNumber"),
        }),
      ),
    street: yup
      .string()
      .max(255)
      .trim()
      .required(() =>
        getTranslateValue("shared:validation.mandatory", {
          field: getTranslateValue("customer-participants:registerForm.street"),
        }),
      ),
    land: yup
      .mixed()
      .oneOf(Object.values(ParticipantAddressCountry))
      .required(() =>
        getTranslateValue("shared:validation.mandatory", {
          field: getTranslateValue("customer-participants:registerForm.land"),
        }),
      ),
    co: yup.string().max(250).trim().nullable().optional(),
    poBox: yup.string().max(250).trim().nullable().optional(),
  }),
}

export const ParticipantRegisterSchema = yup.object().shape(participantObject)

export const ParticipantAdminCreateSchema = yup.object().shape({
  sapGp: yup
    .string()
    .trim()
    .min(7, () =>
      getTranslateValue("shared:validation.length", {
        field: getTranslateValue("customer-participants:registerForm.businessPartnerType"),
        length: 7,
      }),
    )
    .max(7, () =>
      getTranslateValue("shared:validation.length", {
        field: getTranslateValue("customer-participants:registerForm.businessPartnerType"),
        length: 7,
      }),
    ),
  ...participantObject,
})
