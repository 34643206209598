import {
  PagedAllParticipantsBillingResponse,
  PagedVewaAllParticipantsBillingResponse,
  ContractIndividualParticipantBillingCustomerResponse,
  ContractCustomerResponse,
  AllParticipantsBillingState,
  VewaAllParticipantsBillingState,
  IndividualParticipantBillingState,
  BillingDetails,
  BillDetails,
  SummaryType,
} from "../../../../data/generated-sources/openapi"

import { addDays, endOfDay, endOfMonth, isBefore } from "date-fns"
import { formatMoneyLabel } from "../../../Domain.Formatters"
import { isNewStateBillingProduct } from "../../../../services/utils/BillingUtilis"
import { DomainDependencies } from "../../../Domain.Dependencies"
import { IndividualParticipantBillingList, ZevPeriodicBillingsList, VewaPeriodicBillingsList } from "./Billings.Model"
import { formatPersonalFullName } from "../../admin/profiles/Profiles.Formatters"
import DateUtils from "../../../../services/utils/DateUtils"
import { getParticipantFullName } from "../../../../services/utils/ParticipantUtils"
import { CustomBillingDetails } from "../../../../app/portal/manager/utilityUnit/components/billings/UtilityUnitInterfaces"

export const zevPeriodicBillingMapper = (
  response: PagedAllParticipantsBillingResponse,
  serviceComponentResponse: {
    id: string
    hasCollectionFeature: boolean
  }[],
): ZevPeriodicBillingsList => {
  return {
    zevPeriodicBillings: response.elements.map((zevAllParticipantsBilling) => {
      const hasCollectionFeature = serviceComponentResponse.find((res) => res.id === zevAllParticipantsBilling.id)
      const estDate = addDays(endOfMonth(new Date(zevAllParticipantsBilling.endDate)), 7)
      const estDataArrivalDate = isBefore(endOfDay(estDate), new Date())
        ? undefined
        : DateUtils.getDeFormatDate(estDate, "DD. MMM YY")

      const isNewBilling = isNewStateBillingProduct(
        zevAllParticipantsBilling.activeState,
        !!hasCollectionFeature?.hasCollectionFeature,
      )

      const { DATA_AVAILABLE, IN_PROGRESS, IN_PROGRESS_REOPENED, WAITING_FOR_DATA, SUSPENDED, DONE, PAID } =
        AllParticipantsBillingState
      let makeBill = true

      if ([WAITING_FOR_DATA, SUSPENDED].includes(zevAllParticipantsBilling.activeState)) {
        makeBill = false
      }
      if (hasCollectionFeature && zevAllParticipantsBilling.activeState !== AllParticipantsBillingState.DONE) {
        makeBill = false
      }
      if (
        [DATA_AVAILABLE, IN_PROGRESS, IN_PROGRESS_REOPENED, DONE, PAID].includes(zevAllParticipantsBilling.activeState)
      ) {
        makeBill = true
      }

      return {
        ...zevAllParticipantsBilling,
        datePeriod: `${DateUtils.getDeFormatDate(
          zevAllParticipantsBilling.startDate,
          "DD. MMM YY",
        )} - ${DateUtils.getDeFormatDate(zevAllParticipantsBilling.endDate, "DD. MMM YY")}`,
        totalSum:
          zevAllParticipantsBilling.totalSum === "0.00" || zevAllParticipantsBilling.totalSum === undefined
            ? ""
            : formatMoneyLabel(zevAllParticipantsBilling.totalSum),
        estDataArrivalDate,
        isNew: isNewBilling,
        makeBill: makeBill,
        hasCollectionFeature: !!hasCollectionFeature && hasCollectionFeature.hasCollectionFeature,
      }
    }),
  }
}

export const vewaPeriodicBillingMapper = (
  response: PagedVewaAllParticipantsBillingResponse,
  serviceComponentResponse: {
    id: string
    hasCollectionFeature: boolean
  }[],
  contractCustomerResponse: ContractCustomerResponse,
): VewaPeriodicBillingsList => {
  return {
    vewaPeriodicBillings: response.elements.map((vewaParticipantBilling) => {
      const hasCollectionFeature = serviceComponentResponse.find((res) => res.id === vewaParticipantBilling.id)
      const { WAITING_FOR_DATA } = VewaAllParticipantsBillingState

      let makeBill = true
      if ([WAITING_FOR_DATA].includes(vewaParticipantBilling.activeState)) {
        makeBill = false
      }

      const estDataArrivalDate =
        vewaParticipantBilling.activeState !== WAITING_FOR_DATA
          ? DateUtils.getDeFormatDate(addDays(endOfMonth(new Date(vewaParticipantBilling.endDate)), 7), "DD. MMM YY")
          : undefined

      return {
        ...vewaParticipantBilling,
        datePeriod: `${DateUtils.getDeFormatDate(
          vewaParticipantBilling.startDate,
          "DD. MMM YY",
        )} - ${DateUtils.getDeFormatDate(vewaParticipantBilling.endDate, "DD. MMM YY")}`,
        totalSum: "",
        estDataArrivalDate: estDataArrivalDate,
        isNew: false,
        makeBill: makeBill,
        hasCollectionFeature: !!hasCollectionFeature && hasCollectionFeature.hasCollectionFeature,
        productName: contractCustomerResponse.product?.name,
      }
    }),
  }
}

export const individualParticipantBillingMapper = (
  contractIndividualParticipantBillingCustomerResponse: ContractIndividualParticipantBillingCustomerResponse,
  serviceComponentResponse: {
    id: string
    hasCollectionFeature: boolean
  }[],
  deps: DomainDependencies,
): IndividualParticipantBillingList => ({
  individualParticipantBillings: contractIndividualParticipantBillingCustomerResponse.elements.map(
    (zevIndividualParticipantsBilling) => {
      const hasCollectionFeature = serviceComponentResponse.find(
        (res) => res.id === zevIndividualParticipantsBilling.id,
      )
      const estDate = addDays(endOfMonth(new Date(zevIndividualParticipantsBilling.endDate)), 7)
      const estDataArrivalDate = isBefore(endOfDay(estDate), new Date())
        ? undefined
        : DateUtils.getDeFormatDate(estDate, "DD. MMM YY")

      const { WAITING_FOR_DATA, DATA_AVAILABLE, SUSPENDED, IN_PROGRESS, IN_PROGRESS_REOPENED, DONE } =
        IndividualParticipantBillingState
      const isNewBilling = [DATA_AVAILABLE, IN_PROGRESS, IN_PROGRESS_REOPENED].includes(
        zevIndividualParticipantsBilling.activeState,
      )

      let makeBill = true

      if ([WAITING_FOR_DATA, SUSPENDED].includes(zevIndividualParticipantsBilling.activeState)) {
        makeBill = false
      }
      if (hasCollectionFeature && zevIndividualParticipantsBilling.activeState !== DONE) {
        makeBill = false
      }
      if ([DATA_AVAILABLE, IN_PROGRESS, IN_PROGRESS_REOPENED].includes(zevIndividualParticipantsBilling.activeState)) {
        makeBill = true
      }

      return {
        ...zevIndividualParticipantsBilling,
        datePeriod: `${DateUtils.getDeFormatDate(
          zevIndividualParticipantsBilling.startDate,
          "DD. MMM YY",
        )} - ${DateUtils.getDeFormatDate(zevIndividualParticipantsBilling.endDate, "DD. MMM YY")}`,
        participantName: zevIndividualParticipantsBilling?.participant
          ? formatPersonalFullName(zevIndividualParticipantsBilling?.participant.personalData, deps)
          : "",
        estDataArrivalDate,
        isNew: isNewBilling,
        makeBill: makeBill,
        hasCollectionFeature: !!hasCollectionFeature && hasCollectionFeature.hasCollectionFeature,
      }
    },
  ),
})

const setTotalSum = (billDetails?: BillDetails) => {
  if (!billDetails) return "-"

  const foundSum = billDetails?.billingCalculations?.summaryPositions.find(
    (summaryPosition) => summaryPosition.summaryType === SummaryType.FINAL_AMOUNT_DUE,
  )
  const totalSum = foundSum?.amountDue ? `CHF ${foundSum.amountDue}` : "-"
  return totalSum
}

export const utilityUnitsCustomBillingDetails = (billing: BillingDetails): CustomBillingDetails => {
  if (billing.allParticipantBillingDetails) {
    const { allParticipantBillingDetails } = billing
    const { DONE, DATA_AVAILABLE, IN_PROGRESS, IN_PROGRESS_REOPENED } = AllParticipantsBillingState

    return {
      ...allParticipantBillingDetails,
      billingPageType: "all",
      billingPageStatus: allParticipantBillingDetails.billingStatus === DONE ? "finalize" : "edit",
      isNew: [DATA_AVAILABLE, IN_PROGRESS, IN_PROGRESS_REOPENED].includes(allParticipantBillingDetails.billingStatus),
      totalSum: setTotalSum(allParticipantBillingDetails?.billDetails),
      participantName: allParticipantBillingDetails?.participantPersonalData
        ? getParticipantFullName(allParticipantBillingDetails?.participantPersonalData)
        : "-",
      period: `${DateUtils.getDeFormatDateWithMonthString(
        allParticipantBillingDetails?.startDate || "",
      )} - ${DateUtils.getDeFormatDateWithMonthString(allParticipantBillingDetails?.endDate || "")}`,
    }
  } else {
    const { individualParticipantBillingDetails } = billing
    const billingStatus = individualParticipantBillingDetails?.billingStatus
    const { DONE, DATA_AVAILABLE, IN_PROGRESS, IN_PROGRESS_REOPENED } = IndividualParticipantBillingState

    return {
      ...individualParticipantBillingDetails,
      billingPageType: "individual",
      billingPageStatus: billingStatus === DONE ? "finalize" : "edit",
      isNew: billingStatus ? [DATA_AVAILABLE, IN_PROGRESS, IN_PROGRESS_REOPENED].includes(billingStatus) : false,
      totalSum: setTotalSum(individualParticipantBillingDetails?.billDetails),
      participantName: individualParticipantBillingDetails?.participantPersonalData
        ? getParticipantFullName(individualParticipantBillingDetails?.participantPersonalData)
        : "-",
      period: `${DateUtils.getDeFormatDateWithMonthString(
        individualParticipantBillingDetails?.startDate || "",
      )} - ${DateUtils.getDeFormatDateWithMonthString(individualParticipantBillingDetails?.endDate || "")}`,
    }
  }
}
