import { PayloadAction, createSelector, createSlice } from "@reduxjs/toolkit"
import { RootState } from "../../../App.Store"
import { TutorialStatus } from "../../../../domain/auth/Auth.Model"

export interface UiState {
  managerSettingActiveTab: number
  tutorialStatus: TutorialStatus
}

export const initialState: UiState = {
  managerSettingActiveTab: 0,
  tutorialStatus: TutorialStatus.COMPLETED,
}

export const uiSlice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    setTutorialStatus: (state: UiState, { payload }: PayloadAction<TutorialStatus>) => {
      state.tutorialStatus = payload
    },
    setManagerSettingActiveTab: (state: UiState, { payload }: PayloadAction<number>) => {
      state.managerSettingActiveTab = payload
    },
  },
})

export const { setManagerSettingActiveTab } = uiSlice.actions

const uiState = (state: RootState) => state.managerPortal.ui
export const selectTutorialStatus = createSelector(uiState, (state) => state.tutorialStatus)
export const selectManagerSettingActiveTab = createSelector(uiState, (state) => state.managerSettingActiveTab)

export default uiSlice.reducer
