import { useMutation, useQuery } from "@tanstack/react-query"
import { useEffect, useState } from "react"
import { Paper } from "@mui/material"
import { RouteComponentProps } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { AdminPortalRouteParams } from "../../AdminPortal.Routes"

import { AxiosErrorDataType } from "../../../../Shared.Utils"
import {
  getAdminIndividualParticipantBillingDetailsById,
  reopenIndividualParticipantBilling,
  sendSapIndividualParticipantBilling,
} from "../../../../../domain/portal/admin/billings/participant/BillingsIndividualParticipant.Repository"
import { IndividualParticipantBillingState } from "../../../../../data/generated-sources/openapi"
import { ErrorAlert } from "../../../../../uikit/Shared.Alert"
import { ZevBillingFinalize } from "../../../shared/zevBillings/ZevBillingFinalize"
import {
  downloadBillPdf,
  updateAdminBillPaidOrUnpaid,
} from "../../../../../domain/portal/admin/billings/participant/Bill.Repository"

export const ZevIndividualDoneParticipantBilling = ({
  match: { params },
  history,
}: RouteComponentProps<AdminPortalRouteParams>) => {
  const { billingId, id: contractId } = params
  const { t } = useTranslation("billings-participant")
  const [error, setError] = useState<AxiosErrorDataType>()

  const redirectToEditBill = () =>
    history.push(`/management-contracts/${contractId}/billings/individual/${billingId}/edit`)

  const {
    data: billing,
    isFetching: isFetchingBilling,
    remove: removeBill,
    refetch: refetchBill,
  } = useQuery(
    ["getParticipantBilling"],
    () => getAdminIndividualParticipantBillingDetailsById(billingId, contractId),
    {
      enabled: !!billingId,
      onSuccess: (data) => {
        if (
          ![IndividualParticipantBillingState.DONE, IndividualParticipantBillingState.PAID].includes(data.billingStatus)
        ) {
          redirectToEditBill()
        }
      },
      onError: setError,
    },
  )

  const { mutate: reopenBilling, isLoading: isReopeningBilling } = useMutation(
    ["reopenBilling"],
    () => {
      setError(undefined)
      return reopenIndividualParticipantBilling(billingId)
    },
    {
      onSuccess: redirectToEditBill,
      onError: setError,
    },
  )

  const { mutate: downloadPdfBill, isLoading: isDownloadingBill } = useMutation(
    ["downloadPdfBill"],
    (billId: string) => {
      setError(undefined)
      return downloadBillPdf(billId)
    },
    {
      onError: setError,
    },
  )
  const { mutate: togglePayStatus, isLoading: isTogglePayStatus } = useMutation(
    ["togglePayStatus"],
    ({ billId, paidOrUnpaid }: { billId: string; paidOrUnpaid: "paid" | "unpaid" }) => {
      setError(undefined)
      return updateAdminBillPaidOrUnpaid(billId, paidOrUnpaid)
    },
    {
      onSuccess: () => refetchBill(),
      onError: setError,
    },
  )

  const { mutate: sendBillingSap, isLoading: isSendingSap } = useMutation(
    ["sendBillingSap"],
    () => {
      setError(undefined)
      return sendSapIndividualParticipantBilling(billingId)
    },
    {
      onSuccess: () => refetchBill(),
      onError: setError,
    },
  )

  useEffect(() => {
    return () => {
      removeBill()
    }
  }, [removeBill])

  return (
    <Paper sx={{ p: 3, minHeight: "80vh" }}>
      <ErrorAlert
        scrollOnDisplay
        visible={!!error}
        message={t(`error-codes:${error?.response?.data?.code ?? error?.code ?? "OTHER"}`)}
      />
      <ZevBillingFinalize
        isIndividualBill
        billing={billing}
        onSendSAP={sendBillingSap}
        isSendingToSAP={isSendingSap}
        onReopenBilling={reopenBilling}
        isReopeningBilling={isReopeningBilling}
        isLoading={isFetchingBilling || isTogglePayStatus || isDownloadingBill}
        onDownloadBill={(billId: string) => downloadPdfBill(billId)}
        onToggleBillPay={(billId, paidOrUnpaid) => togglePayStatus({ billId, paidOrUnpaid })}
      />
    </Paper>
  )
}
