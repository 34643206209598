import { TFunction } from "i18next"
import { GridBaseColDef } from "@mui/x-data-grid/internals"
import { GridRenderCellParams, GridValueGetterParams } from "@mui/x-data-grid"
import { Typography } from "@mui/material"
import { useTranslation } from "react-i18next"
import { ParticipationResponse } from "../../../../data/generated-sources/openapi"

const CurrentParticipantUnit = ({ value }: GridRenderCellParams) => {
  const { t } = useTranslation("customer-participants")
  const currentUnits = (value || []).filter((part: ParticipationResponse) => part.isCurrent)

  let unitNameValue = "-"
  if (currentUnits.length === 1) {
    unitNameValue = currentUnits[0].utilityUnit?.name
  }
  if (currentUnits.length > 1) {
    unitNameValue = t("shared:several")
  }

  return <Typography fontWeight={400}>{unitNameValue}</Typography>
}

export const ParticipantsListColumns: (t: TFunction) => Array<GridBaseColDef> = (t: TFunction) => [
  {
    field: "lastName",
    headerName: t("customer-participants:tableHeader.lastName"),
    flex: 250,
    valueGetter: (params: GridValueGetterParams) => params.row.personalData?.lastName || "-",
  },
  {
    field: "firstName",
    headerName: t("customer-participants:tableHeader.firstName"),
    valueGetter: (params: GridValueGetterParams) => params.row.personalData?.firstName || "-",
    flex: 250,
  },
  {
    field: "email",
    headerName: t("customer-participants:tableHeader.email"),
    valueGetter: (params: GridValueGetterParams) => params.row?.contactDetails?.email || "-",
    flex: 250,
  },
  {
    field: "phone",
    headerName: t("customer-participants:tableHeader.phone"),
    valueGetter: (params: GridValueGetterParams) => params.row?.contactDetails?.phone || "-",
    flex: 250,
  },
  {
    field: "utilityUnit.",
    headerName: t("customer-participants:tableHeader.utilityUnit"),
    valueGetter: (params: GridValueGetterParams) => params.row?.participations,
    renderCell: CurrentParticipantUnit,
    flex: 200,
  },
]
