import { Map } from "immutable"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Box } from "@mui/material"

import { FormRowColumn, FormView } from "../../../../../uikit/form/FormView"
import { Body1 } from "../../../../../uikit/typography/Typography"
import { UpdateAddressUpsert } from "../../../../../domain/portal/manager/profile/Profile.Model"
import { SingleLineTextField } from "../../../../../uikit/input/SingleLineTextField"
import { validateProfileAddressForm } from "./ProfileAddressForm.validation"
import { ManagerPrimaryButton } from "../../../../../uikit/button/ManagerButtons"
import { bgColorGreen } from "../../../../Shared.Theme"

interface ProfileAddressFormProps {
  editMode: boolean
  isLoading: boolean
  profileAddressValues: UpdateAddressUpsert
  dataChanged: (profileAddressValues: UpdateAddressUpsert) => void
}

export const ProfileAddressForm = ({
  editMode,
  dataChanged,
  profileAddressValues,
  isLoading,
}: ProfileAddressFormProps) => {
  const { t } = useTranslation("profile")
  const [touched, setTouched] = useState<Map<string, boolean>>(Map())
  const [editBackgroundColor, setEditBackgroundColor] = useState("#ffffff")

  useEffect(() => {
    setEditBackgroundColor(editMode ? bgColorGreen : "#ffffff")
  }, [setEditBackgroundColor, editMode])

  return (
    <>
      <div
        style={{
          padding: "16px",
          marginBottom: "32px",
          backgroundColor: editBackgroundColor,
          borderBottomLeftRadius: "8px",
          borderBottomRightRadius: "8px",
        }}
      >
        <FormView>
          <>
            <FormRowColumn>
              {editMode ? (
                <Box sx={{ padding: "8px 0", flex: 1 }}>
                  <SingleLineTextField
                    sx={{ margin: "5px 0" }}
                    name="street"
                    label={t("address.street")}
                    value={profileAddressValues.address.street}
                    onChange={(event) =>
                      dataChanged({ address: { ...profileAddressValues.address, street: event.target.value } })
                    }
                    onBlur={() => setTouched(touched.set("street", true))}
                    helperText={validateProfileAddressForm.street(
                      `${profileAddressValues.address.street}`,
                      touched.has("street"),
                      t,
                    )}
                  />
                </Box>
              ) : (
                <Body1>{`${profileAddressValues.address.street} ${profileAddressValues.address.houseNumber}`}</Body1>
              )}
            </FormRowColumn>
            <FormRowColumn>
              {editMode && (
                <Box sx={{ padding: "8px 0", flex: 1 }}>
                  <SingleLineTextField
                    name="houseNumber"
                    sx={{ margin: "5px 0" }}
                    label={t("address.houseNumber")}
                    value={profileAddressValues.address.houseNumber}
                    onChange={(event) =>
                      dataChanged({ address: { ...profileAddressValues.address, houseNumber: event.target.value } })
                    }
                    onBlur={() => setTouched(touched.set("houseNumber", true))}
                    helperText={validateProfileAddressForm.houseNumber(
                      `${profileAddressValues.address.houseNumber}`,
                      touched.has("houseNumber"),
                      t,
                    )}
                  />
                </Box>
              )}
            </FormRowColumn>
            <FormRowColumn>
              {editMode ? (
                <Box sx={{ padding: "8px 0", flex: 1 }}>
                  <SingleLineTextField
                    name="postalCode"
                    sx={{ margin: "5px 0" }}
                    label={t("address.postalCode")}
                    value={profileAddressValues.address.postalCode}
                    onChange={(event) =>
                      dataChanged({ address: { ...profileAddressValues.address, postalCode: event.target.value } })
                    }
                    onBlur={() => setTouched(touched.set("postalCode", true))}
                    helperText={validateProfileAddressForm.postalCode(
                      `${profileAddressValues.address.postalCode}`,
                      touched.has("postalCode"),
                      t,
                    )}
                  />
                </Box>
              ) : (
                <Body1>{`${profileAddressValues.address.postalCode} ${profileAddressValues.address.city}`}</Body1>
              )}
            </FormRowColumn>
            <FormRowColumn>
              {editMode && (
                <Box sx={{ padding: "8px 0", flex: 1 }}>
                  <SingleLineTextField
                    name="city"
                    sx={{ margin: "5px 0" }}
                    label={t("address.city")}
                    value={profileAddressValues.address.city}
                    onChange={(event) =>
                      dataChanged({ address: { ...profileAddressValues.address, city: event.target.value } })
                    }
                    onBlur={() => setTouched(touched.set("city", true))}
                    helperText={validateProfileAddressForm.city(
                      `${profileAddressValues.address.city}`,
                      touched.has("city"),
                      t,
                    )}
                  />
                </Box>
              )}
            </FormRowColumn>
          </>
        </FormView>
        {editMode && (
          <ManagerPrimaryButton
            type="submit"
            label={t("shared:form.action.save")}
            isLoading={isLoading}
            sx={{ mt: 2 }}
          />
        )}
      </div>
    </>
  )
}
