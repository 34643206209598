import { ButtonBase, Menu, MenuItem, Box, IconButton, Stack } from "@mui/material"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { RouteComponentProps } from "react-router-dom"
import { MainContactIcon } from "../../../../uikit/Shared.Icon"
import { AdminPortalRouteParams } from "../../../portal/admin/AdminPortal.Routes"
import { AppBreadcrumb } from "../../../App.Breadcrumb"
import { AppLogo } from "../AppLogo"
import { COOKIE_ITEMS } from "../../../config/outsorceConstants"

export interface AdminHeaderViewProps {
  navigateAdminRoot: () => void
  navigateAdminContracts: () => void
  navigateAdminMyProfile: () => void
  navigateAdminSettings: () => void
  logout: (previousPathname?: string) => void
}

export const AdminHeaderView = (props: AdminHeaderViewProps & RouteComponentProps<AdminPortalRouteParams>) => {
  const { t } = useTranslation("appContainer")
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const { navigateAdminContracts, navigateAdminMyProfile, navigateAdminSettings, logout } = props

  return (
    <Stack
      py={1}
      px={2}
      flexDirection={"row"}
      alignItems={"center"}
      justifyContent={"flex-start"}
      sx={{
        backgroundColor: "#fafafa",
        borderBottom: ".2rem solid #A7B805",
        boxShadow:
          "0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)",
        position: "fixed",
        zIndex: 5,
        width: "calc(100% - 16px)",
      }}
    >
      <Box width={248}>
        <ButtonBase onClick={() => navigateAdminContracts()}>
          <AppLogo />
        </ButtonBase>
      </Box>
      <AppBreadcrumb {...props} />
      <IconButton onClick={handleClick}>
        <MainContactIcon fontSize="large" color="primary" />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <MenuItem
          onClick={() => {
            handleClose()
            navigateAdminMyProfile()
          }}
        >
          {t("admin.header.profile.menu.myprofile")}
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose()
            navigateAdminSettings()
          }}
        >
          {t("admin.header.profile.menu.settings")}
        </MenuItem>
        <MenuItem
          onClick={() => {
            localStorage.setItem(COOKIE_ITEMS.userCkwIsAdmin, JSON.stringify(true))
            handleClose()
            logout()
          }}
        >
          {t("admin.header.profile.menu.logout")}
        </MenuItem>
      </Menu>
    </Stack>
  )
}
