import { Grid } from "@mui/material"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { Form, Formik } from "formik"
import { ContractAddressData } from "../../../../../data/generated-sources/openapi"

import { OnboardingContactInfoFormProps } from "./OnboardingContactInfo"
import { LabelTypography, FormEditWrapper, ValueTypography, FormContactHeader } from "../fragments/OnboardingUI"
import { SingleLineTextField } from "../../../../../uikit/input/SingleLineTextField"
import { ManagerPrimaryButton } from "../../../../../uikit/button/ManagerButtons"
import { onboardAddressDataSchema } from "./ContactInfoValidation.Schema"
import { useMutation } from "@tanstack/react-query"
import { customerUpdateContactAddressByContractId } from "../../../../../domain/portal/manager/contracts/Contract.Repository"

export const OnboardingContactDetailsForm = ({
  contractId,
  addressData,
  onSuccessUpdate,
  onErrorUpdate,
}: OnboardingContactInfoFormProps) => {
  const { t } = useTranslation("onboarding")
  const [editPartner, setEditPartner] = useState(false)

  const { mutate: updateAddress, isLoading } = useMutation(
    ["updateAddress"],
    (addresssData: ContractAddressData) => customerUpdateContactAddressByContractId(contractId, addresssData),
    {
      onError: onErrorUpdate,
      onSuccess: () => {
        onSuccessUpdate()
        setEditPartner(false)
      },
    },
  )

  return (
    <Formik<ContractAddressData>
      initialValues={addressData}
      enableReinitialize
      validationSchema={onboardAddressDataSchema}
      onSubmit={(data) => updateAddress(data)}
    >
      {({ values, errors, touched, isValid, dirty, handleBlur, setFieldValue, resetForm }) => (
        <Form>
          <FormEditWrapper container item rowGap={2} editMode={editPartner}>
            <FormContactHeader
              headerText={t("contactInfo.details")}
              editMode={editPartner}
              onClick={() => {
                resetForm()
                setEditPartner((prevState) => !prevState)
              }}
            />
            {editPartner ? (
              <>
                <Grid container item>
                  <Grid item xs={6}>
                    <SingleLineTextField
                      sx={{ mb: 1.5 }}
                      name="addressLineOne"
                      type="text"
                      label={t("contracts-management:contactForm.contractor")}
                      value={values?.addressLineOne ?? ""}
                      onBlur={handleBlur}
                      onChange={(event) => setFieldValue("addressLineOne", event.target.value)}
                      helperText={touched.addressLineOne && errors.addressLineOne ? errors.addressLineOne : undefined}
                    />
                  </Grid>
                </Grid>

                <Grid container item pb={2}>
                  <Grid item xs={6}>
                    <SingleLineTextField
                      sx={{ mb: 1.5 }}
                      name="addressLineTwo"
                      type="text"
                      label={t("contracts-management:contactForm.addressAdditional")}
                      value={values?.addressLineTwo ?? ""}
                      onBlur={handleBlur}
                      onChange={(event) => setFieldValue("addressLineTwo", event.target.value)}
                      helperText={touched.addressLineTwo && errors.addressLineTwo ? errors.addressLineTwo : undefined}
                    />
                  </Grid>
                </Grid>
                <ManagerPrimaryButton
                  type="submit"
                  isLoading={isLoading}
                  disabled={!isValid || !dirty || isLoading}
                  label={t("shared:form.action.save")}
                />
              </>
            ) : (
              <>
                <Grid item xs={12}>
                  <LabelTypography>{t("contracts-management:contactForm.contractor")}</LabelTypography>
                  <ValueTypography>{values?.addressLineOne || "-"}</ValueTypography>
                </Grid>

                <Grid item xs={12}>
                  <LabelTypography>{t("contracts-management:contactForm.addressAdditional")}</LabelTypography>
                  <ValueTypography>{values?.addressLineTwo || "-"}</ValueTypography>
                </Grid>
              </>
            )}
          </FormEditWrapper>
        </Form>
      )}
    </Formik>
  )
}
