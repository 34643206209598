import { useEffect, useState } from "react"
import { GridRowId, GridValueGetterParams } from "@mui/x-data-grid"
import { GridBaseColDef } from "@mui/x-data-grid/internals"
import { useTranslation } from "react-i18next"
import { useMutation, useQuery } from "@tanstack/react-query"
import { Box, Dialog, DialogTitle, DialogContent, DialogActions, IconButton } from "@mui/material"
import { MeterResponse, ZevPricePackage } from "../../../../../../data/generated-sources/openapi"
import {
  customerGetMetersForUtilityUnit,
  updateMetersPricePackage,
} from "../../../../../../domain/portal/manager/utilityUnit/UtilityUnit.Repository"
import { AbortIcon } from "../../../../../../uikit/Shared.Icon"
import { ErrorAlert } from "../../../../../../uikit/Shared.Alert"
import { ManagerPrimaryButton } from "../../../../../../uikit/button/ManagerButtons"
import { DataGridTable, DataGridRedirectRowTable } from "../../../../../../uikit/table/DataGridTable"
import { customerGetPricePackagesByContractId } from "../../../../../../domain/portal/manager/contracts/Contract.Repository"
import { AxiosErrorDataType, useQueryDefaultOptions } from "../../../../../Shared.Utils"
import { FullScreenLoader } from "../../../../../../uikit/indicator/ProgressIndicator"

interface UtilityUnitMetersTablePropsInterface {
  utilityUnitId: string
  contractId: string | undefined
}

export const UtilityUnitMetersTable = ({ utilityUnitId, contractId }: UtilityUnitMetersTablePropsInterface) => {
  const { t } = useTranslation("meters")
  const [unitError, setUnitError] = useState<AxiosErrorDataType>()
  const [showPriceEdit, setShowPriceEdit] = useState(false)
  const [selectedPrice, setSelectedPrice] = useState<GridRowId[]>([])
  const [selectedMeter, setSelectedMeter] = useState<MeterResponse | null>(null)
  const [meterError, setMeterError] = useState<AxiosErrorDataType>()

  const { data: meters, isFetching } = useQuery(["getMeters"], () => customerGetMetersForUtilityUnit(utilityUnitId), {
    enabled: !!utilityUnitId,
    onError: setUnitError,
  })

  const { data: pricePackagesList, remove: removePricePackages } = useQuery(
    ["getPricePackage", contractId],
    () => customerGetPricePackagesByContractId(contractId ?? ""),
    {
      ...useQueryDefaultOptions,
      enabled: !!contractId,
    },
  )

  const { mutate: updateMeter, isLoading: isMeterUpdating } = useMutation(
    ["updateMeter"],
    () => updateMetersPricePackage(selectedMeter?.id ?? "", Number(selectedPrice[0])),
    {
      onError: setMeterError,
    },
  )

  useEffect(() => {
    return () => {
      removePricePackages()
    }
  }, [removePricePackages])

  const metersColumns: Array<GridBaseColDef> = [
    {
      field: "meteringCode",
      headerName: t("label.meterIdSpec"),
      flex: 250,
    },
    {
      field: "medium",
      headerName: t("label.medium"),
      flex: 150,
      valueGetter: (params: GridValueGetterParams) => t(`label.${params.row.medium || ""}`),
    },
    {
      field: "pricePackageName",
      headerName: t("label.electricityPrice"),
      flex: 150,
    },
  ]

  const handleRowClick = (meter: MeterResponse) => {
    setSelectedMeter(meter)
    setShowPriceEdit(true)
  }

  return (
    <Box p={6} flex={1}>
      {isMeterUpdating && <FullScreenLoader />}
      <ErrorAlert
        visible={!!unitError}
        message={t(`error-codes:${unitError?.response?.data?.code || unitError?.code || "OTHER"}`)}
      />
      <ErrorAlert
        visible={!!meterError}
        message={t(`error-codes:${meterError?.response?.data?.code || meterError?.code || "OTHER"}`)}
      />
      <DataGridRedirectRowTable<MeterResponse>
        rows={meters || []}
        columns={metersColumns}
        loading={isFetching}
        redirectDescription={t("label.adjustPrices")}
        onRowClick={({ row }) => handleRowClick(row)}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 5,
            },
          },
        }}
      />
      <Dialog
        open={showPriceEdit}
        onBlur={() => {
          !selectedPrice.length && setSelectedPrice([Number(selectedMeter?.pricePackageId)])
        }}
        onClose={() => {
          setShowPriceEdit(false)
          setSelectedPrice([])
        }}
        fullWidth
        maxWidth="md"
      >
        <Box>
          <DialogTitle>{t("label.adjustPrices")}</DialogTitle>
          <IconButton
            aria-label="close"
            onClick={() => setShowPriceEdit(false)}
            sx={{
              top: 28,
              right: 35,
              color: "#000",
              position: "absolute",
            }}
          >
            <AbortIcon />
          </IconButton>
        </Box>

        <DialogContent>
          {pricePackagesList?.length && (
            <DataGridTable<ZevPricePackage>
              checkboxSelection={true}
              sx={{
                ".MuiDataGrid-columnHeaderCheckbox > div": {
                  display: "none",
                },
              }}
              onRowSelectionModelChange={(selection) => {
                if (!selection.length) return null
                if (selection.length > 1) {
                  const selectionSet = new Set(selectedPrice)
                  const result = selection.filter((s) => !selectionSet.has(s))
                  setSelectedPrice(result)
                } else {
                  setSelectedPrice(selection)
                }
              }}
              rowSelectionModel={selectedPrice}
              rows={pricePackagesList}
              columns={[
                {
                  field: "selectedPrice",
                  headerName: t("label.electricityPrice"),
                  flex: 250,
                  valueGetter: (params: GridValueGetterParams) => params.row?.name,
                },
              ]}
            />
          )}
        </DialogContent>
        <DialogActions>
          <ManagerPrimaryButton
            onClick={() => {
              if (selectedPrice.length) {
                updateMeter()
                setShowPriceEdit(false)
              }
            }}
            label={t("label.adjustPrices")}
          />
        </DialogActions>
      </Dialog>
    </Box>
  )
}
