import { useState } from "react"
import { DialogContent, DialogActions, Typography, Button } from "@mui/material"
import { useTranslation } from "react-i18next"
import { Form, Formik } from "formik"
import { useMutation } from "@tanstack/react-query"
import {
  MoveinWithNewParticipantCustomerRequest,
  ParticipantCustomerCreateRequest,
} from "../../../../../../data/generated-sources/openapi"
import DateUtils from "../../../../../../services/utils/DateUtils"
import { ParticipantRegisterSchema } from "../../../participants/ParticipantsValidation.Schema"

import { ErrorAlert } from "../../../../../../uikit/Shared.Alert"
import { ManagerPrimaryButton } from "../../../../../../uikit/button/ManagerButtons"
import { ParticipantCreateUpdateFormFields } from "../../../participants/components/ParticipantCreateUpdateFormFields"
import { initialParticipantValues } from "../../../../../../domain/participant/Participant.Model"
import { customerMoveinWithNewParticipant } from "../../../../../../domain/portal/manager/utilityUnit/UtilityUnit.Repository"
import { AxiosErrorDataType } from "../../../../../Shared.Utils"

interface ParticipantCreateAndMoveInFormProps {
  onClose: () => void
  onUpdateSuccess: () => void
  moveInDate: number
  participationId: string
  utilityUnitId: string
}

export const ParticipantCreateAndMoveInForm = ({
  moveInDate,
  participationId,
  utilityUnitId,
  onClose,
  onUpdateSuccess,
}: ParticipantCreateAndMoveInFormProps) => {
  const { t } = useTranslation("customer-participants")
  const [registerError, setRegisterError] = useState<AxiosErrorDataType>()

  const { mutate: moveInNewUser, isLoading } = useMutation(
    ["moveInNewUser", utilityUnitId],
    (createData: MoveinWithNewParticipantCustomerRequest) =>
      customerMoveinWithNewParticipant(utilityUnitId, participationId, createData),
    {
      onSuccess: onUpdateSuccess,
      onError: setRegisterError,
    },
  )

  const handleOnSubmit = (participant: ParticipantCustomerCreateRequest) => {
    moveInNewUser({
      moveinDate: DateUtils.getDeFormatDate(moveInDate),
      participant,
    })
  }

  return (
    <Formik<ParticipantCustomerCreateRequest>
      onSubmit={handleOnSubmit}
      initialValues={initialParticipantValues}
      validationSchema={ParticipantRegisterSchema}
    >
      {({ handleSubmit, dirty, isValid, ...rest }) => (
        <Form onSubmit={handleSubmit}>
          <DialogContent sx={{ px: 0 }}>
            <ErrorAlert
              visible={!!registerError}
              message={t(`error-codes:${registerError?.response?.data?.code || registerError?.code || "OTHER"}`)}
            />
            <Typography fontSize={24} fontWeight={400} mt={3} mb={5}>
              {t("onboarding:participantsInfo.title")}
            </Typography>
            <ParticipantCreateUpdateFormFields {...rest} />
          </DialogContent>
          <DialogActions sx={{ px: 0, justifyContent: "flex-start" }}>
            <Button variant="outlined" onClick={onClose} disabled={isLoading}>
              {t("shared:form.action.cancel")}
            </Button>
            <ManagerPrimaryButton
              onClick={() => handleSubmit()}
              isLoading={isLoading}
              label={t("registerForm.save")}
              disabled={!dirty || !isValid || isLoading}
            />
          </DialogActions>
        </Form>
      )}
    </Formik>
  )
}
