import { useTranslation } from "react-i18next"
import { useCallback, useEffect, useMemo, useState } from "react"
import { GridBaseColDef } from "@mui/x-data-grid/internals"
import { Box, Paper } from "@mui/material"
import { debounce } from "@mui/material/utils"
import {
  GridActionsColDef,
  GridRenderCellParams,
  GridRow,
  GridRowProps,
  GridSortModel,
  GridValueGetterParams,
} from "@mui/x-data-grid"
import { RouteComponentProps, useHistory } from "react-router-dom"
import { useQuery } from "@tanstack/react-query"
import { getAdminTasks } from "../../../../domain/portal/admin/tasks/Tasks.Repository"
import DateUtils from "../../../../services/utils/DateUtils"
import { AdminPortalRouteParams } from "../AdminPortal.Routes"
import { AxiosErrorDataType } from "../../../Shared.Utils"
import { Task, TaskStatusEnum, TaskTaskScopeTypeEnum } from "../../../../data/generated-sources/openapi"

import { SharedListHeader } from "../sharedListComponet/SharedListHeader.Component"
import { SwitchToggleView } from "../../../../uikit/toggle/SwitchToggleView"
import { DataGridTable } from "../../../../uikit/table/DataGridTable"
import { TaskListSyncIndicator } from "./TaskListSyncIndicator"
import { OpenButton } from "../../../../uikit/button/OpenButton"
import { StatusView } from "../../../../uikit/label/StatusView"
import { ErrorAlert } from "../../../../uikit/Shared.Alert"
import { TaskDetail } from "./TaskDetail"

const ContractAreaCell = ({ value }: GridRenderCellParams) => {
  const { taskScope, taskScopeType, taskScopeName } = value
  const history = useHistory()

  if ([TaskTaskScopeTypeEnum.AREA, TaskTaskScopeTypeEnum.CONTRACT].includes(taskScopeType)) {
    return (
      <OpenButton
        label={taskScopeName}
        open={() => {
          taskScopeType === TaskTaskScopeTypeEnum.AREA
            ? history.push(`/area/${taskScope}`)
            : history.push(`/management-contracts/${taskScope}`)
        }}
      />
    )
  }
  return <>-</>
}

const TaskListColumns = () => {
  const { t } = useTranslation("tasklist")
  const taskListColumns: Array<GridBaseColDef | GridActionsColDef> = [
    {
      flex: 50,
      field: "taskId",
      headerName: t("list.label.id"),
    },
    {
      flex: 100,
      field: "status",
      headerName: t("list.label.status"),
      renderCell: (params: GridRenderCellParams) => <StatusView statusType={params.row?.status} />,
    },
    {
      flex: 80,
      field: "createdAt",
      headerName: t("list.label.date"),
      renderCell: ({ value }) => DateUtils.getDeFormatDateWithMonthString(value),
    },
    {
      flex: 120,
      field: "type",
      headerName: t("list.label.event"),
      renderCell: ({ value }) => t(`state.${value}`),
    },
    {
      flex: 220,
      field: "taskScopeName",
      headerName: t("field.label.contract"),
      valueGetter: (params: GridValueGetterParams) => params.row,
      renderCell: ContractAreaCell,
    },
    {
      flex: 50,
      field: "participantSapSyncStatus",
      headerName: t("list.label.sync"),
      renderCell: (params: GridRenderCellParams) => (
        <TaskListSyncIndicator syncStatus={params?.row?.participantSapSyncStatus} />
      ),
    },
  ]
  return taskListColumns
}

export const TaskList = (props: RouteComponentProps<AdminPortalRouteParams>) => {
  const { t } = useTranslation("tasklist")
  const [taskError, setTaskError] = useState<AxiosErrorDataType>()
  const [showCompleted, setShowCompleted] = useState(false)
  const [expandedRowId, setExpandedRowId] = useState<string>("")
  const [filterQuery, setFilterQuery] = useState<string>("")
  const initialPagination = {
    page: 0,
    pageSize: 25,
  }
  const [paginationModel, setPaginationModel] = useState(initialPagination)
  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: "createdAt",
      sort: "desc",
    },
  ])

  const {
    data: taskList,
    isFetching,
    refetch,
  } = useQuery(
    ["getTasks"],
    () => {
      setTaskError(undefined)
      return getAdminTasks(
        paginationModel.page + 1,
        paginationModel.pageSize,
        sortModel?.[0] ? `${sortModel[0]?.sort}(${sortModel[0]?.field})` : "",
        showCompleted ? "DONE" + filterQuery : filterQuery,
      )
    },
    {
      onError: setTaskError,
    },
  )

  useEffect(() => {
    refetch()
  }, [paginationModel, filterQuery, showCompleted])

  const tasksArr = useMemo(() => {
    if (!showCompleted) {
      return taskList?.elements?.filter((task) => task.status !== TaskStatusEnum.DONE)
    }
    return taskList?.elements
  }, [showCompleted, taskList])

  const sortByColumn = (sorting: GridSortModel) => {
    setSortModel(sorting)
    setPaginationModel((prevState) => ({
      ...prevState,
      page: 0,
    }))
  }

  const handleExpandRow = (rowId: string) => {
    const sameRowId = expandedRowId === rowId
    const expandId = sameRowId ? "" : rowId
    setExpandedRowId(expandId)
  }

  const ExpandableTableRow = useCallback(
    (prop: GridRowProps) => {
      const { rowId, row } = prop
      return (
        <>
          <GridRow {...prop} key={rowId} />
          {rowId === expandedRowId && <TaskDetail {...(row as Task)} onCompleteTask={refetch} {...props} />}
        </>
      )
    },
    [expandedRowId],
  )

  return (
    <Paper sx={{ p: 2 }}>
      <ErrorAlert
        scrollOnDisplay
        visible={!!taskError}
        message={t(`error-codes:${taskError?.response?.data?.code || taskError?.code || "OTHER"}`)}
      />
      <SharedListHeader
        header={{
          title: t("list.title"),
          showButton: false,
          showFilter: true,
        }}
        setFilterQuery={debounce(setFilterQuery, 400)}
      />
      <Box pl={2}>
        <SwitchToggleView
          label={t("list.toggle.showcompleted")}
          checked={showCompleted}
          onChange={(checked) => {
            setPaginationModel(initialPagination)
            setShowCompleted(checked)
          }}
        />
      </Box>
      <DataGridTable<Task>
        autoHeight={false}
        rows={tasksArr || []}
        columns={TaskListColumns()}
        sortModel={sortModel}
        rowCount={taskList?.page.totalElements ?? 0}
        loading={isFetching}
        onSortModelChange={sortByColumn}
        paginationMode="server"
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        pageSizeOptions={[5, 10, 25, 50, 100]}
        onRowClick={({ row }) => handleExpandRow(row.id)}
        slots={{ row: ExpandableTableRow }}
        sx={{ cursor: "pointer" }}
      />
    </Paper>
  )
}
