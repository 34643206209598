import { Box, IconButton, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"
import { GridBaseColDef } from "@mui/x-data-grid/internals"
import { useCallback, useEffect, useState } from "react"
import { GridRenderCellParams, GridRow, GridRowProps, GridValueGetterParams } from "@mui/x-data-grid"
import { DataGridTable } from "../../../../uikit/table/DataGridTable"
import { VewaIndividualParticipationConsumptionResponse } from "../../../../data/generated-sources/openapi"
import { vewaParticipationColumns } from "./VewaBillingParticipatioins"
import { KeyboardArrowDownIcon, KeyboardArrowUpIcon } from "../../../../uikit/Shared.Icon"
import { FormatStringAsNumber } from "../../../../domain/Domain.Formatters"
import { VewaBillingAnalyse } from "./VewaBillingAnalyse"

export const VewaBillingAggregateConsumption = ({
  isLoading,
  participants,
  billStartDate,
  billEndDate,
}: {
  participants: VewaIndividualParticipationConsumptionResponse[]
  isLoading: boolean
  billStartDate: string
  billEndDate: string
}) => {
  const { t } = useTranslation("settlements")
  const [expandedRowId, setExpandedRowId] = useState<string>("")
  const [tableHeight, setTableHeight] = useState<number>(0)
  const [isPaginationChanged, setIsPaginationChanged] = useState<boolean>(false)

  useEffect(() => {
    if (!tableHeight || isPaginationChanged) {
      handleTableHeight()
    }
  }, [expandedRowId, isPaginationChanged])

  const handleTableHeight = () => {
    const tableRows = document.querySelectorAll(".data-grid-table .MuiDataGrid-row")
    const initTableHeight = tableRows[0]?.clientHeight
    const finalHeight = tableRows.length * initTableHeight
    setTableHeight(finalHeight ?? 0)
    setIsPaginationChanged(false)
  }

  const ArrowCell = useCallback(
    ({ value }: GridRenderCellParams) => {
      const sameRowId = expandedRowId === value
      const expandId = sameRowId ? "" : value
      return (
        <IconButton aria-label="expand row" size="small" onClick={() => setExpandedRowId(expandId)}>
          {sameRowId ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </IconButton>
      )
    },
    [expandedRowId],
  )
  const NumberCell = useCallback(
    ({ value }: GridRenderCellParams) => (
      <Typography fontSize={14} fontWeight={500} color="inherit">
        {FormatStringAsNumber(value)}
      </Typography>
    ),
    [],
  )

  const ExpandableTableRow = useCallback(
    (props: GridRowProps) => {
      const { rowId, index, row } = props
      const utilityUnitId = row?.utilityUnit?.id
      const startDate = row?.from
      const endDate = row?.to
      return (
        <>
          <GridRow {...props} key={rowId} />
          {rowId === expandedRowId && (
            <Box
              p={2}
              sx={{
                backgroundColor: index % 2 === 0 ? "#fff" : "#FAFAFB",
              }}
            >
              <VewaBillingAnalyse
                utilityUnitId={utilityUnitId}
                billFrom={billStartDate}
                billTo={billEndDate}
                endDate={endDate}
                startDate={startDate}
              />
            </Box>
          )}
        </>
      )
    },
    [expandedRowId],
  )

  const extendedColumns: Array<GridBaseColDef> = [
    ...vewaParticipationColumns(t),
    {
      field: "energyConsumption",
      headerName: "kWh",
      renderCell: NumberCell,
      flex: 70,
    },
    {
      field: "waterConsumption",
      headerName: "m³",
      renderCell: NumberCell,
      flex: 70,
    },
    {
      field: "expand",
      headerName: "",
      valueGetter: (params: GridValueGetterParams) => params.row?.participationId,
      renderCell: ArrowCell,
      flex: 40,
    },
  ]

  return (
    <Box>
      <Typography mb={4} fontSize={24} fontWeight={400}>
        {t("aggregateConsumption.title")}
      </Typography>
      <DataGridTable
        className={"data-grid-table"}
        autoHeight={false}
        loading={isLoading}
        rows={participants}
        columns={extendedColumns}
        getRowId={({ participationId }) => participationId}
        sx={{
          ".MuiDataGrid-cell--withRenderer": {
            outline: "none !important",
          },
          ".MuiDataGrid-virtualScrollerContent": {
            height: expandedRowId ? tableHeight + 530 + "px !important" : "auto",
          },
        }}
        slots={{
          row: ExpandableTableRow,
        }}
        getRowHeight={() => 64}
        onPaginationModelChange={() => {
          setExpandedRowId("")
          setIsPaginationChanged(true)
        }}
      />
    </Box>
  )
}
