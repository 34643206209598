import { useTranslation } from "react-i18next"
import { Box, Button, Grid, IconButton, Link, Stack, Typography, styled } from "@mui/material"
import { BackIcon, CancelRoundIcon, EditIcon } from "../../../../../uikit/Shared.Icon"
import { ManagerPrimaryButton } from "../../../../../uikit/button/ManagerButtons"
import { appThemeSecondaryColor } from "../../../../Shared.Theme"

export const OnboardingSteperInfo = ({
  stepNumber,
  title,
  subTitle,
  subHeader,
  linkText,
  linkUrl,
}: {
  stepNumber: string
  title: string
  subTitle?: string
  subHeader?: string
  linkText?: string
  linkUrl?: string
}) => {
  const { t } = useTranslation("onboarding")
  return (
    <Box mb={3}>
      <Typography pb={1} fontSize={13} fontWeight={700} textTransform={"uppercase"}>
        {`${t("header.step")} ${stepNumber}`}
      </Typography>
      <Typography pb={1.5} fontSize={23}>
        {title}
      </Typography>
      <Typography fontSize={16} fontWeight={400} mb={2}>
        {subHeader ? (
          subHeader
        ) : (
          <>
            {subTitle ? subTitle : t("header.subTitle1")}
            <Typography component={"span"} fontSize={16}>
              {t("header.subTitle2")}
            </Typography>
            {t("header.subTitle3")}
          </>
        )}
      </Typography>
      {linkText && linkUrl && (
        <Link
          component={"a"}
          fontSize={14}
          target="_blank"
          rel="noreferrer"
          href={`${linkUrl}`}
          sx={{ color: appThemeSecondaryColor, textDecoration: "none" }}
        >
          {linkText}
        </Link>
      )}
    </Box>
  )
}

export const OnboardingActionButtons = ({
  onBackClick,
  onNextClick,
  nextBtnText,
  nextBtnDisabled,
  isNextBtnLoading,
}: {
  onBackClick: () => void
  onNextClick: () => void
  nextBtnText?: string
  nextBtnDisabled?: boolean
  isNextBtnLoading?: boolean
}) => {
  const { t } = useTranslation("onboarding")
  return (
    <Stack flexDirection={"row"} my={6}>
      <Button variant="text" color="primary" onClick={onBackClick}>
        <BackIcon fontSize="small" />
        {t("shared:form.action.back")}
      </Button>
      <ManagerPrimaryButton
        label={nextBtnText ?? t("nextPage.button")}
        onClick={onNextClick}
        disabled={nextBtnDisabled}
        isLoading={isNextBtnLoading}
      />
    </Stack>
  )
}

export const FormContactHeader = ({
  editMode,
  headerText,
  onClick,
}: {
  editMode: boolean
  headerText: string
  onClick: () => void
}) => {
  return (
    <Grid item xs={12}>
      <Stack flexDirection={"row"} justifyContent={"flex-start"} alignItems={"center"} mb={2}>
        <Typography fontSize={16} fontWeight={700}>
          {headerText}
        </Typography>
        <IconButton onClick={onClick} color="primary">
          {editMode ? <CancelRoundIcon fontSize="small" /> : <EditIcon fontSize="small" />}
        </IconButton>
      </Stack>
    </Grid>
  )
}

export const FormEditWrapper = styled(Grid, {
  shouldForwardProp: (prop) => prop !== "editMode",
})<{ editMode: boolean }>(({ theme, editMode }) => ({
  padding: theme.spacing(2, 4),
  marginBottom: theme.spacing(editMode ? 2 : 0),
  marginLeft: theme.spacing(-4),
  marginRight: theme.spacing(-4),
  background: editMode ? theme.palette.background.editPrimary : "transparent",
  borderRadius: "0.5em",
}))

export const LabelTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontWeight: 400,
  fontSize: theme.typography.pxToRem(12),
  paddingBottom: theme.spacing(1),
}))

export const ValueTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontWeight: 400,
  fontSize: theme.typography.pxToRem(16),
  paddingBottom: theme.spacing(2),
}))
