import { useTranslation } from "react-i18next"
import { useEffect } from "react"
import { firstViewState } from "../../../Shared.Reducer"
import { ProgressIndicator } from "../../../../uikit/progress/ProgressIndicator"
import { coerce } from "../../../Shared.View"
import { StatusView } from "../../../../uikit/label/StatusView"
import { Grid, Paper, Stack } from "@mui/material"
import { LocationOn, EditIcon, TelephoneIconFilled, ContactIconFilled } from "../../../../uikit/Shared.Icon"
import { DataItemBox } from "../../../../uikit/box/DataItemBox"
import { MyProfileDetailState } from "./MyProfileDetail.Reducer"
import { mapDispatchToProps } from "./MyProfileDetail.Connect"
import { StatusType } from "../../../../domain/Domain.Model"
import { formatPhone, formatEmail } from "../../../../uikit/Shared.Formatters"
import { SuccessAlert } from "../../../../uikit/Shared.Alert"
import { SectionHeader } from "../profiles/form/ProfilesForm"
import { FabButton } from "../../../../uikit/button/FabButton"

interface MyProfileDetailComponentProps extends MyProfileDetailState, ReturnType<typeof mapDispatchToProps> {}

export const MyProfileDetailComponent = (props: MyProfileDetailComponentProps) => {
  const { t } = useTranslation("profiles")
  const { getMyProfileViewState, getMyProfile, navigateToMyProfileUpdate, showUpdateAlert } = props

  useEffect(() => {
    if (firstViewState(getMyProfileViewState)) {
      getMyProfile()
    }
  }, [getMyProfileViewState, getMyProfile])

  if (getMyProfileViewState.isLoading) return <ProgressIndicator />
  return (
    <>
      {showUpdateAlert && <SuccessAlert message={t("form.alert.success")} />}
      {coerce(getMyProfileViewState.domainResult, (myProfile) => (
        <Paper sx={{ p: 2 }}>
          <Grid container rowGap={4}>
            <Grid item container>
              <Grid item xs={12}>
                <Stack flexDirection={"row"} alignItems={"center"} justifyContent={"space-between"} mb={5}>
                  <StatusView statusType={StatusType.ACTIVE} />
                  <FabButton
                    size="medium"
                    icon={<EditIcon fontSize="medium" />}
                    onClick={() => navigateToMyProfileUpdate(myProfile.id)}
                  />
                </Stack>
              </Grid>
              <Grid item xs={12}>
                <SectionHeader title={t("form.title.user")} icon={<ContactIconFilled />} />
              </Grid>
              <Grid item xs={12} md={6}>
                <DataItemBox title={t("form.field.username")} value={myProfile.name} />
              </Grid>
              <Grid item xs={12} md={6}>
                <DataItemBox title={t("form.user.title")} value={myProfile.title} />
              </Grid>
            </Grid>

            <Grid item container>
              <Grid item xs={12}>
                <SectionHeader title={t("form.title.address")} icon={<LocationOn />} />
              </Grid>
              <Grid item xs={12} md={6}>
                <DataItemBox title={t("form.field.street")} value={myProfile.address} />
              </Grid>
              <Grid item xs={12} md={6}>
                <DataItemBox title={t("form.field.city")} value={myProfile.city} />
              </Grid>
            </Grid>

            <Grid item container>
              <Grid item xs={12}>
                <SectionHeader title={t("form.title.phoneDetails")} icon={<TelephoneIconFilled />} />
              </Grid>
              <Grid item xs={12} md={6}>
                <DataItemBox title={t("form.field.telephone")} value={formatPhone(myProfile.telephone)} />
              </Grid>
              <Grid item xs={12} md={6}>
                <DataItemBox title={t("form.field.mobile")} value={formatPhone(myProfile.mobile)} />
              </Grid>
              <Grid item xs={12} md={6}>
                <DataItemBox title={t("form.field.email")} value={formatEmail(myProfile.email)} />
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      ))}
    </>
  )
}
