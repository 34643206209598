import {
  AllParticipantBillingDetailsAdminResponse,
  BillDetails,
  IndividualParticipantBillingDetailsAdminResponse,
  ZevPricePackage,
} from "../../../../../data/generated-sources/openapi"

export enum ProductName {
  ZEV_COMFORT = "ZEV Comfort",
  ZEV_PREMIUM = "ZEV Premium",
  ZEV_PREMIUM_PLUS = "ZEV Premium+",
}

export interface ZevAdminAllParticipantBilling extends AllParticipantBillingDetailsAdminResponse {
  canCreateBilling: boolean
  hasCollectionFeature: boolean
  productName?: ProductName
  isBillingSuspended: boolean
}

export interface ZevAdminIndividualParticipantBilling extends IndividualParticipantBillingDetailsAdminResponse {
  canCreateBilling: boolean
  isBillingSuspended: boolean
  hasCollectionFeature: boolean
  productName?: ProductName
  bills?: BillDetails[]
  pricePackages: ZevPricePackage[]
}

export enum BillingStatusType {
  WAITING_FOR_DATA = "WAITING_FOR_DATA",
  DATA_AVAILABLE = "DATA_AVAILABLE",
  SUSPENDED = "SUSPENDED",
  IN_PROGRESS = "IN_PROGRESS",
  IN_PROGRESS_REOPENED = "IN_PROGRESS_REOPENED",
  DONE = "DONE",
  PAID = "PAID",
  DELETED = "DELETED",
}
