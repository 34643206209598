import { useTranslation } from "react-i18next"
import { TaskListIcon } from "../../../../uikit/Shared.Icon"
import { IconButton } from "@mui/material"
import { BreadcrumbsNextIcon, BreadcrumbsNavButton } from "./fragments/BreadcrumbsFragments"
import { useCallback, useEffect, useState } from "react"
import { Route, RouteComponentProps, Switch } from "react-router-dom"
import { useQuery } from "@tanstack/react-query"
import { getParticipantById } from "../../../../domain/participant/Participant.Repository"
import { AdminPortalRouteParams } from "../AdminPortal.Routes"
import { getParamsFromUrl } from "../../../Shared.Utils"

export const TasksBreadcrumb = ({ history }: RouteComponentProps<AdminPortalRouteParams>) => {
  const { t } = useTranslation("profiles")
  const { participantId } = getParamsFromUrl("/tasklist/participants/:participantId") ?? ""
  const [participantName, setParticipantName] = useState("")

  const { remove } = useQuery(["getParticipant"], () => getParticipantById(participantId), {
    enabled: !!participantId,
    onSuccess: (data) => {
      setParticipantName(`${data?.personalData?.firstName} ${data?.personalData?.lastName}`)
    },
  })

  useEffect(() => {
    return () => remove()
  }, [remove, participantId])

  const TaskListStartIcon = useCallback(() => {
    return (
      <>
        <IconButton
          onClick={() => history.push("/tasklist")}
          size="large"
          sx={{
            height: "0.8rem",
            "& img": {
              height: "0.8rem",
            },
          }}
        >
          <TaskListIcon />
        </IconButton>
        <BreadcrumbsNextIcon />
      </>
    )
  }, [history])

  return (
    <Switch>
      <Route path="/tasklist/participants/:participantId/update">
        <TaskListStartIcon />
        <BreadcrumbsNavButton
          label={participantName}
          onClick={() => history.push(`/tasklist/participants/${participantId}`)}
        />
        <BreadcrumbsNavButton disabled label={t("update.breadCrumb.title")} />
      </Route>

      <Route path="/tasklist/participants/:participantId">
        <TaskListStartIcon />
        <BreadcrumbsNavButton disabled label={participantName} />
      </Route>

      <Route path="/tasklist">
        <TaskListStartIcon />
        <BreadcrumbsNavButton disabled label={t("tasklist:list.title")} />
      </Route>
    </Switch>
  )
}
