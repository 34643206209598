import React, { useEffect, useState } from "react"
import { RouteComponentProps, withRouter } from "react-router-dom"
import { PaperBox } from "../../../../../uikit/page/PaperBox"
import { AdminPortalRouteParams } from "../../AdminPortal.Routes"
import { Stack } from "@mui/material"
import { useTranslation } from "react-i18next"
import { getProductForExistingContractById } from "../../../../../domain/portal/admin/mediu/Medium.Repository"
import { ErrorAlert } from "../../../../../uikit/Shared.Alert"
import { useQuery } from "@tanstack/react-query"
import { FullScreenLoader } from "../../../../../uikit/indicator/ProgressIndicator"
import { AxiosErrorDataType, useQueryDefaultOptions } from "../../../../Shared.Utils"
import { ProductType } from "../../../../../data/generated-sources/openapi"
import MediumZevComponent from "./components/MediumZev.Component"
import { EditIcon } from "../../../../../uikit/Shared.Icon"
import { FabButton } from "../../../../../uikit/button/FabButton"
import { FormSectionTitle } from "../../../../../uikit/form/FormView"
import { VewaCostSharesTabs } from "./components/VewaCostSharesTabs"

const MediumWrapperComponent = withRouter((props: RouteComponentProps<AdminPortalRouteParams>) => {
  const { history, match } = props
  const { t } = useTranslation("medium")
  const [error, setError] = useState<AxiosErrorDataType>()

  const {
    data: productData,
    isSuccess,
    isLoading,
    remove: productRemove,
  } = useQuery(["getProductFroExistingContract"], () => getProductForExistingContractById(match.params.id), {
    enabled: !!match.params.id,
    ...useQueryDefaultOptions,
    onError: setError,
  })

  useEffect(() => {
    return () => {
      productRemove()
    }
  }, [productRemove])

  const renderMediumShares = () => {
    switch (productData?.productType) {
      case ProductType.ZEV:
        return <MediumZevComponent contractId={match.params.id} />
      case ProductType.VEWA:
        return <VewaCostSharesTabs contractId={match.params.id} />
      default:
        return <ErrorAlert visible={true} message={t("error-codes:GENERIC_FATAL_ERROR")} />
    }
  }

  const renderEditButton = () => {
    if (!productData?.productType || !match.params.id) return null

    return (
      <FabButton
        size="medium"
        icon={<EditIcon />}
        onClick={() =>
          history.push(
            `/management-contracts/${match.params.id}/medium/${productData?.productType.toLowerCase()}/update`,
          )
        }
      />
    )
  }
  const sectionTitle = productData?.productType === ProductType.ZEV ? t("detail.title") : t("costShares.title")

  return (
    <>
      {isLoading && <FullScreenLoader />}
      <PaperBox sx={{ p: 2 }}>
        <Stack direction="row" justifyContent="space-between">
          <FormSectionTitle label={sectionTitle} />
          {renderEditButton()}
        </Stack>
        <ErrorAlert
          visible={!!error}
          message={t(`error-codes:${error?.response?.data?.code || error?.code || "OTHER"}`)}
        />
        {isSuccess && renderMediumShares()}
      </PaperBox>
    </>
  )
})

export default MediumWrapperComponent
