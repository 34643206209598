import { Form, Formik } from "formik"
import {
  Paper,
  IconButton,
  Stack,
  Typography,
  styled,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  DialogTitle,
} from "@mui/material"
import { useTranslation } from "react-i18next"
import * as yup from "yup"

import { getTranslateValue } from "../../../Shared.Utils"
import { ZevPricePackage } from "../../../../data/generated-sources/openapi"
import { SingleLineTextField } from "../../../../uikit/input/SingleLineTextField"
import { CURRENCY_ADORNMENT } from "../../../../uikit/input/CurrencyAdornment"
import { SwitchToggleView } from "../../../../uikit/toggle/SwitchToggleView"
import { ManagerPrimaryButton } from "../../../../uikit/button/ManagerButtons"
import CancelIcon from "@mui/icons-material/Cancel"

import { omit } from "lodash-es"
import { useState } from "react"
import { ExtendedZevPricePackage } from "../../manager/managerSettings/components/electricity/SettingsElectricityTab"

export const pricePackageSchema = yup.object().shape({
  name: yup
    .string()
    .trim()
    .required(() =>
      getTranslateValue("shared:validation.valid.number", {
        field: getTranslateValue("contracts-management:priceForm.name"),
      }),
    ),
  prices: yup.object().shape({
    solarPrice: yup.number().min(0).required(),
    highTariffPrice: yup.number().min(0).required(),
    lowTariffPrice: yup.number().min(0).required(),
    additionalServicesPrice: yup.number().min(0).required(),
    spikePrice: yup.number().min(0).nullable().optional(),
  }),
})

const PricePaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  maxWidth: "20rem",
  background: theme.palette.background.editPrimary,
}))

interface ElectricityPriceProps {
  pricePackage: ExtendedZevPricePackage
  isModifying: boolean
  onEditCancel: () => void
  onCloseNewPackage: () => void
  onDeletePackageConfirm: (pricePackageId: number) => void
  onCreatePricePackage: (updateData: ZevPricePackage) => void
  onUpdatePricePackage: (updateData: ZevPricePackage) => void
}

export const ElectricityPricePackageForm = ({
  pricePackage,
  isModifying,
  onEditCancel,
  onCloseNewPackage,
  onCreatePricePackage,
  onUpdatePricePackage,
  onDeletePackageConfirm,
}: ElectricityPriceProps) => {
  const { t } = useTranslation("contracts-management")
  const [showDeleteDialog, setShowDeleteDialog] = useState(false)

  const handleSubmit = (packagesData: ZevPricePackage) => {
    const pricePack = omit(packagesData, "hasSpikePrice")

    if (pricePackage?.newPackage) {
      const payload = omit(pricePack, "newPackage")
      onCreatePricePackage(payload)
    } else {
      onUpdatePricePackage(pricePack)
    }
  }

  return (
    <PricePaper elevation={0}>
      <Formik<ExtendedZevPricePackage>
        validateOnBlur
        validateOnChange
        enableReinitialize
        onSubmit={handleSubmit}
        initialValues={pricePackage}
        validationSchema={pricePackageSchema}
      >
        {({ values, errors, touched, isValid, dirty, handleBlur, setFieldValue, handleSubmit }) => {
          const touchedFileds = Object.keys(touched)
          const errorsFileds = Object.keys(errors?.prices || {})

          return (
            <Form onSubmit={handleSubmit}>
              <Stack spacing={4} alignItems={"flex-end"}>
                <IconButton
                  onClick={() => {
                    !values?.newPackage ? onEditCancel() : onCloseNewPackage()
                  }}
                >
                  <CancelIcon color="primary" />
                </IconButton>

                <SingleLineTextField
                  id="name"
                  name="name"
                  type="text"
                  label={t("priceForm.name")}
                  value={values.name}
                  onBlur={handleBlur}
                  onChange={({ target }) => setFieldValue("name", target.value)}
                  helperText={touched.name && errors.name ? errors.name : undefined}
                />
                <SingleLineTextField
                  id="solarPrice"
                  type="number"
                  name="solarPrice"
                  label={t("priceForm.solarPrice")}
                  value={values.prices?.solarPrice}
                  onBlur={handleBlur}
                  onChange={({ target }) => setFieldValue("prices.solarPrice", target.value)}
                  helperText={
                    touchedFileds.includes("solarPrice") && errorsFileds.includes("solarPrice")
                      ? getTranslateValue("shared:validation.valid.number")
                      : undefined
                  }
                  InputProps={CURRENCY_ADORNMENT}
                />
                <SingleLineTextField
                  type="number"
                  id="highTariffPrice"
                  name="highTariffPrice"
                  label={t("priceForm.highTariffPrice")}
                  value={values.prices?.highTariffPrice}
                  onBlur={handleBlur}
                  onChange={({ target }) => setFieldValue("prices.highTariffPrice", target.value)}
                  helperText={
                    touchedFileds.includes("highTariffPrice") && errorsFileds.includes("highTariffPrice")
                      ? getTranslateValue("shared:validation.valid.number")
                      : undefined
                  }
                  InputProps={CURRENCY_ADORNMENT}
                />
                <SingleLineTextField
                  type="number"
                  id="lowTariffPrice"
                  name="lowTariffPrice"
                  label={t("priceForm.lowTariffPrice")}
                  value={values.prices?.lowTariffPrice}
                  onBlur={handleBlur}
                  onChange={({ target }) => setFieldValue("prices.lowTariffPrice", target.value)}
                  helperText={
                    touchedFileds.includes("lowTariffPrice") && errorsFileds.includes("lowTariffPrice")
                      ? getTranslateValue("shared:validation.valid.number")
                      : undefined
                  }
                  InputProps={CURRENCY_ADORNMENT}
                />
                <Stack direction={"row"} alignItems="center" spacing={2} width="100%">
                  <Typography>{t("priceForm.consumptionDependant")}</Typography>
                  <SwitchToggleView
                    label={t("priceForm.flatRate")}
                    checked={values.prices?.isFixedRate}
                    onChange={(checked) => setFieldValue("prices.isFixedRate", checked)}
                  />
                </Stack>
                <SingleLineTextField
                  type="number"
                  id="additionalServicesPrice"
                  name="additionalServicesPrice"
                  label={values.prices?.isFixedRate ? t("priceForm.monthlyMeasurement") : t("priceForm.kwhMeasurement")}
                  value={values.prices?.additionalServicesPrice}
                  onBlur={handleBlur}
                  onChange={({ target }) => setFieldValue("prices.additionalServicesPrice", target.value)}
                  helperText={
                    touchedFileds.includes("additionalServicesPrice") &&
                    errorsFileds.includes("additionalServicesPrice")
                      ? getTranslateValue("shared:validation.valid.number")
                      : undefined
                  }
                  InputProps={CURRENCY_ADORNMENT}
                />
                <Stack direction={"row"} alignItems="center" spacing={2} width="100%">
                  <Typography>{t("priceForm.withoutSpike")}</Typography>
                  <SwitchToggleView
                    label={t("priceForm.withSpike")}
                    checked={values.hasSpikePrice}
                    onChange={(checked) => {
                      if (!checked) {
                        setFieldValue("prices.spikePrice", undefined)
                      }
                      setFieldValue("hasSpikePrice", checked)
                    }}
                  />
                </Stack>
                {values.hasSpikePrice && (
                  <SingleLineTextField
                    type="text"
                    id="spikePrice"
                    name="spikePrice"
                    label={t("priceForm.spikePrice")}
                    value={values.prices?.spikePrice}
                    onBlur={handleBlur}
                    onChange={({ target }) => setFieldValue("prices.spikePrice", target.value)}
                    helperText={
                      touchedFileds.includes("spikePrice") && errorsFileds.includes("spikePrice")
                        ? getTranslateValue("shared:validation.valid.number")
                        : undefined
                    }
                    InputProps={CURRENCY_ADORNMENT}
                  />
                )}
              </Stack>

              <Stack flexDirection={"row"} pt={6} alignItems={"center"} justifyContent={"space-between"}>
                <ManagerPrimaryButton
                  type="submit"
                  isLoading={isModifying}
                  label={t("shared:form.action.save")}
                  disabled={isModifying || !isValid || !dirty}
                />
                {values.id !== 0 && !values?.newPackage && (
                  <Button variant="outlined" onClick={() => setShowDeleteDialog(true)} disabled={isModifying}>
                    {t("shared:form.action.clear")}
                  </Button>
                )}
              </Stack>
            </Form>
          )
        }}
      </Formik>
      <Dialog open={showDeleteDialog} onClose={() => setShowDeleteDialog(false)} maxWidth="sm">
        <DialogTitle py={3}>{t("shared:dialog.confirm.title")}</DialogTitle>
        <DialogContent>
          <DialogContentText py={3}>{t("priceForm.dialog.subtitle")}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={() => setShowDeleteDialog(false)} disabled={isModifying}>
            {t("shared:form.action.cancel")}
          </Button>
          <ManagerPrimaryButton
            disabled={isModifying}
            isLoading={isModifying}
            onClick={() => onDeletePackageConfirm(pricePackage.id)}
            label={t("shared:form.action.clear")}
          />
        </DialogActions>
      </Dialog>
    </PricePaper>
  )
}
