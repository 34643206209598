import { useTranslation } from "react-i18next"
import { Route, RouteComponentProps, Switch } from "react-router-dom"
import { AdminPortalRouteParams } from "../AdminPortal.Routes"
import { IconButton } from "@mui/material"
import { BreadcrumbsNextIcon, BreadcrumbsNavButton } from "./fragments/BreadcrumbsFragments"
import { BillingIcon } from "../../../../uikit/Shared.Icon"

export const BillingsRecurringBreadcrumb = (props: RouteComponentProps<AdminPortalRouteParams>) => {
  const { t } = useTranslation("billings-recurring")
  const { history } = props

  return (
    <Switch>
      <Route path="/billings/recurring/create">
        <IconButton onClick={() => history.push("/billings/recurring")}>
          <BillingIcon color="primary" />
        </IconButton>
        <BreadcrumbsNextIcon />
        <BreadcrumbsNavButton disabled label={t("create.title")} />
      </Route>

      <Route path="/billings/recurring/:billingId">
        <IconButton onClick={() => history.push("/billings/recurring")}>
          <BillingIcon color="primary" />
        </IconButton>
        <BreadcrumbsNextIcon />
        <BreadcrumbsNavButton disabled label={t("detail.title")} />
      </Route>

      <Route path="/billings/recurring">
        <IconButton onClick={() => history.push("/billings/recurring")}>
          <BillingIcon color="primary" />
        </IconButton>
        <BreadcrumbsNextIcon />
        <BreadcrumbsNavButton disabled label={t("billingType.RECURRING")} />
      </Route>
    </Switch>
  )
}
