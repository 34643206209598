import { FormikErrors } from "formik"
import { TFunction } from "i18next"
import { ValidationDeps, validationResult, ValidField } from "../../../Shared.Validation"
import { ParticipantResponse } from "../../../../data/generated-sources/openapi"

export function validateParticipants(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  values: Pick<ParticipantResponse, any>,
  validationDeps: ValidationDeps,
  t: TFunction,
): FormikErrors<ParticipantResponse> {
  return validationResult({
    gpType: validateBusinessPartnerType(values.gpType, t),
    personalData: {
      salutation: validateSalutation(values.personalData.salutation, t),
      title: ValidField,
      firstName: validateFirstName(values.personalData.firstName, t),
      lastName: validateLastName(values.personalData.lastName, t),
      firstNameSecondPerson: ValidField,
      lastNameSecondPerson: ValidField,
    },
    contactDetails: {
      phone: validateContactTelephone(values?.contactDetails?.phone ?? "", validationDeps, t),
      mobile: validateContactMobile(values?.contactDetails?.mobile ?? "", validationDeps, t),
      email: validateContactEmail(values?.contactDetails?.email ?? "", validationDeps, t),
    },
    domicileAddress: {
      street: validateAddressStreet(values?.domicileAddress?.street ?? "", t),
      houseNumber: validateAddressHouseNumber(values?.domicileAddress?.houseNumber ?? "", t),
      co: ValidField,
      postBox: ValidField,
      postCode: validateAddressPostCode(values?.domicileAddress?.postCode ?? "", t),
      city: validateAddressCity(values?.domicileAddress?.city ?? "", t),
      country: validateAddressCountry(values?.domicileAddress?.land ?? "", t),
    },
  })
}

const validateBusinessPartnerType = (value: string, t: TFunction) => {
  if (!value) {
    return t("shared:validation.mandatory", {
      field: t("form.field.businessPartnerType"),
    })
  }
  return ValidField
}

const validateSalutation = (value: string, t: TFunction) => {
  if (!value) {
    return t("shared:validation.mandatory", {
      field: t("registerForm.salutation"),
    })
  }
  return ValidField
}

const validateFirstName = (value: string, t: TFunction) => {
  if (!value) {
    return t("shared:validation.mandatory", {
      field: t("registerForm.firstName"),
    })
  }
  return ValidField
}

const validateLastName = (value: string, t: TFunction) => {
  if (!value) {
    return t("shared:validation.mandatory", {
      field: t("registerForm.lastName"),
    })
  }
  return ValidField
}

const validateContactTelephone = (value: string, validationDeps: ValidationDeps, t: TFunction) => {
  if (value && !validationDeps.validSwissPhoneNumber(value)) {
    return t("shared:validation.valid.phoneNumber", {
      field: t("registerForm.phone"),
    })
  }
  return ValidField
}

const validateContactMobile = (value: string, validationDeps: ValidationDeps, t: TFunction) => {
  if (value && !validationDeps.validSwissMobile(value)) {
    return t("shared:validation.valid.mobileNumber", {
      field: t("registerForm.mobile"),
    })
  }
  return ValidField
}

const validateContactEmail = (value: string, validationDeps: ValidationDeps, t: TFunction) => {
  if (value && !validationDeps.validEmail(value)) {
    return t("shared:validation.valid.email", {
      field: t("registerForm.email"),
    })
  }
  return ValidField
}

const validateAddressStreet = (value: string, t: TFunction) => {
  if (!value) {
    return t("shared:validation.mandatory", {
      field: t("registerForm.street"),
    })
  }
  return ValidField
}

const validateAddressHouseNumber = (value: string, t: TFunction) => {
  if (!value) {
    return t("shared:validation.mandatory", {
      field: t("registerForm.houseNumber"),
    })
  }
  return ValidField
}

const validateAddressPostCode = (value: string, t: TFunction) => {
  if (!value) {
    return t("shared:validation.mandatory", {
      field: t("registerForm.postCode"),
    })
  } else if (value.length < 4) {
    return t("shared:validation.length", {
      field: t("registerForm.postCode"),
      length: 4,
    })
  }
  return ValidField
}

const validateAddressCity = (value: string, t: TFunction) => {
  if (!value) {
    return t("shared:validation.mandatory", {
      field: t("registerForm.city"),
    })
  }
  return ValidField
}

const validateAddressCountry = (value: string, t: TFunction) => {
  if (!value) {
    return t("shared:validation.mandatory", {
      field: t("registerForm.land"),
    })
  }
  return ValidField
}
