import { Button, styled, SvgIconProps, ButtonProps, Typography } from "@mui/material"
import { BreadcrumbIcon } from "../../../../../uikit/Shared.Icon"

interface BreadcrumbsButtonProps extends ButtonProps {
  label: string
}

const BreadcrumbsButton = styled(Button)(({ theme }) => ({
  fontWeight: 500,
  padding: 0,
  fontFamily: "Montserrat",
  textTransform: "uppercase",
  fontSize: theme.typography.pxToRem(12),
  color: theme.palette.primary.light,
  ".MuiTypography-root": {
    padding: 0,
    color: theme.palette.primary.light,
    fontSize: theme.typography.pxToRem(12),
  },
  "&.Mui-disabled": {
    ".MuiTypography-root": {
      color: theme.palette.secondary.dark,
    },
  },
}))

export const BreadcrumbsNextIcon = styled((props: SvgIconProps) => <BreadcrumbIcon fontSize="small" {...props} />)(
  ({ theme }) => ({
    color: theme.palette.primary.light,
    alignSelf: "center",
  }),
)

export const BreadcrumbsNavButton = ({ label, disabled, ...rest }: BreadcrumbsButtonProps) => (
  <>
    <BreadcrumbsButton disabled={disabled} {...rest}>
      <Typography noWrap>{label}</Typography>
    </BreadcrumbsButton>
    {!disabled && <BreadcrumbsNextIcon />}
  </>
)
