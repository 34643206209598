import { useHistory } from "react-router-dom"
import { PrimaryEditButton } from "./PrimaryEditButton"
import { Box } from "@mui/material"

interface RedirectButtonPropsInterface {
  redirectUrl: string
}

const RedirectButton = (props: RedirectButtonPropsInterface) => {
  const { redirectUrl } = props
  const history = useHistory()
  return (
    <Box sx={{ display: "flex", justifyContent: "end" }}>
      <PrimaryEditButton onClick={() => history.push(redirectUrl)} />
    </Box>
  )
}

export default RedirectButton
