import { ButtonTypeMap } from "@mui/material/Button"
import { DefaultComponentProps } from "@mui/material/OverridableComponent"
import { appThemePrimaryColor, appThemeSecondaryColor } from "../../app/Shared.Theme"
import { GradientButton } from "./GradientButton"
import { CircularProgress } from "@mui/material"

export interface PrimaryButtonProps {
  id?: string
  label: string
  isLoading?: boolean
  disabled?: boolean
}

export const PrimaryButton = (props: PrimaryButtonProps & DefaultComponentProps<ButtonTypeMap>) => {
  const { id, label, disabled, startIcon, endIcon, onClick, type, sx, isLoading } = props
  return (
    <GradientButton
      id={id}
      sx={sx}
      startColor={appThemePrimaryColor}
      endColor={appThemeSecondaryColor}
      type={type}
      label={label}
      disabled={disabled}
      startIcon={isLoading ? <CircularProgress color="primary" size={20} /> : startIcon}
      endIcon={endIcon}
      onClick={onClick}
    />
  )
}
