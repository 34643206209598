import { useTranslation } from "react-i18next"
import { Route, RouteComponentProps, Switch } from "react-router-dom"
import { useCallback, useEffect } from "react"
import { IconButton } from "@mui/material"
import { useQuery } from "@tanstack/react-query"

import { getParamsFromUrl } from "../../../Shared.Utils"
import { AdminPortalRouteParams } from "../AdminPortal.Routes"
import { getParticipantById } from "../../../../domain/participant/Participant.Repository"
import { getOnlyProfileById } from "../../../../domain/portal/admin/profiles/Profiles.Repository"
import { getContractManagementById } from "../../../../domain/portal/admin/contracts/Contracts.Repository"
import { BreadcrumbsNextIcon, BreadcrumbsNavButton } from "./fragments/BreadcrumbsFragments"
import { DocumentIcon } from "../../../../uikit/Shared.Icon"

export const ContractsBreadcrumb = ({ history }: RouteComponentProps<AdminPortalRouteParams>) => {
  const { t } = useTranslation("contracts-management")
  const contractUrlParams = getParamsFromUrl("/management-contracts/:id") || ""
  const { profileId } = getParamsFromUrl("/management-contracts/:id/profiles/:profileId") || ""
  const { participantId } = getParamsFromUrl("/management-contracts/:id/participants/:participantId") || ""

  const { data: contractData, remove: removeContract } = useQuery(
    ["getContract"],
    () => getContractManagementById(contractUrlParams?.id),
    {
      enabled: !!contractUrlParams?.id,
    },
  )

  const { data: profileData, remove: removeProfile } = useQuery(["getProfile"], () => getOnlyProfileById(profileId), {
    enabled: !!profileId,
  })

  const { data: participantData, remove: removeParticipant } = useQuery(
    ["getParticipant"],
    () => getParticipantById(participantId),
    {
      enabled: !!participantId,
    },
  )

  useEffect(() => {
    return () => {
      removeProfile()
      removeContract()
      removeParticipant()
    }
  }, [removeProfile, removeContract, removeParticipant, participantId, profileId, contractUrlParams?.id])

  const ContractsStartIcon = useCallback(() => {
    return (
      <>
        <IconButton onClick={() => history.push("/management-contracts")}>
          <DocumentIcon />
        </IconButton>
        <BreadcrumbsNextIcon />
      </>
    )
  }, [history])

  const ContractListIcon = useCallback(() => {
    return (
      <>
        <ContractsStartIcon />
        <BreadcrumbsNavButton
          label={contractData?.name || "-"}
          onClick={() => history.push(`/management-contracts/${contractUrlParams?.id}`)}
        />
      </>
    )
  }, [history, ContractsStartIcon, contractData, contractUrlParams?.id])

  return (
    <Switch>
      <Route path="/management-contracts/:id/participants/:participantId/update">
        <ContractsStartIcon />
        <BreadcrumbsNavButton
          label={contractData?.name || t("breadcrumbs.contract")}
          onClick={() => history.push(`/management-contracts/${contractUrlParams?.id}`)}
        />
        <BreadcrumbsNavButton
          label={`${participantData?.personalData?.firstName} ${participantData?.personalData?.lastName}`}
          onClick={() => history.push(`/management-contracts/${contractUrlParams?.id}/participants/${participantId}`)}
        />
        <BreadcrumbsNavButton disabled label={t("customer-participants:participant.edit")} />
      </Route>

      <Route path="/management-contracts/:id/participants/:participantId">
        <ContractListIcon />
        <BreadcrumbsNavButton
          disabled
          label={`${participantData?.personalData?.firstName} ${participantData?.personalData?.lastName}`}
        />
      </Route>

      <Route path="/management-contracts/:id/profiles/:profileId/update">
        <ContractsStartIcon />
        <BreadcrumbsNavButton
          label={contractData?.name || "-"}
          onClick={() => history.push(`/management-contracts/${contractUrlParams?.id}`)}
        />
        <BreadcrumbsNavButton
          label={`${profileData?.data?.personal?.firstName} ${profileData?.data?.personal?.lastName}`}
          onClick={() => history.push(`/management-contracts/${contractUrlParams?.id}/profiles/${profileId}`)}
        />
        <BreadcrumbsNavButton disabled label={t("customer-participants:participant.edit")} />
      </Route>

      <Route path="/management-contracts/:id/profiles/:profileId">
        <ContractListIcon />
        <BreadcrumbsNavButton
          disabled
          label={`${profileData?.data?.personal?.firstName} ${profileData?.data?.personal?.lastName}`}
        />
      </Route>

      <Route path="/management-contracts/:id/area/:areaId/participants/create">
        <ContractListIcon />
        <BreadcrumbsNavButton disabled label={t("area:breadcrumb.participantCreate")} />
      </Route>

      <Route exact path="/management-contracts/:id/billings/vewa/:billingId/validate">
        <ContractListIcon />
        <BreadcrumbsNavButton disabled label={t("breadcrumbs.createBill")} />
      </Route>

      <Route exact path="/management-contracts/:id/billings/vewa/:billingId/bills">
        <ContractListIcon />
        <BreadcrumbsNavButton disabled label={t("breadcrumbs.bills")} />
      </Route>

      <Route path="/management-contracts/:id/billings/all/">
        <ContractListIcon />
        <BreadcrumbsNavButton disabled label={t("breadcrumbs.allParticipantBillings")} />
      </Route>

      <Route path="/management-contracts/:id/billings/individual/">
        <ContractListIcon />
        <BreadcrumbsNavButton disabled label={t("breadcrumbs.individualParticipantBillings")} />
      </Route>

      <Route path="/management-contracts/:id/master-data/update">
        <ContractListIcon />
        <BreadcrumbsNavButton disabled label={t("breadcrumbs.contract.edit")} />
      </Route>

      <Route path="/management-contracts/:id/contact/update">
        <ContractListIcon />
        <BreadcrumbsNavButton disabled label={t("breadcrumbs.contact.edit")} />
      </Route>

      <Route path="/management-contracts/:id/managers/update">
        <ContractListIcon />
        <BreadcrumbsNavButton disabled label={t("breadcrumbs.managers.edit")} />
      </Route>

      <Route path="/management-contracts/:id/building/update">
        <ContractListIcon />
        <BreadcrumbsNavButton disabled label={t("breadcrumbs.building.edit")} />
      </Route>

      <Route path="/management-contracts/:id/medium/zev/update">
        <ContractListIcon />
        <BreadcrumbsNavButton disabled label={t("breadcrumbs.medium.edit")} />
      </Route>

      <Route path="/management-contracts/:id/medium/vewa/update">
        <ContractListIcon />
        <BreadcrumbsNavButton disabled label={t("breadcrumbs.medium.edit")} />
      </Route>

      <Route path="/management-contracts/:id/billing/update">
        <ContractListIcon />
        <BreadcrumbsNavButton disabled label={t("breadcrumbs.billing.edit")} />
      </Route>

      <Route path="/management-contracts/:id/prices/update">
        <ContractListIcon />
        <BreadcrumbsNavButton disabled label={t("breadcrumbs.prices.edit")} />
      </Route>

      <Route path="/management-contracts/:id/meters/activate">
        <ContractListIcon />
        <BreadcrumbsNavButton disabled label={t("breadcrumbs.meters.activate")} />
      </Route>

      <Route path="/management-contracts/:id">
        <ContractsStartIcon />
        <BreadcrumbsNavButton
          label={contractData?.areaName || t("shared:label.area")}
          onClick={() => history.push(`/area/${contractData?.areaId}`)}
        />
        <BreadcrumbsNavButton disabled label={contractData?.name || "-"} />
      </Route>

      <Route path="/management-contracts" exact>
        <ContractsStartIcon />
        <BreadcrumbsNavButton disabled label={t("breadcrumbs.contracts")} />
      </Route>
    </Switch>
  )
}
