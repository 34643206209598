import { FullScreenLoader } from "../../../../../uikit/indicator/ProgressIndicator"
import { Grid, Stack } from "@mui/material"
import { useTranslation } from "react-i18next"
import { PaperBox } from "../../../../../uikit/page/PaperBox"
import { SelectPicker } from "../../../../../uikit/input/SelectPicker"
import { useEffect, useState } from "react"
import { EditServiceBillingPropsInterface, pricingModelsTypeValues } from "./ServiceBillingModels"
import { CancelButton } from "../../../../../uikit/button/CancelButton"
import { PrimaryButtonLoading } from "../../../../../uikit/button/PrimaryButtonLoading"
import { SaveIcon } from "../../../../../uikit/Shared.Icon"
import { useMutation, useQuery } from "@tanstack/react-query"
import { RouteComponentProps, withRouter } from "react-router-dom"
import { AdminPortalRouteParams } from "../../AdminPortal.Routes"
import {
  adminGetServiceBillingByContractId,
  adminUpdateServiceBillingByContractId,
} from "../../../../../domain/portal/admin/contracts/Contracts.Repository"
import { useQueryDefaultOptions } from "../../../../Shared.Utils"
import { ErrorAlert } from "../../../../../uikit/Shared.Alert"
import { PricingModel } from "../../../../../data/generated-sources/openapi"

export const EditServiceBilling = withRouter(
  (props: RouteComponentProps<AdminPortalRouteParams> & EditServiceBillingPropsInterface) => {
    const { history, match } = props
    const contractId = match.params.id
    const { t } = useTranslation("contracts-management")
    const [selectedPricingModel, setSelectedPricingModel] = useState<PricingModel>(PricingModel.RENTAL)

    const {
      isError,
      remove,
      isLoading: loadingServiceBilling,
    } = useQuery(["adminGetServiceBillingModel"], () => adminGetServiceBillingByContractId(contractId), {
      enabled: !!contractId,
      ...useQueryDefaultOptions,
      onSuccess: (data) => {
        setSelectedPricingModel(data.data.pricingModel as PricingModel)
      },
    })

    const {
      mutate,
      isLoading,
      isError: updateIsError,
      reset,
    } = useMutation(
      ["updateContractBuildingsList"],
      () => adminUpdateServiceBillingByContractId(contractId, selectedPricingModel),
      {
        onSuccess: () => history.push(`/management-contracts/${contractId}`),
      },
    )

    useEffect(
      () => () => {
        reset()
        remove()
      },
      [],
    )

    return (
      <>
        <PaperBox sx={{ p: 2, mt: 3 }}>
          {(isLoading || loadingServiceBilling) && <FullScreenLoader />}
          <ErrorAlert visible={updateIsError || isError} message={t("error-codes:GENERIC_FATAL_ERROR")} />
          <Grid item xs={4} mt={2}>
            <SelectPicker
              name="medium"
              type="text"
              label={t("serviceBilling.labelPriceModel")}
              value={selectedPricingModel}
              items={pricingModelsTypeValues.map((type) => ({
                label: t(`serviceBilling.${type}`),
                value: type,
              }))}
              onChange={(event) => setSelectedPricingModel(event.target.value as PricingModel)}
            />
          </Grid>

          <Stack direction={"row"} alignItems="center" justifyContent="space-between" mt={5}>
            <CancelButton onClick={() => history.goBack()} />
            <PrimaryButtonLoading
              isLoading={isLoading}
              disabled={isLoading}
              startIcon={<SaveIcon />}
              onClick={() => mutate()}
              label={t("serviceBilling.updatePriceModel")}
            />
          </Stack>
        </PaperBox>
      </>
    )
  },
)
