import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import DateUtils from "../../../../services/utils/DateUtils"
import { Medium, MeterReadingsCalcResolution } from "../../../../data/generated-sources/openapi"
import { ChartTabsEnum } from "./Analise.Enum"
import { useQuery } from "@tanstack/react-query"
import { isAdminPortal, useQueryDefaultOptions } from "../../../Shared.Utils"
import { Box } from "@mui/material"
import { ErrorAlert } from "../../../../uikit/Shared.Alert"
import { getBarChartColors, getSolarBarChartColors, getTabList } from "./charts/chart.config"
import { ContractAnalysisPropsInterface, TabItemModel } from "./Analise.Model"
import AnaliseContractWrapComponent from "./AnaliseContractWrap.Component"
import { AnaliseDailyWrapComponent } from "./AnaliseDailyWrap.Component"
import { adminGetContractAnalyse, customerGetContractAnalyse } from "../../../../domain/analyse/Analyse.Repository"
import { FullScreenLoader } from "../../../../uikit/indicator/ProgressIndicator"

const ContractAnalysis = (props: ContractAnalysisPropsInterface) => {
  const { contractId, contractProductType } = props
  const { t } = useTranslation()
  const [timestamp, setTimestamp] = useState(DateUtils.getNowTimestamp())
  const [startDate, setStartDate] = useState(DateUtils.getFirstDayOfMonth(timestamp))
  const [endDate, setEndDate] = useState(DateUtils.getLastDayOfMonth(timestamp))
  const [selectedDateRange, setSelectedDateRange] = useState(MeterReadingsCalcResolution.MONTH)
  const [selectedTab, setSelectedTab] = useState<string>("")
  const [chartData, setChartData] = useState<unknown>()
  const [heatWaterTotals, setHeatWaterTotals] = useState<unknown>()
  const [isContentLoaded, setIsContentLoaded] = useState(false)
  const { data, isLoading, isError, isSuccess, isRefetching, isFetchedAfterMount, remove, refetch } = useQuery(
    ["getContractAnalise"],
    () =>
      isAdminPortal()
        ? adminGetContractAnalyse(contractId, startDate, endDate, formatDateRange() as MeterReadingsCalcResolution)
        : customerGetContractAnalyse(contractId, startDate, endDate, formatDateRange() as MeterReadingsCalcResolution),
    {
      enabled: !!contractId,
      ...useQueryDefaultOptions,
    },
  )

  useEffect(() => {
    if (startDate && endDate && selectedDateRange && selectedTab.length) getAnalyseData()
  }, [startDate, endDate, selectedTab, selectedDateRange, contractId])

  useEffect(() => {
    if (startDate && endDate && selectedDateRange && timestamp) {
      setStartDate(getStartDate(timestamp) as string)
      setEndDate(getEndDate(timestamp) as string)
    }
  }, [selectedDateRange, startDate, timestamp, endDate, contractId])

  useEffect(() => {
    return () => {
      remove()
    }
  }, [remove])

  useEffect(() => {
    setSelectedTab("")
    setIsContentLoaded(false)
  }, [contractId])

  const getAnalyseData = () => {
    refetch()
  }

  useEffect(() => {
    const tabs: TabItemModel[] = getTabList(data)
    !selectedTab.length && tabs && tabs?.length && setSelectedTab(tabs[0]?.value as string)
  }, [data, contractId])

  useEffect(() => {
    if (selectedTab.length) getFormattedHeatData()
  }, [selectedTab, data, contractId])

  const getDateRange = (range: MeterReadingsCalcResolution) => {
    setSelectedDateRange(range)
  }

  const getTimeRange = (date: number) => {
    setIsContentLoaded(true)
    setTimestamp(date)
    setStartDate(getStartDate(date) as string)
    setEndDate(getEndDate(date) as string)
  }

  const getStartDate = (date: number): string | undefined => {
    switch (selectedDateRange) {
      case MeterReadingsCalcResolution.WEEK:
        return DateUtils.getFirstDayOfWeek(date)
      case MeterReadingsCalcResolution.MONTH:
        return DateUtils.getFirstDayOfMonth(date)
      case MeterReadingsCalcResolution.YEAR:
        return DateUtils.getFirstDayOfYear(date)
    }
  }

  const getEndDate = (date: number): string | undefined => {
    switch (selectedDateRange) {
      case MeterReadingsCalcResolution.WEEK:
        return DateUtils.getLastDayOfWeek(date)
      case MeterReadingsCalcResolution.MONTH:
        return DateUtils.getLastDayOfMonth(date)
      case MeterReadingsCalcResolution.YEAR:
        return DateUtils.getLastDayOfYear(date)
    }
  }

  const formatDateRange = (): string | undefined => {
    switch (selectedDateRange) {
      case MeterReadingsCalcResolution.WEEK:
      case MeterReadingsCalcResolution.MONTH:
        return MeterReadingsCalcResolution.DAY as string
      case MeterReadingsCalcResolution.YEAR:
        return MeterReadingsCalcResolution.MONTH as string
    }
  }

  const getFormattedHeatData = () => {
    const total1 = data?.vewa?.data[selectedTab === Medium.HEAT ? Medium.HEAT : Medium.WARM_WATER]
    const total2 = data?.vewa?.data[selectedTab === Medium.HEAT ? Medium.COLD : Medium.COLD_WATER]
    // eslint-disable-next-line
    const combinedConsumption: any = {}

    if (total1) {
      // eslint-disable-next-line no-unsafe-optional-chaining
      for (const item of total1?.data) {
        const dateTime = item.dateTime
        const consumption = parseFloat(item.consumption)

        combinedConsumption[dateTime] = { produced: consumption }
      }
    }

    if (total2) {
      // eslint-disable-next-line no-unsafe-optional-chaining
      for (const item of total2?.data) {
        const dateTime = item.dateTime
        const consumption = parseFloat(item.consumption)

        if (combinedConsumption[dateTime]) {
          combinedConsumption[dateTime].energyHighTariff = consumption
        } else {
          combinedConsumption[dateTime] = { energyHighTariff: consumption }
        }
      }
    }

    const combinedArray = Object.keys(combinedConsumption).map((dateTime) => {
      return {
        dateTime,
        ...combinedConsumption[dateTime],
      }
    })

    setChartData(combinedArray)
    setHeatWaterTotals({
      total1: total1?.totalConsumption ?? 0,
      total2: total2?.totalConsumption ?? 0,
    })
  }

  const mapZevData = () => {
    const remapData = {
      totalConsumption:
        parseFloat(data?.zev?.totalSumProduction ?? "0") +
        parseFloat(data?.zev?.totalSumEnergyHighTariff ?? "0") +
        parseFloat(data?.zev?.totalSumEnergyLowTariff ?? "0"),
      totalProduced: parseFloat(data?.zev?.totalSumProduction ?? "0"),
      totalEnergyHighTariff: parseFloat(data?.zev?.totalSumEnergyHighTariff ?? "0"),
      totalEnergyLowTariff: parseFloat(data?.zev?.totalSumEnergyLowTariff ?? "0"),
      data: data?.zev?.data.map((item) => {
        return {
          ...item,
          produced: item.sumProduction,
          energyHighTariff: item.sumEnergyHighTariff,
          energyLowTariff: item.sumEnergyLowTariff,
          consumption: item.cpSum,
        }
      }),
      resolution: data?.zev?.resolution,
    }

    return remapData
  }

  const mapSolarZevData = () => {
    if (!data?.zev) return null
    const remapSolarData = {
      totalConsumption: Number(data?.zev?.totalProduction),
      totalProduced: data?.zev?.totalSumProduction,
      totalEnergyHighTariff: data?.zev?.totalSold,
      data: data?.zev?.data.map((item) => {
        return {
          ...item,
          produced: item.sold,
          energyHighTariff: item.sumProduction,
          consumption: item.cpSum,
        }
      }),
      resolution: data?.zev?.resolution,
    }

    return remapSolarData
  }

  const isRefetched = () => {
    if (isContentLoaded) return true
    return isSuccess && isFetchedAfterMount && !isRefetching
  }

  return (
    <>
      {(isLoading || isRefetching) && !isContentLoaded ? <FullScreenLoader /> : null}
      <Box>
        <ErrorAlert visible={isError} message={t("error-codes:GENERIC_FATAL_ERROR")} />
      </Box>
      {isRefetched() && (
        <AnaliseContractWrapComponent
          handleTimeChange={getTimeRange}
          handleDateChange={getDateRange}
          headerTabs={getTabList(data)}
          handleSelectTab={setSelectedTab}
          meterReadingDateRange={selectedDateRange}
          barColors={getBarChartColors(selectedTab, t)}
          solarBarColors={getSolarBarChartColors(selectedTab, t)}
          isLoading={isRefetching}
          selectedTab={selectedTab}
          isVewa={contractProductType === "VEWA"}
          analiseData={
            selectedTab === ChartTabsEnum.ELECTRICITY ? mapZevData() : { totals: heatWaterTotals, data: chartData }
          }
          solarAnaliseData={mapSolarZevData()}
        />
      )}
      <AnaliseDailyWrapComponent contractId={contractId} contractProductType={contractProductType} />
    </>
  )
}

export default ContractAnalysis
