import { Grid, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"
import { ConsumptionScope, UsageType, VewaCostShare } from "../../../../data/generated-sources/openapi"
import { VewaCostSharePercentage, VewaCostShareTitle } from "./fragments/CostShareFragments"

interface VewaCostShareDataProps {
  vewaCostSharesMap?: Map<ConsumptionScope, VewaCostShare[]>
}

export const VewaCostSharesData = ({ vewaCostSharesMap }: VewaCostShareDataProps) => {
  const { t } = useTranslation("medium")

  return (
    <Grid container>
      {Array.from(vewaCostSharesMap || []).map(([consumptionScope, value]) => {
        return (
          <Grid container item key={consumptionScope}>
            <Grid item xs={12}>
              {(consumptionScope === ConsumptionScope.ROOM_HEATING ||
                consumptionScope === ConsumptionScope.WATER_HEATING) && (
                <Typography fontSize={16} fontWeight={500} mb={1.25}>
                  {t(`label.${consumptionScope}`)}
                </Typography>
              )}
            </Grid>

            {value.map((cost) => (
              <Grid container sm={6} xs={12} item key={cost.type.usageType} mb={4}>
                <Grid item xs={12}>
                  <Typography fontSize={14} fontWeight={500} mb={1.25}>
                    {cost.type.usageType === UsageType.RESIDENCE
                      ? t("label.residentalUnits")
                      : t("label.commercialUnits")}
                  </Typography>
                </Grid>

                <Grid item sm={12} md={5} pr={2} display={"grid"} alignItems={"baseline"}>
                  <VewaCostShareTitle>{t("label.basicCost")}</VewaCostShareTitle>
                  <VewaCostSharePercentage>{cost.costStructure.basicCosts}%</VewaCostSharePercentage>
                </Grid>

                <Grid item sm={12} md={7} pr={2}>
                  <VewaCostShareTitle>{t("label.consumptionBasedCost")}</VewaCostShareTitle>
                  <VewaCostSharePercentage>{cost.costStructure.consumptionBasedCosts}%</VewaCostSharePercentage>
                </Grid>
              </Grid>
            ))}
          </Grid>
        )
      })}
    </Grid>
  )
}
