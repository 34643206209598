import { Paper, TableContainer } from "@mui/material"
import { useQuery } from "@tanstack/react-query"
import { AxiosError, AxiosResponse } from "axios"
import { useEffect, useState } from "react"
import { useAppDispatch } from "../../../hooks"
import { useHistory } from "react-router-dom"

import { getDataFromApi } from "../../../../domain/portal/admin/sharedApiService/SharedApiService.Repository"
import { SharedListHeader } from "./SharedListHeader.Component"
import { DEFAULT_ROWS_PER_PAGE } from "../../../../uikit/Shared.Consts"
import { TableFixed } from "../../../../uikit/table/Table.Fixed"
import { TableColumnSortNew, TableHeaderView } from "../../../../uikit/table/Table.HeaderView"
import { PageRowSlice, TablePaginationView } from "../../../../uikit/table/Table.PaginationView"
import { TableRowViewNew } from "../../../../uikit/table/Table.RowView"
import {
  ColumnEnumInterface,
  SharedListComponentConfigInterface,
} from "../sharedComponentsConfig/Components.Interfaces"
import { Page } from "../../../../data/generated-sources/openapi"
import { useQueryDefaultOptions } from "../../../Shared.Utils"

interface SharedListProps {
  configNew: SharedListComponentConfigInterface
  additionalRouteQueryParams?: ColumnEnumInterface
  onHeaderBtnClick?: () => void
  onHeaderDownloadBtnClick?: () => void
  queryParam?: string
  getParamFromUrl?: () => string
}

const SharedListComponent = (props: SharedListProps) => {
  const {
    configNew,
    queryParam,
    onHeaderBtnClick,
    onHeaderDownloadBtnClick,
    getParamFromUrl,
    additionalRouteQueryParams,
  } = props
  const [filterQuery, setFilterQuery] = useState<string>("")
  const [meters, setMeters] = useState([])
  const [pageData, setPageData] = useState<Page>({
    currentPage: configNew.apiDefaultParams.page,
    limit: configNew.apiDefaultParams.limit,
    totalPages: 1,
    totalElements: 0,
  })
  const dispatch = useAppDispatch()
  const history = useHistory()
  const [pageRowSlice, setPageRowSlice] = useState<PageRowSlice>({
    start: 0,
    end: configNew?.defaultRowsPerPage ?? DEFAULT_ROWS_PER_PAGE,
    page: pageData?.currentPage,
  })

  const [orderBy, setOrderBy] = useState<TableColumnSortNew>({
    column: configNew.defaultSorting.column,
    direction: "desc",
  })
  const apiResponse = useQuery<AxiosResponse, AxiosError>(
    [configNew.name],
    () => {
      if (configNew?.isUsingMockData) {
        return {}
      }

      if (configNew.getDataFromRepository) {
        const queryId = queryParam ?? (getParamFromUrl && getParamFromUrl()) ?? ""
        return configNew.getDataFromRepository(queryId).then((response) => {
          return {
            data: {
              ...response.data,
              elements: [...response.data.elements],
            },
          }
        })
      }

      return getDataFromApi(
        configNew.name,
        pageData?.currentPage,
        pageData?.limit,
        configNew.apiDefaultParams.orderBy,
        queryParam,
        getParamFromUrl,
      )
    },
    useQueryDefaultOptions,
  )
  useEffect(() => {
    if (configNew.dispatchEvent) {
      dispatch(configNew.dispatchEvent)
    }
  }, [configNew.dispatchEvent, dispatch])

  useEffect(() => {
    return () => {
      apiResponse.remove()
    }
  }, [])

  useEffect(() => {
    if (apiResponse.isFetched && !apiResponse.isPreviousData) {
      setMeters(apiResponse?.data?.data?.elements)
      setPageData(apiResponse?.data?.data?.page)
    }
  }, [apiResponse])

  const mockDataLayout = () => {
    return Object.keys(configNew.columnEnums).map((key: string, index: number) => (
      <td key={index}>{configNew.columnEnums[key]} - mock data</td>
    ))
  }

  const handleHeaderBtnClick = () => {
    if (onHeaderBtnClick) {
      onHeaderBtnClick()
    }
  }

  const handleDownloadBtnClick = () => {
    if (onHeaderDownloadBtnClick) {
      onHeaderDownloadBtnClick()
    }
  }

  return (
    <>
      <Paper sx={{ p: 1, mb: 3 }}>
        <SharedListHeader
          {...configNew}
          additionalRouteQueryParams={additionalRouteQueryParams}
          onClick={handleHeaderBtnClick}
          onDownloadClick={handleDownloadBtnClick}
          filterQuery={filterQuery}
          setFilterQuery={setFilterQuery}
        />
        <TableContainer>
          <TableFixed>
            <TableHeaderView
              isLoading={apiResponse.isLoading}
              headers={configNew.tableHeaders}
              isUsingTranslate={true}
              orderBy={orderBy}
              orderByChanged={(orderBy) => {
                setOrderBy(orderBy)
                setMeters(meters.sort(configNew.columnComparator(orderBy)))
              }}
            />
            {configNew.isUsingMockData ? (
              <>
                <tr onClick={() => history.push(`${configNew.name}/123456`)}>{mockDataLayout()}</tr>
              </>
            ) : (
              <TableRowViewNew
                colSpan={4}
                rows={meters || []}
                pageRowSlice={pageRowSlice}
                filterQuery={filterQuery}
                usingBackendPagination={false}
                render={(item) => configNew.renderTableRows(item)}
              />
            )}
          </TableFixed>
          <TablePaginationView
            rowCount={pageData?.totalElements}
            onPageRowSliceChanged={setPageRowSlice}
            defaultRowsPerPage={configNew?.defaultRowsPerPage ?? DEFAULT_ROWS_PER_PAGE}
          />
        </TableContainer>
      </Paper>
    </>
  )
}

export default SharedListComponent
