import { FullScreenLoader } from "../../../../../uikit/indicator/ProgressIndicator"
import { Grid, Paper } from "@mui/material"
import { isAdminPortal, useQueryDefaultOptions } from "../../../../Shared.Utils"
import { FormSectionTitle } from "../../../../../uikit/form/FormView"
import React, { useEffect } from "react"
import { useQuery } from "@tanstack/react-query"
import { adminGetServiceBillingByContractId } from "../../../../../domain/portal/admin/contracts/Contracts.Repository"
import { ErrorAlert } from "../../../../../uikit/Shared.Alert"
import { useTranslation } from "react-i18next"
import { DataItemBoxNewDesign } from "../../../../../uikit/box/DataItemBox"
import RedirectButton from "../../../../../uikit/button/RedirectButton"

export const ServiceBilling = (props: { contractId: string }) => {
  const { contractId } = props
  const { t } = useTranslation("contracts-management")
  const { data, isLoading, isSuccess, isError, remove } = useQuery(
    ["adminGetServiceBilling"],
    () => adminGetServiceBillingByContractId(contractId),
    {
      enabled: !!contractId,
      ...useQueryDefaultOptions,
    },
  )

  useEffect(() => () => remove(), [])

  return (
    <>
      {isLoading ? <FullScreenLoader /> : null}
      <Paper
        style={{
          boxShadow: isAdminPortal() ? "indigo" : "none",
        }}
      >
        <Grid container>
          {isError ? (
            <Grid item md={12} p={3}>
              <ErrorAlert visible={isError && !isLoading} message={t("error-codes:OTHER")} />
            </Grid>
          ) : null}
          <Grid item md={10} p={3}>
            <FormSectionTitle label={t("serviceBilling.title")} />
          </Grid>
          <Grid item md={2} pr={3} pt={5}>
            <RedirectButton redirectUrl={`${location.pathname}/service-billing/update`} />
          </Grid>
          {isSuccess ? (
            <Grid item md={12} pl={3} pb={3}>
              <DataItemBoxNewDesign
                title={t("serviceBilling.pricingModel")}
                value={t(`serviceBilling.${data?.data.pricingModel}`)}
              />
            </Grid>
          ) : null}
        </Grid>
      </Paper>
    </>
  )
}
