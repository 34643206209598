import { Body3 } from "../../../../../uikit/typography/Typography"
import { DomicileAddress } from "../../../../../data/generated-sources/openapi"
import { useTranslation } from "react-i18next"

const ParticipantDetailDomicileAddress = (props: DomicileAddress) => {
  const { t } = useTranslation("customer-participants")
  const { street, houseNumber, co, poBox, postCode, city, land } = props

  return (
    <>
      <Body3 mt={3}>
        {street} {houseNumber}
      </Body3>
      {co && (
        <Body3 mt={2}>
          {t("registerForm.co")} {co}
        </Body3>
      )}
      {poBox && <Body3 mt={2}>{poBox}</Body3>}
      <Body3 mt={2}>
        {postCode} {city}
      </Body3>
      <Body3 mt={2}>{land}</Body3>
    </>
  )
}

export default ParticipantDetailDomicileAddress
