import TableRow from "@mui/material/TableRow"
import TableBody from "@mui/material/TableBody"
import { PageRowSlice } from "./Table.PaginationView"
import { HasChildren } from "../Shared.Prop"
import { useTranslation } from "react-i18next"
import { TableCell } from "@mui/material"
import { styled } from "@mui/material/styles"
import { SxProps, Theme } from "@mui/system"
import { getTranslateValue } from "../../app/Shared.Utils"

export interface TableData {
  id: string | number
}

export type TableComparator<T extends TableData> = (a: T, b: T) => number
export type TableComparatorNew = (a: string, b: string) => number

interface TableRowViewPropsNew<T extends TableData> {
  id?: string
  rows: T[]
  pageRowSlice: PageRowSlice
  render: (data: T) => React.ReactNode
  comparator?: () => TableComparatorNew
  filterQuery?: string
  colSpan?: number
  usingBackendPagination?: boolean
}

interface SimpleTableRowViewProps<T extends TableData> {
  tableId?: string
  rows: T[] | undefined
  render: (data: T) => React.ReactNode
}

interface TableRowViewProps<T extends TableData> {
  id?: string
  rows: T[]
  pageRowSlice: PageRowSlice
  render: (data: T) => React.ReactNode
  comparator?: () => TableComparator<T>
  filterQuery?: string
  colSpan?: number
  usingBackendPagination?: boolean
}

export const StripedRow = styled(TableRow)(() => ({
  minHeight: "72px",
  "&:nth-of-type(even)": {
    backgroundColor: "#fafafb",
  },
  "& > td": {
    borderBottom: "none",
  },
}))

export function TableRowViewNew<T extends TableData>(props: TableRowViewPropsNew<T>) {
  const { t } = useTranslation()
  const { id, rows, render, pageRowSlice, filterQuery, colSpan, usingBackendPagination } = props
  let items = rows?.filter((row) => rowsFilter<T>(row, filterQuery?.trim()))

  if (!usingBackendPagination) {
    items = items.slice(pageRowSlice.start, pageRowSlice.end)
  }

  return (
    <>
      {items.length === 0 && (
        <TableBody>
          <StripedRow>
            <TableCell colSpan={colSpan} align="center">
              <em>{t("shared:label.empty.list")}</em>
            </TableCell>
          </StripedRow>
        </TableBody>
      )}
      {items.length > 0 && (
        <TableBody
          sx={{
            td: { padding: "16px", borderBottom: "none" },
            tr: { "&:nth-of-type(even)": { backgroundColor: "#fafafb" } },
          }}
          id={id}
        >
          {items.map((row) => render(row))}
        </TableBody>
      )}
    </>
  )
}
export function TableRowView<T extends TableData>(props: TableRowViewProps<T>) {
  const { t } = useTranslation()
  const { id, rows, render, comparator, pageRowSlice, filterQuery, colSpan, usingBackendPagination } = props
  let items = rows.filter((row) => rowsFilter<T>(row, filterQuery?.trim())).sort(comparator && comparator())

  if (!usingBackendPagination) {
    items = items.slice(pageRowSlice.start, pageRowSlice.end)
  }

  return (
    <>
      {items.length === 0 && (
        <TableBody>
          <StripedRow>
            <TableCell colSpan={colSpan} align="center">
              <em>{t("shared:label.empty.list")}</em>
            </TableCell>
          </StripedRow>
        </TableBody>
      )}
      {items.length > 0 && (
        <TableBody
          sx={{
            td: { padding: "16px", borderBottom: "none" },
            tr: { "&:nth-of-type(even)": { backgroundColor: "#fafafb" } },
          }}
          id={id}
        >
          {items.map((row) => render(row))}
        </TableBody>
      )}
    </>
  )
}

interface TableRowClickableProps<T extends TableData> {
  rowData: T
  rowClick: (item: T) => void
  rowClickable?: boolean
  styleOverride?: SxProps<Theme>
}

export function TableRowClickable<T extends TableData>(props: TableRowClickableProps<T> & HasChildren) {
  const { rowData, rowClick, children, rowClickable = true, styleOverride } = props
  return (
    <StripedRow
      sx={{
        cursor: rowClickable ? "pointer" : "default",
        "&:hover": {
          backgroundColor: rowClickable ? "#FBFCE2 !important" : "transparent",
        },
        ...styleOverride,
      }}
      onClick={() => (rowClickable ? rowClick(rowData) : undefined)}
    >
      {children}
    </StripedRow>
  )
}

function rowsFilter<T extends TableData>(row: T, query?: string) {
  if (!query) return true
  const queryString = query.toLowerCase()

  return Object.values(row).some((entry) => {
    if (typeof entry === "string")
      return (
        entry.toLowerCase().includes(queryString) ||
        getTranslateValue(`shared:status.${entry}`).toLowerCase().includes(queryString)
      )
    return false
  })
}

export function SimpleTableRowView<T extends TableData>(props: SimpleTableRowViewProps<T>) {
  const { t } = useTranslation()
  const { tableId, rows, render } = props

  return (
    <>
      {!rows?.length ? (
        <TableBody>
          <StripedRow>
            <TableCell colSpan={4} align="center">
              <em>{t("shared:label.empty.list")}</em>
            </TableCell>
          </StripedRow>
        </TableBody>
      ) : (
        <TableBody
          sx={{
            td: { padding: "16px", borderBottom: "1px solid #f3f3f3" },
          }}
          id={tableId}
        >
          {rows.map((row) => render(row))}
        </TableBody>
      )}
    </>
  )
}
