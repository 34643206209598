import { Box, Stack } from "@mui/material"
import { SingleLineTextField } from "../../../../uikit/input/SingleLineTextField"
import { DataGridRedirectRowTable } from "../../../../uikit/table/DataGridTable"
import { BuildingAdminResponse, UtilityUnitResponse } from "../../../../data/generated-sources/openapi"
import { t } from "i18next"
import { useEffect, useState } from "react"
import { RouteComponentProps } from "react-router"
import { ManagerPortalRoutesProps } from "../ManagerPortal.Routes"
import { AxiosErrorDataType, filterArrayBySearchInput } from "../../../Shared.Utils"
import { useQuery } from "@tanstack/react-query"
import { UtilityUnitTableColumnsConfig } from "./UtilityUnitsTableColumnsConfig"
import { HeaderCardsWrapper, IHeaderCard } from "../commonComponents/HeaderCardsWrapper/HeaderCardsWrapper"
import { customerGetBuildingsByAreaId } from "../../../../domain/portal/manager/buildings/Buildings.Repository"
import { customerGetUtilityUnitsByBuildingId } from "../../../../domain/portal/manager/utilityUnit/UtilityUnit.Repository"
import { ErrorAlert } from "../../../../uikit/Shared.Alert"
import { ApartmentIcon } from "../../../../uikit/Shared.Icon"

const UtilityUnitLists = ({ match: { params } }: RouteComponentProps<ManagerPortalRoutesProps>) => {
  const { areaId } = params
  const [filterQuery, setFilterQuery] = useState<string>("")
  const [selectedBuildingId, setSelectedBuildingId] = useState<string>("")
  const [error, setError] = useState<AxiosErrorDataType>()
  const [buildingCards, setBuildingCards] = useState<IHeaderCard[]>([])
  const [utilityUnitList, setUtilityUnitList] = useState<UtilityUnitResponse[]>([])

  const {
    isLoading: isLoadingBuilding,
    isFetching: isFetchingBuilding,
    remove: removeBuildings,
  } = useQuery(["getAreaBuildings", areaId], () => customerGetBuildingsByAreaId(areaId), {
    enabled: !!areaId,
    onError: setError,
    onSuccess: (data: BuildingAdminResponse[]) => {
      setError(undefined)
      mapBuildingCardsData(data)
    },
  })

  const mapBuildingCardsData = (buildings: BuildingAdminResponse[]) => {
    setSelectedBuildingId(buildings[0]?.id)
    const buildingsData = buildings?.map((building) => {
      return {
        id: building.id,
        title: building.name,
        descriptions: [
          `${building.address.street} ${building.address.houseNumber}`,
          `${building.address.postalCode} ${building.address.city}`,
        ],
        productChangeDate: "",
        icon: <ApartmentIcon fontSize="large" />,
      }
    })
    setBuildingCards(buildingsData)
  }

  const {
    isLoading: isLoadingUnits,
    isFetching: isFetchingUnits,
    data: unitsData,
    remove: removeUnits,
    refetch,
  } = useQuery(
    ["getUnitsForBuilding", selectedBuildingId],
    () => customerGetUtilityUnitsByBuildingId(selectedBuildingId),
    {
      enabled: !!selectedBuildingId,
      onError: (error: AxiosErrorDataType) => {
        setError(error)
        setUtilityUnitList([])
      },
      onSuccess: (data: UtilityUnitResponse[]) => setUtilityUnitList(data),
    },
  )

  useEffect(() => {
    selectedBuildingId && refetch()
  }, [selectedBuildingId, refetch])

  useEffect(() => {
    return () => {
      removeUnits()
      removeBuildings()
    }
  }, [removeUnits, removeBuildings])

  const filterData = (searchInput: string) => {
    if (unitsData) {
      const filtered = filterArrayBySearchInput<UtilityUnitResponse>(unitsData, searchInput)
      setUtilityUnitList(filtered)
    }
  }

  const isLoadingData: boolean = (isLoadingBuilding && isFetchingBuilding) || (isLoadingUnits && isFetchingUnits)

  return (
    <>
      <HeaderCardsWrapper
        cards={buildingCards}
        selectedId={selectedBuildingId}
        onCardSelect={({ id }) => setSelectedBuildingId(id)}
      />
      <Box p={5} pt={6}>
        <ErrorAlert
          visible={!!error}
          message={t(`error-codes:${error?.response?.data?.code || error?.code || "OTHER"}`)}
        />
        <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"} pb={5}>
          <SingleLineTextField
            autoFocus={false}
            id="filter"
            name="filter"
            type="text"
            sx={{ width: "24rem" }}
            label={t("shared:form.search.label")}
            value={filterQuery}
            onChange={(event) => {
              setFilterQuery(event.target.value)
              filterData(event.target.value)
            }}
          />
        </Stack>
        <DataGridRedirectRowTable<UtilityUnitResponse>
          rows={utilityUnitList}
          columns={UtilityUnitTableColumnsConfig(t)}
          loading={isLoadingData}
          redirectParamName={"id"}
          redirectUrl={location.pathname}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 25,
              },
            },
          }}
        />
      </Box>
    </>
  )
}

export default UtilityUnitLists
