/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState, MouseEvent } from "react"
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridEventListener,
  DataGridProps,
  GridComparatorFn,
  GridPagination,
} from "@mui/x-data-grid"
import { useHistory } from "react-router-dom"
import { styled, Typography, Stack, LinearProgress, Popover } from "@mui/material"
import ArrowForwardIcon from "@mui/icons-material/ArrowForward"
import { getTranslateValue } from "../../app/Shared.Utils"

const RedirectStack = styled(Stack)(({ theme }) => ({
  alignItems: "center",
  justifyContent: "center",
  color: theme.palette.primary.dark,
  background: "rgba(241, 249, 232, 0.8)",
}))

export const EmptyTableRow = () => (
  <Typography color="inherit" p={1}>
    {getTranslateValue("shared:label.empty.list")}
  </Typography>
)

export const LightCell = ({ value }: GridRenderCellParams) => <Typography fontWeight={400}>{value}</Typography>

export const chfMoneyLabelComparator: GridComparatorFn<string> = (v1, v2) => {
  return parseFloat(v1) > parseFloat(v2) ? 1 : -1
}

export const dateSortComparator: GridComparatorFn<Date> = (v1, v2) => (v1 > v2 ? 1 : -1)

interface RowData<T> {
  [key: string | number | symbol]: any
}

interface DataGridTableProps<T> extends DataGridProps {
  rows: readonly T[]
  columns: GridColDef[]
  redirectUrl?: string
  redirectDescription?: string
  redirectParamName?: string
  onRowClickHandle?: (id: string) => void
}

export const CustomPagination = (props: any) => <GridPagination {...props} labelRowsPerPage="Zeilen pro Seite:" />

export const defaultDataTableSlots = {
  loadingOverlay: LinearProgress,
  noRowsOverlay: EmptyTableRow,
  pagination: CustomPagination,
}

export const DataGridTable = <T extends RowData<T>>({ rows, columns, ...props }: DataGridTableProps<T>) => {
  return (
    <DataGrid<T>
      rows={rows}
      columns={columns}
      autoHeight
      rowHeight={64}
      disableColumnMenu
      disableColumnFilter
      disableRowSelectionOnClick
      hideFooterSelectedRowCount
      hideFooter={!rows?.length}
      sortingOrder={["asc", "desc"]}
      pageSizeOptions={[5, 10, 25, 50]}
      {...props}
      initialState={{
        pagination: {
          paginationModel: {
            pageSize: 10,
          },
        },
        ...props.initialState,
      }}
      slots={{ ...defaultDataTableSlots, ...props.slots }}
    />
  )
}

export const DataGridRedirectRowTable = <T extends RowData<T>>({
  rows,
  columns,
  redirectDescription,
  redirectParamName,
  redirectUrl,
  onRowClickHandle,
  ...props
}: DataGridTableProps<T>) => {
  const history = useHistory()
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const open = Boolean(anchorEl)

  const handlePopoverOpen = (event: MouseEvent<HTMLElement>) => {
    const target = event.currentTarget as HTMLElement

    const canNotRedirect = target.classList.contains("RowNotClickable")
    if (!canNotRedirect) {
      setAnchorEl(event.currentTarget)
    }
  }
  const handlePopoverClose = () => setAnchorEl(null)

  const handleRowClick: GridEventListener<"rowClick"> = (params) => {
    if (onRowClickHandle) {
      return onRowClickHandle(params.row[redirectParamName ?? "id"])
    }
    const goTo = `${redirectUrl ?? ""}/${params.row[redirectParamName ?? "id"]}`
    history.push(goTo)
  }

  return (
    <>
      <DataGridTable<T>
        rows={rows}
        columns={columns}
        onRowClick={handleRowClick}
        {...props}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 5,
            },
          },
          ...props.initialState,
        }}
        sx={{ cursor: "pointer", ...props.sx }}
        slotProps={{
          row: {
            onMouseEnter: handlePopoverOpen,
            onMouseLeave: handlePopoverClose,
            onWheel: handlePopoverClose,
          },
          ...props.slotProps,
        }}
      />
      <Popover
        sx={{ pointerEvents: "none" }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
        elevation={0}
        disablePortal
        disableScrollLock
        keepMounted={false}
        hideBackdrop
        slotProps={{
          paper: {
            sx: {
              paddingLeft: 2,
              marginLeft: -3,
              background: "rgba(241, 249, 232, 0.6)",
            },
          },
        }}
      >
        <RedirectStack direction={"row"} spacing={1} p={0.5}>
          <Typography color="inherit" fontSize={14}>
            {redirectDescription ?? getTranslateValue("shared:link.goHere")}
          </Typography>
          <ArrowForwardIcon fontSize="small" color="inherit" />
        </RedirectStack>
      </Popover>
    </>
  )
}
